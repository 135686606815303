import alooma from 'services/alooma';
import trackFacebookEvent from 'functions/trackFacebookEvent';
import trackGoogleTagEvent from 'functions/trackGoogleTagEvent';

export default async data => {
  trackFacebookEvent('CompleteRegistration');
  alooma.UNSAFE_trackAloomaEvent('guest_reg', alooma.formRegistrationData(data));

  await trackGoogleTagEvent('signup');
};
