import { call, put } from 'redux-saga/effects';
import { getQueryParams } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { processReferralLink } from 'functions/redirectLink';
import { ITEMS } from 'consts/localStorage';
import createGuestUser from 'functions/authentication/createGuestUser';
import CConsole from 'functions/CConsole';
import * as userActions from '../actions';

function* initDemoFlowHandler() {
  const { token, uidToken, xuid } = getQueryParams([QUERY_PARAMS.token, QUERY_PARAMS.uidToken, QUERY_PARAMS.xuid]);

  try {
    yield call(processReferralLink);

    if (token && uidToken) {
      yield put(
        userActions.loginWithToken({
          data: { t: token, ut: uidToken },
        }),
      );

      return;
    }

    if (xuid) {
      localStorage.setItem(ITEMS.xuid, xuid);
    }

    if (!localStorage.getItem(ITEMS.token)) {
      yield call(createGuestUser);
    }

    if (window.location.href.includes('https://www.karmanow.com')) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  } catch (error) {
    CConsole.error(error);
  } finally {
    yield put(userActions.fetchUser());
  }
}

export default initDemoFlowHandler;
