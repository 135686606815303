import walletSlice from './slice';

export const {
  addPayout,
  fetchEarnings,
  fetchPayouts,
  fetchMoreEarnings,
  fetchMorePayouts,
  saveEarnings,
  savePayouts,
  saveMoreEarnings,
  saveMorePayouts,
  setEarningsFilter,
  setPayoutsFilter,
} = walletSlice.actions;
