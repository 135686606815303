import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const PlayIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 49 48"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4992 4.09922C13.5088 4.09922 4.59922 13.0088 4.59922 23.9992C4.59922 34.9897 13.5088 43.8992 24.4992 43.8992C35.4897 43.8992 44.3992 34.9897 44.3992 23.9992C44.3992 13.0088 35.4897 4.09922 24.4992 4.09922ZM1.19922 23.9992C1.19922 11.131 11.631 0.699219 24.4992 0.699219C37.3675 0.699219 47.7992 11.131 47.7992 23.9992C47.7992 36.8675 37.3675 47.2992 24.4992 47.2992C11.631 47.2992 1.19922 36.8675 1.19922 23.9992Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7355 24.0206L21.922 19.5408L21.9491 28.5474L29.7355 24.0206ZM33.5407 25.7412C34.8717 24.9674 34.8659 23.0429 33.5303 22.2771L21.5094 15.3851C20.1738 14.6193 18.51 15.5866 18.5147 17.1262L18.5565 30.9826C18.5611 32.5221 20.2307 33.4794 21.5617 32.7055L33.5407 25.7412Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PlayIcon;
