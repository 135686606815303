import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAction from 'hooks/useAction';
import { changeFilter } from 'store/filters/actions';
import { getCurrentTagListsWithDetails } from 'functions/listsFunctions';
import { FAVORITES_LIST_OPTION, FULFILLED_LIST_OPTION } from 'consts/lists';
import { PATHS } from 'consts/paths';
import { ListTag, ListTagsWrapper } from './ModalProductLists.style';

const ModalProductLists = ({ closeModal, tag }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const currentLists = useMemo(() => getCurrentTagListsWithDetails(tag), [tag]);

  const toggleValueInFilter = useAction(changeFilter);

  const moveToMyItemPage = value => {
    closeModal();

    if (value === FAVORITES_LIST_OPTION.id) {
      toggleValueInFilter({ filterKey: FAVORITES_LIST_OPTION.id, filterValue: true, withReset: true });
    } else if (value === FULFILLED_LIST_OPTION.id) {
      toggleValueInFilter({ filterKey: FULFILLED_LIST_OPTION.id, filterValue: true, withReset: true });
    } else {
      toggleValueInFilter({ filterKey: 'lists', filterValue: value, withReset: true });
    }

    if (!location.pathname.includes(PATHS.ITEMS)) history.push(PATHS.ITEMS);
  };

  if (!currentLists.length) return null;

  return (
    <ListTagsWrapper>
      {currentLists.map(item => (
        <ListTag key={item.id} onClick={() => moveToMyItemPage(item.id)}>
          #{item.enableTranslation ? t(item.name) : item.name}
        </ListTag>
      ))}
    </ListTagsWrapper>
  );
};

export default ModalProductLists;
