import React from 'react';
import { IGridProps } from './Grid.types';
import { GridContainer } from './Grid.styles';

const Grid: React.FC<IGridProps> = ({
  children,
  style,
  columns,
  columnGap,
  rowGap,
  gap,
  gridColumnStart,
  gridColumnEnd,
  fullWidth,
  ...props
}) => (
  <GridContainer
    style={style}
    columns={columns}
    columnGap={columnGap}
    rowGap={rowGap}
    gap={gap}
    gridColumnStart={gridColumnStart}
    gridColumnEnd={gridColumnEnd}
    fullWidth={fullWidth}
    {...props}
  >
    {children}
  </GridContainer>
);

export default Grid;
