import styled, { css } from 'styled-components';
import { LabelMediumStyles } from '../Typography/Typography.styles';
import omitForwardedProps from '../utils/omitForwardedProps';
import { InputLabelRootProps } from './InputLabel.types';

export const InputLabelRoot = styled.label.withConfig(
  omitForwardedProps<InputLabelRootProps>(['shrink', 'disabled'])
)<InputLabelRootProps>`
  ${LabelMediumStyles};

  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  user-select: none;

  transition-property: max-width, transform, color;
  transition-duration: 0.15s;
  transition-delay: 0.05s;
  transition-timing-function: ease-in-out;

  color: ${({ shrink, disabled, theme }) => {
    const colors = theme[theme.color].InputLabel;

    if (disabled) return colors.disabled;

    return shrink ? colors.active : colors.normal;
  }};

  ${({ shrink }) =>
    shrink
      ? css`
          max-width: 110%;
          transform: translate(17px, 2px) scale(0.75);
          font-weight: 400;
        `
      : css`
          transform: translate(17px, 23px) scale(1);
          max-width: calc(100% - 32px);
        `};
`;
