import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CouponIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10156 3.88962H21.3266C22.9006 3.88962 24.1766 5.16561 24.1766 6.73962V17.7771C24.1766 19.3511 22.9006 20.6271 21.3266 20.6271H1.10156V15.2658L1.95156 15.2658C3.38209 15.2658 4.65736 13.9858 4.65736 12.2584C4.65736 10.5309 3.38209 9.25093 1.95156 9.25092L1.10156 9.25092V3.88962ZM2.80156 5.58962V7.63726C4.86907 8.06445 6.35736 10.0213 6.35736 12.2584C6.35736 14.4954 4.86907 16.4523 2.80156 16.8795V18.9271H21.3266C21.9617 18.9271 22.4766 18.4122 22.4766 17.7771V6.73962C22.4766 6.10449 21.9617 5.58962 21.3266 5.58962H2.80156Z"
        fill="currentColor"
      />
      <path
        d="M19.5016 12.2584C19.5016 13.18 18.7544 13.9271 17.8328 13.9271C16.9112 13.9271 16.1641 13.18 16.1641 12.2584C16.1641 11.3368 16.9112 10.5896 17.8328 10.5896C18.7544 10.5896 19.5016 11.3368 19.5016 12.2584Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CouponIcon;
