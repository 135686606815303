import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Checkbox } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import { PATHS } from 'consts/paths';
import Alooma from 'services/alooma';
import { AgreementV3, AgreementLinkV3 } from './TermsAndPrivacyAgreement.style';

const TermsAndPrivacyAgreement = ({ checked, onChangeHandler, error }) => {
  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <AgreementV3>
      <Checkbox
        error={!!error}
        helperText={error}
        value={checked}
        onChange={onChangeHandler}
        id="terms-and-privacy-agreement"
        name="terms-and-privacy-agreement"
        label={
          <Trans i18nKey="app:termsAgreementWithCheckbox">
            I agree to &nbsp;
            <AgreementLinkV3
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.TERMS}
              as={Link}
              target="_blank"
              to={PATHS.TERMS}
            >
              Terms and Conditions
            </AgreementLinkV3>
            &nbsp;and&nbsp;
            <AgreementLinkV3
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.PRIVACY}
              as={Link}
              target="_blank"
              to={PATHS.PRIVACY}
            >
              Privacy Policy
            </AgreementLinkV3>
          </Trans>
        }
      />
    </AgreementV3>
  );
};

TermsAndPrivacyAgreement.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChangeHandler: PropTypes.func,
};

TermsAndPrivacyAgreement.defaultProps = {
  checked: false,
  error: false,
  onChangeHandler: () => null,
};

export default TermsAndPrivacyAgreement;
