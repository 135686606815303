import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featured: {
    loaded: false,
    retailers: [],
  },
  cashback: {
    loaded: false,
    retailers: [],
  },
  trending: {
    loaded: false,
    retailers: [],
  },
  loving: {
    loaded: false,
    retailers: [],
  },
};

export const retailersGroupsSlice = createSlice({
  name: 'retailersGroups',
  initialState,
  reducers: {
    receiveRetailersGroups: (state, action) => {
      const { container, retailers } = action.payload;

      return {
        ...state,
        [container]: {
          loaded: true,
          retailers,
        },
      };
    },
  },
});

export default retailersGroupsSlice.reducer;
