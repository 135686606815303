import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, ListItem, Select, TextField, Typography } from '@karma/components';
import { CheckmarkIcon } from '@karma/components/icons';
import { lambdaApiInstance } from 'functions/request';
import useAction from 'hooks/useAction';
import { handleServerError } from 'store/alerts/actions';
import LandingTypography from 'components/atoms/LandingTypography/LandingTypography';
import { StyledLoader, Form, Wrapper, SelectsContainer } from './ModalBodyPartnershipsContact.style';

const ModalBodyPartnershipsContact = () => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const { t } = useTranslation();

  const [requestSended, setRequestSended] = useState(false);
  const [dataSended, setDataSended] = useState(false);
  const handleServerErrorFunc = useAction(handleServerError);

  const dictionary = {
    performance_basis: t('app:performanceBasis'),
    fixed_fee: t('app:fixedFee'),
    fullName: t('app:fullName'),
    emailOrPhoneNumber: t('app:emailOrPhoneNumber'),
    retailerName: t('app:retailerName'),
    affiliateNetwork: t('app:affiliateNetwork'),
    budgetStructure: t('app:budgetStructure'),
    budget: t('app:budget'),
    tellUsMore: t('app:tellUsMore'),
  };

  const budgetStructureOptions = [dictionary.performance_basis, dictionary.fixed_fee].reduce(
    (acc, item) => [...acc, { value: item, label: item }],
    [],
  );

  const budgetOption = {
    [dictionary.fixed_fee]: ['$2,000 - $10,000', '$10,000 - $30,000', '$30,000+'].reduce(
      (acc, item) => [...acc, { value: item, label: item }],
      [],
    ),
    [dictionary.performance_basis]: ['5% - 10%', '10% - 30%', '30%+'].reduce(
      (acc, item) => [...acc, { value: item, label: item }],
      [],
    ),
  };

  const allOptions = [...budgetOption[dictionary.performance_basis], ...budgetOption[dictionary.fixed_fee]];

  const current = watch('budgetStructure');
  const currentOption = current ? budgetOption[current] : allOptions;

  const createTemplateLetter = data => {
    let templateLetter = '<table>';

    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (data[key]) templateLetter += `<tr><td>${dictionary[key]}</td><td>${data[key]}</td></tr>`;
    }
    templateLetter += '</table>';

    return templateLetter;
  };

  const setBudgetStructure = value => {
    return budgetOption[dictionary.fixed_fee].some(el => el.value === value)
      ? dictionary.fixed_fee
      : dictionary.performance_basis;
  };

  const submitHandler = async data => {
    setRequestSended(true);
    const templateLetter = createTemplateLetter(data);

    try {
      const postData = {
        html: templateLetter,
      };

      await lambdaApiInstance.post('/partnership-smtp/v1', postData);
      setDataSended(true);
      reset();
    } catch (error) {
      handleServerErrorFunc(error);
    } finally {
      setRequestSended(false);
    }
  };

  if (requestSended) {
    return <StyledLoader />;
  }

  if (dataSended) {
    return (
      <Wrapper>
        <CheckmarkIcon width={60} height={60} />
        <Typography variant="h3" tMargin={32} bMargin={8}>
          {t('app:thanksForSubmitting')}
        </Typography>
        <Typography variant="paragraphLarge">{t('app:someoneFromOurPartnershipsTeamWillBeInTouchShortly')}</Typography>
      </Wrapper>
    );
  }

  return (
    <>
      <LandingTypography desktopVariant="h2" mobileVariant="h3" align="center" bMargin={40}>
        {t('app:letsKickStartOurPartnership')}
      </LandingTypography>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, value, ...field } }) => (
            <TextField
              fullWidth
              {...field}
              value={value || ''}
              inputRef={ref}
              label={`*${dictionary.fullName}`}
              error={errors?.fullName}
              helperText={errors?.fullName && t('app:thisFieldIsRequired')}
            />
          )}
          name="fullName"
        />

        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, value, ...field } }) => (
            <TextField
              {...field}
              value={value || ''}
              inputRef={ref}
              fullWidth
              label={`*${dictionary.emailOrPhoneNumber}`}
              error={errors?.emailOrPhoneNumber}
              helperText={errors?.emailOrPhoneNumber && t('app:thisFieldIsRequired')}
            />
          )}
          name="emailOrPhoneNumber"
        />

        <Controller
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              value={value || ''}
              fullWidth
              label={dictionary.retailerName}
              placeholder={dictionary.retailerName}
            />
          )}
          name="retailerName"
        />

        <Controller
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              value={value || ''}
              fullWidth
              placeholder={dictionary.affiliateNetwork}
              label={dictionary.affiliateNetwork}
            />
          )}
          name="affiliateNetwork"
        />

        <SelectsContainer spacing={16} fullWidth>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                label={dictionary.budgetStructure}
                inputRef={ref}
                value={value}
                name={name}
                fullWidth
                onChange={selectedOption => {
                  onChange(selectedOption);
                  setValue('budget', null);
                }}
              >
                {budgetStructureOptions.map(option => (
                  <ListItem key={option.value} value={option.value}>
                    {option.label}
                  </ListItem>
                ))}
              </Select>
            )}
            name="budgetStructure"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                label={dictionary.budget}
                inputRef={ref}
                value={value}
                name={name}
                fullWidth
                onChange={selectedOption => {
                  onChange(selectedOption);
                  setValue('budgetStructure', setBudgetStructure(selectedOption));
                }}
              >
                {currentOption.map(option => (
                  <ListItem key={option.value} value={option.value}>
                    {option.label}
                  </ListItem>
                ))}
              </Select>
            )}
            name="budget"
          />
        </SelectsContainer>

        <Controller
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              value={value || ''}
              fullWidth
              placeholder={dictionary.tellUsMore}
              multiline
              inputProps={{
                rows: 3,
              }}
            />
          )}
          name="tellUsMore"
        />

        <Button size="medium" fullWidth>
          {t('app:submit')}
        </Button>
      </Form>
    </>
  );
};

export default ModalBodyPartnershipsContact;
