import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BackIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25605 1.04294L9.4604 2.24275L4.83827 6.88236L9.48949 11.5424L8.28627 12.7433L2.4375 6.88349L8.25605 1.04294Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63867 6.04285H22.4887V22.7428H3.63867V21.0428H20.7887V7.74285H3.63867V6.04285Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BackIcon;
