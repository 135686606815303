import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AndroidShareIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0613 2.49285C18.8306 2.49285 17.8328 3.4906 17.8328 4.72138C17.8328 5.95217 18.8306 6.94991 20.0613 6.94991C21.2921 6.94991 22.2899 5.95217 22.2899 4.72138C22.2899 3.4906 21.2921 2.49285 20.0613 2.49285ZM16.1328 4.72138C16.1328 2.55171 17.8917 0.792847 20.0613 0.792847C22.231 0.792847 23.9899 2.55171 23.9899 4.72138C23.9899 6.89105 22.231 8.64991 20.0613 8.64991C17.8917 8.64991 16.1328 6.89105 16.1328 4.72138Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2176 9.91432C3.98681 9.91432 2.98906 10.9121 2.98906 12.1429C2.98906 13.3736 3.98681 14.3714 5.2176 14.3714C6.44838 14.3714 7.44613 13.3736 7.44613 12.1429C7.44613 10.9121 6.44838 9.91432 5.2176 9.91432ZM1.28906 12.1429C1.28906 9.97319 3.04793 8.21432 5.2176 8.21432C7.38727 8.21432 9.14613 9.97319 9.14613 12.1429C9.14613 14.3125 7.38727 16.0714 5.2176 16.0714C3.04793 16.0714 1.28906 14.3125 1.28906 12.1429Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0613 17.3358C18.8306 17.3358 17.8328 18.3335 17.8328 19.5643C17.8328 20.7951 18.8306 21.7928 20.0613 21.7928C21.2921 21.7928 22.2899 20.7951 22.2899 19.5643C22.2899 18.3335 21.2921 17.3358 20.0613 17.3358ZM16.1328 19.5643C16.1328 17.3946 17.8917 15.6358 20.0613 15.6358C22.231 15.6358 23.9899 17.3946 23.9899 19.5643C23.9899 21.734 22.231 23.4928 20.0613 23.4928C17.8917 23.4928 16.1328 21.734 16.1328 19.5643Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34427 11.007L8.56535 11.196L8.16445 9.54393L8.94337 9.35491C11.6961 8.68692 14.2598 7.39892 16.4389 5.58921L17.525 6.89702C15.1467 8.87217 12.3486 10.2779 9.34427 11.007Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4006 17.4729L17.1868 18.1968L16.0353 19.4475L15.2491 18.7235C13.161 16.8009 10.6721 15.366 7.96197 14.5224L8.46725 12.8992C11.4177 13.8177 14.1273 15.3798 16.4006 17.4729Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AndroidShareIcon;
