import styled from 'styled-components';

export const AlertsContainer = styled.div`
  position: fixed;
  bottom: 160px;
  left: 20px;
  transform: translateY(100%);
  display: flex;
  flex-direction: column-reverse;
  max-width: 520px;
  z-index: 2021;

  &:empty {
    display: none;
  }
`;
