import styled, { css } from 'styled-components';
import { getColor } from '@karma/components/colors';
import { clampLandingMobileSize } from 'functions/landingMobileDimensions';

const commonStyle = css`
  text-align: ${({ align }) => align || 'left'};
  margin-bottom: ${({ bMargin }) => clampLandingMobileSize(bMargin) || 0};
  margin-left: ${({ lMargin }) => clampLandingMobileSize(lMargin) || 0};
  margin-right: ${({ rMargin }) => clampLandingMobileSize(rMargin) || 0};
  margin-top: ${({ tMargin }) => clampLandingMobileSize(tMargin) || 0};
  color: ${({ color }) => getColor(color || 'primary1')};
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};
`;

const primaryFont = css`
  font-family: 'Rebond';
  font-style: normal;
`;

const secondaryFont = css`
  font-family: 'Roboto';
  font-style: normal;
`;

export const H1Styles = css`
  font-weight: 700;
  font-size: ${clampLandingMobileSize(40)};
  line-height: ${clampLandingMobileSize(46)};
  ${primaryFont};
`;

export const H2Styles = css`
  font-weight: 600;
  font-size: ${clampLandingMobileSize(30)};
  line-height: ${clampLandingMobileSize(34)};
  ${primaryFont};
`;

export const H3Styles = css`
  font-weight: 600;
  font-size: ${clampLandingMobileSize(24)};
  line-height: ${clampLandingMobileSize(27)};
  ${primaryFont};
`;

export const H4Styles = css`
  font-weight: 500;
  font-size: ${clampLandingMobileSize(20)};
  line-height: ${clampLandingMobileSize(23)};
  ${primaryFont};
`;

export const H5Styles = css`
  font-weight: 600;
  font-size: ${clampLandingMobileSize(18)};
  line-height: ${clampLandingMobileSize(21)};
  ${primaryFont};
`;

export const ParagraphExtraLargeStyles = css`
  font-weight: 500;
  font-size: ${clampLandingMobileSize(18)};
  line-height: ${clampLandingMobileSize(26)};
  ${secondaryFont};
`;

export const ParagraphLargeStyles = css`
  font-weight: 400;
  font-size: ${clampLandingMobileSize(16)};
  line-height: ${clampLandingMobileSize(24)};
  ${secondaryFont};
`;

export const ParagraphMediumStyles = css`
  font-weight: 400;
  font-size: ${clampLandingMobileSize(14)};
  line-height: ${clampLandingMobileSize(20)};
  ${secondaryFont};
`;

export const LabelLargeStyles = css`
  font-weight: 500;
  font-size: ${clampLandingMobileSize(16)};
  line-height: ${clampLandingMobileSize(24)};
  ${secondaryFont};
`;

export const LabelMediumStyles = css`
  font-weight: 400;
  font-size: ${clampLandingMobileSize(14)};
  line-height: ${clampLandingMobileSize(20)};
  ${secondaryFont};
`;

export const LabelMediumBoldStyles = css`
  ${LabelMediumStyles};
  font-weight: 700;
`;

export const LabelSmallStyles = css`
  font-weight: 400;
  font-size: ${clampLandingMobileSize(12)};
  line-height: ${clampLandingMobileSize(16)};
  ${secondaryFont};
`;

export const LineThroughStyles = css`
  font-weight: 400;
  font-size: ${clampLandingMobileSize(14)};
  line-height: ${clampLandingMobileSize(18)};
  text-decoration-line: line-through;
  ${secondaryFont};
`;

export const LabelSmallUppercaseStyles = css`
  ${LabelSmallStyles};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const LabelSmallUppercaseBoldStyles = css`
  ${LabelSmallUppercaseStyles};
  font-weight: 700;
`;

export const LabelExtraSmallUppercaseStyles = css`
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: ${clampLandingMobileSize(10)};
  line-height: ${clampLandingMobileSize(14)};
  ${secondaryFont};
`;

export const LabelExtraSmallUppercaseSemiboldStyles = css`
  ${LabelExtraSmallUppercaseStyles};
  font-weight: 600;
`;

export const H1 = styled.h1`
  ${H1Styles}
  ${commonStyle}
`;

export const H2 = styled.h2`
  ${H2Styles}
  ${commonStyle}
`;

export const H3 = styled.h3`
  ${H3Styles}
  ${commonStyle}
`;

export const H4 = styled.h4`
  ${H4Styles}
  ${commonStyle}
`;

export const H5 = styled.h5`
  ${H5Styles}
  ${commonStyle}
`;

export const ParagraphExtraLarge = styled.p`
  ${ParagraphLargeStyles}
  ${commonStyle}
`;

export const ParagraphLarge = styled.p`
  ${ParagraphLargeStyles}
  ${commonStyle}
`;

export const ParagraphMedium = styled.p`
  ${ParagraphMediumStyles}
  ${commonStyle}
`;

export const LabelLarge = styled.div`
  ${LabelLargeStyles}
  ${commonStyle}
`;

export const LabelMedium = styled.div`
  ${LabelMediumStyles}
  ${commonStyle}
`;

export const LabelMediumBold = styled.div`
  ${LabelMediumBoldStyles}
  ${commonStyle}
`;

export const LabelSmall = styled.div`
  ${LabelSmallStyles}
  ${commonStyle}
`;

export const LabelSmallUppercase = styled.div`
  ${LabelSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelSmallUppercaseBold = styled.div`
  ${LabelSmallUppercaseBoldStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercase = styled.div`
  ${LabelExtraSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercaseSemibold = styled.div`
  ${LabelExtraSmallUppercaseSemiboldStyles}
  ${commonStyle}
`;

export const LineThrough = styled.div`
  ${LineThroughStyles}
  ${commonStyle}
`;
