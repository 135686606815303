import styled, { css } from 'styled-components';
import { getColor } from '@karma/components/colors';
import { clampLandingSize } from 'functions/landingDimensions';

const commonStyle = css`
  text-align: ${({ align }) => align || 'left'};
  margin-bottom: ${({ bMargin }) => clampLandingSize(bMargin) || 0};
  margin-left: ${({ lMargin }) => clampLandingSize(lMargin) || 0};
  margin-right: ${({ rMargin }) => clampLandingSize(rMargin) || 0};
  margin-top: ${({ tMargin }) => clampLandingSize(tMargin) || 0};
  color: ${({ color }) => getColor(color || 'primary1')};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;
const primaryFont = css`
  font-family: 'Rebond';
  font-style: normal;
`;

const secondaryFont = css`
  font-family: 'Roboto';
  font-style: normal;
`;

export const LargeTitleStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(100)};
  line-height: ${clampLandingSize(114)};
  ${primaryFont}
`;

export const MediumTitleStyles = css`
  font-weight: 700;
  font-size: ${clampLandingSize(80)};
  line-height: ${clampLandingSize(91)};
  ${primaryFont}
`;

export const H1Styles = css`
  font-weight: 700;
  font-size: ${clampLandingSize(50)};
  line-height: ${clampLandingSize(57)};
  ${primaryFont}
`;

export const H2Styles = css`
  font-weight: 600;
  font-size: ${clampLandingSize(40)};
  line-height: ${clampLandingSize(46)};
  ${primaryFont}
`;

export const H3Styles = css`
  font-weight: 600;
  font-size: ${clampLandingSize(30)};
  line-height: ${clampLandingSize(34)};
  ${primaryFont}
`;

export const H4Styles = css`
  font-weight: 500;
  font-size: ${clampLandingSize(24)};
  line-height: ${clampLandingSize(27)};
  ${primaryFont}
`;

export const H5Styles = css`
  font-weight: 600;
  font-size: ${clampLandingSize(20)};
  line-height: ${clampLandingSize(23)};
  ${primaryFont}
`;

export const ParagraphExtraLargeStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(28)};
  line-height: ${clampLandingSize(56)};
  ${secondaryFont}
`;

export const ParagraphLargeStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(18)};
  line-height: ${clampLandingSize(24)};
  ${secondaryFont}
`;

export const ParagraphMediumStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(16)};
  line-height: ${clampLandingSize(20)};
  ${secondaryFont}
`;

export const ParagraphSmallStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(14)};
  line-height: ${clampLandingSize(20)};
  ${secondaryFont}
`;

export const LabelLargeStyles = css`
  font-weight: 500;
  font-size: ${clampLandingSize(18)};
  line-height: ${clampLandingSize(24)};
  ${secondaryFont}
`;

export const LabelMediumStyles = css`
  font-weight: 500;
  font-size: ${clampLandingSize(16)};
  line-height: ${clampLandingSize(19)};
  ${secondaryFont}
`;

export const LabelSmallStyles = css`
  font-weight: 400;
  font-size: ${clampLandingSize(12)};
  line-height: ${clampLandingSize(16)};
  ${secondaryFont}
`;

export const LabelSmallUppercaseStyles = css`
  ${LabelSmallStyles};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const LabelSmallUppercaseBoldStyles = css`
  ${LabelSmallUppercaseStyles};
  font-weight: 700;
`;

export const LabelExtraSmallUppercaseStyles = css`
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: ${clampLandingSize(10)};
  line-height: ${clampLandingSize(14)};
  ${secondaryFont}
`;

export const LabelExtraSmallUppercaseSemiboldStyles = css`
  ${LabelExtraSmallUppercaseStyles};
  font-weight: 600;
`;

export const LargeTitle = styled.h1`
  ${LargeTitleStyles}
  ${commonStyle}
`;

export const MediumTitle = styled.h1`
  ${MediumTitleStyles}
  ${commonStyle}
`;

export const H1 = styled.h1`
  ${H1Styles}
  ${commonStyle}
`;

export const H2 = styled.h2`
  ${H2Styles}
  ${commonStyle}
`;

export const H3 = styled.h3`
  ${H3Styles}
  ${commonStyle}
`;

export const H4 = styled.h4`
  ${H4Styles}
  ${commonStyle}
`;

export const H5 = styled.h5`
  ${H5Styles}
  ${commonStyle}
`;

export const H1SEO = styled.h2`
  ${H1Styles}
  ${commonStyle}
`;

export const ParagraphExtraLarge = styled.p`
  ${ParagraphExtraLargeStyles}
  ${commonStyle}
`;

export const ParagraphLarge = styled.p`
  ${ParagraphLargeStyles}
  ${commonStyle}
`;

export const ParagraphMedium = styled.p`
  ${ParagraphMediumStyles}
  ${commonStyle}
`;

export const ParagraphSmall = styled.p`
  ${ParagraphSmallStyles}
  ${commonStyle}
`;

export const LabelLarge = styled.div`
  ${LabelLargeStyles}
  ${commonStyle}
`;

export const LabelMedium = styled.div`
  ${LabelMediumStyles}
  ${commonStyle}
`;

export const LabelSmall = styled.div`
  ${LabelSmallStyles}
  ${commonStyle}
`;

export const LabelSmallUppercase = styled.div`
  ${LabelSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelSmallUppercaseBold = styled.div`
  ${LabelSmallUppercaseBoldStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercase = styled.div`
  ${LabelExtraSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercaseSemibold = styled.div`
  ${LabelExtraSmallUppercaseSemiboldStyles}
  ${commonStyle}
`;
