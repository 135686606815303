import styled, { css } from 'styled-components';
import { getColor } from '../colors';
import { StoreLogoWrapper } from './StoreLogo.types';

export const StoreLogoWrap = styled.div<StoreLogoWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${getColor('neutral4')};
  background: #fff;
  border-radius: 100px;
  ${({ width, height }) => css`
    min-width: ${width}px;
    min-height: ${height}px;
    max-width: ${width}px;
    max-height: ${height}px;
  `}
  overflow: hidden;
`;

export const StoreLogoImage = styled.img`
  object-fit: contain;
`;
