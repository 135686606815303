import { call, put, select } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as api from '../api';
import { setUpdatedTag } from '../actions';
import { getGlobalItemsGroup } from '../selectors';

export default function* ({ payload: tag }) {
  try {
    const globalItems = yield select(getGlobalItemsGroup);

    const globalTag = globalItems?.tags?.find(({ id }) => id === tag.id);

    if (globalTag) {
      const globalItemId = tag.id;
      const globalSavedItemId = globalTag.global_saved_tag_id;

      const tagToUpdate = {
        ...tag,
        id: globalSavedItemId,
      };

      const { data } = yield call(api.updateTag, tagToUpdate);

      const updatedTag = {
        ...data.data,
        id: globalItemId,
        global_saved_tag_id: globalSavedItemId,
      };

      yield put(setUpdatedTag(updatedTag));
    } else {
      const { data } = yield call(api.updateTag, tag);

      yield put(setUpdatedTag(data.data));
    }
  } catch (error) {
    yield put(handleServerError(error));
  }
}
