import { normalize, schema } from 'normalizr';
import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as actions from '../actions';
import * as api from '../api';

const listSchema = new schema.Entity('lists');
const listListSchema = new schema.Array(listSchema);

const retailerSchema = new schema.Entity('retailers');
const retailerListSchema = new schema.Array(retailerSchema);

function* fetchLists() {
  try {
    const response = yield call(api.fetchLists);
    const { data } = response.data;

    const { entities, result } = normalize(data, {
      lists: listListSchema,
      retailers: retailerListSchema,
    });

    yield put(
      actions.receiveNormalizedLists({
        entities: entities.lists || {},
        ids: result.lists,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchLists;
