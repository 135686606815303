import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { COLOR_THEMES } from '@karma/components/themes';
import { getColor } from '@karma/components/colors';

export const Body = styled(Modal.Body)`
  position: relative;
  padding: 0;
`;

export const CloseIconButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${({ theme }) => (theme.color === COLOR_THEMES.DARK ? getColor('primary2') : getColor('primary1'))};
  z-index: 40;
  padding: 5px;

  ${({ withGradient }) =>
    withGradient &&
    css`
      right: 15px;
      top: 15px;
    `}
`;

export const TopGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #fbfbf8 41.19%, rgba(251, 251, 248, 0) 100%);
`;
