import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GoogleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M23.8683 9.818H12.2407V14.727H18.8305C17.7779 18 15.1763 19.091 12.1879 19.091C11.0302 19.0924 9.88927 18.8186 8.86186 18.293C7.83445 17.7674 6.95089 17.0053 6.28616 16.0716C5.62142 15.1378 5.19513 14.0598 5.04343 12.9291C4.89174 11.7985 5.01912 10.6484 5.41478 9.57658C5.81043 8.50473 6.46269 7.54272 7.3162 6.77217C8.16971 6.00161 9.1993 5.44524 10.3176 5.15027C11.4359 4.85529 12.6099 4.83042 13.7401 5.07774C14.8702 5.32507 15.9232 5.83731 16.8095 6.571L20.4303 3.171C18.9719 1.84852 17.208 0.896627 15.2916 0.397932C13.3752 -0.100764 11.3642 -0.131197 9.43317 0.309276C7.50213 0.749748 5.70937 1.64782 4.21047 2.92554C2.71158 4.20327 1.55184 5.82205 0.831894 7.6414C0.111951 9.46075 -0.146439 11.4257 0.079147 13.3657C0.304733 15.3057 1.00748 17.1622 2.1264 18.774C3.24532 20.3859 4.7466 21.7043 6.49997 22.615C8.25333 23.5257 10.2058 24.0011 12.1879 24C18.9046 24 24.9778 19.636 23.8683 9.818Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GoogleIcon;
