import { call, put } from 'redux-saga/effects';
import { handleServerError, showErrorAlert } from 'store/alerts/actions';
import * as userApi from '../api';

function* loginAsUser({ payload }) {
  try {
    const { data } = yield call(userApi.loginAsUser, { email: payload });

    if (!data.error) {
      localStorage.setItem('token', data.token);
      window.location.reload();
    } else {
      yield put(showErrorAlert({ message: data.error }));
    }
  } catch (error) {
    yield put(handleServerError(error?.response));
  }
}

export default loginAsUser;
