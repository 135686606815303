import { call, put } from 'redux-saga/effects';
import logout from 'functions/authentication/logout';
import { handleServerError } from 'store/alerts/actions';
import * as userApi from '../api';
import { setAccountDeleteState } from '../actions';

function* deleteAccount() {
  try {
    yield put(setAccountDeleteState({ isFetching: true }));
    const response = yield call(userApi.deleteAccount);

    if (response.data.success) {
      yield put(setAccountDeleteState({ isFetching: false, success: true }));
      setTimeout(() => {
        logout();
      }, 2000);
    }
  } catch (error) {
    yield put(handleServerError(error));
    yield put(setAccountDeleteState({ isFetching: false }));
  }
}

export default deleteAccount;
