import React, { useEffect, useRef, useState } from 'react';
import { HelperTextRoot, HelperTextWrapper } from './HelperText.styles';
import { HelperTextProps } from './HelperText.types';

const HelperText: React.FC<HelperTextProps> = ({
  show,
  children,
  focused,
  disabled,
  success,
  error,
  ...props
}) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [cachedChildren, setCachedChildren] = useState(children);

  useEffect(() => {
    let timerChildren;

    if (!show) timerChildren = setTimeout(() => setCachedChildren(children), 200);
    else if (cachedChildren !== children) setCachedChildren(children);

    return () => timerChildren && clearTimeout(timerChildren);
  }, [show, children]);

  useEffect(() => setHeight(contentRef.current?.offsetHeight), [cachedChildren]);

  return (
    <HelperTextWrapper show={show} maxHeight={height}>
      <HelperTextRoot
        {...props}
        show={show}
        focused={focused}
        disabled={disabled}
        success={success}
        error={error}
        ref={contentRef}
      >
        {cachedChildren}
      </HelperTextRoot>
    </HelperTextWrapper>
  );
};

export default HelperText;
