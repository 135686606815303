import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Alooma from 'services/alooma';
import {
  TYPE_ERROR,
  TYPE_SUCCESS,
  TYPE_DELETED_TAG,
  TYPE_NOTIFICATION_SUCCESS,
  TYPE_NOTIFICATION_UNSUCCESSFUL,
  TYPE_DELETE_NOTIFICATION,
  TYPE_MUTE_NOTIFICATION,
} from 'store/alerts/slice';
import AlertUndoButton from 'components/atoms/AlertUndoButton/AlertUndoButton';
import BaseAlert from '../BaseAlert/BaseAlert';
import { AlertTitle } from './Alert.style';

const Alert = ({ alert: { data, id, type } }) => {
  const { t } = useTranslation();
  const [shouldShowReverseAnimation, setShouldShowReverseAnimation] = useState(false);

  const handleCloseClick = useCallback(() => {
    setTimeout(() => {
      setShouldShowReverseAnimation(true);
    }, 0);
  }, []);

  const isStringContainsURL = message => {
    const urlRegExp = new RegExp(
      '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
    );

    return urlRegExp.test(message);
  };

  Alooma.usePageViewTracker(data.source);

  const renderedBaseAlert = useMemo(() => {
    switch (type) {
      case TYPE_ERROR:
        return (
          <BaseAlert
            type="error"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h5" lMargin={16}>
                {typeof data.message === 'string' && !isStringContainsURL(data.message)
                  ? t(data.message)
                  : data.message}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      case TYPE_NOTIFICATION_UNSUCCESSFUL:
        return (
          <BaseAlert
            type="error"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h5" lMargin={16} rMargin={16}>
                <Trans i18nKey="app:weCannotSaveThisItem">
                  We cannot save this item <br /> as this store is not supported
                </Trans>
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      case TYPE_SUCCESS:
        return (
          <BaseAlert
            type="success"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8}>
                {typeof data.message === 'string' ? t(data.message) : data.message}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      case TYPE_DELETED_TAG:
        return (
          <BaseAlert
            type="success"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8}>
                {typeof data.message === 'string' ? t(data.message) : data.message}
              </AlertTitle>
            }
            Button={<AlertUndoButton tagId={data.id} handleCloseClick={handleCloseClick} />}
            onClose={handleCloseClick}
          />
        );

      case TYPE_NOTIFICATION_SUCCESS:
        return (
          <BaseAlert
            type="success"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8} rMargin={8}>
                {t('app:itemSaving')}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      case TYPE_DELETE_NOTIFICATION:
        return (
          <BaseAlert
            type="warning"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8}>
                {typeof data.message === 'string' ? t(data.message) : data.message}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      case TYPE_MUTE_NOTIFICATION:
        return (
          <BaseAlert
            type="info"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8}>
                {typeof data.message === 'string' ? t(data.message) : data.message}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );

      default:
        return (
          <BaseAlert
            type="info"
            alertId={id}
            shouldShowReverseAnimation={shouldShowReverseAnimation}
            Content={
              <AlertTitle variant="h6" lMargin={8}>
                {typeof data.message === 'string' ? t(data.message) : data.message}
              </AlertTitle>
            }
            onClose={handleCloseClick}
          />
        );
    }
  }, [data.id, data.message, handleCloseClick, id, shouldShowReverseAnimation, t, type]);

  useEffect(() => {
    let timeout;

    if (![TYPE_ERROR, TYPE_NOTIFICATION_UNSUCCESSFUL, TYPE_DELETE_NOTIFICATION].includes(type)) {
      timeout = setTimeout(() => {
        handleCloseClick();
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [handleCloseClick, type]);

  return <Alooma.SourceContext.Provider value={data.source}>{renderedBaseAlert}</Alooma.SourceContext.Provider>;
};

Alert.propTypes = {
  alert: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.node,
      source: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.oneOf([
      TYPE_ERROR,
      TYPE_SUCCESS,
      TYPE_DELETED_TAG,
      TYPE_NOTIFICATION_SUCCESS,
      TYPE_NOTIFICATION_UNSUCCESSFUL,
    ]),
  }).isRequired,
};

export default Alert;
