import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as extensionActions from 'store/extension/actions';
import * as extensionSelectors from 'store/extension/selectors';
import CConsole from 'functions/CConsole';
import useAction from 'hooks/useAction';

const INSTALLED_MESSAGE = 'shoptagr:IOSExtInstalled';
const RECEIVE_MESSAGE_DELAY = 5000;

export default () => {
  const timerRef = useRef();
  const isIOSExtensionInstalled = useSelector(extensionSelectors.getIOSExtInstalled);
  const setIOSExtensionInstalled = useAction(extensionActions.setIOSInstalled);
  const setIOSExtensionNotInstalled = useAction(extensionActions.setNotIOSInstalled);

  const [isIOSExtensionInstallationChecked, setIsIOSExtensionInstallationChecked] = useState(!!isIOSExtensionInstalled);

  const messageHandler = useCallback(
    event => {
      if (event.data === INSTALLED_MESSAGE) {
        setIOSExtensionInstalled();
        clearTimeout(timerRef.current);
        window.removeEventListener('message', messageHandler);
      }
    },
    [setIOSExtensionInstalled],
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  // ! Sets a time limit to receive a message from the extension
  useEffect(() => {
    if (!isIOSExtensionInstallationChecked) {
      timerRef.current = setTimeout(() => {
        setIsIOSExtensionInstallationChecked(true);
        if (isIOSExtensionInstalled === null) {
          setIOSExtensionNotInstalled();
        }
        CConsole.log('receive message delay is ended');
      }, RECEIVE_MESSAGE_DELAY);
    }

    return () => clearTimeout(timerRef.current);
  }, [isIOSExtensionInstallationChecked]);
};
