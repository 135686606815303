export const LAST_SAVED = 'last_saved';
export const HIGH_TO_LOW = 'high_to_low';
export const LOW_TO_HIGH = 'low_to_high';
export const ON_SALE = 'on_sale';
export const BACK_IN_STOCK = 'back_in_stock';
export const LOW_IN_STOCK = 'low_in_stock';
export const FAVORITES = 'favorites';
export const FULFILLED = 'fulfilled';

export const MUTUALLY_EXCLUSIVE_SORTS = [LAST_SAVED, HIGH_TO_LOW, LOW_TO_HIGH];
export const MUTUALLY_EXCLUSIVE_FILTERS = [ON_SALE, BACK_IN_STOCK, LOW_IN_STOCK];
export const MUTUALLY_EXCLUSIVE_LISTS = [FAVORITES, FULFILLED];
