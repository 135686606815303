import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as notificationsActions from '../actions';
import * as notificationsApi from '../api';

function* fetchUnseenCount() {
  try {
    const response = yield call(notificationsApi.fetchUnseenCount);

    yield put(notificationsActions.successFetchUnseenCount(response.data.data.unseen_count));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchUnseenCount;
