import { useTranslation } from 'react-i18next';
import { MailIcon } from '@karma/components/icons';
import { Button } from '@karma/components';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';

const EmailShareButton = ({ description, url, wideButton }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const message = `${t(description || 'app:whenIFindSmth')}%0D%0A${encodeURI(url)}`;
  const emailShareLink = `mailto:?body=${message}&subject=${t('app:karmaItem')}`;

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: emailShareLink,
    'data-alooma-element': ALOOMA.ELEMENTS.EMAIL,
    ...contextDataAttrs,
  };

  return wideButton ? (
    <Button as="a" Icon={MailIcon} size="small" fullWidth {...linkProps}>
      {t('app:shareByEmail')}
    </Button>
  ) : (
    <a {...linkProps}>
      <MailIcon role="button" size="medium" />
    </a>
  );
};

export default EmailShareButton;
