import * as React from 'react';
import InputLabel from '../InputLabel';
import useId from '../utils/useId';
import FieldSet from '../FieldSet';
import {
  OptionsWrapper,
  SelectInputButton,
  PopoverWrapper,
  HiddenInput,
  SelectIconContainer,
  SelectArrow,
} from './Select.styles';
import { SelectProps, ViewStateProps } from './Select.types';
import HelperText from '../HelperText';
import Popover from '../Popover';
import useOnClickOutside from '../utils/useOnClickOutside';
import FieldWrapper from '../FieldWrapper';
import InputContainer from '../InputContainer';
import { ListItemProps } from '../ListItem';
import { setMultipleRef } from '../utils/setMultipleRef';
import Typography from '../Typography';

const defaultRenderValueFunc = (_value: any) =>
  Array.isArray(_value) ? _value.join(', ') : _value || ' ';

const Select = React.forwardRef<HTMLDivElement, SelectProps>(function Select(
  {
    children,

    id: idOverride,
    name,
    value,
    placeholder,
    disabled = false,
    fullWidth = false,
    shrink = false,
    multiple = false,
    required = false,

    error = false,
    success = false,

    label,
    labelProps = {},

    inputRef = null,
    inputProps = {},

    inputContainerRef = null,

    helperText,
    helperTextProps = {},

    listboxProps = {},
    popoverProps = {},

    beforeInput,
    afterInput,
    afterInputContainer,
    arrowIconContainer = null,

    onFocus = _e => {},
    onKeyDown = _e => {},
    onBlur = _e => {},
    onChange = _e => {},
    onClick = _e => {},

    renderValue,

    ...restContainerProps
  },
  ref
) {
  const id = useId(idOverride);
  const selectedOptionRef = React.useRef<HTMLElement>(null);
  const [selectContainerRef, setSelectContainerRef] = React.useState(null);
  const [opened, setOpened] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const shrinkLabel = focused || shrink || !!beforeInput || !!value || !!placeholder;

  const childrenArray = React.Children.toArray(children);

  const menuId = childrenArray.length > 0 && id ? `${id}-menu` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setFocused(v => !v);
    setOpened(v => !v);
    onClick(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    setFocused(true);
    onFocus(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setOpened(true);
      onKeyDown(e);
    }

    if (e.key === 'Escape') {
      setOpened(false);
      onKeyDown(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    setFocused(false);
    onBlur(e);
  };

  const viewState: ViewStateProps = {
    disabled,
    success,
    error,
    focused,
    shrink: shrinkLabel,
  };

  const clickOutsideCallback = () => {
    setFocused(false);
    setOpened(false);
  };

  useOnClickOutside(selectContainerRef, clickOutsideCallback, { capture: true });

  const onEnter = () => {
    if (selectedOptionRef.current) selectedOptionRef.current.focus();
  };

  const handleItemClick = child => event => {
    let newValue;

    if (multiple && Array.isArray(value)) {
      newValue = Array.isArray(value) ? value.slice() : [];
      const itemIndex = value.indexOf(child.props.value);
      if (itemIndex === -1) newValue.push(child.props.value);
      else newValue.splice(itemIndex, 1);
    } else newValue = child.props.value;

    if (child.props.value && onChange) onChange(newValue);
    if (child.props.onClick) child.props.onClick(event);

    if (!multiple) {
      setOpened(false);
      setFocused(false);
    }
  };

  const items = childrenArray.map(child => {
    if (!React.isValidElement(child)) return null;

    const selected =
      multiple && Array.isArray(value)
        ? value.includes(child.props.value)
        : child.props.value === value;

    return React.cloneElement(child as React.ReactElement<ListItemProps>, {
      role: 'option',
      onClick: handleItemClick(child),
      value: undefined,
      ...(!multiple && { selected }),
      ...(!multiple && selected && { ref: setMultipleRef(selectedOptionRef, child.props.ref) }),

      // @ts-expect-error
      'data-value': child.props.value,
    });
  });

  const renderFunc = renderValue || defaultRenderValueFunc;
  const renderedValue = React.useMemo(() => {
    const newValue = renderFunc(value);

    return (
      newValue ||
      (placeholder ? (
        <Typography variant="labelMedium" color="neutral2">
          {placeholder}
        </Typography>
      ) : (
        ' '
      ))
    );
  }, [value, placeholder, renderFunc]);

  return (
    <FieldWrapper {...restContainerProps} fullWidth={fullWidth} ref={ref}>
      {label != null && label !== '' && (
        <InputLabel id={inputLabelId} htmlFor={id} {...viewState} {...labelProps}>
          {label}
        </InputLabel>
      )}

      <InputContainer
        disabled={disabled}
        ref={setMultipleRef(inputContainerRef, setSelectContainerRef)}
      >
        {beforeInput}
        <SelectInputButton
          disabled={disabled}
          aria-disabled={disabled}
          hasPrefix={!!beforeInput}
          tabIndex={disabled ? -1 : 0}
          role="button"
          aria-expanded={opened}
          aria-haspopup="listbox"
          aria-labelledby={[inputLabelId, id].join(' ')}
          onClick={handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        >
          {renderedValue}
        </SelectInputButton>
        <HiddenInput
          aria-hidden
          id={id}
          name={name}
          disabled={disabled}
          tabIndex={-1}
          value={Array.isArray(value) ? value.join(',') : value}
          ref={inputRef}
          required={required}
          onChange={() => null}
          {...inputProps}
        />
        {afterInput}
        {arrowIconContainer || (
          <SelectIconContainer>
            <SelectArrow isOpened={opened} disabled={disabled} size="small" />
          </SelectIconContainer>
        )}
        <FieldSet label={label} {...viewState} />
      </InputContainer>
      <PopoverWrapper
        id={menuId}
        style={
          !opened
            ? {
                zIndex: -1,
              }
            : {}
        }
      >
        <Popover
          {...popoverProps}
          style={{ ...(popoverProps.style ? popoverProps.style : {}) }}
          wrapperStyle={{
            overflow: 'hidden',
            ...(popoverProps.wrapperStyle ? popoverProps.wrapperStyle : {}),
          }}
          anchorElement={selectContainerRef}
          isOpened={opened}
          onEnter={onEnter}
        >
          <OptionsWrapper
            direction="column"
            component="ul"
            role="listbox"
            tabIndex={-1}
            aria-labelledby={inputLabelId}
            justifyContent="flex-start"
            {...listboxProps}
          >
            {items}
          </OptionsWrapper>
        </Popover>
      </PopoverWrapper>
      {afterInputContainer}
      <HelperText {...helperTextProps} show={!!helperText} id={helperTextId} {...viewState}>
        {helperText}
      </HelperText>
    </FieldWrapper>
  );
});

export default Select;
