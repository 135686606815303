import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as ALOOMA from 'consts/alooma';
import { getList } from 'store/lists/selectors';
import ModalBodyListEdit from 'components/molecules/ModalBodyListEdit/ModalBodyListEdit';
import Modal from 'components/templates/Modal/Modal';

const ModalListEdit = props => {
  const { closeModal, listId } = props;
  const list = useSelector(getList)(listId);

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.MODAL_LIST_UPDATE} onClose={closeModal}>
      <ModalBodyListEdit list={list} {...props} />
    </Modal>
  );
};

ModalListEdit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  listId: PropTypes.number.isRequired,
};

export default ModalListEdit;
