import { API_URL } from '@karma/utils/constants/env';
import CConsole from 'functions/CConsole';

const ROUTES = {
  api: {
    signUpPath: `${API_URL}/sign_up`,
    signInPath: `${API_URL}/sessions/email_login`,
    openPositionsPath: `${API_URL}/open_positions`,
    groupedStores: `${API_URL}/stores/grouped`,
    frequentlyAskedQuestionsPath: `${API_URL}/questions`,
    resetPasswordPath: '/user/reset_password',
    extensionUninstallationTrackPath: `${API_URL}/extensions/uninstall`,
    advertismentsPath: '/advertisments',
    addMissingStorePath: `${API_URL}/stores/missing_store`,
    paypalConnectPath: `${API_URL}/plus/paypal_connect`,
    cashbacksPath: `${API_URL}/plus/cashbacks`,
    payoutsPath: `${API_URL}/plus/payouts`,
    withdrawPath: `${API_URL}/plus/withdraw`,
    profilePath: `${API_URL}/user/profile`,
    guestSignUpPath: `${API_URL}/guest_sign_up`,
    convertFromGuest: `${API_URL}/user/convert_from_guest`,
  },
};

// TODO: Check if this function really needed
const cachedRedirect = ({ fallback }) => {
  const url = new URL(window.location);
  const timestamp = url.searchParams.get('redirect_timestamp');

  const redirectUrl = localStorage.getItem(`redirect_${timestamp}`) || localStorage.getItem('redirect');

  if (redirectUrl) {
    localStorage.removeItem('redirect');
    localStorage.removeItem(`redirect_${timestamp}`);
    window.location.href = redirectUrl;
  }

  if (!redirectUrl) {
    if (typeof fallback !== 'function') {
      CConsole.error('Please provide correct fallback function for cached redirect!');
    } else {
      fallback();
    }
  }
};

export default ROUTES;
export { cachedRedirect };
