import { put } from 'redux-saga/effects';
import { setQueryParam } from 'functions/urlHelpers';

function* setFilter({ elementId, fetchAction, queryParam }, { payload: value }) {
  setQueryParam(queryParam, value);
  yield put(fetchAction());
  document.getElementById(elementId).scrollTop = 0;
}

export default setFilter;
