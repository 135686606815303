import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAction from 'hooks/useAction';
import { PATHS } from 'consts/paths';
import { showErrorAlert } from 'store/alerts/actions';
import * as userActions from 'store/user/actions';
import useQueryParams from 'hooks/useQueryParams';
import { loginUser } from 'functions/authentication/logInFunctions';
import getIsSeoPage from 'functions/getIsSeoPage';
import getEmailPasswordFormSchema from 'functions/getEmailPasswordFormSchema';

const useGetLogInFormProps = (callback = () => {}, defaultEmail = '', authorizationHandler) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const { cix } = useQueryParams();
  const fetchUser = useAction(userActions.fetchUser);
  const isSeoPage = getIsSeoPage();

  const autoClose = cix === 'true';

  const formProps = useForm({
    defaultValues: {
      email: defaultEmail,
      password: '',
    },
    resolver: yupResolver(getEmailPasswordFormSchema({ t })),
  });

  const { handleSubmit } = formProps;

  const showErrorAlertFunc = useAction(showErrorAlert);

  const showError = message => showErrorAlertFunc({ message });

  const login = async ({ email, password }) => {
    setFetching(true);

    loginUser({ email, password })
      .then(user => {
        if (typeof authorizationHandler === 'function') {
          authorizationHandler(user);
        } else if (autoClose) {
          window.postMessage(`closeTab_${window.location.href}`, '*');
          setTimeout(() => {
            window.location.href = PATHS.DASHBOARD;
          }, 2000);
        } else if (isSeoPage) {
          fetchUser();
          callback();
        } else {
          window.location.href = PATHS.DASHBOARD;
        }
      })
      .catch(showError)
      .finally(() => setFetching(false));
  };

  const onSubmitFunc = data => login(data);
  const onSubmit = handleSubmit(onSubmitFunc);

  return {
    formProps,
    fetching,
    onSubmit,
  };
};

export default useGetLogInFormProps;
