import { FAVORITES_LIST_OPTION, FULFILLED_LIST_OPTION, REGULAR_LISTS_OPTIONS } from 'consts/lists';

export const getCurrentTagLists = tag => {
  const { lists, favorites, fulfilled } = tag;
  const selectedLists = lists ? lists.map(({ id }) => id) : [];

  if (fulfilled) selectedLists.push(FULFILLED_LIST_OPTION.id);
  if (favorites) selectedLists.push(FAVORITES_LIST_OPTION.id);

  return selectedLists;
};

export const getCurrentTagListsWithDetails = tag => {
  const { lists, favorites, fulfilled } = tag;
  const selectedLists = lists ? [...lists] : [];

  const fulfilledOption = { ...FULFILLED_LIST_OPTION };
  const favoritesOption = { ...FAVORITES_LIST_OPTION };

  if (fulfilled) selectedLists.push(fulfilledOption);
  if (favorites) selectedLists.push(favoritesOption);

  return selectedLists;
};

export const getTagLists = lists => [...REGULAR_LISTS_OPTIONS, ...lists];
