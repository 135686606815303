import { all, call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as retailersGroupsTitlesActions from '../actions';
import * as retailersGroupsTitlesApi from '../api';

function* fetchRetailersGroupsTitles() {
  try {
    const response = yield call(retailersGroupsTitlesApi.getRetailersGroupsTitles);

    yield all([
      put(retailersGroupsTitlesActions.receiveRetailersGroupsTitles(response.data.data)),
      put(retailersGroupsTitlesActions.receiveMarketingCopy(response.data.marketing_copy)),
    ]);
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchRetailersGroupsTitles;
