import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const IconWrapper = styled.div`
  top: 53%;
  transform: translateY(-50%) rotate(-90deg);
  width: 20px;
  height: 20px;
  background: transparent;
  color: ${getColor('primary1')};
  cursor: pointer;
`;
