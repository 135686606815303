import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const LockIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40039 11.4844C2.40039 10.1865 3.45252 9.1344 4.75039 9.1344H19.2504C20.5483 9.1344 21.6004 10.1865 21.6004 11.4844V21.1094C21.6004 22.4073 20.5483 23.4594 19.2504 23.4594H4.75039C3.45252 23.4594 2.40039 22.4073 2.40039 21.1094V11.4844ZM4.75039 10.8344C4.39141 10.8344 4.10039 11.1254 4.10039 11.4844V21.1094C4.10039 21.4684 4.39141 21.7594 4.75039 21.7594H19.2504C19.6094 21.7594 19.9004 21.4684 19.9004 21.1094V11.4844C19.9004 11.1254 19.6094 10.8344 19.2504 10.8344H4.75039Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40039 7.14065C5.40039 3.49557 8.35531 0.540649 12.0004 0.540649C15.6455 0.540649 18.6004 3.49557 18.6004 7.14065V10.8344H5.40039V7.14065ZM12.0004 2.24065C9.2942 2.24065 7.10039 4.43445 7.10039 7.14065V9.1344H16.9004V7.14065C16.9004 4.43445 14.7066 2.24065 12.0004 2.24065Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36914 7.14065C8.36914 5.13517 9.99491 3.5094 12.0004 3.5094C14.0059 3.5094 15.6316 5.13517 15.6316 7.14065V10.8344H8.36914V7.14065ZM12.0004 5.2094C10.9338 5.2094 10.0691 6.07405 10.0691 7.14065V9.1344H13.9316V7.14065C13.9316 6.07405 13.067 5.2094 12.0004 5.2094Z"
        fill="currentColor"
      />
      <path
        d="M6.84375 12.6094C6.84375 13.1789 6.38204 13.6406 5.8125 13.6406C5.24296 13.6406 4.78125 13.1789 4.78125 12.6094C4.78125 12.0398 5.24296 11.5781 5.8125 11.5781C6.38204 11.5781 6.84375 12.0398 6.84375 12.6094Z"
        fill="currentColor"
      />
      <path
        d="M6.84375 19.9844C6.84375 20.5539 6.38204 21.0156 5.8125 21.0156C5.24296 21.0156 4.78125 20.5539 4.78125 19.9844C4.78125 19.4148 5.24296 18.9531 5.8125 18.9531C6.38204 18.9531 6.84375 19.4148 6.84375 19.9844Z"
        fill="currentColor"
      />
      <path
        d="M19.2188 12.6094C19.2188 13.1789 18.757 13.6406 18.1875 13.6406C17.618 13.6406 17.1562 13.1789 17.1562 12.6094C17.1562 12.0398 17.618 11.5781 18.1875 11.5781C18.757 11.5781 19.2188 12.0398 19.2188 12.6094Z"
        fill="currentColor"
      />
      <path
        d="M19.2188 19.9844C19.2188 20.5539 18.757 21.0156 18.1875 21.0156C17.618 21.0156 17.1562 20.5539 17.1562 19.9844C17.1562 19.4148 17.618 18.9531 18.1875 18.9531C18.757 18.9531 19.2188 19.4148 19.2188 19.9844Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 14.8969C11.2272 14.8969 10.6004 15.5237 10.6004 16.2969C10.6004 17.0701 11.2272 17.6969 12.0004 17.6969C12.7736 17.6969 13.4004 17.0701 13.4004 16.2969C13.4004 15.5237 12.7736 14.8969 12.0004 14.8969ZM8.90039 16.2969C8.90039 14.5848 10.2883 13.1969 12.0004 13.1969C13.7125 13.1969 15.1004 14.5848 15.1004 16.2969C15.1004 18.009 13.7125 19.3969 12.0004 19.3969C10.2883 19.3969 8.90039 18.009 8.90039 16.2969Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LockIcon;
