import { isClient } from 'functions/isClient';
import { ITEMS } from 'consts/localStorage';
import * as COMMON from 'consts/common';

class CustomConsole {
  constructor() {
    this.possibleLog = ['log', 'error', 'warn'];
    this.pool = [];
    this.enable = undefined;

    this._init();
  }

  _init = () => {
    if (process.env.REACT_APP_ENV === COMMON.ENV_PRODUCTION) {
      this._enableLogging(isClient() ? !!localStorage.getItem(ITEMS.isLoggingEnabled) : false);
    } else {
      this._enableLogging(true);
    }
  };

  _enableLogging = enable => {
    this.enable = enable;

    if (enable) {
      this._sendMsgFromPool();
    }
  };

  _sendMsgFromPool = () => {
    this.pool.forEach(this._sendMsg);
  };

  _sendMsg = ({ type, msg }) => {
    if (this.possibleLog.includes(type)) {
      console[type](...msg);
    }
  };

  _initSendingMsg = ({ type, msg }) => {
    if (this.enable) {
      this._sendMsg({ type, msg });
    } else if (this.enable === undefined) {
      this.pool.push({ type, msg });
    }
  };

  log = (...msg) => {
    this._initSendingMsg({ type: 'log', msg });
  };

  error = (...msg) => {
    this._initSendingMsg({ type: 'error', msg });
  };

  warn = (...msg) => {
    this._initSendingMsg({ type: 'warn', msg });
  };
}

const CConsole = new CustomConsole();

export default CConsole;
