import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

export const getLists = state => state.lists;

export const getListsLoaded = createSelector([getLists], ({ loaded }) => {
  return loaded;
});

export const getMyLists = createSelector([getLists], lists => {
  const { entities, ids } = lists.lists;

  return ids.map(id => entities[id]);
});

export const getList = createSelector(getLists, ({ lists }) => memoize(listId => lists.entities[listId]));
