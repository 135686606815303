import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: undefined,
  url: undefined,
  loaded: false,
};

export const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {
    setLandingPage: (state, action) => {
      const { id, url } = action.payload;

      return {
        ...state,
        id,
        url,
        loaded: true,
      };
    },
    fetchLandingPageRequest: state => ({
      ...state,
      loaded: false,
    }),
    resetLandingPage: () => initialState,
  },
});

export default landingPageSlice.reducer;
