import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const ContentContainer = styled(Stack)`
  padding: 40px 80px;
  color: ${getColor('primary2')};
  position: relative;
  height: 100%;
`;

export const ArrowButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
`;
