import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, Typography } from '@karma/components';
import Popover from '@karma/components/Popover/Popover';
import useOnClickOutside from '@karma/components/utils/useOnClickOutside';
import Alooma from 'services/alooma';
import {
  PopoverBodyWrapper,
  PopoverWrapper,
  SelectContainer,
  SelectWrapper,
  StyledSelectArrow,
} from './PropertySelect.style';

const PropertySelect = ({ propertyKey, property, propertyChangeHandler }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const closeToggleModal = () => setShowModal(false);

  const { value, values } = property;

  const clickOutsideDropdownConfig = useMemo(() => {
    return {
      enabled: showModal,
      capture: true,
    };
  }, [showModal]);

  useOnClickOutside(containerRef, closeToggleModal, clickOutsideDropdownConfig);

  return (
    <Alooma.SourceContext.Provider value={propertyKey.toLowerCase().replace(/ /g, '_')}>
      <SelectWrapper ref={containerRef}>
        <SelectContainer
          role="button"
          $hasSelectedValue={!!value}
          spacing={8}
          onClick={() => setShowModal(prevValue => !prevValue)}
        >
          <Typography variant="paragraphSmall">{value || t('app:selectProp', { name: propertyKey })}</Typography>
          <StyledSelectArrow size="small" isOpened={showModal} />
        </SelectContainer>

        <PopoverWrapper>
          <Popover
            isOpened={showModal}
            anchorElement={containerRef.current}
            WrapperComponent={PopoverBodyWrapper}
            onExited={() => setShowModal(false)}
          >
            {values.map(propertyValue => (
              <ListItem
                key={propertyValue}
                value={propertyValue}
                onClick={() => {
                  propertyChangeHandler(propertyKey, propertyValue);
                  closeToggleModal();
                }}
              >
                {propertyValue}
              </ListItem>
            ))}
          </Popover>
        </PopoverWrapper>
      </SelectWrapper>
    </Alooma.SourceContext.Provider>
  );
};

export default PropertySelect;
