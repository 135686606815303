import { ButtonVariants } from './Button.types';
import {
  ButtonIconWrap,
  OutlineButton,
  IconButton,
  PrimaryButton,
  SocialButton,
  TextButton,
  TextButtonIconWrap,
  UnderlinedTextButton,
} from './Button.styles';

export const getComponent = (
  type: keyof ButtonVariants
):
  | typeof PrimaryButton
  | typeof OutlineButton
  | typeof SocialButton
  | typeof IconButton
  | typeof TextButton
  | typeof UnderlinedTextButton => {
  switch (type) {
    case 'primary':
      return PrimaryButton;
    case 'outline':
      return OutlineButton;
    case 'social':
      return SocialButton;
    case 'icon1':
      return IconButton;
    case 'icon2':
      return IconButton;
    case 'text':
      return TextButton;
    case 'underlinedText':
      return UnderlinedTextButton;
    default:
      return getComponent('primary');
  }
};

export const getIconWrapper = (
  type: keyof ButtonVariants
): typeof TextButtonIconWrap | typeof ButtonIconWrap =>
  ['text', 'underlinedText'].includes(type) ? TextButtonIconWrap : ButtonIconWrap;
