import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import Grow from '../transitions/Grow';
import { PopoverRoot, PopoverWrapper } from './Popover.styles';
import { PopoverProps } from './Popover.types';

const Popover: React.FC<PopoverProps> = ({
  children,
  style,
  wrapperStyle,

  isOpened = false,
  fixedScreen = false,

  anchorElement = null,
  defaultPlacement = 'bottom-start',
  fallbackPlacements = ['top-start'],
  offset = [0, 8],
  modifiers = [],
  otherPopperSettings = {},

  transitionProps = {},

  onEnter = () => {},
  onEntered = () => {},
  onExit = () => {},
  onExited = () => {},

  WrapperComponent = null,

  ...props
}) => {
  const [focused, setFocused] = useState(isOpened);
  const [height, setHeight] = useState('0');
  const [popperElementRef, setPopperElementRef] = useState(null);

  const popperSettings = {
    ...otherPopperSettings,
    placement: defaultPlacement,
    modifiers: [
      ...modifiers,
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
        },
      },
    ],
  };

  const {
    styles,
    attributes,
    state,
    update: updatePopper,
  } = usePopper(anchorElement, popperElementRef, popperSettings);

  let transformOrigin;
  if (state?.placement.includes('bottom')) transformOrigin = 'top left';
  else if (state?.placement.includes('top')) transformOrigin = 'bottom left';

  useEffect(() => {
    setFocused(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (typeof updatePopper === 'function') updatePopper();
  }, [focused, updatePopper]);

  useEffect(() => {
    if (fixedScreen && focused === true) document.body.style.overflow = 'hidden';
    if (fixedScreen && focused === false) delete document.body.style.overflow;
  }, [fixedScreen, focused]);

  const PopoverWrapperComponent = WrapperComponent || PopoverWrapper;

  const handleEnter = (isAppearing: boolean) => {
    setHeight('unset');

    if (onEnter) onEnter(isAppearing);
  };

  const handleExited = () => {
    setHeight('0');

    if (onExited) onExited();
  };

  return (
    <PopoverRoot
      ref={setPopperElementRef}
      style={{ transformOrigin, ...style, height, ...styles.popper }}
      {...attributes.popper}
      {...props}
    >
      <Grow
        in={isOpened}
        onEnter={handleEnter}
        onExit={onExit}
        onEntered={onEntered}
        onExited={handleExited}
        {...transitionProps}
      >
        <PopoverWrapperComponent style={{ transformOrigin, height, ...wrapperStyle }}>
          {children}
        </PopoverWrapperComponent>
      </Grow>
    </PopoverRoot>
  );
};

export default Popover;
