import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ArrowInCircleUpIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M6.59961 9.67809C9.38363 9.67809 11.6405 7.4212 11.6405 4.63718L11.6406 4.63559L13.3406 4.63559L13.3406 4.63709C13.3406 7.42111 15.5975 9.678 18.3815 9.678V11.378C16.3762 11.378 14.5754 10.5024 13.3406 9.11259L13.3406 19.3629H11.6406L11.6406 9.11256C10.4058 10.5024 8.60498 11.3781 6.59961 11.3781L6.59961 9.67809Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04961 12C2.04961 17.4953 6.50438 21.95 11.9996 21.95C17.4948 21.95 21.9496 17.4953 21.9496 12C21.9496 6.50479 17.4948 2.05003 11.9996 2.05003C6.50438 2.05003 2.04961 6.50479 2.04961 12ZM11.9996 23.65C5.56549 23.65 0.349609 18.4341 0.349609 12C0.349609 5.56591 5.56549 0.350025 11.9996 0.350025C18.4337 0.350024 23.6496 5.56591 23.6496 12C23.6496 18.4341 18.4337 23.65 11.9996 23.65Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ArrowInCircleUpIcon;
