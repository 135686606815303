import styled, { css } from 'styled-components';
import { COLOR_THEMES } from '../themes';
import { LabelMediumStyles } from '../Typography/Typography.styles';
import omitForwardedProps from '../utils/omitForwardedProps';
import { InputBaseRootProps } from './InputBase.types';

export const InputBaseRoot = styled.input.withConfig(
  omitForwardedProps<InputBaseRootProps>(['hasLabel', 'shrink', 'hasSuffix'])
)<InputBaseRootProps>`
  &::placeholder {
    transition-property: transform, opacity, color;
    transition-duration: 0.15s;
    transition-delay: 0.05s;
    transition-timing-function: ease-in-out;
    opacity: ${({ shrink, hasLabel }) => (shrink || !hasLabel ? 1 : 0)};
    transform: ${({ shrink, hasLabel }) =>
      shrink || !hasLabel ? 'translateX(0px)' : 'translateX(10px)'};

    ${({ disabled, theme }) => {
      const colors = theme[theme.color].InputBase;
      const color = disabled ? colors.disabled.placeholder : colors.normal.placeholder;

      return css`
        color: ${color};
        /* required on Safari for disabled input */
        -webkit-text-fill-color: ${color};
      `;
    }};
  }

  ${({ disabled, theme }) => {
    const colors = theme[theme.color].InputBase;
    const color = disabled ? colors.disabled.text : colors.normal.text;

    return css`
      color: ${color};
      /* required on Safari for disabled input */
      -webkit-text-fill-color: ${color};
    `;
  }};

  ${({ theme }) => {
    return (
      theme.color === COLOR_THEMES.DARK &&
      css`
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
      `
    );
  }};

  opacity: 1;
  transition: color 0.3s ease-in-out;
  background: transparent;

  ${LabelMediumStyles};
  display: block;
  min-width: 0;
  width: 100%;
  border: 0;
  box-sizing: content-box;

  &:focus {
    outline: 0;
  }

  &:invalid {
    box-shadow: 0;
  }

  padding: 15px ${({ hasSuffix }) => (hasSuffix ? '0' : '16px')} 15px
    ${({ hasPrefix }) => (hasPrefix ? '0' : '16px')};

  border-radius: 8px;

  ${({ multiline }) =>
    multiline &&
    css`
      height: auto;
      resize: none;
    `};

  @keyframes onAutoFillStart {
  }

  @keyframes onAutoFillCancel {
  }

  &:-webkit-autofill {
    animation-name: onAutoFillStart;
    -webkit-background-clip: text;
  }

  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
`;
