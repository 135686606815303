import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as actions from '../actions';
import * as api from '../api';

function* deleteList(event) {
  try {
    yield call(api.deleteList, event.payload);
    yield put(actions.deleteList(event.payload));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default deleteList;
