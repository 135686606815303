import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const RefreshIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86098 6.2508C8.38213 5.2344 10.1705 4.69189 12 4.69189C13.1743 4.69189 13.9991 4.73155 14.5758 4.79551C15.1084 4.85459 15.554 4.94463 15.8598 5.12978C16.0247 5.22963 16.2888 5.4433 16.3567 5.82279C16.4249 6.20342 16.248 6.4837 16.1502 6.60557C16.048 6.73281 15.9348 6.81392 15.8765 6.85265C15.8423 6.87538 15.812 6.89332 15.7887 6.90633C15.7769 6.91295 15.7664 6.91859 15.7576 6.92323L15.7454 6.9295L15.7402 6.93212L15.7378 6.9333L15.7367 6.93386C15.7362 6.93413 15.7356 6.93439 15.3767 6.20579L15.7356 6.9344L14.9731 7.31002L14.5785 6.50889C14.5222 6.50099 14.459 6.49299 14.3883 6.48515C13.9001 6.43099 13.1451 6.39189 12 6.39189C10.5068 6.39189 9.04704 6.83469 7.80545 7.6643C6.56386 8.4939 5.59616 9.67305 5.02471 11.0526C4.45327 12.4322 4.30376 13.9503 4.59508 15.4148C4.88639 16.8794 5.60546 18.2247 6.66135 19.2805C7.71723 20.3364 9.06252 21.0555 10.5271 21.3468C11.9916 21.6381 13.5097 21.4886 14.8893 20.9172C16.2688 20.3457 17.448 19.378 18.2776 18.1364C19.1072 16.8949 19.55 15.4351 19.55 13.9419V13.0919H21.25V13.9419C21.25 15.7714 20.7075 17.5598 19.6911 19.0809C18.6747 20.6021 17.23 21.7877 15.5398 22.4878C13.8496 23.1879 11.9897 23.3711 10.1954 23.0142C8.40109 22.6572 6.7529 21.7763 5.45927 20.4826C4.16563 19.189 3.28465 17.5408 2.92774 15.7465C2.57083 13.9522 2.75401 12.0923 3.45412 10.4021C4.15423 8.71185 5.33982 7.2672 6.86098 6.2508Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0458 1.0639C12.3778 0.731955 12.916 0.731955 13.2479 1.0639L17.1248 4.94082C17.4568 5.27277 17.4568 5.81096 17.1248 6.1429L13.2479 10.0198C12.916 10.3518 12.3778 10.3518 12.0458 10.0198C11.7139 9.68788 11.7139 9.14969 12.0458 8.81775L15.3217 5.54186L12.0458 2.26598C11.7139 1.93404 11.7139 1.39585 12.0458 1.0639Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RefreshIcon;
