import { call, put, select } from 'redux-saga/effects';
import { DEFAULT_PER_PAGE, SEED } from 'consts/queries';
import { handleServerError } from 'store/alerts/actions';
import * as retailersActions from '../actions';
import * as retailersApi from '../api';
import * as retailersSelectors from '../selectors';
import RETAILERS_SORT_ORDERS from '../../../consts/retailersSortOrders';
import RETAILERS_SORT_BY from '../../../consts/retailersSortBy';

function* fetchRetailers({ payload: { group, page, cashback } }) {
  try {
    const filters = yield select(retailersSelectors.getFilters);
    const response = yield call(retailersApi.getRetailers, {
      group,
      page,
      cashback,
      perPage: DEFAULT_PER_PAGE,
      seed: SEED,
      sortBy:
        filters.sortOrder === RETAILERS_SORT_ORDERS.DEFAULT
          ? RETAILERS_SORT_BY.PRIORITY
          : RETAILERS_SORT_BY.CASHBACK_STAKE,
      ...filters,
    });

    yield put(
      retailersActions.receiveRetailers({
        page,
        retailers: response.data.data,
        retailersLeft: response.data.data.length === DEFAULT_PER_PAGE,
        sectionName: response.data.meta.section_name,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchRetailers;
