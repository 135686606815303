import { createAction } from '@reduxjs/toolkit';
import { userSlice } from './reducer';

export const fetchUser = createAction('user/fetchUser');
export const loginWithToken = createAction('user/loginWithToken');
export const initDemoFlow = createAction('user/initDemoFlow');
export const saveUser = createAction('user/saveUser');

export const updateUserSuccess = createAction('user/updateUserSuccess');
export const updateUserError = createAction('user/updateUserError');

export const updateUser = createAction('user/updateUser');
export const unsubscribePP = createAction('user/unsubscribePP');
export const unsubscribe = createAction('user/unsubscribe');
export const loginAsUser = createAction('user/loginAsUser');
export const updateDonationCause = createAction('user/updateDonationCause');
export const deleteAccount = createAction('user/deleteAccount');

export const { receiveUser, clearPassword, removePPAccount, setAccountDeleteState } = userSlice.actions;
