export const checkConnectPayPalError = checkTime => {
  // Check if user clicked 'Connect with PayPal' button but PayPal account wasn't connected.
  // if (checkTime == true), check if PayPal account wasn't connected for more than 24 hours after button click.

  const timeCondition = checkTime ? localStorage.getItem('connectPPClickExpires') < new Date().getTime() : true;

  if (localStorage.getItem('connectPPClickExpires') && timeCondition) {
    localStorage.removeItem('connectPPClickExpires');
  }
};
