import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { selectLandingPage } from 'store/landingPage/selectors';
import { fetchLandingPageRequest, resetLandingPage } from 'store/landingPage/actions';
import { getCookieValue } from 'functions/cookies';
import { processReferralLink } from 'functions/redirectLink';
import { isClient } from 'functions/isClient';
import configureApiRequests from 'functions/configureApiRequests';
import Alooma from 'services/alooma';
import useInitHotJar from 'services/useInitHotJar';
import useSetCjeInCookies from 'hooks/useSetCjeInCookies';
import setLpTestToStorages from 'functions/setLpTestToStorages';
import useGoogleTagManager from 'hooks/useGoogleTagManager';
import { COOKIES } from 'consts/cookies';
import { PATHS } from 'consts/paths';
import * as ALOOMA from 'consts/alooma';
import { getQueryParamFromUrl } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { Iframe } from './LandingPage.style';

const LandingPage = ({ match }) => {
  const dispatch = useDispatch();
  const { url, loaded } = useSelector(selectLandingPage);
  const landingPageId = match?.params?.id;

  useInitHotJar();
  useSetCjeInCookies();
  useGoogleTagManager();
  Alooma.usePageViewTracker(null, !!(url || loaded));

  useLayoutEffect(() => {
    if (isClient()) configureApiRequests();
  }, []);

  useEffect(() => {
    if (!loaded) dispatch(fetchLandingPageRequest(landingPageId));
  }, [dispatch, loaded, landingPageId]);

  useEffect(() => {
    if (!getCookieValue(COOKIES.logged)) {
      processReferralLink();
    }

    return () => dispatch(resetLandingPage());
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nocache" />
        {url && <link rel="preconnect" href={new URL(url).origin} />}
      </Helmet>
      {loaded && <Iframe title="Karma" src={url} allow="identity-credentials-get" frameBorder="0" />}
    </>
  );
};

const LPTestWrapper = ({ match }) => {
  const { url } = useSelector(selectLandingPage);
  const lpTest = url ? getQueryParamFromUrl(url, QUERY_PARAMS.lpTest) : null;

  const nonStandardAloomaParams = useMemo(
    () => ({
      [ALOOMA.PARAMS.LP_TEST]: lpTest,
    }),
    [lpTest],
  );

  useEffect(() => {
    if (lpTest) {
      setLpTestToStorages(lpTest, match?.params?.id);
    }
  }, [lpTest, match?.params?.id]);

  return (
    <Alooma.NonStandardDataContext.Provider value={nonStandardAloomaParams}>
      <LandingPage match={match} />
    </Alooma.NonStandardDataContext.Provider>
  );
};

export const LandingPageWithRoute = () => {
  return (
    <Switch>
      <Route
        path={[PATHS.LANDING_PAGES, PATHS.LANDING_PAGES_2]}
        render={props => (
          <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.LANDING_LP}>
            <LPTestWrapper {...props} />
          </Alooma.SourceContext.Provider>
        )}
      />
    </Switch>
  );
};
