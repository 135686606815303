import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Typography } from '@karma/components';
import { ENTITIES } from 'consts/entities';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useAction from 'hooks/useAction';
import { deleteListRequest } from 'store/lists/actions';
import { getListsFilters } from 'store/filters/selectors';
import { changeFilter } from 'store/filters/actions';
import { getMainGroupTags } from 'store/myItems/store/selectors';
import TagStack from 'components/organisms/TagStack/TagStack';
import { Container } from './ModalBodyListDelete.style';

const ModalBodyListDelete = ({ closeModal, list }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const location = useLocation();
  const listsFilters = useSelector(getListsFilters);
  const tags = useSelector(getMainGroupTags);
  const size = tags?.length;
  const headTag = tags?.[0];
  const deleteList = useAction(deleteListRequest);
  const toggleValueInFilter = useAction(changeFilter);

  const deleteHandler = useCallback(() => {
    if (list) {
      if (listsFilters.includes(list.id))
        toggleValueInFilter({ filterKey: 'lists', filterValue: list.id, toggleMode: true });
      deleteList(list.id);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, location, listsFilters, closeModal, deleteList, toggleValueInFilter]);

  return (
    <Container>
      <Typography variant="h3">{t('app:sureYouWantToDeleteThisList')}</Typography>
      <TagStack headTag={headTag} multiple={size > 1} />

      <Button
        size="medium"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.YES}
        onClick={deleteHandler}
        fullWidth
      >
        {t('app:deleteList')}
      </Button>
      <Button
        size="medium"
        type="outline"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.NO}
        onClick={closeModal}
        fullWidth
      >
        {t('app:no')}
      </Button>
    </Container>
  );
};

ModalBodyListDelete.propTypes = {
  closeModal: PropTypes.func.isRequired,
  list: ENTITIES.list.isRequired,
};

export default ModalBodyListDelete;
