import { forwardRef } from 'react';
import ReactRangeSlider from 'react-rangeslider';
import PropTypes from 'prop-types';
import { CDN_URL } from '@karma/utils/constants/env';
import Icon from 'components/atoms/Icon/Icon';
import { ControlsContainer, PlayButton, ProgressBarContainer } from './PlayerControls.style';

const PlayerControls = forwardRef(({ onSeek, onPlayPause, playing, played, showControls }, ref) => {
  return (
    <ControlsContainer ref={ref} showControls={showControls}>
      <PlayButton onClick={onPlayPause} playing={playing}>
        <Icon imgUrl={`${CDN_URL}/images/karma/icons/16px_play.svg`} altText="play" />
      </PlayButton>
      <ProgressBarContainer>
        <ReactRangeSlider value={played * 100} min={0} max={100} tooltip={false} onChange={onSeek} />
      </ProgressBarContainer>
    </ControlsContainer>
  );
});

PlayerControls.propTypes = {
  onSeek: PropTypes.func.isRequired,
  onPlayPause: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  showControls: PropTypes.bool.isRequired,
  played: PropTypes.number.isRequired,
};

export default PlayerControls;
