import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${getColor('neutral6')};
  border-radius: 10px;
  width: fit-content;
`;

export const ClipboardWrapper = styled.div`
  text-align: center;
  height: 5px;
`;

export const List = styled.ul`
  margin: 10px -10px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
`;
