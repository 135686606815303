import { createAction } from '@reduxjs/toolkit';
import { favoriteRetailersSlice } from './reducer';

export const requestAddFavoriteRetailers = createAction('favoriteRetailers/requestAddFavoriteRetailers');

export const failureCreateFavoriteRetailers = createAction('favoriteRetailers/failureCreateFavoriteRetailers');

export const requestCreateFavoriteRetailers = createAction('favoriteRetailers/requestCreateFavoriteRetailers');

export const requestDeleteFavoriteRetailers = createAction('favoriteRetailers/requestDeleteFavoriteRetailers');

export const requestFetchFavoriteRetailers = createAction('favoriteRetailers/requestFetchFavoriteRetailers');

export const {
  successCreateFavoriteRetailers,
  initialUpdateCompleted,
  successDeleteFavoriteRetailers,
  successFetchFavoriteRetailers,
} = favoriteRetailersSlice.actions;
