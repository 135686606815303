import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TikTokIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M9.91262 9.19225V12.9708C9.43674 12.8453 8.93877 12.8281 8.45541 12.9207C7.97205 13.0132 7.51561 13.2131 7.11976 13.5055C6.7239 13.7979 6.39871 14.1754 6.16815 14.6102C5.9376 15.045 5.80756 15.526 5.78762 16.0177C5.75963 16.4417 5.82281 16.8668 5.9729 17.2643C6.123 17.6618 6.35651 18.0225 6.65774 18.3221C6.95896 18.6218 7.32089 18.8534 7.71916 19.0015C8.11744 19.1495 8.5428 19.2104 8.96662 19.1803C9.39398 19.2127 9.82331 19.1521 10.225 19.0026C10.6266 18.853 10.9911 18.6181 11.2932 18.3141C11.5952 18.0101 11.8278 17.6441 11.9747 17.2415C12.1216 16.8389 12.1795 16.4091 12.1442 15.982V1H15.8829C16.5195 4.9545 18.4954 5.873 21.145 6.2965V10.086C19.3098 9.93603 17.5393 9.33832 15.9887 8.34525V15.8321C15.986 19.2188 13.9785 23 8.99412 23C8.0592 22.9957 7.13453 22.8049 6.27429 22.4387C5.41406 22.0725 4.63555 21.5384 3.98439 20.8675C3.33323 20.1966 2.82251 19.4025 2.48215 18.5317C2.14178 17.661 1.97863 16.731 2.00224 15.7964C2.03164 14.8231 2.26704 13.8671 2.69293 12.9914C3.11882 12.1157 3.72551 11.3403 4.47299 10.7163C5.22047 10.0922 6.09176 9.63372 7.0294 9.37102C7.96704 9.10833 8.94971 9.0474 9.91262 9.19225Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TikTokIcon;
