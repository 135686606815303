import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import { BONUS_KINDS } from 'consts/bonuses';
import { MODAL_TYPES } from 'consts/modals';
import { DASHBOARD_PATHS } from 'consts/paths';
import { handleServerError } from 'store/alerts/actions';
import { bulkPushBack } from 'store/modals/actions';
import { getUserNotShownBonus, getGuest, getUserId } from 'store/user/selectors';
import useAction from 'hooks/useAction';
import * as api from './api';

const dashboardRegexps = DASHBOARD_PATHS.map(path => pathToRegexp(path));

const checkIsDashboardPath = path => dashboardRegexps.some(regexp => path.match(regexp));

const mapKindToModalType = {
  [BONUS_KINDS.welcome]: MODAL_TYPES.bonusWelcome,
};

const transformBonus = bonus => ({ ...bonus, value: Math.round(bonus.value) });

const Bonuses = {
  useInspector: () => {
    const { pathname } = useLocation();
    const guest = useSelector(getGuest);
    const userId = useSelector(getUserId);
    const userNotShownBonus = useSelector(getUserNotShownBonus);
    const bulkPushBackFunc = useAction(bulkPushBack);
    const inspectedForGuestUser = useRef(false);
    const inspectedForUser = useRef(false);

    useEffect(() => {
      if (
        !userId ||
        !checkIsDashboardPath(pathname) ||
        (guest && inspectedForGuestUser.current) ||
        (!guest && inspectedForUser.current)
      ) {
        return;
      }

      if (userNotShownBonus) {
        const modalType = mapKindToModalType[userNotShownBonus.kind];

        if (modalType) {
          bulkPushBackFunc([{ type: modalType, data: { bonus: transformBonus(userNotShownBonus) } }]);
        }
      }

      if (guest) {
        inspectedForGuestUser.current = true;
      } else {
        inspectedForUser.current = true;
      }
    }, [bulkPushBackFunc, guest, pathname, userId, userNotShownBonus]);
  },

  useNotifier: bonus => {
    const handleServerErrorFunc = useAction(handleServerError);

    useEffect(() => {
      const notify = async () => {
        try {
          await api.notify(bonus.id);
        } catch (error) {
          handleServerErrorFunc(error.response);
        }
      };

      notify();
    }, []);
  },
};

export default Bonuses;
