import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CopyIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28906 4.64285C1.28906 2.51655 3.01277 0.792847 5.13906 0.792847H17.9891V13.6428C17.9891 15.7691 16.2654 17.4928 14.1391 17.4928H1.28906V4.64285ZM5.13906 2.49285C3.95165 2.49285 2.98906 3.45543 2.98906 4.64285V15.7928H14.1391C15.3265 15.7928 16.2891 14.8303 16.2891 13.6428V2.49285H5.13906Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2891 8.49285H17.1859V6.79285H23.9891V19.6428C23.9891 21.7691 22.2654 23.4928 20.1391 23.4928H7.28906V16.6428H8.98906V21.7928H20.1391C21.3265 21.7928 22.2891 20.8303 22.2891 19.6428V8.49285Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CopyIcon;
