import styled, { css } from 'styled-components';
import { BaseIconProps } from './BaseIcon.types';

export const BaseIconRoot = styled.svg<BaseIconProps>`
  vertical-align: middle;

  ${({ width, height }) => css`
    min-width: ${!width ? 'auto' : `${width}px`};
    min-height: ${!height ? 'auto' : `${height}px`};
    max-width: ${!width ? 'none' : `${width}px`};
    max-height: ${!height ? 'none' : `${height}px`};
  `};

  ${({ bMargin }) =>
    bMargin &&
    css`
      margin-bottom: ${bMargin}px;
    `};

  ${({ lMargin }) =>
    lMargin &&
    css`
      margin-left: ${lMargin}px;
    `};

  ${({ rMargin }) =>
    rMargin &&
    css`
      margin-right: ${rMargin}px;
    `};

  ${({ tMargin }) =>
    tMargin &&
    css`
      margin-top: ${tMargin}px;
    `};
`;
