import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as allCouponsActions from '../actions';
import * as allCouponsApi from '../api';

function* fetchLiveCoupons({ payload: { coupon_ids = [], timestamp = null } }) {
  try {
    const response = yield call(allCouponsApi.getLatestOffers, {
      coupon_ids,
      timestamp,
    });

    if (timestamp) {
      yield put(allCouponsActions.recieveNewLiveCoupons(response.data));
    } else {
      yield put(allCouponsActions.recieveLiveCoupons(response.data));
    }
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchLiveCoupons;
