import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const LeftArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4764 1.04294L14.6807 2.24275L4.83826 12.1224L14.7395 22.0424L13.5363 23.2433L2.4375 12.1236L13.4764 1.04294Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6387 12.9929L3.63867 12.9928L3.63867 11.2928L24.6387 11.2929L24.6387 12.9929Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LeftArrowIcon;
