import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TextBubbleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.55078 4.2875C1.55078 2.71349 2.82677 1.4375 4.40078 1.4375H19.6008C21.1748 1.4375 22.4508 2.71349 22.4508 4.2875V14.6875C22.4508 16.2615 21.1748 17.5375 19.6008 17.5375H16.1226L10.6723 22.6089C9.60986 23.5975 7.87475 23.0336 7.59643 21.6093L6.8008 17.5375H4.40078C2.82677 17.5375 1.55078 16.2615 1.55078 14.6875V4.2875ZM4.40078 3.1375C3.76565 3.1375 3.25078 3.65237 3.25078 4.2875V14.6875C3.25078 15.3226 3.76565 15.8375 4.40078 15.8375H7.08903C7.73479 15.8375 8.29013 16.2948 8.41397 16.9286L9.26488 21.2833C9.28744 21.3987 9.42813 21.4445 9.51427 21.3643L15.0653 16.1992C15.3151 15.9667 15.6437 15.8375 15.9849 15.8375H19.6008C20.2359 15.8375 20.7508 15.3226 20.7508 14.6875V4.2875C20.7508 3.65237 20.2359 3.1375 19.6008 3.1375H4.40078Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TextBubbleIcon;
