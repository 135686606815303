import moment from 'moment';
import { setCookie, setCookieForLp } from 'functions/cookies';
import { COOKIES } from 'consts/cookies';
import { ITEMS as LOCAL_STORAGE_ITEMS } from 'consts/localStorage';

const setLpTestToStorages = (lpTest, lpId) => {
  setCookie(`${COOKIES.lpTest}_${lpId}`, lpTest, moment().add(30, 'days').toDate().toUTCString());
  localStorage.setItem(LOCAL_STORAGE_ITEMS.lpTest, lpTest);
};

export const setLpTestToStoragesForLp = (lpTest, lpId) => {
  return `
    (function setLpTestToStoragesForLp() {
      const MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

      ${setCookieForLp()}

      setCookieForLp("${
        COOKIES.lpTest
      }_${lpId}", ${lpTest}, new Date(new Date().getTime() + MONTH_IN_MILLISECONDS).toUTCString());
      localStorage.setItem("${LOCAL_STORAGE_ITEMS.lpTest}", ${lpTest});
    })();
  `;
};

export default setLpTestToStorages;
