import React, { useState, useMemo } from 'react';
import { StoreLogoProps } from './StoreLogo.types';
import { getLogoDimension, getPlaceholderDimension } from './utils';
import { StoreLogoImage, StoreLogoWrap } from './StoreLogo.styles';
import KarmaLogo from '../KarmaLogo';

const StoreLogo: React.FC<StoreLogoProps> = ({
  src = '',
  size = 'medium',
  alt = 'logo',
  ...props
}) => {
  const placeholderDimension = useMemo(() => getPlaceholderDimension(size), [size]);
  const logoDimension = useMemo(() => getLogoDimension(size), [size]);

  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const onErrorImageHandler = () => {
    setShowPlaceholder(true);
  };

  if (showPlaceholder) {
    return (
      <StoreLogoWrap {...props} width={logoDimension} height={logoDimension}>
        <KarmaLogo width={placeholderDimension} variant="symbol" color="primary2" />
      </StoreLogoWrap>
    );
  }

  return (
    <StoreLogoWrap {...props} width={logoDimension} height={logoDimension}>
      <StoreLogoImage
        width={logoDimension}
        height={logoDimension}
        onError={onErrorImageHandler}
        src={src}
        alt={alt}
      />
    </StoreLogoWrap>
  );
};

export default StoreLogo;
