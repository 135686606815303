import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const SavedIconButtonContainer = styled.button`
  all: unset;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: 0.2s;

  &:hover {
    background-color: ${getColor('secondary1')};
    color: ${getColor('neutral6')} !important;
  }
`;

export const PopoverBodyWrapper = styled.div`
  display: block;
  width: calc(100% + 40px);
  margin-left: -20px;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: ${getColor('neutral6')};
`;

export const PopoverWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
`;

export const ToggleButtonWrapper = styled.div`
  position: relative;
  z-index: 1080;
`;
