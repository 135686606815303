import { matchPath } from 'react-router-dom';
import { PATHS } from 'consts/paths';

const getPath = pathName => {
  const match = matchPath(window.location.pathname, pathName);

  return match?.path;
};

export default function () {
  return getPath(PATHS.SEO_TAG) === PATHS.SEO_TAG || getPath(PATHS.SEO_TAG_2) === PATHS.SEO_TAG_2;
}
