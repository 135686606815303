import styled from 'styled-components';
import { FieldWrapperProps } from './FieldWrapper.types';

export const FieldWrapper = styled.div<FieldWrapperProps>`
  position: relative;

  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  padding-top: 8px;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : '250px')};
`;
