import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import { getUserId } from 'store/user/selectors';
import getRetailerLinkProps from 'functions/getRetailerLinkProps';
import Alooma from 'services/alooma';

const DEFAULT_BUTTON_PROPS = {};

const GoToStoreButton = ({ retailer, buttonProps = DEFAULT_BUTTON_PROPS }) => {
  const { t } = useTranslation();

  const source = useContext(ReferralSourceContext);
  const userId = useSelector(getUserId);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const redirectLink = getRetailerLinkProps({ retailer, source, userId })?.href;

  return (
    <Button
      {...buttonProps}
      {...contextDataAttrs}
      as="a"
      target="_blank"
      href={redirectLink}
      data-alooma-element={ALOOMA.ELEMENTS.GO_TO_STORE}
      data-alooma-retailer-id={retailer.self_id}
    >
      {t('app:goToStore')}
    </Button>
  );
};

export default GoToStoreButton;
