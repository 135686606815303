import { combineReducers } from 'redux';
import contentCards from './contentCards/reducer';
import wallet from './wallet/reducer';
import retailer from './retailer/reducer';
import alerts from './alerts/reducer';
import myItems from './myItems/store/reducers';
import user from './user/reducer';
import lists from './lists/reducer';
import filters from './filters/reducer';
import dashboardInterface from './dashboardInterface/reducer';
import categories from './categories/reducer';
import retailers from './retailers/reducer';
import retailersGroups from './retailersGroups/reducer';
import retailersGroupsTitles from './retailersGroupsTitles/reducer';
import notifications from './notifications/reducer';
import coupons from './coupons/reducer';
import favoriteRetailers from './favoriteRetailers/reducer';
import extension from './extension/reducer';
import newSales from './newSales/reducer';
import onboarding from './onboarding/reducer';
import allCoupons from './allCoupons/reducer';
import features from './features/reducer';
import multiEdit from './multiEdit/reducer';
import tag from './tag/reducer';
import shop from './shop/reducer';
import share from './share/reducer';
import modals from './modals/reducer';
import headerBanners from './headerBanners/reducer';
import landingPage from './landingPage/reducer';
import globalSearch from './globalSearch/reducer';

const appReducer = combineReducers({
  alerts,
  allCoupons,
  categories,
  coupons,
  contentCards,
  dashboardInterface,
  extension,
  favoriteRetailers,
  features,
  filters,
  lists,
  modals,
  multiEdit,
  myItems,
  newSales,
  notifications,
  onboarding,
  retailer,
  retailers,
  retailersGroups,
  retailersGroupsTitles,
  share,
  shop,
  tag,
  user,
  wallet,
  headerBanners,
  landingPage,
  globalSearch,
});

export default appReducer;
