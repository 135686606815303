import { useCallback, useState } from 'react';
import { StarIcon, StarIconFilled } from '@karma/components/icons';
import { useSelector } from 'react-redux';
import { Button } from '@karma/components';
import { useTranslation } from 'react-i18next';
import useAction from 'hooks/useAction';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { requestCreateFavoriteRetailers, requestDeleteFavoriteRetailers } from 'store/favoriteRetailers/actions';
import { getIds } from 'store/favoriteRetailers/selectors';
import { FavoritedButton } from './ButtonFavoriteStore.style';

const ButtonFavoriteStore = ({ retailerId }) => {
  const { t } = useTranslation();
  const [isFavoritedButtonHovered, setIsFavoritedButtonHovered] = useState(false);
  const requestCreateFavoriteRetailersFunc = useAction(requestCreateFavoriteRetailers);
  const requestDeleteFavoriteRetailersFunc = useAction(requestDeleteFavoriteRetailers);

  const favoriteRetailersIds = useSelector(getIds);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const isFavoriteRetailer = favoriteRetailersIds.some(id => id === retailerId);

  const handleButtonClick = useCallback(() => {
    if (!isFavoriteRetailer) {
      requestCreateFavoriteRetailersFunc([retailerId]);
    } else {
      requestDeleteFavoriteRetailersFunc([retailerId]);
    }
  }, [isFavoriteRetailer, requestCreateFavoriteRetailersFunc, requestDeleteFavoriteRetailersFunc, retailerId]);

  const commonButtonProps = {
    size: 'small',
    onClick: handleButtonClick,
    ...contextDataAttrs,
    'data-alooma-retailer-id': retailerId,
  };

  return isFavoriteRetailer ? (
    <FavoritedButton
      onMouseEnter={() => setIsFavoritedButtonHovered(true)}
      onMouseLeave={() => setIsFavoritedButtonHovered(false)}
      Icon={isFavoritedButtonHovered ? StarIcon : StarIconFilled}
      {...commonButtonProps}
      data-alooma-element={ALOOMA.ELEMENTS.REMOVE_FROM_FAVORITES}
    >
      {t('app:favorited')}
    </FavoritedButton>
  ) : (
    <Button
      type="outline"
      Icon={StarIcon}
      {...commonButtonProps}
      data-alooma-element={ALOOMA.ELEMENTS.ADD_TO_FAVORITES}
    >
      {t('app:favorite')}
    </Button>
  );
};

export default ButtonFavoriteStore;
