import { useMemo } from 'react';
import { Stack } from '@karma/components';
import Alooma from 'services/alooma';
import { SLIDER_MAX_COUNT } from 'consts/interface';
import CardCoupon from 'components/molecules/CardCoupon/CardCoupon';
import CouponContentCard from 'components/molecules/CouponContentCard/CouponContentCard';
import isContentCardValid from 'functions/isContentCardValid';
import useMultipleLinesGridSlides from 'hooks/useMultipleLinesGridSlides';
import { useCardCouponBreakpoints } from 'hooks/grid/useCardCouponBreakpoint';
import { StyledSwiperSlider } from './SliderCardCoupon.style';

function shuffle(arr) {
  const array = [...arr];

  for (let i = array.length - 1; i > 0; i -= i) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

function mixCoupons(coupons) {
  let newCoupons = coupons.map((coupon, index) => ({
    ...coupon,
    originalIndex: index,
  }));

  const ratedAndSponsoredCoupons = newCoupons.filter(
    coupon => (coupon.exclusive && coupon.rate === '1.0') || coupon.media_type === 'sponsored' || !coupon.exclusive,
  );

  newCoupons = newCoupons.filter(coupon => !ratedAndSponsoredCoupons.includes(coupon));

  const shuffled = shuffle(newCoupons);

  ratedAndSponsoredCoupons.forEach(coupon => {
    shuffled.splice(coupon.originalIndex, 0, coupon);
  });

  return shuffled.slice(0, SLIDER_MAX_COUNT);
}

const SliderCardCoupon = ({ coupons, contentCards, rowsNumber = 1, specialBreakpoints }) => {
  const breakpoints = useCardCouponBreakpoints();

  const mixedCoupons = useMemo(() => mixCoupons(coupons), [coupons]);

  const multipleLinesGridSlides = useMultipleLinesGridSlides(mixedCoupons, breakpoints, rowsNumber);

  if (!multipleLinesGridSlides?.length) return null;

  return (
    <StyledSwiperSlider breakpoints={specialBreakpoints || breakpoints} isMultipleLinesSlider v2>
      {contentCards?.length && isContentCardValid(contentCards[0]) ? (
        <div style={{ width: '100%' }}>
          <CouponContentCard contentCard={contentCards[0]} />
        </div>
      ) : (
        <></>
      )}

      {multipleLinesGridSlides.map((pairOfCoupons, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack key={`C-${index}`} direction="column" spacing={30}>
          {pairOfCoupons.map(coupon =>
            !coupon.ad_package_id ? (
              <div key={coupon.id} style={{ width: '100%' }}>
                <Alooma.CouponIdContext.Provider value={coupon.id}>
                  <Alooma.RetailerIdContext.Provider value={coupon.retailer_id}>
                    <CardCoupon coupon={coupon} />
                  </Alooma.RetailerIdContext.Provider>
                </Alooma.CouponIdContext.Provider>
              </div>
            ) : (
              <div key={coupon.ad_package_id} style={{ width: '100%' }}>
                <Alooma.PackageIdContext.Provider value={coupon.ad_package_id}>
                  <CardCoupon coupon={coupon} adCard />
                </Alooma.PackageIdContext.Provider>
              </div>
            ),
          )}
        </Stack>
      ))}
    </StyledSwiperSlider>
  );
};

export default SliderCardCoupon;
