import { newApiInstance } from 'functions/request';

export const getCouponsGroup = ({ sortBy, page, perPage, retailers } = {}) =>
  newApiInstance.get('/coupons', {
    params: {
      sort_by: sortBy,
      page,
      per_page: perPage,
      filter: {
        retailers: retailers || null,
      },
    },
  });

export const getCouponsWithAds = ({ sortBy, page, perPage, retailers } = {}) =>
  newApiInstance.get('/mixed_coupons', {
    params: {
      sort_by: sortBy,
      page,
      per_page: perPage,
      coupons_filter: {
        retailers: retailers || null,
      },
    },
  });
