import getUserAgent from 'functions/getUserAgent';
import { isClient } from 'functions/isClient';

export const TABLE_REGEX =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

function isTablet() {
  const userAgent = getUserAgent();

  if (!userAgent) return false;
  if (!isClient()) return TABLE_REGEX.test(userAgent.toLowerCase());

  return (
    TABLE_REGEX.test(userAgent.toLowerCase()) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
}

export default isTablet;
