import styled, { css } from 'styled-components';
import { getColor } from '../colors';
import { IBadgeProps } from './Badge.types';

export const BadgeContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const badgePosition = {
  right: {
    top: css`
      top: 0;
      right: 0;
    `,
    bottom: css`
      bottom: 0;
      right: 0;
    `,
  },
  left: {
    top: css`
      top: 0;
      left: 0;
    `,
    bottom: css`
      bottom: 0;
      left: 0;
    `,
  },
};

export const StyledBadge = styled.div<IBadgeProps>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => getColor(color)};
  ${({ positionX, positionY }) => badgePosition[positionX][positionY]}
`;
