import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CashbackIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0633 7.71858C17.2976 7.9529 17.2976 8.3328 17.0633 8.56711L9.06333 16.5671C8.82901 16.8014 8.44911 16.8014 8.2148 16.5671C7.98048 16.3328 7.98048 15.9529 8.2148 15.7186L16.2148 7.71858C16.4491 7.48427 16.829 7.48427 17.0633 7.71858Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43828 7.94286C8.886 7.94286 8.43828 8.39057 8.43828 8.94286C8.43828 9.49514 8.886 9.94286 9.43828 9.94286C9.99057 9.94286 10.4383 9.49514 10.4383 8.94286C10.4383 8.39057 9.99057 7.94286 9.43828 7.94286ZM7.23828 8.94286C7.23828 7.72783 8.22325 6.74286 9.43828 6.74286C10.6533 6.74286 11.6383 7.72783 11.6383 8.94286C11.6383 10.1579 10.6533 11.1429 9.43828 11.1429C8.22325 11.1429 7.23828 10.1579 7.23828 8.94286Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8367 14.3429C15.2844 14.3429 14.8367 14.7906 14.8367 15.3429C14.8367 15.8951 15.2844 16.3429 15.8367 16.3429C16.389 16.3429 16.8367 15.8951 16.8367 15.3429C16.8367 14.7906 16.389 14.3429 15.8367 14.3429ZM13.6367 15.3429C13.6367 14.1278 14.6217 13.1429 15.8367 13.1429C17.0517 13.1429 18.0367 14.1278 18.0367 15.3429C18.0367 16.5579 17.0517 17.5429 15.8367 17.5429C14.6217 17.5429 13.6367 16.5579 13.6367 15.3429Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.858 1.04112C11.8989 0.208421 13.3779 0.20842 14.4187 1.04112L16.434 2.65335C16.6379 2.81647 16.8913 2.90535 17.1524 2.90535H19.0259C20.5999 2.90535 21.8759 4.18134 21.8759 5.75535V7.62878C21.8759 7.88992 21.9647 8.14327 22.1279 8.34718L23.7401 10.3625C24.5728 11.4033 24.5728 12.8824 23.7401 13.9232L22.1279 15.9385C21.9647 16.1424 21.8759 16.3958 21.8759 16.6569V18.5303C21.8759 20.1044 20.5999 21.3803 19.0259 21.3803H17.1524C16.8913 21.3803 16.6379 21.4692 16.434 21.6323L14.4187 23.2446C13.3779 24.0773 11.8989 24.0773 10.858 23.2446L8.8427 21.6323C8.63879 21.4692 8.38543 21.3803 8.1243 21.3803H6.25086C4.67685 21.3803 3.40086 20.1044 3.40086 18.5303V16.6569C3.40086 16.3958 3.31199 16.1424 3.14886 15.9385L1.53663 13.9232C0.703935 12.8824 0.703934 11.4033 1.53663 10.3625L3.14886 8.34718C3.31199 8.14327 3.40086 7.88992 3.40086 7.62878V5.75535C3.40086 4.18133 4.67685 2.90535 6.25086 2.90535H8.1243C8.38543 2.90535 8.63879 2.81647 8.8427 2.65335L10.858 1.04112ZM13.3568 2.3686C12.9368 2.0326 12.34 2.0326 11.92 2.3686L9.90468 3.98082C9.39933 4.3851 8.77145 4.60535 8.1243 4.60535H6.25086C5.61573 4.60535 5.10086 5.12022 5.10086 5.75535V7.62878C5.10086 8.27594 4.88061 8.90382 4.47634 9.40916L2.86411 11.4244C2.52811 11.8444 2.52811 12.4412 2.86411 12.8612L4.47634 14.8765C4.88061 15.3819 5.10086 16.0098 5.10086 16.6569V18.5303C5.10086 19.1655 5.61573 19.6803 6.25086 19.6803H8.1243C8.77145 19.6803 9.39934 19.9006 9.90468 20.3049L11.92 21.9171C12.34 22.2531 12.9368 22.2531 13.3568 21.9171L15.372 20.3049C15.8774 19.9006 16.5053 19.6803 17.1524 19.6803H19.0259C19.661 19.6803 20.1759 19.1655 20.1759 18.5303V16.6569C20.1759 16.0098 20.3961 15.3819 20.8004 14.8765L22.4126 12.8612C22.7486 12.4412 22.7486 11.8444 22.4126 11.4244L20.8004 9.40916C20.3961 8.90382 20.1759 8.27594 20.1759 7.62878V5.75535C20.1759 5.12022 19.661 4.60535 19.0259 4.60535H17.1524C16.5053 4.60535 15.8774 4.3851 15.372 3.98082L13.3568 2.3686Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CashbackIcon;
