import { useEffect } from 'react';
import { Loader, Stack } from '@karma/components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchCouponsGroup, resetCouponsGroup } from 'store/coupons/actions';
import { getCoupons } from 'store/coupons/selectors';
import useAction from 'hooks/useAction';
import { COUPONS_GROUPS } from 'consts/couponsGroups';
import { getSavedItemsFromStore } from 'store/myItems/store/selectors';
import ModalStoreInfoSavedFromStoreCards from 'components/organisms/ModalStoreInfoSavedFromStoreCards/ModalStoreInfoSavedFromStoreCards';
import { fetchSavedItemsFromStore, resetSavedItemsFromStore } from 'store/myItems/store/actions';
import { DEFAULT_PER_GROUP_SAVED_FROM_STORE } from 'consts/queries';
import SliderCardCoupon from 'components/organisms/SliderCardCoupon/SliderCardCoupon';
import GlobalSearchSection from 'components/organisms/GlobalSearchSection/GlobalSearchSection';
import useStoreShippingInfo from 'hooks/useStoreShippingInfo';
import StoreInfoHeader from '../StoreInfoHeader/StoreInfoHeader';
import StoreInfoEmptyState from '../StoreInfoEmptyState/StoreInfoEmptyState';
import { LoaderWrapper, CouponsWrapper } from './ModalBodyStoreInfo.style';

const cardCouponBreakpoints = {
  1025: { slidesPerView: 3 },
  768: { slidesPerView: 2 },
  1: { slidesPerView: 1 },
};

const ModalBodyStoreInfo = ({ retailer }) => {
  const { t } = useTranslation();
  const fetchSavedItemsFromStoreFunc = useAction(fetchSavedItemsFromStore);
  const fetchCouponsGroupFunc = useAction(fetchCouponsGroup);
  const resetCouponsGroupFunc = useAction(resetCouponsGroup);
  const resetSavedItemsFromStoreFunc = useAction(resetSavedItemsFromStore);

  const {
    [COUPONS_GROUPS.mixedForRetailer]: { coupons, loaded: isCouponsLoaded },
  } = useSelector(getCoupons);

  const { loaded: isSavedFromStoreLoaded, totalCount: savedFromStoreCount } = useSelector(getSavedItemsFromStore);

  const ruleId = retailer?.rule_id;

  const couponsCount = coupons?.length || 0;

  const isEmpty = !couponsCount && !savedFromStoreCount;

  const { shippingInfo, shippingInfoLoaded } = useStoreShippingInfo(ruleId);

  useEffect(() => {
    if (retailer) {
      fetchCouponsGroupFunc({
        group: COUPONS_GROUPS.mixedForRetailer,
        retailers: retailer.self_id,
      });
      fetchSavedItemsFromStoreFunc({ retailerId: retailer?.self_id, perGroup: DEFAULT_PER_GROUP_SAVED_FROM_STORE });
    }

    return () => {
      resetCouponsGroupFunc(COUPONS_GROUPS.mixedForRetailer);
      resetSavedItemsFromStoreFunc();
    };
  }, []);

  if (!isCouponsLoaded || (ruleId && !shippingInfoLoaded) || !isSavedFromStoreLoaded) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Stack direction="column" alignItems="flex-start" fullWidth spacing={32}>
      <StoreInfoHeader retailer={retailer} shippingInfo={shippingInfo} />

      {isEmpty ? (
        <StoreInfoEmptyState retailer={retailer} />
      ) : (
        <>
          {!!couponsCount && (
            <GlobalSearchSection
              title={t('app:coupons')}
              subtitle={t('app:availableCoupons', { count: couponsCount })}
              spacing={12}
            >
              <CouponsWrapper direction="column" alignItems="flex-start">
                <SliderCardCoupon
                  coupons={coupons}
                  rowsNumber={2}
                  maxSliderCount={couponsCount}
                  specialBreakpoints={cardCouponBreakpoints}
                />
              </CouponsWrapper>
            </GlobalSearchSection>
          )}

          {!!savedFromStoreCount && (
            <GlobalSearchSection
              title={t('app:savedFromStore')}
              subtitle={t('app:itemFound', { count: savedFromStoreCount })}
              spacing={12}
            >
              <ModalStoreInfoSavedFromStoreCards retailerId={retailer.self_id} />
            </GlobalSearchSection>
          )}
        </>
      )}
    </Stack>
  );
};

export default ModalBodyStoreInfo;
