import { useState, useEffect } from 'react';
import { oldApiInstance } from 'functions/request';
import CConsole from 'functions/CConsole';

const usePriceEvolutionGetter = (id, params = {}) => {
  const [isDisplayChart, setIsDisplayChart] = useState(true);
  const [receivedData, setReceivedData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (id) {
      setIsFetching(true);
      oldApiInstance
        .get(`/shop/tags/${id}/price_evolution`, params)
        .then(({ data }) => {
          setReceivedData(data);
          setIsFetching(false);
          setIsDisplayChart(!!data?.dates?.length);
        })
        .catch(error => {
          setIsFetching(false);
          setIsDisplayChart(false);
          CConsole.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { isDisplayChart, receivedData, isFetching };
};

export default usePriceEvolutionGetter;
