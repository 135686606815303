import { createAction } from '@reduxjs/toolkit';
import { allCouponsSlice } from './reducer';

export const fetchAllCoupons = createAction('allCoupons/fetchAllCoupons');
export const fetchLiveCoupons = createAction('allCoupons/fetchLiveCoupons');

export const {
  setCoupons,
  addCoupons,
  allCouponsLoaded,
  toggleLoading,
  recieveLiveCoupons,
  recieveNewLiveCoupons,
  moveNewCoupons,
} = allCouponsSlice.actions;
