import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ShowPasswordIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6375 9.39263C11.1187 9.39263 9.8875 10.6238 9.8875 12.1426C9.8875 13.6614 11.1187 14.8926 12.6375 14.8926C14.1563 14.8926 15.3875 13.6614 15.3875 12.1426C15.3875 10.6238 14.1563 9.39263 12.6375 9.39263ZM8.1875 12.1426C8.1875 9.68496 10.1798 7.69263 12.6375 7.69263C15.0952 7.69263 17.0875 9.68496 17.0875 12.1426C17.0875 14.6003 15.0952 16.5926 12.6375 16.5926C10.1798 16.5926 8.1875 14.6003 8.1875 12.1426Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79049 12.1426C4.80346 15.9788 8.4858 18.4926 12.6383 18.4926C16.7908 18.4926 20.4731 15.9788 22.4861 12.1426C20.4731 8.30643 16.7908 5.79262 12.6383 5.79262C8.4858 5.79262 4.80346 8.30643 2.79049 12.1426ZM1.0738 11.771C3.27798 7.2363 7.60284 4.09262 12.6383 4.09262C17.6737 4.09262 21.9986 7.2363 24.2028 11.771C24.3168 12.0056 24.3168 12.2796 24.2028 12.5142C21.9986 17.0489 17.6737 20.1926 12.6383 20.1926C7.60284 20.1926 3.27798 17.0489 1.0738 12.5142C0.959773 12.2796 0.959773 12.0056 1.0738 11.771Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ShowPasswordIcon;
