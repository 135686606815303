import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const Container = styled(Stack)`
  width: 100%;
  border-radius: 10px;
  background-color: ${getColor('shades14')};
`;

export const LeftContainer = styled(Stack)`
  background-color: ${getColor('neutral6')};
  border-radius: 10px 0px 0px 10px;
  padding: 100px 20px;
  text-align: center;
  min-width: 445px;
`;

export const RightContainer = styled(Stack)`
  height: 100%;
`;

export const GiftCardImage = styled.img`
  width: 234px;
  height: 251px;
`;
