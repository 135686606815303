import PropTypes from 'prop-types';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import Modal from 'components/templates/Modal/Modal';
import ModalProductBody from 'components/organisms/ModalProductBody/ModalProductBody';

const ModalProduct = props => {
  const { closeModal, tagId, sourcePage } = props;

  return (
    <Alooma.TagIdContext.Provider value={tagId}>
      <Modal
        source={sourcePage}
        aloomaModalType={ALOOMA.MODAL_TYPES.WINDOW}
        aloomaModalName={ALOOMA.MODAL_NAMES.PRODUCT}
        sendPageView={false}
        onClose={closeModal}
      >
        <ModalProductBody {...props} />
      </Modal>
    </Alooma.TagIdContext.Provider>
  );
};

ModalProduct.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tagId: PropTypes.number.isRequired,
  sourcePage: PropTypes.string.isRequired,
};

export default ModalProduct;
