import { call, put, select } from 'redux-saga/effects';
import { DEFAULT_RESPONSE_COUNT, JAVA_FILTERS } from 'consts/wallet';
import { handleServerError } from 'store/alerts/actions';

function* fetch({ entity, filterSelector, getRequest, isJavaRequest, saveAction }) {
  try {
    const filter = yield select(filterSelector);
    const page = 1;
    const parameters = {
      filter: isJavaRequest && filter === JAVA_FILTERS.all ? undefined : filter,
      page,
    };
    const { data } = yield call(getRequest, parameters);

    yield put(
      saveAction({
        [entity]: isJavaRequest ? data.list : data,
        hasMore: isJavaRequest ? !data['last-page'] : data.length === DEFAULT_RESPONSE_COUNT,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetch;
