import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VariableSizeList as List } from 'react-window';
import Typography from '@karma/components/Typography';
import { Loader } from '@karma/components';
import Alooma from 'services/alooma';
import { RetailersDBContext } from 'contexts/RetailersDBContext';
import RetailersCatalogueRecord from 'components/molecules/RetailersCatalogueRecord/RetailersCatalogueRecord';
import * as favoriteRetailersSelectors from 'store/favoriteRetailers/selectors';
import { Container, LoadingContainer, StyledDivider } from './RetailersCatalogue.style';

const LETTER_RECORD_HEIGHT = 64;
const RECORD_HEIGHT = 48;
const RECORD_OFFSET = 20;
const LIST_HEIGHT = 380;

const RetailersCatalogue = ({ query }) => {
  const { t } = useTranslation();

  const listRef = useRef(null);

  // TODO use useFavoriteRetailers
  const retailersDB = useContext(RetailersDBContext);

  const favoriteRetailersIds = useSelector(favoriteRetailersSelectors.getIds);

  const [lettersAndRetailers, setLettersAndRetailers] = useState([]);
  const [isConfigured, setIsConfigured] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleRecordClick = useCallback(
    event => {
      const record = lettersAndRetailers[event.currentTarget.dataset.index];
      let newSelected;

      if (selected.findIndex(({ id }) => id === record?.id) !== -1) {
        newSelected = selected.filter(({ id }) => id !== record?.id);
      } else {
        newSelected = [record];
      }

      if (newSelected?.length) {
        setSelected(newSelected);
      }
    },
    [selected, lettersAndRetailers],
  );

  const renderItems = useCallback(
    listProps => {
      const { index, data, style } = listProps;
      const record = data.records[index];
      const isLetter = Boolean(record.symbol);

      return (
        <div style={{ ...style, paddingRight: 0 }}>
          {isLetter ? (
            <>
              <Typography variant="h5" bMargin={8}>
                {record.symbol}
              </Typography>
              <StyledDivider color="neutral3" />
            </>
          ) : (
            <Alooma.RetailerIdContext.Provider value={record.id}>
              <RetailersCatalogueRecord {...listProps} record={record} onRecordClick={handleRecordClick} />
            </Alooma.RetailerIdContext.Provider>
          )}
        </div>
      );
    },
    [handleRecordClick],
  );

  const getItemSize = useCallback(
    index => {
      if (lettersAndRetailers[index].symbol) {
        return LETTER_RECORD_HEIGHT;
      }

      if (lettersAndRetailers.length === index + 1) {
        return RECORD_HEIGHT - RECORD_OFFSET;
      }

      if (lettersAndRetailers[index + 1]?.symbol) {
        return RECORD_HEIGHT + RECORD_OFFSET;
      }

      return RECORD_HEIGHT;
    },
    [lettersAndRetailers],
  );

  useEffect(() => {
    // ! Mounted variable is used to skip out-of-order responses
    // ! https://ru.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    let mounted = true;

    const getRetailersSlice = async () => {
      const { isConfigured: isRetailersDBConfigured, getFavoriteRetailersSlice } = retailersDB;

      if (isRetailersDBConfigured) {
        // eslint-disable-next-line no-shadow
        const { lettersAndRetailers } = await getFavoriteRetailersSlice(query, favoriteRetailersIds);

        if (mounted) {
          setLettersAndRetailers(lettersAndRetailers);
          setIsConfigured(true);
        }

        if (listRef.current) {
          // Recalculates dimensions for each rendering item from the beginning
          listRef.current.resetAfterIndex(0);
          listRef.current.scrollTo(0);
        }
      }
    };

    getRetailersSlice();

    return () => {
      mounted = false;
    };
  }, [retailersDB, query, favoriteRetailersIds]);

  const itemData = useMemo(() => ({ records: lettersAndRetailers, selected }), [lettersAndRetailers, selected]);

  if (!isConfigured) {
    return (
      <LoadingContainer>
        <Loader height={`${LIST_HEIGHT}px`} />
      </LoadingContainer>
    );
  }

  if (lettersAndRetailers.length) {
    return (
      <Container>
        <List
          ref={listRef}
          itemCount={lettersAndRetailers.length}
          itemData={itemData}
          itemSize={getItemSize}
          height={LIST_HEIGHT}
          width="100%"
        >
          {renderItems}
        </List>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="paragraphLarge">{t('app:nothingFound')}</Typography>
    </Container>
  );
};

RetailersCatalogue.propTypes = {
  query: PropTypes.string.isRequired,
};

export default RetailersCatalogue;
