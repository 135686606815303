import { useSelector } from 'react-redux';
import { getRedirectLinkToTag } from '@karma/utils/functions/redirectLink';
import Typography from '@karma/components/Typography';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { getUserId } from 'store/user/selectors';
import { EFFECT, THRESHOLD } from 'consts/lazyLoadParams';
import hasDiscount from 'functions/hasDiscount';
import checkIsTagOutOfStock from 'functions/checkIsTagOutOfStock';
import checkItemHasPrice from 'functions/checkItemHasPrice';
import {
  CardItemDescription,
  CardItemBrand,
  StyledLazyLoadImage,
  LinkSlide,
  CardBody,
  OriginalPrice,
} from './SimilarTagCard.style';

const SimilarTagCard = ({ tag }) => {
  const userId = useSelector(getUserId);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const {
    main_img_url: mainImgUrl,
    name,
    price,
    marker,
    stake,
    id,
    rule_name: ruleName,
    original_url: originalUrl,
  } = tag;

  const redirectUrl = getRedirectLinkToTag({
    tagId: id,
    ruleName,
    userId,
    cashback: !!stake,
    source: 'DASHBOARD_similar',
    originalUrl,
  });

  return (
    <LinkSlide
      target="_blank"
      href={redirectUrl}
      rel="nofollow"
      {...contextDataAttrs}
      data-alooma-element={ALOOMA.ELEMENTS.SIMILAR_ITEM}
    >
      <StyledLazyLoadImage alt={name} srcSet={mainImgUrl} effect={EFFECT} threshold={THRESHOLD} />
      <CardBody>
        {checkItemHasPrice(price.current_price?.full) && !checkIsTagOutOfStock(marker) ? (
          <>
            {hasDiscount(price?.current_price, price?.current_price, price?.currency) && (
              <OriginalPrice>{price.original_price.humanized}</OriginalPrice>
            )}
            <Typography variant="labelMedium">{price.current_price.humanized}</Typography>
          </>
        ) : (
          <Typography variant="labelMedium">&nbsp;</Typography>
        )}

        <CardItemDescription>{name}</CardItemDescription>
        {ruleName && <CardItemBrand>{ruleName}</CardItemBrand>}
      </CardBody>
    </LinkSlide>
  );
};

export default SimilarTagCard;
