import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'lottie-web';
import { AnimationWrapper } from './LottieAnimation.styles';
import { LottieAnimationProps } from './LottieAnimation.types';

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  autoplay = true,
  loop = true,
  name = uuidv4(),
  path,
  renderer = 'svg',
  width = '200px',
  height = '200px',
  ...rest
}) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    const container = lottieRef.current;

    const animationItem = container
      ? Lottie.loadAnimation({
          autoplay,
          container,
          loop,
          name,
          path,
          renderer,
        })
      : null;

    return () => {
      if (animationItem?.name) Lottie.destroy(animationItem.name);
    };
  }, [autoplay, loop, name, path, renderer]);

  return <AnimationWrapper ref={lottieRef} width={width} height={height} {...rest} />;
};

export default React.memo(LottieAnimation);
