import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AppleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M21.3542 16.4869C20.0162 15.9809 19.1212 14.7659 19.0202 13.3169C18.9212 11.9049 19.6132 10.6509 20.8712 9.96191L21.9172 9.38891L21.1702 8.45891C19.9152 6.89591 18.1192 5.96191 16.3662 5.96191C15.1512 5.96191 14.3082 6.27991 13.6312 6.53591C13.1532 6.71691 12.7762 6.85891 12.3622 6.85891C11.8902 6.85891 11.4242 6.69291 10.8842 6.50091C10.1762 6.24891 9.37422 5.96291 8.34422 5.96291C6.35422 5.96291 4.34722 7.15091 3.10722 9.06091C1.25622 11.9099 1.76422 16.7949 4.31522 20.6769C5.32622 22.2149 6.74322 23.9819 8.75022 23.9999C8.76322 23.9999 8.77622 23.9999 8.78922 23.9999C10.4322 23.9999 10.7922 23.1239 12.3872 23.1139C14.1292 23.1959 14.3492 24.0069 15.9762 23.9959C17.9372 23.9779 19.3512 22.2249 20.4752 20.5119C21.1392 19.5049 21.3962 18.9779 21.9132 17.8339L22.3512 16.8639L21.3542 16.4869Z"
        fill="currentColor"
      />
      <path
        d="M15.1012 3.44996C15.7512 2.61596 16.2442 1.43896 16.0652 0.235962C15.0032 0.308962 13.7632 0.983962 13.0382 1.86396C12.3802 2.66296 11.8372 3.84696 12.0482 4.99896C13.2062 5.03496 14.4052 4.34296 15.1012 3.44996Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AppleIcon;
