import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tagsLeft: true,
  loaded: false,
  loading: false,
  tags: [],
  page: 1,
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    receiveTags: (_, { payload: { page, tags_left, tags } }) => ({
      tagsLeft: tags_left,
      tags,
      page,
      loaded: true,
      loading: false,
    }),
    setTags: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    resetTags: () => initialState,
  },
});

export default shopSlice.reducer;
