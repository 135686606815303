import PropTypes from 'prop-types';
import { COLOR_THEMES } from '@karma/components/themes';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import ModalBodyDeleteAccount from 'components/molecules/ModalBodyDeleteAccount/ModalBodyDeleteAccount';

const ModalManageStores = props => {
  const { closeModal } = props;

  return (
    <Modal
      source={ALOOMA.SOURCE_PAGES.PROFILE_ACCOUNT_SETTINGS}
      aloomaModalType={ALOOMA.MODAL_TYPES.POPUP}
      aloomaModalName={ALOOMA.MODAL_NAMES.DELETE_ACCOUNT}
      onClose={closeModal}
      colorTheme={COLOR_THEMES.LIGHT}
    >
      <ModalBodyDeleteAccount {...props} />
    </Modal>
  );
};

ModalManageStores.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onCloseCallback: PropTypes.func,
  sourcePage: PropTypes.string.isRequired,
};

ModalManageStores.defaultProps = {
  onCloseCallback: null,
};

export default ModalManageStores;
