import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const FacebookIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M24 12.005C24 5.381 18.624 0.00500488 12 0.00500488C5.376 0.00500488 0 5.381 0 12.005C0 17.813 4.128 22.649 9.6 23.765V15.605H7.2V12.005H9.6V9.005C9.6 6.689 11.484 4.805 13.8 4.805H16.8V8.405H14.4C13.74 8.405 13.2 8.945 13.2 9.605V12.005H16.8V15.605H13.2V23.945C19.26 23.345 24 18.233 24 12.005Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default FacebookIcon;
