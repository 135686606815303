import { call, put } from 'redux-saga/effects';
import { DEFAULT_PER_PAGE } from 'consts/queries';
import { handleServerError } from 'store/alerts/actions';
import * as newSalesActions from '../actions';
import * as newSalesApi from '../api';

function* requestGetNewSales(action) {
  try {
    const { page, biscotti } = action.payload;

    const response = yield call(newSalesApi.getNewSales, {
      page,
      perPage: DEFAULT_PER_PAGE,
      biscotti,
    });

    yield put(
      newSalesActions.successGetNewSales({
        newSales: response.data.data,
        page,
        newSalesLeft: response.data.data.length === DEFAULT_PER_PAGE,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default requestGetNewSales;
