import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as userActions from '../actions';
import * as userApi from '../api';

function* updateDonationCause(action) {
  try {
    const response = yield call(userApi.putUser, action.payload);

    yield put(userActions.saveUser(response.data));
  } catch (error) {
    yield put(handleServerError(error?.response));
  }
}

export default updateDonationCause;
