import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { BLACK_005 } from 'consts/colors';

export const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const StyledReactPlayer = styled(ReactPlayer)`
  min-height: 145px;
  min-width: 270px;
  & video {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 ${BLACK_005};
  }
`;
