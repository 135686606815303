import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as ALOOMA from 'consts/alooma';
import { PATHS } from 'consts/paths';
import Alooma from 'services/alooma';

const TermsAndPrivacy = () => {
  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <Trans i18nKey="app:termsAgreement">
      By signing up, you agree to our &nbsp;
      <Link {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.TERMS} target="_blank" to={PATHS.TERMS}>
        Terms
      </Link>
      &nbsp;and&nbsp;
      <Link {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.PRIVACY} target="_blank" to={PATHS.PRIVACY}>
        Privacy Policy
      </Link>
    </Trans>
  );
};

export default TermsAndPrivacy;
