import { useEffect, useState } from 'react';
import { lambdaApiInstance } from 'functions/request';
import CConsole from 'functions/CConsole';

const SHIPPING_INFO_API = '/price/v2/shipping-details';

const useStoreShippingInfo = ruleId => {
  const [loaded, setLoaded] = useState(false);
  const [shippingInfo, setShippingInfo] = useState();

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        const { data } = (await lambdaApiInstance.get(`${SHIPPING_INFO_API}?rulesIds=${ruleId}`)) || {};

        if (data?.valid) {
          setShippingInfo(Object.values(data.data)[0]);
        }
      } catch (error) {
        CConsole.error(error);
      } finally {
        setLoaded(true);
      }
    };

    if (!loaded && ruleId) {
      fetchShippingInfo();
    }
  }, [loaded, ruleId]);

  return { shippingInfo, shippingInfoLoaded: loaded };
};

export default useStoreShippingInfo;
