import getBrowser from 'functions/getBrowser';
import { ITEMS } from 'consts/localStorage';
import { PATHS } from 'consts/paths';
import { EXTENSION_UNINSTALL_CALENDLY_CLICKED } from 'consts/braze';
import * as COMMON from 'consts/common';
import BROWSERS from 'consts/browsers';
import { isClient } from 'functions/isClient';
import EventObserver from 'functions/EventObserver';
import { retry } from 'functions/lazyImport';

const brazeSdkObserver = new EventObserver();

let braze;

(async () => {
  if (isClient()) {
    try {
      braze = await retry(() => import('@braze/web-sdk'));
      if (braze) brazeSdkObserver.broadcastOnce();
    } catch (error) {
      if (window.Rollbar) window.Rollbar.error('Module import failed (Braze SDK)', { error });
    }
  }
})();

const resubscribeUserToWebPush = Braze => {
  try {
    if (!localStorage.getItem(ITEMS.webPushResubscribed) && Braze.getUser()) {
      Braze.unregisterPush(() => {
        Braze.requestPushPermission(() => {
          localStorage.setItem(ITEMS.webPushResubscribed, 'true');
        });
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const fetchContentCards = cardsCallBack => {
  try {
    if (!isClient()) return;

    if (!braze) {
      brazeSdkObserver.subscribe(() => fetchContentCards(cardsCallBack));

      return;
    }

    if (window.braze) {
      braze.subscribeToContentCardsUpdates(updates => {
        const { cards } = updates;

        cardsCallBack(cards.filter(({ clicked }) => !clicked));
      });
      braze.requestContentCardsRefresh();
    }
  } catch (e) {
    console.error(e);
  }
};

export const logContentCardClick = card => {
  try {
    if (!isClient()) return;

    if (!braze) {
      brazeSdkObserver.subscribe(() => logContentCardClick(card));

      return;
    }

    if (window.braze) {
      braze.logCardClick(card, true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const logContentCardImpression = card => {
  try {
    if (!isClient()) return;

    if (!braze) {
      brazeSdkObserver.subscribe(() => logContentCardImpression(card));

      return;
    }

    if (window.braze) {
      braze.logCardImpressions([card], true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const trackBrazeUninstall = () => {
  try {
    if (!isClient()) return;

    if (!braze) {
      brazeSdkObserver.subscribe(trackBrazeUninstall);

      return;
    }

    if (window.braze) {
      braze?.getUser()?.setCustomUserAttribute(EXTENSION_UNINSTALL_CALENDLY_CLICKED, true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const setBrazeToken = brazeToken => {
  if (!brazeToken) return;

  localStorage.setItem(ITEMS.brazeToken, brazeToken);
};

export const removeBrazeToken = () => {
  localStorage.removeItem(ITEMS.brazeToken);
};

// eslint-disable-next-line consistent-return
export const initBraze = () => {
  try {
    if (!isClient()) return;

    if (!braze) {
      brazeSdkObserver.subscribe(initBraze);

      return;
    }

    const userId = localStorage.getItem(ITEMS.userId);
    const brazeToken = localStorage.getItem(ITEMS.brazeToken);

    const shouldInitialize =
      process.env.REACT_APP_BRAZE_API_KEY &&
      userId &&
      !window.braze &&
      window.location.pathname !== PATHS.REDIRECT &&
      brazeToken;

    if (!shouldInitialize) {
      return;
    }

    const isInitialized = braze.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
      baseUrl: 'https://sdk.fra-01.braze.eu',
      safariWebsitePushId: 'web.com.karmanow.push',
      allowUserSuppliedJavascript: true,
      enableSdkAuthentication: true,
      enableLogging:
        process.env.REACT_APP_ENV !== COMMON.ENV_PRODUCTION ||
        (isClient() && !!localStorage.getItem(ITEMS.isBrazeLoggingEnabled)) ||
        false,
    });

    if (isInitialized === true) {
      window.braze = braze;
      braze.changeUser(userId, brazeToken);
      braze.openSession();
      braze.subscribeToInAppMessage(inAppMessage => {
        if (
          window.location.href.includes(PATHS.HOME) ||
          window.location.href.includes(PATHS.ITEMS) ||
          window.location.href.includes(PATHS.DASHBOARD)
        ) {
          braze.showInAppMessage(inAppMessage);
        }
      });
      resubscribeUserToWebPush(braze);

      if (getBrowser() === BROWSERS.SAFARI) {
        document.addEventListener('click', braze.requestPushPermission, { once: true });
      } else {
        braze.requestPushPermission();
      }
    }
  } catch (e) {
    console.error(e);
  }
};
