import { useTranslation } from 'react-i18next';
import { WhatsappIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';

const WhatsappShareButton = ({ description, url }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const message = `${t(description || `app:whenIFindSmth`)} ${encodeURI(url)}`;
  const whatsappShareLink = `https://wa.me/?text=${message}`;

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: whatsappShareLink,
    'data-alooma-element': ALOOMA.ELEMENTS.WHATSAPP,
    ...contextDataAttrs,
  };

  return (
    <a {...linkProps}>
      <WhatsappIcon size="medium" />
    </a>
  );
};

export default WhatsappShareButton;
