// eslint-disable-next-line import/no-extraneous-dependencies
import distance from 'jaro-winkler';
import isString from 'lodash/isString';

/**
 * Sanitizes a retailer's name by converting to lowercase and removing non-alphanumeric characters.
 * @param {string} str - The string to sanitize.
 * @returns {string} The sanitized string.
 */
function sanitizeRetailerName(str) {
  if (!isString(str)) return '';

  return str
    .toLowerCase()
    .replace(/[^\p{L}\p{N}]+/gu, '')
    .replace(/ /g, '');
}

/** @constant {number} THRESHOLD - The similarity threshold for matching */
const THRESHOLD = 0.85;

/**
 * Searches for a string using the Jaro-Winkler distance algorithm, considering only sanitized retailer names.
 * @param {string} stringToMatch - The string to match against.
 * @param {string} query - The query string.
 * @returns {number} A value between 0 and 1 representing the match strength, where 1 is an exact match.
 */
function jaroWinklerSearch(stringToMatch, query) {
  if (stringToMatch != null) {
    const sanitizedQuery = sanitizeRetailerName(query);
    const retailerName = sanitizeRetailerName(stringToMatch ?? '');

    const queryDistance = distance(sanitizedQuery, retailerName);

    if (queryDistance >= THRESHOLD) return queryDistance;
    if (retailerName.startsWith(sanitizedQuery)) return 1;
    if (retailerName.includes(sanitizedQuery)) return 0.99;
  }

  return 0;
}

export default jaroWinklerSearch;
