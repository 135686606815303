import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Stack, TextField, Typography } from '@karma/components';
import useAction from 'hooks/useAction';
import { handleServerError } from 'store/alerts/actions';
import * as userSelectors from 'store/user/selectors';
import { newApiInstance } from 'functions/request';
import { setCookie } from 'functions/cookies';
import { COOKIES } from 'consts/cookies';

const ModalBodyDoNotSellMyInfo = ({ closeModal }) => {
  const { t } = useTranslation();
  const handleServerErrorFunc = useAction(handleServerError);
  const { name, email, last_name, first_name } = useSelector(userSelectors.getUser);

  const submitHandler = () => {
    newApiInstance.get('/users/do_not_sell_my_personal_information').catch(handleServerErrorFunc);
    setCookie(COOKIES.doNotSellMyInfo, true);
    closeModal();
  };

  return (
    <Stack fullWidth direction="column" spacing={20}>
      <TextField
        id="first_name"
        label={t('app:firstName')}
        type="text"
        placeholder={t('app:enterFirstName')}
        value={first_name || name}
        disabled
        fullWidth
      />

      <TextField
        id="last_name"
        label={t('app:lastName')}
        type="text"
        placeholder={t('app:enterLastName')}
        value={last_name}
        disabled
        fullWidth
      />
      <TextField
        id="email"
        label={t('app:email')}
        type="email"
        placeholder={t('app:enterEmailLower')}
        value={email}
        disabled
        fullWidth
      />
      <Typography variant="labelSmall" align="center">
        {t('app:ccpaText')}
      </Typography>
      <Button size="medium" fullWidth onClick={submitHandler}>
        {t('app:submit')}
      </Button>
    </Stack>
  );
};

export default ModalBodyDoNotSellMyInfo;
