import { useEffect } from 'react';

const useAuthorizationListener = () => {
  useEffect(() => {
    const handleStorageChange = event => {
      // If the token is changed, added or removed, then the session has been modified
      // The page is reloaded to handle this
      if (event.key === 'token') {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
};

export default useAuthorizationListener;
