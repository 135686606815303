import { useMemo } from 'react';
import chunk from 'lodash/chunk';
import useCurrentBreakPoint from 'hooks/useCurrentBreakPoint';

/**
 * @description return element matrix for the swiper slider
 * @param {Array} items - array of items
 * @param {Object} breakpoints - swiper breakpoints
 * @param {Number} rowsNumber - number of rows in slider
 */
function useMultipleLinesGridSlides(items, breakpoints, rowsNumber) {
  const currentBreakpoint = useCurrentBreakPoint(breakpoints);

  return useMemo(() => {
    if (!currentBreakpoint) return [];

    const { slidesPerView } = breakpoints[currentBreakpoint];

    /**
     * @description Elements are enough for the whole screen
     * @example
     * items: [1, 2, 3, 4, 5, 6, 7, 8, 9]
     * rowsNumber: 2
     * slidesPerView: 3
     * result: [[1, 2], [3, 4], [5, 6], [7, 8], [9]]
     */
    if (items.length >= slidesPerView * rowsNumber) {
      return chunk(items, rowsNumber);
    }

    /**
     * @description Elements are not enough for the whole screen
     * @example
     * items: [1, 2, 3, 4, 5]
     * rowsNumber: 2
     * slidesPerView: 3
     * result: [[1, 4], [2, 5], [3]]
     */
    return items.reduce((result, item, index) => {
      const chunkIndex = index % slidesPerView;

      if (!result[chunkIndex]) {
        // eslint-disable-next-line no-param-reassign
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);

      return result;
    }, []);
  }, [items, breakpoints, rowsNumber, currentBreakpoint]);
}

export default useMultipleLinesGridSlides;
