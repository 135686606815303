import { call, put } from 'redux-saga/effects';
import { showErrorAlert } from 'store/alerts/actions';
import * as actions from '../actions';
import * as api from '../api';

function* addList(event) {
  try {
    const { data } = yield call(api.addList, event.payload);

    yield put(actions.addNewList(data.data));
  } catch (error) {
    yield put(
      showErrorAlert({
        message: error?.response?.data?.errors?.[0] || error?.response?.statusText,
      }),
    );
  }
}

export default addList;
