import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as userSelectors from 'store/user/selectors';
import getLanguage from 'functions/getLanguage';
import initKustomer from 'services/initKustomer';
import { isClient } from 'functions/isClient';
import { newApiInstance } from 'functions/request';
import { setKustomerLoaded } from 'store/dashboardInterface/actions';
import useAction from './useAction';

const useInitKustomer = () => {
  const setKustomerLoadedFunc = useAction(setKustomerLoaded);
  const isUserLoaded = useSelector(userSelectors.getLoaded);
  const userId = useSelector(userSelectors.getUserId);
  const lang = getLanguage();

  const getJwtToken = async () => {
    const response = await newApiInstance.get('/users/kustomer_token');

    return response.data.data.token;
  };

  const onLoaded = useCallback(() => {
    const options = {
      hideChatIcon: true,
      lang,
    };

    window.Kustomer.start(options, async () => {
      const jwtToken = await getJwtToken();

      window.Kustomer.login({ jwtToken }, () => {
        setKustomerLoadedFunc(true);
      });
    });
  }, []);

  useEffect(() => {
    if (isClient() && isUserLoaded && !window.Kustomer && process.env.REACT_APP_KUSTOMER && userId) {
      initKustomer({ onLoaded });
    }
  }, [isUserLoaded, onLoaded, userId]);
};

export default useInitKustomer;
