import PropTypes from 'prop-types';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import ModalBodyDoNotSellMyInfo from 'components/molecules/ModalBodyDoNotSellMyInfo/ModalBodyDoNotSellMyInfo';

const ModalDoNotSellMyInfo = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.DO_NOT_SELL_MY_INFO} title="app:ccpaTitle" onClose={closeModal}>
      <ModalBodyDoNotSellMyInfo {...props} />
    </Modal>
  );
};

ModalDoNotSellMyInfo.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalDoNotSellMyInfo;
