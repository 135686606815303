import React from 'react';
import useId from '../utils/useId';
import {
  RadioButtonContainer,
  RadioButton,
  RadioButtonInput,
  RadioButtonPoint,
  RadioContainer,
  StyledLabel,
} from './Radio.styles';
import { RadioProps } from './Radio.types';

const Radio = React.forwardRef<HTMLLabelElement, RadioProps>(function Radio(
  {
    disabled,
    label,
    id: idOverride,
    name,
    value,
    checked,
    inputProps = {},
    inputRef = null,
    labelProps = {},
    ...props
  },
  ref
) {
  const id = useId(idOverride);
  const labelId = label && `${id}-label`;

  return (
    <RadioButtonContainer disabled={disabled} {...props} ref={ref}>
      <RadioContainer>
        <RadioButtonInput
          {...inputProps}
          name={name}
          aria-describedby={labelId}
          disabled={disabled}
          value={value}
          type="radio"
          ref={inputRef}
        />
        <RadioButton disabled={disabled} checked={checked}>
          <RadioButtonPoint checked={checked} />
        </RadioButton>
      </RadioContainer>

      {label && (
        <StyledLabel
          {...labelProps}
          variant="labelMedium"
          color={disabled ? 'neutral3' : 'primary1'}
          id={labelId}
        >
          {label}
        </StyledLabel>
      )}
    </RadioButtonContainer>
  );
});

export default Radio;
