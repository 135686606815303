import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import Modal from 'components/templates/Modal/Modal';
import ModalBodyUnderMaintenance from 'components/molecules/ModalBodyUnderMaintenance/ModalBodyUnderMaintenance';

const ModalUnderMaintenance = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.UNDER_MAINTENANCE} onClose={closeModal}>
      <ModalBodyUnderMaintenance {...props} />
    </Modal>
  );
};

ModalUnderMaintenance.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalUnderMaintenance;
