import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

export const ControlsContainer = styled.div`
  visibility: ${({ showControls }) => (showControls ? 'visible' : 'hidden')};
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: ${getColor('primary1')};
  border: none;
  border-radius: 100%;
  padding: 0;

  img {
    margin-left: 3px;
  }

  &:hover {
    background: ${getColor('secondary1')};
  }
`;

export const ProgressBarContainer = styled.div`
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  bottom: 20px;

  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: block;
  }
  .rangeslider {
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    width: 100%;
  }

  .rangeslider .rangeslider__handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: -15px;
    position: absolute;
    outline: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    opacity: 0;
  }

  .rangeslider-horizontal {
    height: 10px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      height: 10px;
      border-radius: 10px;
      background: ${getColor('primary2')};
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;
    }

    .rangeslider__fill,
    .rangeslider__fill-1 {
      display: block;
      background: ${getColor('secondary1')};
      height: 10px;
      min-width: 10px;
      border-radius: 15px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 5;
    }
  }
`;
