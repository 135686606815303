import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const KarmaIconOutlined: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49856 17.1735V3.7H3.7V19.3996C3.7 19.9814 4.01911 20.2997 4.59913 20.2997C5.01808 20.2997 5.22821 20.1802 5.39267 20.0031L5.39755 19.9978L10.9612 14.1061L12.1404 15.9101C13.7854 18.4266 16.7458 20.1647 20.2942 20.2925V18.6598C17.0574 18.3565 14.2726 16.3603 13.1723 13.5372L12.7692 12.5029L20.0322 5.23841C20.3802 4.89019 20.3977 4.32747 20.0323 3.962C19.7054 3.63498 19.1324 3.61139 18.7416 3.97811L5.49856 17.1735ZM17.5555 2.76008C18.5554 1.80016 20.1948 1.72003 21.2346 2.76008C22.3143 3.84003 22.1943 5.47992 21.2346 6.44015L14.7563 12.9199C15.676 15.2799 18.1951 16.9999 21.1545 16.9999H21.1944C21.6744 16.9999 21.9942 17.3198 21.9942 17.7999V21.2C21.9942 21.6798 21.6744 22 21.1944 22H20.7147C17.0943 22 13.9032 20.5396 11.7715 18.2002C11.3838 17.7748 11.0312 17.3203 10.7175 16.8403L6.63847 21.1598C6.11858 21.7197 5.4388 21.9997 4.59913 21.9997C3.07967 21.9997 2 20.9197 2 19.3996V2.79999C2 2.31987 2.31979 2 2.79978 2H6.39878C6.87877 2 7.19856 2.31987 7.19856 2.79999V13.0798L17.5555 2.76008Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default KarmaIconOutlined;
