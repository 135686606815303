import { createSlice } from '@reduxjs/toolkit';
import RETAILERS_SORT_ORDERS from '../../consts/retailersSortOrders';

export const initialState = {
  activeCategories: [],
  isFetching: false,
  loaded: false,
  page: 1,
  retailers: [],
  retailersLeft: true,
  search: '',
  sortOrder: RETAILERS_SORT_ORDERS.DEFAULT,
  sectionName: '',
};

export const retailersSlice = createSlice({
  name: 'retailers',
  initialState,
  reducers: {
    clearRetailers: () => {
      return initialState;
    },
    fetchRetailers: state => {
      return {
        ...state,
        isFetching: true,
      };
    },
    receiveRetailers: (state, action) => {
      const { page, retailers, retailersLeft, sectionName } = action.payload;

      return {
        ...state,
        isFetching: false,
        loaded: true,
        page,
        retailers: [...state.retailers, ...retailers],
        retailersLeft,
        sectionName,
      };
    },
    setFilter: (state, action) => {
      const { filterName, value } = action.payload;

      return {
        ...state,
        [filterName]: value,
        retailers: [],
        loaded: false,
      };
    },
  },
});

export default retailersSlice.reducer;
