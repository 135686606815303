export const getSettings = otherSettings => ({
  spaceBetween: 30,
  updateOnWindowResize: true,
  shouldSwiperUpdate: true,
  mousewheel: {
    forceToAxis: true,
    sensitivity: 0.8,
    releaseOnEdges: true,
  },
  watchOverflow: true,
  keyboard: true,
  observer: true,
  freeMode: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  ...otherSettings,
  on: {
    reachEnd: () => {
      if (otherSettings?.on?.reachEnd) {
        otherSettings.on.reachEnd();
      }
      otherSettings.updateArrows();
    },
    reachBeginning: () => {
      if (otherSettings?.on?.reachBeginning) {
        otherSettings.on.reachBeginning();
      }
      otherSettings.updateArrows();
    },
    realIndexChange: () => {
      if (otherSettings?.on?.realIndexChange) {
        otherSettings.on.realIndexChange();
      }
      otherSettings.updateArrows();
    },
  },
});
