import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { COLOR_THEMES } from '@karma/components/themes';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import ModalBodyChatWithUs from 'components/molecules/ModalBodyChatWithUs/ModalBodyChatWithUs';
import { CHAT_WITH_US_WINDOW_PARAM } from 'consts/modals';

const ModalChatWithUs = props => {
  const { closeModal, onCloseCallback, sourcePage } = props;

  const onClose = () => {
    closeModal();
    if (onCloseCallback && onCloseCallback instanceof Function) onCloseCallback();
  };

  useEffect(() => {
    window[CHAT_WITH_US_WINDOW_PARAM] = true;
  }, []);

  return (
    <Modal
      source={sourcePage}
      aloomaModalType={ALOOMA.MODAL_TYPES.POPUP}
      aloomaModalName={ALOOMA.MODAL_NAMES.CHAT_WITH_US}
      onClose={onClose}
      colorTheme={COLOR_THEMES.LIGHT}
    >
      <ModalBodyChatWithUs />
    </Modal>
  );
};

ModalChatWithUs.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onCloseCallback: PropTypes.func,
  sourcePage: PropTypes.string.isRequired,
};

ModalChatWithUs.defaultProps = {
  onCloseCallback: null,
};

export default ModalChatWithUs;
