import React from 'react';
import { StackProps } from './Stack.types';
import { StackContainer } from './Stack.styles';

const Stack = React.forwardRef<HTMLDivElement, StackProps>(function (
  {
    children,
    direction = 'row',
    spacing = 0,
    alignItems = 'center',
    justifyContent = 'center',
    component,
    fullWidth = false,
    bMargin = 0,
    lMargin = 0,
    rMargin = 0,
    tMargin = 0,
    ...props
  },
  ref
) {
  return (
    <StackContainer
      {...props}
      direction={direction}
      spacing={spacing}
      alignItems={alignItems}
      justifyContent={justifyContent}
      as={component}
      fullWidth={fullWidth}
      ref={ref}
      bMargin={bMargin}
      lMargin={lMargin}
      rMargin={rMargin}
      tMargin={tMargin}
    >
      {children}
    </StackContainer>
  );
});

export default Stack;
