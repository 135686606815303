import { all, takeLatest } from 'redux-saga/effects';
import * as tagActions from '../actions';
import fetchTag from './worker.fetchTag';
import fetchTagSimilar from './worker.fetchTagSimilar';
import fetchTagAlsoLike from './worker.fetchTagAlsoLike';

function* tagSaga() {
  yield all([
    takeLatest(tagActions.fetchTagData.type, fetchTag),
    takeLatest(tagActions.fetchTagSimilar.type, fetchTagSimilar),
    takeLatest(tagActions.fetchTagAlsoLike.type, fetchTagAlsoLike),
  ]);
}

export default tagSaga;
