import styled from 'styled-components';

export const AgreementV3 = styled.div`
  margin: 0 auto;
  display: block;
`;

export const AgreementLinkV3 = styled.a`
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;
