import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as notificationsActions from '../actions';
import * as notificationsApi from '../api';

function* createNotificationRemindIn(action) {
  try {
    const { uuid, minutes } = action.payload;

    yield call(notificationsApi.createNotificationRemindIn, uuid, { minutes });
    yield put(notificationsActions.successCreateNotificationRemindIn(uuid));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default createNotificationRemindIn;
