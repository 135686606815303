import { useMemo } from 'react';
import useCurrentBreakPoint from 'hooks/useCurrentBreakPoint';

const CARD_COUPON_BREAKPOINTS = {
  1920: {
    itemsPerView: 5,
  },
  1440: {
    itemsPerView: 4,
  },
  1200: {
    itemsPerView: 3,
  },
  1025: {
    itemsPerView: 3,
  },
  768: {
    itemsPerView: 2,
  },
  500: {
    itemsPerView: 1,
  },
};

/**
 * @typedef {Object} CardCouponBreakpoint - config of the breakpoint
 *
 * @property {Number} itemsPerView - how many cards fit on the screen
 */

/**
 * Function returns config for current breakpoint
 *
 * @returns {CardCouponBreakpoint}
 */
export const useCardCouponBreakpoint = () => {
  const breakpointKey = useCurrentBreakPoint(CARD_COUPON_BREAKPOINTS);

  return useMemo(() => {
    return CARD_COUPON_BREAKPOINTS[breakpointKey];
  }, [breakpointKey]);
};

/**
 * Function returns breakpoints for swiper slider
 *
 * @example:
 * {
 *   2250: {
 *     slidesPerView: 5,
 *   },
 *   1500: {
 *     slidesPerView: 4,
 *   },
 * }
 */
export const useCardCouponBreakpoints = () => {
  return useMemo(() => {
    return Object.keys(CARD_COUPON_BREAKPOINTS).reduce((result, breakpointKey) => {
      return { ...result, [breakpointKey]: { slidesPerView: CARD_COUPON_BREAKPOINTS[breakpointKey].itemsPerView } };
    }, {});
  }, []);
};
