import { createAction } from '@reduxjs/toolkit';
import multiEditSlice from './slice';

export const { deleteTag, reset, resetExcluded, toggleAll, toggleTag, toggleIncludedTags } = multiEditSlice.actions;

export const requestTagsBulkAddToLists = createAction('multiEdit/requestTagsBulkAddToLists');
export const requestTagsBulkDelete = createAction('multiEdit/requestTagsBulkDelete');
export const requestTagsBulkFulfill = createAction('multiEdit/requestTagsBulkFulfill');
export const requestTagsBulkMute = createAction('multiEdit/requestTagsBulkMute');
export const selectAllMultiEdit = createAction('multiEdit/selectAll');
