import { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { CheckmarkIcon, CloseIcon, InfoIcon } from '@karma/components/icons';
import { ThemeContext } from 'styled-components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { hideAlert } from 'store/alerts/actions';
import useAction from 'hooks/useAction';
import { AlertContainer, CloseIconContainer } from './BaseAlert.style';
import { baseAlertConfig } from './config';

const BaseAlert = ({ alertId, Content, Button, showClose, shouldShowReverseAnimation, onClose, type, ...rest }) => {
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const { color } = useContext(ThemeContext);

  const alertContainerRef = useRef();

  const hideAlertFunc = useAction(hideAlert);

  useEffect(() => {
    const makeAnimationEndHandler = () => {
      let endedAnimationsCount = 0;

      return () => {
        if (endedAnimationsCount > 0) hideAlertFunc({ alertId });
        endedAnimationsCount += 1;
      };
    };

    const animationEndHandler = makeAnimationEndHandler();
    const alertElement = alertContainerRef.current;

    alertElement.addEventListener('animationend', animationEndHandler);

    return () => alertElement.removeEventListener('animationend', animationEndHandler);
  }, [alertId, hideAlertFunc]);

  const alertColor = useMemo(() => {
    switch (type) {
      case 'success':
        return 'secondary4';

      case 'error':
        return 'secondary2';

      case 'warning':
        return 'secondary5';

      case 'info':
        return 'secondary5';

      default:
        return 'secondary5';
    }
  }, [type]);

  const alertIcon = useMemo(() => {
    switch (type) {
      case 'success':
        return <CheckmarkIcon width={24} height={24} color={alertColor} />;

      case 'error':
        return <CloseIcon width={24} height={24} color={alertColor} />;

      case 'warning':
        return <InfoIcon width={24} height={24} color={alertColor} />;

      case 'info':
        return <InfoIcon width={24} height={24} color={alertColor} />;

      default:
        return null;
    }
  }, [alertColor, type]);

  return (
    <AlertContainer
      ref={alertContainerRef}
      shouldShowReverseAnimation={shouldShowReverseAnimation}
      // onClick={handleBubblingCloseEvent}
      containerBorderColor={alertColor}
      color={baseAlertConfig[color].color}
      backgroundColor={baseAlertConfig[color].backgroundColor}
      {...rest}
    >
      {alertIcon}
      {Content}
      {Button}
      {showClose && (
        <CloseIconContainer {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.CROSS} onClick={onClose}>
          <CloseIcon size="small" />
        </CloseIconContainer>
      )}
    </AlertContainer>
  );
};

BaseAlert.propTypes = {
  alertId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info', 'custom']),
  Content: PropTypes.element.isRequired,
  Button: PropTypes.element,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  shouldShowReverseAnimation: PropTypes.bool.isRequired,
};

BaseAlert.defaultProps = {
  type: 'success',
  Button: null,
  showClose: true,
  onClose: null,
};

export default BaseAlert;
