import { all as combinatorAll, call, put, select } from 'redux-saga/effects';
import { BACK_IN_STOCK, FAVORITES, LOW_IN_STOCK, ON_SALE } from 'consts/staticFilterKeys';
import { handleServerError } from 'store/alerts/actions';
import { getFilters } from 'store/filters/selectors';
import { fetchMyItems } from 'store/myItems/store/actions';
import { reset } from '../actions';
import { getMultiEditEntity } from '../selectors';

const formActionData = ({ payload }) => {
  const actionData = {};

  if (payload?.lists) {
    actionData.lists = payload?.lists;
  }

  return actionData;
};

const formFiltersData = ({ lists, search, staticFilter }) => {
  const filter = [BACK_IN_STOCK, FAVORITES, LOW_IN_STOCK, ON_SALE].reduce(
    (result, key) =>
      staticFilter[key]
        ? {
            ...result,
            [key]: true,
          }
        : result,
    {},
  );

  if (lists.length) {
    filter.lists = lists.join();
  }

  if (search) {
    filter.search = search;
  }

  return Object.keys(filter).length ? { filter } : {};
};

const formMultiEditData = ({ all, excluded: except, included: tags }) => {
  const multiEditData = {};

  if (all) {
    multiEditData.all = all;

    if (except.length) {
      multiEditData.except = except;
    }
  } else if (tags.length) {
    multiEditData.tags = tags;
  }

  return multiEditData;
};

function* handleError() {
  yield put(handleServerError('There was a problem please try again later.'));
}

function* tagsBulkUpdate(request, action) {
  try {
    const [multiEditEntity, filtersEntity] = yield combinatorAll([select(getMultiEditEntity), select(getFilters)]);

    const data = {
      ...formActionData(action),
      ...formFiltersData(filtersEntity),
      ...formMultiEditData(multiEditEntity),
    };

    const response = yield call(request, data);
    const success = response.data.data;

    if (success) {
      yield put(reset());
      yield put(fetchMyItems({ isFetching: true }));
    } else {
      yield handleError();
    }
  } catch (error) {
    yield handleError();
  }
}

export default tagsBulkUpdate;
