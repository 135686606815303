import { memo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import ErrorBoundary from 'hocs/ErrorBoundary';

/**
 * !!!! IMPORTANT !!!! LoadableIcon can't be used for server side rendering.
 */
const LoadableIcon = ({ iconName, ...componentProps }) => {
  const IconNew = loadable(props => import(`@karma/components/icons/${props.iconName}/index`));

  return <IconNew iconName={iconName} {...componentProps} />;
};

LoadableIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

export default ErrorBoundary(memo(LoadableIcon));
