import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IGNORE_WISE_POPS_PATHS } from 'consts/paths';
import * as userSelectors from 'store/user/selectors';

const useWisePops = () => {
  const location = useLocation();
  const isScriptLoaded = useRef(false);
  const isUserLoaded = useSelector(userSelectors.getLoaded);
  const isUserAuthorized = Boolean(useSelector(userSelectors.getUserId));
  const ignorePaths = IGNORE_WISE_POPS_PATHS.includes(location.pathname);

  useEffect(() => {
    if (isUserLoaded && !isUserAuthorized && !isScriptLoaded.current && !ignorePaths) {
      isScriptLoaded.current = true;

      /* eslint-disable */
      (function (W, i, s, e, P, o, p) {
        W['WisePopsObject'] = P;
        (W[P] =
          W[P] ||
          function () {
            (W[P].q = W[P].q || []).push(arguments);
          }),
          (W[P].l = 1 * new Date());
        (o = i.createElement(s)), (p = i.getElementsByTagName(s)[0]);
        o.defer = 1;
        o.src = e;
        p.parentNode.insertBefore(o, p);
      })(window, document, 'script', '//loader.wisepops.com/get-loader.js?v=1&site=rGnYY6c3GD', 'wisepops');
      /* eslint-enable */
    }
  }, [isUserLoaded, isUserAuthorized, ignorePaths]);
};

export default useWisePops;
