import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CatIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 101 102"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.0488 29.5628C86.0965 28.0247 90.2914 31.4452 89.5996 35.7197L85.5646 60.6497C86.2904 63.2019 86.6757 65.8711 86.6757 68.616C86.6757 87.4249 68.9985 101.751 48.4229 101.751C27.8472 101.751 10.17 87.4249 10.17 68.616C10.17 65.6288 10.6264 62.732 11.4806 59.9786L6.77665 36.2079C5.93606 31.9601 10.009 28.3953 14.108 29.7912L36.028 37.2561C39.9228 36.1032 44.0937 35.4805 48.4229 35.4805C52.9557 35.4805 57.315 36.1632 61.3654 37.4223L82.0488 29.5628ZM82.2036 37.0815L61.5101 44.9448L60.2961 44.5254C56.6461 43.2642 52.6386 42.5639 48.4229 42.5639C44.4108 42.5639 40.5874 43.1982 37.0812 44.3465L35.9569 44.7147L14.2156 37.3107L18.7958 60.4556L18.4807 61.3629C17.6809 63.6662 17.2534 66.0983 17.2534 68.616C17.2534 82.4955 30.6576 94.6681 48.4229 94.6681C66.1882 94.6681 79.5924 82.4955 79.5924 68.616C79.5924 66.3126 79.2345 64.0816 78.5618 61.9559L78.3077 61.1529L82.2036 37.0815Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4372 77.4211L26.2869 77.3696L28.582 70.6685L28.7324 70.7199C32.0985 71.8729 35.755 71.858 39.1117 70.6779L41.4611 77.3602C36.6023 79.0685 31.3097 79.09 26.4372 77.4211Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7712 77.4211L54.6208 77.3696L56.916 70.6685L57.0663 70.7199C60.4325 71.8729 64.0889 71.858 67.4457 70.6779L69.7951 77.3602C64.9363 79.0685 59.6437 79.09 54.7712 77.4211Z"
        fill="currentColor"
      />
      <path
        d="M54.3118 88.1233C54.3118 90.9296 51.5681 93.2046 48.1836 93.2046C44.7991 93.2046 42.0554 90.9296 42.0554 88.1233C42.0554 85.317 44.7991 83.042 48.1836 83.042C51.5681 83.042 54.3118 85.317 54.3118 88.1233Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6431 16.0674H66.1822C68.9311 16.0674 70.3503 19.3521 68.4663 21.3538L62.9298 27.2364H69.4667V31.4031H60.5459C57.797 31.4031 56.3778 28.1184 58.2618 26.1166L63.7983 20.234H56.6431V16.0674Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0469 0.126465H87.3162C90.1107 0.126465 91.5102 3.50509 89.5342 5.48108L81.3643 13.651H90.3514V17.8177H78.8776C76.0831 17.8177 74.6837 14.439 76.6597 12.4631L84.8296 4.29313H75.0469V0.126465Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CatIcon;
