import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  features: {},
  isLoaded: false,
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures: (state, { payload }) => ({
      features: payload || {},
      isLoaded: true,
    }),
  },
});

export default featuresSlice.reducer;
