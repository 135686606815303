import { createSlice } from '@reduxjs/toolkit';
import { QUERY_PARAMS } from 'consts/queryParams';
import { isClient } from 'functions/isClient';
import { getQueryParam } from 'functions/urlHelpers';
import { FILTERS } from 'consts/wallet';

const initialState = {
  earnings: {
    earnings: [],
    filter: (isClient() && getQueryParam(QUERY_PARAMS.filter.earnings)) || FILTERS.all,
    hasMore: true,
    isFetching: false,
    isLoaded: false,
    page: 1,
  },

  payouts: {
    payouts: [],
    filter: (isClient() && getQueryParam(QUERY_PARAMS.filter.payouts)) || FILTERS.all,
    hasMore: true,
    isFetching: false,
    isLoaded: false,
    page: 1,
  },
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    addPayout: (state, { payload: { payout } }) => ({
      ...state,
      payouts: {
        ...state.payouts,
        payouts: [payout, ...state.payouts.payouts],
      },
    }),

    fetchEarnings: state => ({
      ...state,
      earnings: {
        ...state.earnings,
        isLoaded: false,
        isFetching: true,
      },
    }),

    fetchPayouts: state => ({
      ...state,
      payouts: {
        ...state.payouts,
        isLoaded: false,
        isFetching: true,
      },
    }),

    fetchMoreEarnings: state => ({
      ...state,
      earnings: {
        ...state.earnings,
        isFetching: true,
      },
    }),

    fetchMorePayouts: state => ({
      ...state,
      payouts: {
        ...state.payouts,
        isFetching: true,
      },
    }),

    saveEarnings: (state, { payload: { earnings, hasMore } }) => ({
      ...state,
      earnings: {
        ...state.earnings,
        earnings,
        hasMore,
        isFetching: false,
        isLoaded: true,
        page: 1,
      },
    }),

    savePayouts: (state, { payload: { payouts, hasMore } }) => ({
      ...state,
      payouts: {
        ...state.payouts,
        payouts,
        hasMore,
        isFetching: false,
        isLoaded: true,
        page: 1,
      },
    }),

    saveMoreEarnings: (state, { payload: { earnings, hasMore, page } }) => ({
      ...state,
      earnings: {
        ...state.earnings,
        earnings: [...state.earnings.earnings, ...earnings],
        hasMore,
        isFetching: false,
        isLoaded: true,
        page,
      },
    }),

    saveMorePayouts: (state, { payload: { payouts, hasMore, page } }) => ({
      ...state,
      payouts: {
        ...state.payouts,
        payouts: [...state.payouts.payouts, ...payouts],
        hasMore,
        isFetching: false,
        isLoaded: true,
        page,
      },
    }),

    setEarningsFilter: (state, { payload: filter }) => ({
      ...state,
      earnings: {
        ...state.earnings,
        filter,
      },
    }),

    setPayoutsFilter: (state, { payload: filter }) => ({
      ...state,
      payouts: {
        ...state.payouts,
        filter,
      },
    }),
  },
});

export default walletSlice;
