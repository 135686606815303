import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

const useStaticFile = (path: string) => {
  const { staticPath } = useContext(ThemeContext);

  return useMemo(
    () => `${staticPath.replace(/\/$/, '')}/${path.replace(/^\//, '')}`,
    [staticPath, path]
  );
};

export default useStaticFile;
