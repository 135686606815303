import styled from 'styled-components';
import { Stack } from '@karma/components';

export const CouponsWrapper = styled(Stack)`
  max-width: 100%;
  min-width: 100%;
  width: 100%;

  .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const LoaderWrapper = styled(Stack)`
  position: relative;
  width: 100%;
  height: 400px;
`;
