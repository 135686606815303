import { useTranslation } from 'react-i18next';
import Typography from '@karma/components/Typography';
import { COLOR_THEMES } from '@karma/components/themes';
import { ThemeProvider } from 'styled-components';
import { Stack } from '@karma/components';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { COUPONS_TYPES } from 'consts/couponTypes';
import useGetLastSuccessUseOfCoupon from 'hooks/useGetLastSuccessUseOfCoupon';
import { OFFER_TYPES } from 'consts/coupons';
import CouponCTASection from '../CouponCTASection/CouponCTASection';
import {
  Container,
  CouponDescription,
  MainContent,
  SideBar,
  StyledSideBarText,
  RetailerNameTitle,
  EmptySidebar,
  CouponInfoContainer,
} from './CardCoupon.style';

const getColorState = coupon => {
  const { ad_package_id: adPackageId, exclusive } = coupon || {};

  if (adPackageId) return COUPONS_TYPES.sponsored;
  if (exclusive) return COUPONS_TYPES.exclusive;

  return COUPONS_TYPES.coupon;
};

const CardCoupon = ({ coupon, source = '' }) => {
  const { t } = useTranslation();

  const {
    description,
    last_success_use: lastSuccessUse,
    retailer_name: retailerName,
    title,
    offer_type: offerType,
  } = coupon || {};

  const contextDataAttrs = Alooma.useContextDataAttrs();
  const lastSuccessUseText = useGetLastSuccessUseOfCoupon(lastSuccessUse);
  const $state = getColorState(coupon);

  const showLastSuccessUse = $state !== COUPONS_TYPES.sponsored && !title && offerType !== OFFER_TYPES.KARMA_CASH;

  return (
    <ThemeProvider theme={{ color: COLOR_THEMES.LIGHT }}>
      <Container
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.COUPON}
      >
        {[COUPONS_TYPES.sponsored, COUPONS_TYPES.exclusive].includes($state) ? (
          <SideBar $state={$state} direction="column">
            <StyledSideBarText variant="labelSmallUppercase">
              {t($state === COUPONS_TYPES.exclusive ? 'app:exclusive' : 'app:sponsored')}
            </StyledSideBarText>
          </SideBar>
        ) : (
          <EmptySidebar />
        )}
        <MainContent direction="column" alignItems="flex-start" justifyContent="flex-start">
          <RetailerNameTitle variant="paragraphMediumLarge" noWrap bMargin={4}>
            {retailerName}
          </RetailerNameTitle>

          <CouponInfoContainer direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={8}>
            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
              {!!title && (
                <Typography variant="paragraphSmall" fontWeight={500}>
                  {title}
                </Typography>
              )}
              <CouponDescription variant="paragraphSmall">{description}</CouponDescription>
            </Stack>
            {showLastSuccessUse && (
              <Typography
                variant="labelSmall"
                color="neutral2"
                style={{
                  marginTop: 'auto',
                }}
              >
                {lastSuccessUseText}
              </Typography>
            )}
          </CouponInfoContainer>

          <CouponCTASection coupon={coupon} source={source} />
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default CardCoupon;
