import styled from 'styled-components';
import { StyledLottieAnimationProps } from './LottieAnimation.types';
import omitForwardedProps from '../utils/omitForwardedProps';

export const AnimationWrapper = styled.div.withConfig(
  omitForwardedProps<StyledLottieAnimationProps>(['width', 'height'])
)<StyledLottieAnimationProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 0 auto;
`;
