import * as ALOOMA from 'consts/alooma';
import ModalBodyPaypalConnect from 'components/molecules/ModalBodyPaypalConnect/ModalBodyPaypalConnect';
import Modal from 'components/templates/Modal/Modal';

const ModalPaypalConnect = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.PAYPAL_CONNECT} onClose={closeModal}>
      <ModalBodyPaypalConnect {...props} />
    </Modal>
  );
};

export default ModalPaypalConnect;
