import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const DownloadIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48377 10.171L5.14453 9.91662L6.16453 8.55662L6.50377 8.81104C8.70362 10.4609 10.673 12.3975 12.3596 14.5694L12.6565 14.9517L13.5408 13.8723C14.9421 12.1617 16.5232 10.6067 18.257 9.23413L19.0957 8.57018L20.1509 9.90306L19.3122 10.567C17.674 11.8639 16.1799 13.3332 14.8559 14.9496L12.621 17.6777L11.0169 15.6121C9.42326 13.5599 7.5624 11.73 5.48377 10.171Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7883 15.8929L11.7883 1.31473L13.4883 1.31473L13.4883 15.8929L11.7883 15.8929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98906 15.8178V20.6353C2.98906 21.2705 3.50393 21.7853 4.13906 21.7853H21.1391C21.7742 21.7853 22.2891 21.2705 22.2891 20.6353V15.8178H23.9891V20.6353C23.9891 22.2093 22.7131 23.4853 21.1391 23.4853H4.13906C2.56505 23.4853 1.28906 22.2093 1.28906 20.6353V15.8178H2.98906Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DownloadIcon;
