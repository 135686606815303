import styled, { css } from 'styled-components';
import { getColor } from '@karma/components/colors';
import Stack from '@karma/components/Stack';

export const Container = styled(Stack)`
  width: 100%;
  border-radius: 10px;

  ${({ withoutContainer }) =>
    !withoutContainer &&
    css`
      padding: 20px 20px 20px 30px;
      background: ${getColor('neutral6')};
    `}
`;

export const ButtonContainer = styled(Stack)`
  width: 100%;
  background: ${getColor('neutral6')};

  border-radius: 10px;
  padding: 20px 17%;
`;

export const Content = styled(Stack)`
  width: 100%;
  overflow: hidden;
`;
