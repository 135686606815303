import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MasterCardIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2891 15.0179C24.2891 18.2487 21.6699 20.8679 18.4391 20.8679L0.989063 20.8679L0.989064 8.74286C0.989065 5.51199 3.6082 2.89286 6.83907 2.89286L24.2891 2.89286L24.2891 15.0179ZM18.4391 19.1679C20.731 19.1679 22.5891 17.3098 22.5891 15.0179L22.5891 4.59286L6.83907 4.59286C4.54708 4.59286 2.68907 6.45088 2.68907 8.74286L2.68906 19.1679L18.4391 19.1679Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1937 9.38662C13.8165 9.38662 12.7 10.5031 12.7 11.8804C12.7 13.2576 13.8165 14.3741 15.1937 14.3741C16.571 14.3741 17.6875 13.2576 17.6875 11.8804C17.6875 10.5031 16.571 9.38662 15.1937 9.38662ZM11 11.8804C11 9.56422 12.8776 7.68661 15.1937 7.68661C17.5099 7.68661 19.3875 9.56422 19.3875 11.8804C19.3875 14.1965 17.5099 16.0741 15.1937 16.0741C12.8776 16.0741 11 14.1965 11 11.8804Z"
        fill="currentColor"
      />
      <path
        d="M14.9902 11.8804C14.9902 14.1585 13.1434 16.0054 10.8652 16.0054C8.58706 16.0054 6.74023 14.1585 6.74023 11.8804C6.74023 9.6022 8.58706 7.75537 10.8652 7.75537C13.1434 7.75537 14.9902 9.6022 14.9902 11.8804Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MasterCardIcon;
