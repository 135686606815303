import { useMemo } from 'react';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelSmallUppercase,
  LabelExtraSmallUppercase,
  ParagraphLarge,
  ParagraphMedium,
  ParagraphSmall,
  LabelSmallUppercaseBold,
  LabelExtraSmallUppercaseSemibold,
  LargeTitle,
  MediumTitle,
  ParagraphExtraLarge,
  H1SEO,
} from './LandingTypographyDesktop.style';

const getComponent = variant => {
  switch (variant) {
    case 'titleLarge':
      return LargeTitle;

    case 'titleMedium':
      return MediumTitle;

    case 'h1':
      return H1;

    case 'h2':
      return H2;

    case 'h3':
      return H3;

    case 'h4':
      return H4;

    case 'h5':
      return H5;

    case 'h1SEO':
      return H1SEO;

    case 'paragraphExtraLarge':
      return ParagraphExtraLarge;

    case 'paragraphLarge':
      return ParagraphLarge;

    case 'paragraphMedium':
      return ParagraphMedium;

    case 'paragraphSmall':
      return ParagraphSmall;

    case 'labelLarge':
      return LabelLarge;

    case 'labelMedium':
      return LabelMedium;

    case 'labelSmall':
      return LabelSmall;

    case 'labelSmallUppercase':
      return LabelSmallUppercase;

    case 'labelSmallUppercaseBold':
      return LabelSmallUppercaseBold;

    case 'labelExtraSmallUppercase':
      return LabelExtraSmallUppercase;

    case 'labelExtraSmallUppercaseSemibold':
      return LabelExtraSmallUppercaseSemibold;

    default:
      return getComponent('paragraphLarge');
  }
};

const LandingTypographyDesktop = ({
  children,
  align = 'inherit',
  bMargin = 0,
  lMargin = 0,
  rMargin = 0,
  tMargin = 0,
  noWrap = false,
  variant = 'h1',
  color = 'currentColor',
  fontWeight,
  component,
  ...props
}) => {
  const Component = useMemo(() => getComponent(variant), [variant]);

  return (
    <Component
      align={align}
      bMargin={bMargin}
      lMargin={lMargin}
      rMargin={rMargin}
      tMargin={tMargin}
      noWrap={noWrap}
      variant={variant}
      color={color}
      fontWeight={fontWeight}
      as={component}
      {...props}
    >
      {children}
    </Component>
  );
};

export default LandingTypographyDesktop;
