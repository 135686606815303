import hasDiscount from 'functions/hasDiscount';

function hasTagMarker(tag) {
  try {
    if (!tag || !tag.marker) return false;

    const { marker, price } = tag;

    if (['On Sale', 'on sale', 'on_sale'].includes(marker)) {
      return hasDiscount(price?.current_price, price?.original_price, price?.currency?.symbol);
    }

    return true;
  } catch (e) {
    return false;
  }
}

export default hasTagMarker;
