import axios from 'axios';
import { API_URL, NEW_API_URL, BASE_URL, LAMBDA_API_URL } from '@karma/utils/constants/env';
import { X_PLATFORMS } from '@karma/utils/constants/axios';
import { v4 as uuidv4 } from 'uuid';
import { ITEMS } from 'consts/localStorage';
import { isClient } from 'functions/isClient';
import paramsSerializer from 'functions/paramsSerializer';

const newApiInstance = axios.create({
  baseURL: NEW_API_URL,
  paramsSerializer,
});

const oldApiInstance = axios.create({
  baseURL: API_URL,
  paramsSerializer,
});

const javaApiInstance = axios.create({
  baseURL: `https://${BASE_URL}/ws`,
  paramsSerializer,
});

const lambdaApiInstance = axios.create({
  baseURL: LAMBDA_API_URL,
  paramsSerializer,
});

javaApiInstance.interceptors.response.use(
  response => response,
  error => Promise.reject(error.response),
);

const addCustomHeaders = config => {
  /* eslint-disable no-param-reassign */
  const token = isClient() ? localStorage.getItem(ITEMS.token) : null;

  if (!config.headers.authorization && token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  if (process.env.REACT_APP_VERSION) {
    config.headers.common['X-Platform-Version'] = process.env.REACT_APP_VERSION;
  }

  config.headers.common['x-request-id'] = uuidv4();
  config.headers.common['X-Platform'] = X_PLATFORMS.dashboard;

  return config;
  /* eslint-enable no-param-reassign */
};

newApiInstance.interceptors.request.use(addCustomHeaders);
oldApiInstance.interceptors.request.use(addCustomHeaders);
javaApiInstance.interceptors.request.use(addCustomHeaders);
lambdaApiInstance.interceptors.request.use(addCustomHeaders);

export { newApiInstance, oldApiInstance, javaApiInstance, lambdaApiInstance };
