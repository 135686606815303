function isValidUrl(urlString) {
  try {
    const urlFromString = new URL(urlString);

    return !!urlFromString;
  } catch (e) {
    return false;
  }
}

export default isValidUrl;
