import { put, take } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as favoriteRetailersActions from '../actions';

function* addFavoriteRetailers({ payload }) {
  try {
    yield put(favoriteRetailersActions.requestCreateFavoriteRetailers(payload));
    yield take([
      favoriteRetailersActions.successCreateFavoriteRetailers.type,
      favoriteRetailersActions.failureCreateFavoriteRetailers.type,
    ]);

    yield put(favoriteRetailersActions.requestFetchFavoriteRetailers());
    yield put(favoriteRetailersActions.initialUpdateCompleted());
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default addFavoriteRetailers;
