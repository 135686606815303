import { all, takeEvery } from 'redux-saga/effects';
import {
  requestCreateFavoriteRetailers,
  requestAddFavoriteRetailers,
  requestDeleteFavoriteRetailers,
  requestFetchFavoriteRetailers,
} from '../actions';
import createFavoriteRetailers from './worker.createFavoriteRetailers';
import addFavoriteRetailers from './worker.addFavoriteRetailers';
import deleteFavoriteRetailers from './worker.deleteFavoriteRetailers';
import fetchFavoriteRetailers from './worker.fetchFavoriteRetailers';

function* favoriteRetailersSaga() {
  yield all([
    takeEvery([requestCreateFavoriteRetailers.type], createFavoriteRetailers),
    takeEvery([requestAddFavoriteRetailers.type], addFavoriteRetailers),
    takeEvery([requestDeleteFavoriteRetailers.type], deleteFavoriteRetailers),
    takeEvery([requestFetchFavoriteRetailers.type], fetchFavoriteRetailers),
  ]);
}

export default favoriteRetailersSaga;
