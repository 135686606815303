import { createSlice } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';
import * as ALOOMA from 'consts/alooma';
import CConsole from 'functions/CConsole';

export const TYPE_ERROR = 'error';
export const TYPE_SUCCESS = 'success';
export const TYPE_DELETED_TAG = 'delete_tag';
export const TYPE_NOTIFICATION_SUCCESS = 'notification_success';
export const TYPE_NOTIFICATION_UNSUCCESSFUL = 'notification_unsuccessful';
export const TYPE_DELETE_NOTIFICATION = 'delete_notification';
export const TYPE_MUTE_NOTIFICATION = 'mute_notification';

const initialState = { alerts: [] };

const showAlert = (state, { errorData, ...payload }, type) => {
  if (errorData) CConsole.error(payload?.message, errorData);

  if (payload?.message === '') return state;

  return {
    ...state,
    alerts: [
      ...state.alerts,
      {
        data: {
          source: ALOOMA.SOURCE_PAGES.ALERT,
          ...payload,
        },
        id: uniqueId(),
        type,
      },
    ],
  };
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    hideAlert: (state, { payload: { id } }) => ({
      ...state,
      alerts: state.alerts.filter(alert => alert.id !== id),
    }),

    showErrorAlert: (state, { payload }) => showAlert(state, payload, TYPE_ERROR),

    showSuccessAlert: (state, { payload }) => showAlert(state, payload, TYPE_SUCCESS),

    showDeleteTagAlert: (state, { payload }) => showAlert(state, payload, TYPE_DELETED_TAG),

    showNotificationSuccessAlert: (state, { payload }) => showAlert(state, payload, TYPE_NOTIFICATION_SUCCESS),

    showNotificationUnsuccessfulAlert: (state, { payload }) =>
      showAlert(state, payload, TYPE_NOTIFICATION_UNSUCCESSFUL),

    showDeleteNotificationAlert: (state, { payload }) => showAlert(state, payload, TYPE_DELETE_NOTIFICATION),

    showMuteNotificationAlert: (state, { payload }) => showAlert(state, payload, TYPE_MUTE_NOTIFICATION),
  },
});

export default alertsSlice;
