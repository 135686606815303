import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WinkIcon } from '@karma/components/icons';
import { Button, Stack, Typography } from '@karma/components';
import { ENTITIES } from 'consts/entities';
import { PATHS } from 'consts/paths';
import Bonuses from 'services/Bonuses';

const ModalBodyBonus = ({ bonus, closeModal, description }) => {
  const { t } = useTranslation();

  Bonuses.useNotifier(bonus);

  return (
    <Stack direction="column" spacing={20}>
      <WinkIcon width={60} height={60} />
      <Stack direction="column" spacing={8}>
        <Typography variant="h3" align="center">
          {t('app:karmaGivesModalTitle', { value: bonus.value })}
        </Typography>
        <Typography variant="paragraphLarge" align="center">
          {t(description)}
        </Typography>
      </Stack>

      <Button size="medium" onClick={closeModal}>
        {t('app:collectAndStartSaving')}
      </Button>
      <Typography variant="paragraphMedium">
        <Button
          size="small"
          type="underlinedText"
          onClick={closeModal}
          as="a"
          href={PATHS.TERMS}
          rel="noopener noreferrer"
          target="_blank"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        >
          {t('app:termsAndConditionsAppersant')}
        </Button>{' '}
        {t('app:mayApply')}
      </Typography>
    </Stack>
  );
};

ModalBodyBonus.propTypes = {
  bonus: ENTITIES.bonus.isRequired,
  closeModal: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

export default ModalBodyBonus;
