// elementRef is needed when you call this function from react-bootstrap modal (elementRef is a ref to element inside modal window)
// In other cases you can pass only 'value' prop
export default (value, elementRef) => {
  const el = document.createElement('textarea');

  el.value = value;
  if (elementRef) {
    elementRef.current.parentNode.insertBefore(el, elementRef.current);
  } else {
    document.body.appendChild(el);
  }
  el.select();
  document.execCommand('copy');
  el.parentNode.removeChild(el);
};
