import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { requestTagsBulkAddToLists } from 'store/multiEdit/actions';
import { getHeadTag, getSelectedTagsCount } from 'store/multiEdit/selectors';
import useAction from 'hooks/useAction';
import AddToList from 'components/organisms/AddToList/AddToList';

const ModalBodyTagsAddToList = ({ closeModal }) => {
  const headTag = useSelector(getHeadTag);
  const size = useSelector(getSelectedTagsCount);

  const requestTagsBulkAddToListsFunc = useAction(requestTagsBulkAddToLists);

  const submitHandler = useCallback(
    selectedLists => {
      requestTagsBulkAddToListsFunc({ lists: selectedLists });
      closeModal();
    },
    [closeModal, requestTagsBulkAddToListsFunc],
  );

  return <AddToList headTag={headTag} multiple size={size} onSubmit={submitHandler} />;
};

ModalBodyTagsAddToList.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalBodyTagsAddToList;
