import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@karma/components';
import { CheckmarkIcon, CopyIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import copyToClipboard from 'functions/copyToClipboard';

const COPIED_TIMEOUT = 3000;

const CopyButton = ({ value, text }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const [copied, setCopied] = useState(false);
  const elementRef = useRef(null);

  const copyHandler = useCallback(() => {
    copyToClipboard(value, elementRef);
    setCopied(true);
  }, [value, setCopied]);

  useEffect(() => {
    let timeout;

    if (copied) timeout = setTimeout(() => setCopied(false), COPIED_TIMEOUT);

    return () => timeout && clearTimeout(timeout);
  }, [copied]);

  return (
    <Button
      type="outline"
      size="small"
      ref={elementRef}
      onClick={copyHandler}
      data-alooma-element={ALOOMA.ELEMENTS.COPY_LINK}
      {...contextDataAttrs}
      Icon={copied ? CheckmarkIcon : CopyIcon}
      iconPosition="right"
      fullWidth
      active={copied}
    >
      {copied ? t('app:copiedToClipboard') : text || value}
    </Button>
  );
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
};

CopyButton.defaultProps = {
  text: undefined,
};

export default CopyButton;
