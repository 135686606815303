import { call, put } from 'redux-saga/effects';
import { showErrorAlert } from 'store/alerts/actions';
import { fetchMyItems } from 'store/myItems/store/actions';
import * as actions from '../actions';
import * as api from '../api';

function* editList(event) {
  try {
    const { data } = yield call(api.editList, event.payload);

    yield put(actions.setUpdatedList(data.data));
    yield put(fetchMyItems({ isFetching: true }));
  } catch (error) {
    yield put(
      showErrorAlert({
        message: error?.response?.data?.errors?.[0] || error?.response?.statusText,
      }),
    );
  }
}

export default editList;
