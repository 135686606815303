import React from 'react';
import { StyledTab } from './Tab.styles';
import { TabProps } from './Tab.types';

const Tab: React.FC<TabProps> = ({
  active,
  disabled,
  label,
  value,
  isTabChild,
  children,
  ...props
}) => (
  <StyledTab
    active={active}
    disabled={disabled}
    value={value}
    label={label}
    isTabChild={isTabChild}
    {...props}
  >
    {label || children}
  </StyledTab>
);

export default Tab;
