import styled, { css } from 'styled-components';
import { IGridProps } from './Grid.types';

export const GridContainer = styled.div<IGridProps>`
  display: grid;

  grid-template-columns: ${({ columns }) => columns && `repeat(${columns}, 1fr);`};

  grid-gap: ${({ gap }) => gap && `${gap}px`};
  grid-row-gap: ${({ rowGap }) => rowGap && `${rowGap}px`};
  grid-column-gap: ${({ columnGap }) => columnGap && `${columnGap}px`};

  grid-column-start: ${({ gridColumnStart }) => gridColumnStart};
  grid-column-end: ${({ gridColumnEnd }) => gridColumnEnd};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
