import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const FlameIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5384 3.92671C12.6007 4.401 12.6016 4.83769 12.5498 5.2397C12.4132 6.29806 11.92 7.03979 11.368 7.52684L11.3232 7.56636L11.2733 7.59923C8.68247 9.30649 8.12236 12.3249 7.98234 13.7765C7.93517 14.2655 7.5154 14.6529 7.00169 14.6315C6.08415 14.5932 5.44423 14.0759 5.03311 13.5059C4.71164 15.089 4.84845 16.6219 5.01511 17.3606L5.01612 17.3651L5.01611 17.3651C5.37149 18.981 6.17817 20.2456 7.18038 21.2283C10.1788 24.1684 15.3585 24.3525 18.6027 21.6642C20.039 20.474 20.7306 19.1769 20.8654 18.4425L20.8714 18.4101L20.8798 18.3783C21.2799 16.8676 21.0478 15.3122 20.549 13.9079C20.3587 13.372 20.1325 12.8667 19.8955 12.4051C19.6546 13.0218 19.2786 13.5369 18.8418 13.9483C17.9673 14.7718 16.8394 15.1941 16.0487 15.2499C15.6532 15.2778 15.3258 15.0612 15.1641 14.7723C15.0096 14.4962 14.9984 14.1494 15.1589 13.8535C16.4305 11.5088 16.0622 9.19345 14.969 7.13522C14.3266 5.9259 13.4446 4.83195 12.5384 3.92671ZM10.4374 0.965989C10.7252 0.714374 11.2084 0.618965 11.6132 0.909531C13.1828 2.03642 15.2075 3.96031 16.4703 6.33777C17.5041 8.28411 18.0468 10.5843 17.3515 12.9772C17.463 12.8974 17.5722 12.8086 17.6763 12.7107C18.2264 12.1926 18.6225 11.4306 18.4276 10.3028C18.3422 9.80812 18.6412 9.42944 18.9545 9.27593C19.2789 9.11696 19.8133 9.11583 20.1415 9.56774C20.7435 10.3966 21.5817 11.736 22.151 13.3389C22.7166 14.9316 23.0366 16.8421 22.5312 18.7829C22.293 20.0144 21.3188 21.6213 19.6874 22.9732C15.7833 26.2084 9.62046 26.0018 5.99017 22.4422C4.78626 21.2617 3.79233 19.713 3.35627 17.7324C3.07573 16.4865 2.85271 13.4958 4.11657 10.928C4.33054 10.4932 4.78013 10.3507 5.13419 10.4134C5.48045 10.4747 5.83377 10.7454 5.91052 11.1929C5.98092 11.6034 6.13015 12.0803 6.36071 12.4365C6.38599 12.4756 6.41137 12.512 6.43687 12.5459C6.76065 10.7456 7.65818 7.97627 10.2822 6.21677C10.5425 5.97373 10.7888 5.60316 10.8638 5.02215C10.9447 4.39469 10.8341 3.45109 10.2003 2.08437C10.0014 1.65554 10.1559 1.21214 10.4374 0.965989Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default FlameIcon;
