import React, { useMemo } from 'react';
import { InputBaseRoot } from './InputBase.styles';
import { InputBaseProps } from './InputBase.types';

const InputBase = React.forwardRef<HTMLInputElement, InputBaseProps>(function InputBase(
  {
    type = 'text',
    id,
    value,
    placeholder,
    required,
    ariaDescribedby,
    shrink = false,
    hasSuffix = false,
    hasPrefix = false,
    hasLabel = false,
    disabled = false,

    multiline = false,

    inputComponent,

    ...props
  },
  ref
) {
  const otherInputBaseProps = useMemo(
    () => ({
      ...(multiline
        ? {
            as: 'textarea',
          }
        : { type }),
      ...(inputComponent !== null && {
        as: inputComponent,
      }),
    }),
    [multiline, type]
  );

  return (
    <InputBaseRoot
      {...props}
      shrink={shrink}
      hasSuffix={hasSuffix}
      hasPrefix={hasPrefix}
      hasLabel={hasLabel}
      multiline={multiline}
      disabled={disabled}
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      aria-describedby={ariaDescribedby}
      value={value}
      ref={ref}
      {...otherInputBaseProps}
    />
  );
});

export default InputBase;
