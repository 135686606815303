import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BagIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02681 10.0054C0.943625 9.37627 1.43318 8.81772 2.06775 8.81772H23.2114C23.8459 8.81772 24.3355 9.37626 24.2523 10.0054L22.5598 22.8054C22.4907 23.3275 22.0455 23.7177 21.5188 23.7177H3.76031C3.23362 23.7177 2.78842 23.3275 2.71937 22.8054L1.02681 10.0054ZM2.80936 10.5177L4.33002 22.0177H20.9491L22.4698 10.5177H2.80936Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46484 6.74284C6.46484 3.33248 9.22949 0.567841 12.6398 0.567841C16.0502 0.567841 18.8148 3.33248 18.8148 6.74284V13.418H17.1148V6.74284C17.1148 4.27137 15.1113 2.26784 12.6398 2.26784C10.1684 2.26784 8.16484 4.27136 8.16484 6.74284V13.418H6.46484V6.74284Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BagIcon;
