import trackFacebookEvent from 'functions/trackFacebookEvent';
import trackGoogleTagEvent from 'functions/trackGoogleTagEvent';
import alooma from 'services/alooma';

export default async data => {
  trackFacebookEvent('CompleteRegistration');

  alooma.UNSAFE_trackAloomaEvent('Started Signup Tutorial');
  alooma.UNSAFE_trackAloomaEvent('standart_reg', alooma.formRegistrationData(data));

  await trackGoogleTagEvent('signup');
};
