import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ROUTES from 'consts/routes';
import { oldApiInstance } from 'functions/request';
import useAction from 'hooks/useAction';
import { showErrorAlert } from 'store/alerts/actions';

const useGetForgotPasswordFormProps = (setInstructionsSent, setEmailSentTo) => {
  const { t } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const showErrorAlertFunc = useAction(showErrorAlert);

  const showError = err => showErrorAlertFunc({ message: err });

  const sendResetPasswordInstructions = ({ email }) => {
    setFetching(true);
    setEmailSentTo(email);
    oldApiInstance
      .post(ROUTES.api.resetPasswordPath, { email })
      .then(() => {
        setInstructionsSent(true);
      })
      .catch(({ response = {} }) => {
        if (response.data && response.data.error) {
          const serverError = response.data.error[0];

          showError(serverError.err || serverError);
        }
      })
      .finally(() => setFetching(false));
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      yup
        .object({
          email: yup.string().email(t('app:invalidEmailError')).required(t('app:thisFieldIsRequired')),
        })
        .required(),
    ),
  });

  const onSubmitFunc = data => sendResetPasswordInstructions(data);
  const onSubmit = handleSubmit(onSubmitFunc);

  return { onSubmit, control, fetching };
};

export default useGetForgotPasswordFormProps;
