import PropTypes from 'prop-types';
import { Button } from '@karma/components';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import OAuth2 from 'functions/OAuth2';
import Alooma from 'services/alooma';
import { oauthProviders } from 'consts/oauthProviders';
import { SocialListV3, SocialItem } from './SocialLogin.style';

const SocialLogin = ({
  disabled,
  agreementValue,
  setAgreementError,
  requestSended,
  autoClose,
  redirectTo,
  buttonSize,
}) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const handleClick = provider => {
    if (!agreementValue) setAgreementError();
    else OAuth2.login({ provider, autoClose, redirectTo });
  };

  return (
    <SocialListV3 hide={requestSended}>
      {oauthProviders.map(([provider, IconComponent]) => (
        <SocialItem key={provider}>
          <Button
            Icon={IconComponent}
            {...contextDataAttrs}
            data-alooma-element={provider}
            onClick={() => handleClick(provider)}
            disabled={disabled}
            fullWidth
            size={buttonSize}
          >
            {t('app:continueWith', {
              provider: capitalize(provider),
            })}
          </Button>
        </SocialItem>
      ))}
    </SocialListV3>
  );
};

SocialLogin.propTypes = {
  disabled: PropTypes.bool,
  agreementValue: PropTypes.bool,
  setAgreementError: PropTypes.func,
  requestSended: PropTypes.bool,
  autoClose: PropTypes.bool,
  redirectTo: PropTypes.string,
  buttonSize: PropTypes.string,
};

SocialLogin.defaultProps = {
  disabled: false,
  agreementValue: true,
  setAgreementError: () => null,
  requestSended: false,
  autoClose: false,
  redirectTo: null,
  buttonSize: 'medium',
};

export default SocialLogin;
