import { all, takeLatest } from 'redux-saga/effects';
import * as globalSearchActions from '../actions';
import fetchGlobalItemsHandler from './worker.fetchGlobalItems';
import restartGlobalSearchHandler from './worker.restartGlobalSearch';

function* globalSearchSaga() {
  yield all([takeLatest(globalSearchActions.fetchGlobalItems.type, fetchGlobalItemsHandler)]);
  yield all([takeLatest(globalSearchActions.restartGlobalSearch.type, restartGlobalSearchHandler)]);
}

export default globalSearchSaga;
