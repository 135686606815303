import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { deleteTagRequest, setUpdatedTag } from 'store/myItems/store/actions';
import { saveTagByUrl } from 'store/myItems/store/api';
import { getUserId } from 'store/user/selectors';
import CConsole from 'functions/CConsole';
import * as ALOOMA from 'consts/alooma';
import { showDeleteTagAlert } from 'store/alerts/actions';
import useAction from './useAction';

export const useSaveGlobalItem = () => {
  const setUpdatedTagFunc = useAction(setUpdatedTag);
  const userId = useSelector(getUserId);

  return useCallback(
    async tag => {
      try {
        const { original_url: url } = tag;
        const response = (await saveTagByUrl(url)) || {};

        const createdTag = response.data;

        if (createdTag.tag.id) {
          setUpdatedTagFunc({
            ...tag,
            user_id: userId,
            global_saved_tag_id: createdTag.tag.id,
          });
        }
      } catch (error) {
        CConsole.error(error);
      }
    },
    [setUpdatedTagFunc, userId],
  );
};

export const useDeleteTag = () => {
  const { t } = useTranslation();
  const setUpdatedTagFunc = useAction(setUpdatedTag);
  const deleteTagRequestFunc = useAction(deleteTagRequest);
  const showDeleteTagAlertFunc = useAction(showDeleteTagAlert);

  return useCallback(
    (tag, callback) => {
      const { id, global_saved_tag_id } = tag;

      if (global_saved_tag_id) {
        deleteTagRequestFunc(global_saved_tag_id);
        setUpdatedTagFunc({
          ...tag,
          user_id: null,
          favorites: false,
          fulfilled: false,
          prevent_notifications: false,
          notify_when: null,
          lists: [],
          global_saved_tag_id: undefined,
        });
      } else {
        deleteTagRequestFunc(id);
        callback();
        showDeleteTagAlertFunc({
          message: t('app:productDeleted'),
          id,
          source: ALOOMA.SOURCE_PAGES.POP_UP_FOR_DELETE_ITEM,
        });
      }
    },
    [deleteTagRequestFunc, setUpdatedTagFunc, showDeleteTagAlertFunc, t],
  );
};

export const useIsSavedItem = tag => {
  const userId = useSelector(getUserId);

  return tag.user_id === userId;
};
