import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Image } from './Icon.style';

// eslint-disable-next-line react/prop-types
const Icon = ({ imgUrl, altText, alt, onClick, className, ...attrs }) => {
  const { t } = useTranslation();

  if (onClick) {
    return <Image {...attrs} src={imgUrl} alt={t(altText || alt)} onClick={onClick} className={className} />;
  }

  return <Image srcSet={imgUrl} alt={t(altText || alt)} className={className} />;
};

Icon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attrs: PropTypes.object,
  imgUrl: PropTypes.string,
  altText: PropTypes.string,
  alt: PropTypes.string,
};

Icon.defaultProps = {
  attrs: {},
  imgUrl: '',
  altText: '',
  alt: '',
};
export default Icon;
