import getOnboardingPathAfterAuthorization from 'functions/getOnboardingPathAfterAuthorization';
import useTransitionTo from './useTransitionTo';

const useGetRedirectAfterAuthorization = () => {
  const transitionTo = useTransitionTo();

  return isExtensionInstalled => transitionTo(getOnboardingPathAfterAuthorization(isExtensionInstalled));
};

export default useGetRedirectAfterAuthorization;
