import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSavedItemsFromStore } from 'store/myItems/store/selectors';
import { fetchSavedItemsFromStore } from 'store/myItems/store/actions';
import useAction from 'hooks/useAction';
import { DEFAULT_PER_GROUP_SAVED_FROM_STORE } from 'consts/queries';
import Alooma from 'services/alooma';
import CardTagSquare from 'components/molecules/CardTagSquare/CardTagSquare';
import { SAVED_FROM_STORE } from 'consts/myItemsGroups';
import BaseSwiperSlider from '../BaseSwiperSlider/BaseSwiperSlider';
import { SliderWrapper } from './ModalStoreInfoSavedFromStoreCards.style';

const cardTagSquareBreakpoints = {
  1025: { slidesPerView: 6 },
  768: { slidesPerView: 5 },
  500: { slidesPerView: 4 },
  1: { slidesPerView: 3 },
};

const ModalStoreInfoSavedFromStoreCards = ({ retailerId }) => {
  const { tags, page, tagsLeft } = useSelector(getSavedItemsFromStore);
  const currentRef = useRef({ page, tagsLeft });

  const fetchSavedItemsFromStoreFunc = useAction(fetchSavedItemsFromStore);

  const fetchMoreTags = () => {
    if (currentRef.current.tagsLeft)
      fetchSavedItemsFromStoreFunc({
        retailerId,
        page: currentRef.current.page + 1,
        perGroup: DEFAULT_PER_GROUP_SAVED_FROM_STORE,
      });
  };

  const settings = {
    on: {
      reachEnd: () => {
        fetchMoreTags();
      },
    },
    breakpoints: cardTagSquareBreakpoints,
  };

  useEffect(() => {
    currentRef.current = { page, tagsLeft };
  }, [page, tagsLeft]);

  return (
    <SliderWrapper>
      <BaseSwiperSlider {...settings} v2>
        {tags.map(tag => (
          <div key={tag.id}>
            <Alooma.TagIdContext.Provider value={tag.id}>
              <Alooma.RuleIdContext.Provider value={tag.rule_id}>
                <CardTagSquare group={SAVED_FROM_STORE} tag={tag} forceRedirectToTag />
              </Alooma.RuleIdContext.Provider>
            </Alooma.TagIdContext.Provider>
          </div>
        ))}
      </BaseSwiperSlider>
    </SliderWrapper>
  );
};

export default ModalStoreInfoSavedFromStoreCards;
