import { MAX_TABLET_WIDTH, MIN_SCREEN_WIDTH, M_PAGE_BASE_WIDTH } from 'consts/common';

function currentMobileSize(size, units = true) {
  const value = ((size / M_PAGE_BASE_WIDTH) * 100).toFixed(2);

  return units ? `${value}vw` : value;
}

function maxMobileSize(size) {
  return `${Math.round((currentMobileSize(size, false) / 100) * MAX_TABLET_WIDTH)}px`;
}

function minMobileSize(size) {
  return `${Math.round((currentMobileSize(size, false) / 100) * MIN_SCREEN_WIDTH)}px`;
}

export function clampLandingMobileSize(size) {
  return `clamp(${minMobileSize(size)}, ${currentMobileSize(size)}, ${maxMobileSize(size)})`;
}
