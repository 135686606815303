export const baseAlertConfig = {
  lightConfig: {
    backgroundColor: 'neutral6',
    color: 'primary1',
  },
  darkConfig: {
    backgroundColor: 'primary1',
    color: 'neutral6',
  },
};
