import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Button, TextField } from '@karma/components';
import { ENTITIES } from 'consts/entities';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useAction from 'hooks/useAction';
import { showErrorAlert } from 'store/alerts/actions';
import { editList as editListAction } from 'store/lists/actions';
import { Container } from './ModalBodyListEdit.style';

const ModalBodyListEdit = ({ closeModal, list }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const [name, setName] = useState(list.name);
  const [description, setDescription] = useState(list.description || '');
  const showErrorAlertFunc = useAction(showErrorAlert);
  const editList = useAction(editListAction);

  const changeName = event => setName(event.target.value);

  const changeDesc = event => setDescription(event.target.value);

  const saveHandler = useCallback(() => {
    if (!name.trim()) {
      showErrorAlertFunc({ message: t('app:listNameCanNotBeBlanck') });
    } else {
      const listData = {
        name: name.trim(),
        description: description.trim() || null,
      };

      if (listData.name !== list.name || listData.description !== list.description) {
        editList({ id: list.id, data: listData });
      }

      closeModal();
    }
  }, [name, description, list, showErrorAlertFunc, editList, closeModal, t]);

  return (
    <Container>
      <Typography variant="h3">{t('app:editList')}</Typography>
      <Alooma.SectionContext.Provider value={ALOOMA.SECTIONS.NAME}>
        <TextField
          label={t('app:listName')}
          placeholder={t('app:enterListName')}
          value={name}
          onChange={changeName}
          fullWidth
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.INPUT}
        />
      </Alooma.SectionContext.Provider>

      <Alooma.SectionContext.Provider value={ALOOMA.SECTIONS.DESCRIPTION}>
        <TextField
          multiline
          inputProps={{ rows: 2 }}
          label={t('app:listDescriptionOptional')}
          placeholder={t('app:enterDescriptionForList')}
          value={description}
          onChange={changeDesc}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.INPUT}
          fullWidth
        />
      </Alooma.SectionContext.Provider>

      <Button
        size="medium"
        onClick={saveHandler}
        fullWidth
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.SAVE}
      >
        {t('app:save')}
      </Button>
    </Container>
  );
};

ModalBodyListEdit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  list: ENTITIES.list.isRequired,
};

export default ModalBodyListEdit;
