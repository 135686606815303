import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const HeartIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37956 15.9444L12.7059 20.4879L21.5739 11.326C23.3941 9.44553 23.3941 6.37443 21.5739 4.49396C19.7921 2.65307 16.9301 2.64365 15.1371 4.47162L12.6219 7.03587L10.0863 4.41615C8.31365 2.58478 5.46334 2.58478 3.69072 4.41615C1.87672 6.29028 1.89001 9.35479 3.71884 11.2118L8.37956 15.9444ZM12.6959 22.9426L7.15828 17.1271L2.50759 12.4047C0.0307958 9.88967 0.0135542 5.77086 2.4692 3.23382C4.90991 0.712221 8.86708 0.71222 11.3078 3.23382L12.63 4.59989L13.9235 3.28121C16.3862 0.770366 20.3489 0.783956 22.7955 3.31164C25.2536 5.85123 25.2536 9.96873 22.7955 12.5083L12.6959 22.9426Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HeartIcon;
