const symbols = {
  У: 'Y',
  К: 'K',
  Е: 'E',
  Н: 'H',
  З: '3',
  Х: 'X',
  у: 'y',
  е: 'e',
  г: 'r',
  х: 'x',
  В: 'B',
  А: 'A',
  Р: 'P',
  О: 'O',
  а: 'a',
  р: 'p',
  о: 'o',
  С: 'C',
  М: 'M',
  Т: 'T',
  Ь: 'b',
  с: 'c',
  б: '6',
  м: 'm',
};

export default symbols;
