import { LANGUAGE_OPTIONS } from 'consts/locales';
import getLanguage from 'functions/getLanguage';

const useDefaultLanguageOption = () => {
  const locale = getLanguage();

  return LANGUAGE_OPTIONS.find(({ value }) => value === locale);
};

export default useDefaultLanguageOption;
