import partial from 'lodash/partial';

const _get = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return localStorage.getItem(key);
  }
};
const _set = (key, value) => localStorage.setItem(key, value);

const keys = {
  token: 'token',
  isDashboardBannerClosed: 'isDashboardBannerClosed',
};

const storage = {
  getToken: partial(_get, keys.token),
  setToken: partial(_set, keys.token),
};

export default storage;
