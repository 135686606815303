import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as favoriteRetailersActions from '../actions';
import * as favoriteRetailersApi from '../api';

function* createFavoriteRetailers(action) {
  try {
    yield call(favoriteRetailersApi.createFavoriteRetailers, action.payload);
    yield put(favoriteRetailersActions.successCreateFavoriteRetailers(action.payload));
    yield put(favoriteRetailersActions.requestFetchFavoriteRetailers());
  } catch (error) {
    yield put(favoriteRetailersActions.failureCreateFavoriteRetailers());
    yield put(handleServerError(error));
  }
}

export default createFavoriteRetailers;
