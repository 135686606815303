import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const QuestionMarkIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M12.2458 18.0866C14.0783 18.0866 15.3218 17.6612 16.3035 17.1376V14.6179C15.1582 15.3378 13.8165 15.8286 12.2785 15.8286C10.4787 15.8286 9.52976 15.1414 9.52976 14.1597C9.52976 11.2146 18.0379 11.1492 18.0379 5.42254C18.0379 2.3138 15.5836 0.186768 12.1476 0.186768C8.6135 0.186768 5.96289 2.37925 5.96289 5.52071C5.96289 6.27335 6.09379 6.92782 6.32285 7.61502H8.80984C8.6135 7.05872 8.54805 6.50242 8.54805 6.01156C8.54805 3.81908 9.98789 2.47742 12.0495 2.47742C14.0456 2.47742 15.2891 3.72091 15.2891 5.45526C15.2891 10.0038 6.78098 9.05486 6.78098 14.2579C6.78098 16.6467 8.67895 18.0866 12.2458 18.0866ZM11.984 23.8132C13.1294 23.8132 14.0129 22.9297 14.0129 21.7843C14.0129 20.6063 13.1294 19.7227 11.984 19.7227C10.806 19.7227 9.92244 20.6063 9.92244 21.7843C9.92244 22.9297 10.806 23.8132 11.984 23.8132Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default QuestionMarkIcon;
