import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TimeIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6391 2.9616C7.5684 2.9616 3.45781 7.07218 3.45781 12.1428C3.45781 17.2135 7.5684 21.3241 12.6391 21.3241C17.7097 21.3241 21.8203 17.2135 21.8203 12.1428C21.8203 7.07218 17.7097 2.9616 12.6391 2.9616ZM1.75781 12.1428C1.75781 6.1333 6.62951 1.2616 12.6391 1.2616C18.6486 1.2616 23.5203 6.1333 23.5203 12.1428C23.5203 18.1524 18.6486 23.0241 12.6391 23.0241C6.62951 23.0241 1.75781 18.1524 1.75781 12.1428Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7937 13.4872C10.5063 11.236 10.5116 8.95702 10.8094 6.70715L12.4947 6.93021C12.2318 8.91647 12.2126 10.927 12.4373 12.917L14.5843 13.8712L17.147 14.8771L16.5259 16.4596L13.9282 15.44L10.8695 14.0805L10.7937 13.4872Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TimeIcon;
