import styled, { css } from 'styled-components';
import { TypographyRootProps } from './ExtTypography.types';
import { getColor } from '../colors';
import omitForwardedProps from '../utils/omitForwardedProps';

const omittedProps: Array<keyof TypographyRootProps> = [
  'align',
  'bMargin',
  'lMargin',
  'rMargin',
  'noWrap',
  'lineThrough',
  'variant',
  'color',
];

const commonStyle = css<TypographyRootProps>`
  text-align: ${({ align }) => align || 'left'};
  margin-bottom: ${({ bMargin }) => bMargin || 0}px;
  margin-left: ${({ lMargin }) => lMargin || 0}px;
  margin-right: ${({ rMargin }) => rMargin || 0}px;
  margin-top: ${({ tMargin }) => tMargin || 0}px;
  color: ${({ color }) => getColor(color || 'primary1')};
  text-decoration: ${({ lineThrough }) => (lineThrough ? 'line-through' : 'none')};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};

  ${({ textTransform }) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `};

  ${({ textDecoration }) =>
    textDecoration &&
    css`
      text-decoration: ${textDecoration};
    `};

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    `};
`;

const primaryFont = css`
  font-family: 'Rebond';
  font-style: normal;
`;

const secondaryFont = css`
  font-family: 'Roboto';
  font-style: normal;
`;

export const H1Styles = css`
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  ${primaryFont};
`;

export const H2Styles = css`
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  ${primaryFont};
`;

export const H3Styles = css`
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  ${primaryFont};
`;

export const H4Styles = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  ${primaryFont};
`;

export const H4SemiBoldStyles = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  ${primaryFont};
`;

export const H5Styles = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  ${primaryFont};
`;

export const H6Styles = css`
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  ${primaryFont};
`;

export const ParagraphLargeStyles = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  ${secondaryFont};
`;

export const ParagraphBigStyles = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  ${secondaryFont};
`;

export const ParagraphMediumStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${secondaryFont};
`;

export const LabelLargeStyles = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ${secondaryFont};
`;

export const LabelMediumStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${secondaryFont};
`;

export const LabelMediumBoldStyles = css`
  ${LabelMediumStyles};
  font-weight: 700;
`;

export const LabelSmallStyles = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  ${secondaryFont};
`;

export const LineThroughStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: line-through;
  ${secondaryFont};
`;

export const LabelSmallUppercaseStyles = css`
  ${LabelSmallStyles};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const LabelSmallUppercaseBoldStyles = css`
  ${LabelSmallUppercaseStyles};
  font-weight: 700;
`;

export const LabelExtraSmallUppercaseStyles = css`
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  ${secondaryFont};
`;

export const LabelExtraSmallUppercaseSemiboldStyles = css`
  ${LabelExtraSmallUppercaseStyles};
  font-weight: 600;
`;

export const H1 = styled.h1.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H1Styles}
  ${commonStyle}
`;

export const H2 = styled.h2.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H2Styles}
  ${commonStyle}
`;

export const H3 = styled.h3.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H3Styles}
  ${commonStyle}
`;

export const H4 = styled.h4.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H4Styles}
  ${commonStyle}
`;

export const H4SemiBold = styled.h4.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H4SemiBoldStyles}
  ${commonStyle}
`;

export const H5 = styled.h5.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H5Styles}
  ${commonStyle}
`;

export const H6 = styled.h6.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${H6Styles}
  ${commonStyle}
`;

export const ParagraphBig = styled.p.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${ParagraphBigStyles}
  ${commonStyle}
`;

export const ParagraphLarge = styled.p.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${ParagraphLargeStyles}
  ${commonStyle}
`;

export const ParagraphMedium = styled.p.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${ParagraphMediumStyles}
  ${commonStyle}
`;

// export const ParagraphSmall = styled.p.withConfig(
//   omitForwardedProps<TypographyRootProps>(omittedProps)
// )<TypographyRootProps>`
//   ${ParagraphSmallStyles}
//   ${commonStyle}
// `;

export const LabelLarge = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelLargeStyles}
  ${commonStyle}
`;

export const LabelMedium = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelMediumStyles}
  ${commonStyle}
`;

export const LabelMediumBold = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelMediumBoldStyles}
  ${commonStyle}
`;

export const LabelSmall = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelSmallStyles}
  ${commonStyle}
`;

export const LabelSmallUppercase = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelSmallUppercaseBold = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelSmallUppercaseBoldStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercase = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelExtraSmallUppercaseStyles}
  ${commonStyle}
`;

export const LabelExtraSmallUppercaseSemibold = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LabelExtraSmallUppercaseSemiboldStyles}
  ${commonStyle}
`;

export const LineThrough = styled.div.withConfig(
  omitForwardedProps<TypographyRootProps>(omittedProps)
)<TypographyRootProps>`
  ${LineThroughStyles}
  ${commonStyle}
`;
