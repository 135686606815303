import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TrendUpIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 17 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M14.9757 9.54896L16.109 9.54682L16.0939 3.53104L10.043 3.52344L10.0419 4.65677L14.2858 4.66241L8.99664 9.86686L5.86997 6.72002L0.393066 11.9221L1.17357 12.7438L5.84703 8.30489L8.98762 11.4657L14.9652 5.58387L14.9757 9.54896Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrendUpIcon;
