import styled from 'styled-components';
import { LabelMediumStyles } from '../Typography/Typography.styles';
import { SwitchRootProps } from './Switch.types';

export const SwitchRoot = styled.label<SwitchRootProps>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  margin: 0;
`;

export const SwitchWrapper = styled.div`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  min-width: 54px;
  height: 30px;
`;

export const Slider = styled.span<SwitchRootProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s;
  border-radius: 100px;

  background: ${({ checked, disabled, theme }) => {
    const colors = theme[theme.color].Switch.control;

    if (checked && disabled) return colors.activeDisabled.background;
    if (disabled) return colors.disabled.background;
    if (checked) return colors.active.background;
    return colors.normal.background;
  }};

  border: 1px solid
    ${({ checked, theme, disabled }) => {
      const colors = theme[theme.color].Switch.control;

      if (checked && disabled) return colors.activeDisabled.border;
      if (disabled) return colors.disabled.border;
      if (checked) return colors.active.border;
      return colors.normal.border;
    }};

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    left: 2px;
    bottom: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background: ${({ theme, disabled, checked }) => {
      const colors = theme[theme.color].Switch.circle;

      if (checked && disabled) return colors.activeDisabled;
      if (disabled) return colors.disabled;
      return colors.normal;
    }};
    transform: ${({ checked }) => (checked ? 'translateX(24px)' : 'translateX(0px)')};
    transition-property: transform, background;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
`;

export const ToggleSwitchInput = styled.input`
  all: unset;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchLabel = styled.span<SwitchRootProps>`
  ${LabelMediumStyles};
  padding-left: 8px;
  color: ${({ theme, disabled, checked }) => {
    const colors = theme[theme.color].Switch.label;

    if (checked && disabled) return colors.activeDisabled;
    if (disabled) return colors.disabled;
    return colors.normal;
  }};
`;
