import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as notificationsActions from '../actions';
import * as notificationsApi from '../api';

function* deleteNotification(action) {
  try {
    yield call(notificationsApi.deleteNotification, action.payload);
    yield put(notificationsActions.successDeleteNotification(action.payload));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default deleteNotification;
