import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BrowserSafariIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15039 12C1.15039 6.00758 6.00795 1.15002 12.0004 1.15002C17.9928 1.15002 22.8504 6.00758 22.8504 12C22.8504 17.9925 17.9928 22.85 12.0004 22.85C6.00795 22.85 1.15039 17.9925 1.15039 12ZM12.0004 2.85002C6.94683 2.85002 2.85039 6.94647 2.85039 12C2.85039 17.0536 6.94683 21.15 12.0004 21.15C17.0539 21.15 21.1504 17.0536 21.1504 12C21.1504 6.94647 17.0539 2.85002 12.0004 2.85002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1015 6.89895C17.3291 7.1266 17.4086 7.46335 17.3068 7.76878L15.0568 14.5188C14.9722 14.7726 14.773 14.9718 14.5192 15.0564L7.76921 17.3064C7.46377 17.4082 7.12703 17.3287 6.89937 17.101C6.67172 16.8734 6.59222 16.5366 6.69403 16.2312L8.94403 9.48119C9.02864 9.22738 9.22781 9.02821 9.48162 8.94361L16.2316 6.69361C16.5371 6.59179 16.8738 6.67129 17.1015 6.89895ZM10.4224 10.422L8.84438 15.156L13.5784 13.578L15.1564 8.84395L10.4224 10.422Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BrowserSafariIcon;
