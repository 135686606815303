import React from 'react';
import { getColor } from '../colors';
import { StyledDivider } from './Divider.styles';
import { IDividerProps } from './Divider.types';

const Divider: React.FC<IDividerProps> = ({
  orientation = 'horizontal',
  fullWidth = true,
  width = '1px',
  height = '1px',
  color = 'currentColor',
  bMargin = 0,
  lMargin = 0,
  rMargin = 0,
  tMargin = 0,
  ...props
}) => (
  <StyledDivider
    orientation={orientation}
    fullWidth={fullWidth}
    width={width}
    height={height}
    color={getColor(color)}
    bMargin={bMargin}
    lMargin={lMargin}
    rMargin={rMargin}
    tMargin={tMargin}
    {...props}
  />
);

export default Divider;
