import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const Column = styled.div`
  min-width: ${({ primary }) => (primary ? `380px` : '280px')};
  width: 100%;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  height: 125px;
`;
