import React from 'react';
import { FieldSetRoot, Legend, LabelSpan } from './FieldSet.styles';
import { FieldSetProps } from './FieldSet.types';

const FieldSet: React.FC<FieldSetProps> = ({
  label,
  focused,
  shrink,
  disabled,
  success,
  error,
  ...props
}) => (
  <FieldSetRoot
    {...props}
    aria-hidden="true"
    focused={focused}
    disabled={disabled}
    success={success}
    error={error}
  >
    <Legend shrink={label && shrink}>
      <LabelSpan>{label}</LabelSpan>
    </Legend>
  </FieldSetRoot>
);

export default FieldSet;
