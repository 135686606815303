import { Fragment, useCallback, useMemo } from 'react';
import { Divider } from '@karma/components';
import useAction from 'hooks/useAction';
import { setUpdatedTag, updateTagRequest } from 'store/myItems/store/actions';
import PropertySelect from 'components/molecules/PropertySelect/PropertySelect';
import { PropertiesSelectLists } from './PropertiesSelectList.style';

const PropertiesSelectList = ({ tag, isSavedItem }) => {
  const updateTag = useAction(updateTagRequest);
  const setUpdatedTagFunc = useAction(setUpdatedTag);

  const { properties, properties_l, id } = tag;

  const propertiesValues = useMemo(() => {
    return properties_l
      ? Object.entries(properties_l)?.reduce((acc, [key, value]) => {
          const values = value?.split(',') || [];

          if (values.length <= 1) return acc;

          const valuesArray = value?.split(',') || [];

          acc[key] = {
            values,
            value: valuesArray.includes(properties[key]) ? properties[key] : null,
          };

          return acc;
        }, {})
      : {};
  }, [properties, properties_l]);

  const propertyChangeHandler = useCallback(
    (propertyKey, newValue) => {
      const oldValue = properties[propertyKey];

      if (newValue === oldValue) return;

      if (!isSavedItem) {
        setUpdatedTagFunc({
          ...tag,
          properties: {
            ...tag.properties,
            [propertyKey]: newValue,
          },
        });
      } else {
        updateTag({ id, data: { properties: { [propertyKey]: newValue } } });
      }
    },
    [id, isSavedItem, properties, setUpdatedTagFunc, tag, updateTag],
  );

  const propertiesEntries = useMemo(
    () => Object.entries(propertiesValues).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
    [propertiesValues],
  );

  if (!properties) return null;

  return (
    <PropertiesSelectLists justifyContent="flex-start" spacing={16}>
      {propertiesEntries.map(([propertyKey, property], index) => (
        <Fragment key={propertyKey}>
          <PropertySelect propertyKey={propertyKey} property={property} propertyChangeHandler={propertyChangeHandler} />
          {index !== propertiesEntries.length - 1 && <Divider orientation="vertical" color="neutral2" />}
        </Fragment>
      ))}
    </PropertiesSelectLists>
  );
};

export default PropertiesSelectList;
