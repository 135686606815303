import { oldApiInstance } from 'functions/request';
import getRecaptchaToken from 'functions/getRecaptchaToken';
import { authenticateUser } from './authentication';
import { handleErrorFromServer } from './handleErrorFromServer';

const logInRequest = data => {
  return oldApiInstance.post('/sessions/email_login', data).catch(handleErrorFromServer);
};

export const loginUser = async ({ email, password }) => {
  const recaptchaToken = await getRecaptchaToken('LOGIN');
  const userId = localStorage.getItem('user_id');
  const userData = {
    'g-recaptcha-response': recaptchaToken,
    email,
    password,
    // don't know how it happens but need to handle this.
    merge_guest_user_id: userId !== 'null' ? userId : null,
  };

  return logInRequest(userData).then(({ data }) => {
    authenticateUser(data);

    return data;
  });
};
