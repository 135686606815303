import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as myItemsApi from 'store/myItems/store/api';
import { receiveMyItems, setIsFetchingItems } from 'store/myItems/store/actions';
import { GLOBAL_ITEMS, MY_GLOBAL_ITEMS } from 'consts/myItemsGroups';
import { getQueryParam } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { DEFAULT_PER_PAGE_MY_ITEMS_SEARCH } from 'consts/queries';
import { addNewRecentSearch } from '../actions';

function* fetchGlobalItemsHandler({
  payload: { page = 1, per_page = DEFAULT_PER_PAGE_MY_ITEMS_SEARCH, includeGlobal, includeUser },
}) {
  try {
    if (includeGlobal) {
      yield put(
        setIsFetchingItems({
          group: GLOBAL_ITEMS,
          isFetching: true,
        }),
      );
    }

    if (includeUser) {
      yield put(
        setIsFetchingItems({
          group: MY_GLOBAL_ITEMS,
          isFetching: true,
        }),
      );
    }

    const query = getQueryParam(QUERY_PARAMS.query);
    const { data } = yield call(myItemsApi.fetchMyItemsRequest, {
      filters: {
        search: query,
      },
      page,
      per_page,
      sort_by: 'created_at',
      sort_order: 'desc',
      include_global: includeGlobal,
      include_user: includeUser,
    }) || {};

    if (data.global.length || data.data.length) yield put(addNewRecentSearch(query));

    if (includeGlobal) {
      const globalTags = data.global;

      yield put(
        receiveMyItems({
          group: GLOBAL_ITEMS,
          page,
          tags: globalTags,
          tagsLeft: globalTags.length >= per_page,
          // totalCount can not be calculated correctly due to duplicate global tags
          totalCount: 0,
        }),
      );
    }

    if (includeUser) {
      const myItemsTags = data.data;
      const totalMyItems = per_page * (page - 1) + myItemsTags.length;
      const totalMyItemsCount = data.meta.total_count;

      yield put(
        receiveMyItems({
          group: MY_GLOBAL_ITEMS,
          page,
          tags: myItemsTags,
          tagsLeft: totalMyItemsCount > totalMyItems,
          totalCount: totalMyItemsCount,
        }),
      );
    }
  } catch (error) {
    if (includeGlobal) {
      yield put(
        setIsFetchingItems({
          group: GLOBAL_ITEMS,
          isFetching: false,
        }),
      );
    }

    if (includeUser) {
      yield put(
        setIsFetchingItems({
          group: MY_GLOBAL_ITEMS,
          isFetching: false,
        }),
      );
    }

    yield put(handleServerError(error));
  }
}

export default fetchGlobalItemsHandler;
