import { CDN_URL } from '@karma/utils/constants/env';

const onErrorImageHandler = e => {
  const defaultSrc = `${CDN_URL}/images/dashboard/placement_image.png`;

  e.target.onerror = null;
  e.target.src = e.target.dataset.errorSrc || defaultSrc;
  e.target.classList.add('--error');
  e.target.style.margin = e.target.dataset.errorMargin;
};

export default onErrorImageHandler;
