import { all, takeLatest, call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import { setLandingPage, fetchLandingPageRequest } from './actions';
import { fetchLandingPage } from './api';

function* getLandingPage(event) {
  try {
    const { data } = yield call(fetchLandingPage, event.payload);

    yield put(setLandingPage({ id: event.payload, ...data }));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default function* () {
  yield all([takeLatest(fetchLandingPageRequest.type, getLandingPage)]);
}
