import PropTypes from 'prop-types';

const ToggleModal = ({ tag, setting, activeBtnId, closeToggleModal }) => {
  const { Component } = activeBtnId ? setting.find(elem => elem.id === activeBtnId) : {};

  if (!Component) return null;

  return <Component closeModal={closeToggleModal} tag={tag} />;
};

ToggleModal.propTypes = {
  tag: PropTypes.shape({}).isRequired,
  setting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      tooltip: PropTypes.string.isRequired,
      Icon: PropTypes.func.isRequired,
      alooma: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      callback: PropTypes.func,
      Component: PropTypes.func,
    }),
  ).isRequired,
  closeToggleModal: PropTypes.func.isRequired,
  activeBtnId: PropTypes.number,
};

ToggleModal.defaultProps = {
  activeBtnId: null,
};

export default ToggleModal;
