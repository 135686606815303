import { call, put, select } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as filtersSelectors from 'store/filters/selectors';
import * as myItemsActions from 'store/myItems/store/actions';
import * as myItemsApi from 'store/myItems/store/api';
import { myItemsGetSortBy, myItemsGetSortOrder } from 'functions/urlHelpers';
import { DEFAULT_PER_PAGE } from 'consts/queries';
import { MAIN } from 'consts/myItemsGroups';

function* fetchMyItems({ payload: { page = 1, per_page = DEFAULT_PER_PAGE } }) {
  try {
    const filters = yield select(filtersSelectors.getFilters);
    const sort_by = myItemsGetSortBy(filters.staticFilter);
    const sort_order = myItemsGetSortOrder(filters.staticFilter);
    const response = yield call(myItemsApi.fetchMyItemsRequest, {
      filters,
      page,
      per_page,
      sort_by,
      sort_order,
    });

    const totalItems = per_page * (page - 1) + response.data.data.length;
    const totalCount = response.data.meta.total_count;

    yield put(
      myItemsActions.receiveMyItems({
        group: MAIN,
        page,
        tags: response.data.data,
        tagsLeft: totalCount > totalItems,
        totalCount,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchMyItems;
