import { newApiInstance } from 'functions/request';

export const createFavoriteRetailers = ids => {
  const data = { ids };

  return newApiInstance.post('/my/favorite_retailers', data);
};

export const deleteFavoriteRetailers = ids => {
  const data = { ids };
  const config = { data };

  return newApiInstance.delete('/my/favorite_retailers', config);
};

export const fetchFavoriteRetailers = ({ page, perPage, search } = {}) => {
  const params = {
    page: page || null,
    per_page: perPage || null,
    filter: { search: search || null },
  };
  const config = { params };

  return newApiInstance.get('/my/favorite_retailers/coupons_availability', config);
};
