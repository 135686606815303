import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CameraIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44873 4.9149C8.26388 4.9149 8.12013 5.03983 8.0857 5.19208L7.75546 6.65239C7.66784 7.03982 7.3236 7.3149 6.92639 7.3149H4.07751C3.184 7.3149 2.48516 8.02282 2.48516 8.8649V19.6649C2.48516 20.507 3.184 21.2149 4.07751 21.2149H19.9528C20.8463 21.2149 21.5452 20.507 21.5452 19.6649V8.8649C21.5452 8.02282 20.8463 7.3149 19.9528 7.3149H17.1029C16.7057 7.3149 16.3614 7.03981 16.2738 6.65239L15.9436 5.19208C15.9091 5.03983 15.7654 4.9149 15.5805 4.9149H8.44873ZM6.42757 4.8171C6.64146 3.87126 7.48885 3.2149 8.44873 3.2149H15.5805C16.5404 3.2149 17.3878 3.87126 17.6017 4.8171L17.7821 5.6149H19.9528C21.757 5.6149 23.2452 7.05602 23.2452 8.8649V19.6649C23.2452 21.4738 21.757 22.9149 19.9528 22.9149H4.07751C2.27327 22.9149 0.785156 21.4738 0.785156 19.6649V8.8649C0.785156 7.05602 2.27327 5.6149 4.07751 5.6149H6.24715L6.42757 4.8171Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78516 14.265C6.78516 11.4759 9.04605 9.21497 11.8352 9.21497C14.6243 9.21497 16.8852 11.4759 16.8852 14.265C16.8852 17.0541 14.6243 19.315 11.8352 19.315C9.04605 19.315 6.78516 17.0541 6.78516 14.265ZM11.8352 10.915C9.98494 10.915 8.48516 12.4147 8.48516 14.265C8.48516 16.1152 9.98494 17.615 11.8352 17.615C13.6854 17.615 15.1852 16.1152 15.1852 14.265C15.1852 12.4147 13.6854 10.915 11.8352 10.915Z"
        fill="currentColor"
      />
      <path
        d="M5.23516 11.2649C5.89792 11.2649 6.43516 10.7277 6.43516 10.0649C6.43516 9.40217 5.89792 8.86493 5.23516 8.86493C4.5724 8.86493 4.03516 9.40217 4.03516 10.0649C4.03516 10.7277 4.5724 11.2649 5.23516 11.2649Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CameraIcon;
