import { TIconSize } from './BaseIcon.types';

export const getIconDimension = (size: TIconSize): number => {
  switch (size) {
    case 'extraLarge':
      return 36;
    case 'large':
      return 24;
    case 'medium':
      return 20;
    case 'small':
      return 16;
    case 'extraSmall':
      return 12;
    default:
      return getIconDimension('large');
  }
};
