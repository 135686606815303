import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const RightArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.301 1.04294L12.0966 2.24275L21.9391 12.1224L12.0379 22.0424L13.2411 23.2433L24.3398 12.1236L13.301 1.04294Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1387 12.9928L2.13867 12.9928L2.13867 11.2928L23.1387 11.2928L23.1387 12.9928Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RightArrowIcon;
