import { newApiInstance, javaApiInstance, oldApiInstance } from 'functions/request';
import { DEFAULT_PER_PAGE } from 'consts/queries';
import { ON_SALE, BACK_IN_STOCK, LOW_IN_STOCK, FAVORITES, FULFILLED } from 'consts/staticFilterKeys';

export const deleteTag = id => oldApiInstance.delete(`tags/${id}`);

export const saveTagByUrl = url => newApiInstance.post(`/tags/create_from_url`, { url });

export const fetchMyItemsRequest = ({
  filters,
  page,
  per_page,
  sort_by,
  sort_order,
  include_global = false,
  include_user = true,
}) => {
  const config = {
    params: {
      filter: {
        lists: filters?.lists?.length ? filters.lists : null,
        search: filters?.search ? filters.search : null,
        retailers: filters?.retailers?.length ? filters.retailers : null,
        favorites: filters?.staticFilter ? filters.staticFilter[FAVORITES] || null : null,
        fulfilled: filters?.staticFilter ? filters.staticFilter[FULFILLED] || null : null,
        on_sale: filters?.staticFilter ? filters.staticFilter[ON_SALE] || null : null,
        back_in_stock: filters?.staticFilter ? filters.staticFilter[BACK_IN_STOCK] || null : null,
        low_in_stock: filters?.staticFilter ? filters.staticFilter[LOW_IN_STOCK] || null : null,
      },
      page: page || 1,
      per_page: per_page || DEFAULT_PER_PAGE,
      sort_by: sort_by || null,
      sort_order: sort_order || null,
      include_global,
      include_user,
    },
  };

  return filters?.search ? javaApiInstance.get('/api/my/v1/items', config) : newApiInstance.get('/my/items', config);
};

export const fetchMyItemsPreviewRequest = ({ groups, per_group }) =>
  newApiInstance.get('/my/items/preview', {
    params: {
      groups: groups?.length ? groups : null,
      per_group: per_group || null,
    },
  });

export const updateTag = ({ id, data }) => newApiInstance.put(`my/items/${id}`, data);
