import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const RainIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1716 1.68126C8.00599 1.68126 5.40318 4.31958 5.40318 7.61983C5.40318 7.644 5.40332 7.66812 5.4036 7.69221L5.41178 8.3991L4.71821 8.53596C3.11772 8.85179 1.91094 10.2644 1.91094 11.957C1.91094 13.8829 3.47218 15.4442 5.39808 15.4442H18.6013C20.5272 15.4442 22.0884 13.8829 22.0884 11.957C22.0884 10.0311 20.5272 8.46989 18.6013 8.46989H16.94V7.61988C16.94 4.31963 14.3372 1.68126 11.1716 1.68126ZM3.72398 7.04623C4.01031 3.11276 7.21713 -0.0187378 11.1716 -0.0187378C15.033 -0.0187378 18.1815 2.9671 18.5941 6.76989H18.6013C21.4661 6.76989 23.7884 9.09225 23.7884 11.957C23.7884 14.8218 21.4661 17.1442 18.6013 17.1442H5.39808C2.5333 17.1442 0.210938 14.8218 0.210938 11.957C0.210938 9.67746 1.6807 7.74271 3.72398 7.04623Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25785 11.4893L4.2168 11.4752L4.76764 9.86694L4.80869 9.88101C5.75297 10.2044 6.77867 10.2003 7.72029 9.8692L8.28415 11.473C6.98203 11.9308 5.56364 11.9365 4.25785 11.4893Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62699 11.4893L9.58594 11.4752L10.1368 9.86694L10.1778 9.88101C11.1221 10.2044 12.1478 10.2003 13.0894 9.8692L13.6533 11.473C12.3512 11.9308 10.9328 11.9365 9.62699 11.4893Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25785 11.4893L4.2168 11.4752L4.76764 9.86694L4.80869 9.88101C5.75297 10.2044 6.77867 10.2003 7.72029 9.8692L8.28415 11.473C6.98203 11.9308 5.56364 11.9365 4.25785 11.4893Z"
        fill="currentColor"
      />
      <path d="M2 22L3.6 18H5.43096L3.83096 22H2Z" fill="currentColor" />
      <path d="M16 22L17.6 18H19.431L17.831 22H16Z" fill="currentColor" />
      <path d="M9 22L10.6 18H12.431L10.831 22H9Z" fill="currentColor" />
    </BaseIcon>
  );
};

export default RainIcon;
