import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGoogleTagManager = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
        dataLayerName: 'gtm',
      });
    }
  }, []);
};

export default useGoogleTagManager;
