import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TimeIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'neutral6',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <circle cx="12" cy="12" r="11" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2263 13.5056C9.91907 11.0991 9.92469 8.66289 10.243 6.25781L12.0446 6.49626C11.7636 8.61955 11.7431 10.7687 11.9833 12.8961L14.2784 13.9161L17.0179 14.9914L16.3539 16.6831L13.577 15.5931L10.3073 14.1399L10.2263 13.5056Z"
        fill={getColor(secondaryColor)}
      />
    </BaseIcon>
  );
};

export default TimeIconFilled;
