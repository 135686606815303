import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MuteNotificationsIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4119 10.2502V11.4876C6.4119 13.5196 5.62397 15.4724 4.21379 16.9354L3.88945 17.2719V18.4926H21.3895V17.2719L21.0651 16.9354C19.6549 15.4724 18.867 13.5196 18.867 11.4876V11.0599C18.867 8.39708 17.8148 6.82181 16.5634 5.88708C15.2706 4.92138 13.697 4.59264 12.6639 4.59264C10.0917 4.59264 8.57072 5.56614 7.67066 6.70794C6.74179 7.88628 6.41961 9.31698 6.4119 10.2502ZM6.33559 5.65552C7.57186 4.08722 9.59375 2.89264 12.6639 2.89264C13.982 2.89264 15.9348 3.29563 17.5808 4.5251C19.2682 5.78555 20.567 7.86891 20.567 11.0599V11.4876C20.567 13.0796 21.1843 14.6095 22.2891 15.7557L22.7114 16.1938C22.9539 16.4454 23.0895 16.7813 23.0895 17.1307V20.1926H2.18945V17.1307C2.18945 16.7813 2.32496 16.4454 2.56747 16.1938L2.98981 15.7557C4.0946 14.6095 4.7119 13.0796 4.7119 11.4876V10.2405C4.7212 9.00554 5.12916 7.18597 6.33559 5.65552Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0233 20.1924C10.3814 21.2952 11.4173 22.0924 12.6395 22.0924C13.8616 22.0924 14.8975 21.2952 15.2556 20.1924H10.0233ZM16.2395 19.3424V18.4924H9.03945V19.3424H8.18945C8.18945 21.8001 10.1818 23.7924 12.6395 23.7924C15.0971 23.7924 17.0895 21.8001 17.0895 19.3424H16.2395Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 0.142639V3.74264H11.7891V0.142639H13.4891Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6321 3.89195L1.69018 21.7356L0.646484 20.3937L23.5884 2.55005L24.6321 3.89195Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MuteNotificationsIcon;
