import { useCallback, useState } from 'react';
import { Typography } from '@karma/components';
import { useTranslation } from 'react-i18next';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import RetailersCatalogue from 'components/organisms/RetailersCatalogue/RetailersCatalogue';
import { StyledSearch } from './ModalBodyManageStores.style';

const ModalBodyManageStores = () => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const [query, setQuery] = useState('');

  const autoFocusFn = useCallback(element => {
    setTimeout(() => {
      element?.focus();
    }, 0);
  }, []);

  const handleChange = useCallback(newValue => {
    if (newValue.length > 2) {
      setQuery(newValue.trim());
    } else if (!newValue) {
      setQuery('');
    }
  }, []);

  const handleClear = useCallback(() => {
    setQuery('');
  }, []);

  return (
    <>
      <Typography variant="h3" bMargin={16}>
        {t('app:manageFavoriteStores')}
      </Typography>
      <StyledSearch
        inputRef={autoFocusFn}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.INPUT}
        defaultValue={query}
        placeholder={t('app:search')}
        onChange={handleChange}
        onClear={handleClear}
        fullWidth
        inputProps={{
          tabIndex: -1,
        }}
      />
      <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.POPUP_AUTOCOMPLETE_SUGGESTIONS}>
        <RetailersCatalogue query={query} />
      </Alooma.SourceContext.Provider>
    </>
  );
};

export default ModalBodyManageStores;
