import styled from 'styled-components';
import { IDividerProps } from './Divider.types';

export const StyledDivider = styled.hr<IDividerProps>`
  all: unset;
  display: block;
  min-height: 1px;
  min-width: 1px;
  background: ${({ color }) => color};
  height: ${({ fullWidth, orientation, height }) =>
    fullWidth && orientation === 'vertical' ? 'auto' : height};
  width: ${({ fullWidth, orientation, width }) =>
    fullWidth && orientation === 'horizontal' ? '100%' : width};
  align-self: ${({ fullWidth, orientation }) =>
    fullWidth && orientation === 'vertical' ? 'stretch' : 'center'};
  margin-bottom: ${({ bMargin }) => bMargin || 0}px;
  margin-left: ${({ lMargin }) => lMargin || 0}px;
  margin-right: ${({ rMargin }) => rMargin || 0}px;
  margin-top: ${({ tMargin }) => tMargin || 0}px;
`;
