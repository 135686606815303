import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SortIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2402 7.00679L18.0404 8.21113L12.6522 2.84324L7.2408 8.24447L6.03986 7.04125L12.6511 0.442474L19.2402 7.00679Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03906 17.2789L7.23887 16.0746L12.6271 21.4425L18.0385 16.0412L19.2394 17.2445L12.6282 23.8432L6.03906 17.2789Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 1.64285L13.4891 22.6429L11.7891 22.6429L11.7891 1.64285L13.4891 1.64285Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SortIcon;
