import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const DiamondIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0014 22.8642C11.7408 22.8642 11.4945 22.7446 11.3334 22.5398L0.216008 8.40826C0.0147559 8.15245 -0.0227369 7.80416 0.119458 7.51137C0.261653 7.21858 0.558564 7.0327 0.884057 7.0327L23.1187 7.0327C23.4442 7.0327 23.7411 7.21858 23.8833 7.51137C24.0255 7.80416 23.988 8.15244 23.7868 8.40826L12.6694 22.5398C12.5083 22.7446 12.262 22.8642 12.0014 22.8642ZM21.3685 8.7327L2.63427 8.73271L12.0014 20.6395L21.3685 8.7327Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58547 1.50343C3.73732 1.24047 4.01791 1.07849 4.32156 1.07849H19.6795C19.9832 1.07849 20.2638 1.24047 20.4156 1.50343L23.854 7.45765C24.0058 7.72064 24.0059 8.04467 23.854 8.30768C23.7022 8.57069 23.4216 8.73271 23.1179 8.73271H0.883203C0.579514 8.73271 0.298896 8.57069 0.147063 8.30768C-0.00476985 8.04467 -0.00474893 7.72064 0.147118 7.45765L3.58547 1.50343ZM4.81226 2.77849L2.35559 7.03271H21.6455L19.1888 2.77849H4.81226Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14916 1.80566L9.69652 2.50971L7.24022 7.90817L12.8341 21.7531L11.2579 22.3899L5.39062 7.86838L8.14916 1.80566Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7532 1.80566L14.2058 2.50971L16.6621 7.90817L11.0682 21.7531L12.6444 22.3899L18.5117 7.86838L15.7532 1.80566Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DiamondIcon;
