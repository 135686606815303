import { useContext } from 'react';
import { Button, Stack, StoreLogo, Typography } from '@karma/components';
import { CashbackIcon } from '@karma/components/icons';
import { useTranslation } from 'react-i18next';
import { getRedirectLinkToRetailer } from '@karma/utils/functions/redirectLink';
import { useSelector } from 'react-redux';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import ButtonFavoriteStore from 'components/atoms/ButtonFavoriteStore/ButtonFavoriteStore';
import { getUserId } from 'store/user/selectors';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import LeaveIcon from 'components/atoms/LeaveIcon/LeaveIcon';
import GoToStoreButton from 'components/atoms/GoToStoreButton/GoToStoreButton';
import StoreInfoBadgeDivider from 'components/atoms/StoreInfoBadgeDivider/StoreInfoBadgeDivider';
import StoreInfoBadge from 'components/atoms/StoreInfoBadge/StoreInfoBadge';
import StoreInfoShippingInfo from '../StoreInfoShippingInfo/StoreInfoShippingInfo';

const StoreInfoHeader = ({ retailer, shippingInfo }) => {
  const { name, logo_image_uri: logoSrc, self_id: id, stake, rule_id: ruleId } = retailer;
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const userId = useSelector(getUserId);
  const { t } = useTranslation();
  const source = useContext(ReferralSourceContext);
  const isQuillRetailer = retailer.id === 7550;
  const isLenovoRetailer = ruleId === 12073;

  const url = getRedirectLinkToRetailer({
    retailerId: id,
    ruleName: name,
    userId,
    cashback: !!(userId && stake),
    source,
  });

  const handleLogoClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Stack justifyContent="space-between" alignItems="center" spacing={20} fullWidth>
      <Stack direction="column" alignItems="flex-start" spacing={12}>
        <Stack direction="row" spacing={8}>
          <StoreLogo
            style={{ cursor: 'pointer' }}
            size="large"
            src={logoSrc}
            alt={name}
            onClick={handleLogoClick}
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.RETAILER_LOGO}
            data-alooma-retailer-id={retailer.self_id}
          />
          <Stack direction="column" alignItems="flex-start">
            <Button
              type="text"
              size="medium"
              as="a"
              href={url}
              target="_blank"
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.RETAILER_NAME}
              data-alooma-retailer-id={retailer.self_id}
            >
              {name}
            </Button>
            {isQuillRetailer && <Typography variant="paragraphSmall">{t('app:quillDisclosure')}</Typography>}
          </Stack>
        </Stack>
        <Stack direction="row">
          <StoreInfoShippingInfo shippingInfo={shippingInfo} isLenovoRetailer={isLenovoRetailer} />

          {retailer.stake ? (
            <>
              <StoreInfoBadgeDivider />
              <StoreInfoBadge Icon={CashbackIcon} text={t('app:earnKarmaCashShort', { percent: retailer.stake })} />
            </>
          ) : null}
        </Stack>
      </Stack>

      <Stack direction="column" spacing={16} alignItems="flex-start">
        <GoToStoreButton
          buttonProps={{
            size: 'small',
            Icon: LeaveIcon,
          }}
          retailer={retailer}
        />

        <ButtonFavoriteStore retailerId={retailer.self_id} />
      </Stack>
    </Stack>
  );
};

export default StoreInfoHeader;
