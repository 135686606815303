import getTheCurrentYear from 'functions/getTheCurrentYear';

export const MINUTE_IN_MILLISECONDS = 60000;
export const HOUR_IN_MILLISECONDS = 3600000;
export const DAY_IN_MILLISECONDS = 86400000;
export const WEEK_IN_MILLISECONDS = 604800000;
export const CURRENT_YEAR = getTheCurrentYear();

export const FORMATS = {
  date: 'DD.MM.YYYY',
  day: 'D',
  month: 'MMM',
  fullMonth: 'MMMM',
  time: 'HH:mm:ss',
  timeHoursAndMinutes: 'HH:mm',
  dateMonthName: 'DD MMM, YYYY',
  monthDate: 'MMM DD, YYYY',
};
