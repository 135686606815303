import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CreditCardIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2891 15.0179C24.2891 18.2487 21.6699 20.8679 18.4391 20.8679L0.989063 20.8679L0.989064 8.74286C0.989065 5.51199 3.6082 2.89286 6.83907 2.89286L24.2891 2.89286L24.2891 15.0179ZM18.4391 19.1679C20.731 19.1679 22.5891 17.3098 22.5891 15.0179L22.5891 4.59286L6.83907 4.59286C4.54708 4.59286 2.68907 6.45088 2.68907 8.74286L2.68906 19.1679L18.4391 19.1679Z"
        fill="currentColor"
      />
      <path d="M1.83789 8.39285H23.4379V10.7929H1.83789V8.39285Z" fill="currentColor" />
    </BaseIcon>
  );
};

export default CreditCardIcon;
