import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GhostIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91797 5.97501C1.91797 2.74414 4.5371 0.125 7.76797 0.125H16.232C19.4629 0.125 22.082 2.74413 22.082 5.975V21.2782C22.082 22.8184 20.31 23.6841 19.095 22.7375L17.7486 21.6884L16.4531 23.128C15.7553 23.9034 14.5549 23.9489 13.8004 23.2286L12.1792 21.6808L10.2568 23.3514C9.52034 23.9914 8.41342 23.9494 7.72753 23.2555L6.1657 21.6754L4.92977 22.6727C3.71973 23.6492 1.91797 22.7879 1.91797 21.233V5.97501ZM7.76797 1.825C5.47599 1.825 3.61797 3.68302 3.61797 5.97501V21.233C3.61797 21.274 3.62847 21.2981 3.63945 21.3147C3.65249 21.3344 3.67395 21.3544 3.70328 21.3684C3.7326 21.3824 3.76158 21.3866 3.78511 21.3843C3.80493 21.3825 3.83027 21.3755 3.86217 21.3498L5.34437 20.1537C5.88374 19.7184 6.66509 19.7623 7.15231 20.2552L8.9366 22.0605C8.99221 22.1167 9.08196 22.1201 9.14167 22.0682L11.3047 20.1885C11.8308 19.7313 12.6184 19.7497 13.1225 20.231L14.9743 21.999C15.0355 22.0574 15.1328 22.0537 15.1894 21.9908L16.703 20.3089C17.1769 19.7822 17.9773 19.7116 18.5362 20.147L20.1398 21.3965C20.2383 21.4732 20.382 21.403 20.382 21.2782V5.975C20.382 3.68302 18.524 1.825 16.232 1.825H7.76797Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6516 9.37524C16.6516 9.06845 16.3601 8.70484 15.853 8.70484C15.3459 8.70484 15.0544 9.06845 15.0544 9.37524L13.3544 9.37524C13.3544 8.00264 14.5391 7.00484 15.853 7.00484C17.1668 7.00484 18.3516 8.00264 18.3516 9.37524L16.6516 9.37524Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94453 9.37524C8.94453 9.06845 8.65308 8.70484 8.14595 8.70484C7.63883 8.70484 7.34738 9.06845 7.34738 9.37524L5.64738 9.37524C5.64738 8.00264 6.83211 7.00484 8.14595 7.00484C9.4598 7.00484 10.6445 8.00264 10.6445 9.37524L8.94453 9.37524Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GhostIcon;
