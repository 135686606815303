import { isClient } from 'functions/isClient';
import { retry } from 'functions/lazyImport';

const reportWebVitals = async onPerfEntry => {
  try {
    if (onPerfEntry && onPerfEntry instanceof Function && isClient()) {
      const { onINP } = await retry(() => import('web-vitals/attribution'));

      onINP(onPerfEntry, { reportAllChanges: true });
    }
  } catch (e) {
    console.error(e);
  }
};

export default reportWebVitals;
