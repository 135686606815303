import de from './de';
import en from './en';
import es from './es';
import esAR from './es-ar';
import fr from './fr';
import hi from './hi';
import it from './it';
import ptBR from './pt-br';
import ru from './ru';

/**
 * en - english
 * de - german
 * hi - hindi
 * es - spanish
 * es-ar - spanish-latin-america
 * pt-br - portuguese-brazil
 * fr - french
 * it - italian
 * ru - russian
 */

export default {
  de,
  en,
  es,
  esAR,
  fr,
  hi,
  it,
  ptBR,
  ru,
};
