import { Stack, Typography } from '@karma/components';

const GlobalSearchSection = ({ title, subtitle, children, spacing = 24 }) => {
  return (
    <Stack fullWidth direction="column" alignItems="flex-start" spacing={spacing}>
      <Stack fullWidth direction="column" alignItems="flex-start">
        <Typography variant="paragraphMediumLarge">{title}</Typography>
        {subtitle && (
          <Typography variant="paragraphSmall" color="neutral2">
            {subtitle}
          </Typography>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default GlobalSearchSection;
