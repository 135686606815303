import { Button, Stack, Typography } from '@karma/components';
import { BookmarkIcon } from '@karma/components/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATHS } from 'consts/paths';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';

const ModalBodyItemSaved = ({ closeModal }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <Stack direction="column" spacing={20} alignItems="center">
      <BookmarkIcon width={60} height={60} />
      <Stack direction="column" spacing={8} alignItems="center">
        <Typography variant="h3" align="center">
          {t('app:itemSaved')}
        </Typography>
        <Typography variant="paragraphLarge" align="center">
          {t('app:wellKeepAnEyeOnIt')}
        </Typography>
      </Stack>
      <Button
        as={Link}
        to={PATHS.ITEMS}
        size="medium"
        onClick={closeModal}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.SEE_YOUR_SAVED_ITEMS}
      >
        {t('app:seeYourSavedItems')}
      </Button>
    </Stack>
  );
};

export default ModalBodyItemSaved;
