import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GeneralShareIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19961 2.50914C4.42647 2.50914 2.98906 3.94655 2.98906 5.71969V21.7931H21.1391C21.7742 21.7931 22.2891 21.2782 22.2891 20.6431V18.5498H23.9891V20.6431C23.9891 22.2171 22.7131 23.4931 21.1391 23.4931H1.28906V5.71969C1.28906 3.00767 3.48759 0.809143 6.19961 0.809143V2.50914Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98906 21.7931V6.47098H1.28906V23.4931H21.1391C22.7131 23.4931 23.9891 22.2171 23.9891 20.6431V18.5498H22.2891V20.6431C22.2891 21.2782 21.7742 21.7931 21.1391 21.7931H2.98906Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5129 7.08081L15.9952 1.5631L17.1973 0.361023L23.917 7.08081L17.2356 13.7623L16.0335 12.5602L21.5129 7.08081Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46094 10.0335C8.46094 7.90721 10.1846 6.1835 12.3109 6.1835H22.0141V7.8835H12.3109C11.1235 7.8835 10.1609 8.84609 10.1609 10.0335V16.9241H8.46094V10.0335Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GeneralShareIcon;
