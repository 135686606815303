import { Stack, Typography } from '@karma/components';
import styled from 'styled-components';

export const Container = styled(Stack)`
  min-height: 356px;
  min-width: 523px;
`;

export const SuccessSign = styled(Typography)`
  max-width: 400px;
  text-align: center;
`;
