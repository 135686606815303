import floorTo from 'functions/floorTo';
import * as ALOOMA from 'consts/alooma';
import CConsole from 'functions/CConsole';

function getSelector(node) {
  if (!node || node.nodeType !== 1) return null;

  const attributes = node.getAttributeNames() || [];

  if (attributes.includes(ALOOMA.ATTRS.ELEMENT)) {
    return attributes.reduce((result, attrName) => {
      if (attrName.startsWith('data-alooma-')) {
        return `${result}[${attrName}="${node.getAttribute(attrName)}"]`;
      }

      return result;
    }, '');
  }

  return node.parentNode ? getSelector(node.parentNode) : null;
}

function getSelectorFromInpEvent(metric) {
  try {
    // Logic taken from https://github.com/GoogleChrome/web-vitals/blob/main/src/attribution/onINP.ts#L30-L38
    const longestEntry = metric.entries.sort((a, b) => {
      // Sort by: 1) duration (DESC), then 2) processing time (DESC)
      return b.duration - a.duration || b.processingEnd - b.processingStart - (a.processingEnd - a.processingStart);
    })[0];

    return getSelector(longestEntry?.target) || metric.attribution?.eventTarget;
  } catch (e) {
    CConsole.error(e);
  }

  return null;
}

export function getWebVitalsElement(metric) {
  switch (metric.name) {
    case 'CLS':
      return metric.attribution?.largestShiftTarget;

    case 'LCP':
      return metric.attribution?.element;

    case 'FID':
      return metric.attribution?.eventTarget;

    case 'INP':
      return getSelectorFromInpEvent(metric);

    default:
      return null;
  }
}

export function getWebVitalsDetails(metric) {
  if (metric.name === 'INP') {
    /** The input delay, which starts when the user initiates an interaction with the page, and ends when the event callbacks for the interaction begin to run. */
    const inputDelay = metric.attribution?.eventEntry?.processingStart - metric.attribution?.eventEntry?.startTime;
    /** The processing time, which consists of the time it takes for event callbacks to run to completion. */
    const processingTime =
      metric.attribution?.eventEntry?.processingEnd - metric.attribution?.eventEntry?.processingStart;
    /** The presentation delay, which is the time it takes for the browser to present the next frame which contains the visual result of the interaction. */
    const presentationDelay = metric.attribution?.eventEntry?.duration - inputDelay - processingTime;

    return {
      wvInputDelay: floorTo(inputDelay),
      wvProcessingTime: floorTo(processingTime),
      wvPresentationDelay: floorTo(presentationDelay),
      wvLoadState: metric.attribution?.loadState,
      wvElement: getWebVitalsElement(metric),
    };
  }

  return null;
}

// export function sendWebVitalsReportToGA4({ name, delta, id }) {
//   if (!window.ga) return;
//
//   window.ga('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     // The `id` value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     eventLabel: id,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
//     // Use a non-interaction event to avoid affecting bounce rate.
//     nonInteraction: true,
//     // Use `sendBeacon()` if the browser supports it.
//     transport: 'beacon',
//
//     // OPTIONAL: any additional attribution params here.
//     // See: https://web.dev/debug-performance-in-the-field/
//     // dimension1: '...',
//     // dimension2: '...',
//     // ...
//   });
// }
//
// export function sendWebVitalsReportToGA3({ name, delta, id }) {
//   if (!window.gtag) return;
//
//   window.gtag('event', name, {
//     event_category: 'Web Vitals',
//     // The `id` value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     value: Math.round(name === 'CLS' ? delta * 1000 : delta),
//     // Use a non-interaction event to avoid affecting bounce rate.
//     non_interaction: true,
//
//     // OPTIONAL: any additional attribution params here.
//     // See: https://web.dev/debug-performance-in-the-field/
//     // dimension1: '...',
//     // dimension2: '...',
//     // ...
//   });
// }
