import { createRoot, hydrateRoot } from 'react-dom/client';
import { match } from 'path-to-regexp';
import { SSR_PATHS } from 'consts/paths';
import alooma from 'services/alooma';
import CConsole from 'functions/CConsole';
import ClientApp from './ClientApp';
import reportWebVitals from './reportWebVitals';
import { unregister } from './serviceWorkerRegistration';

const matchFn = match(SSR_PATHS, { decode: decodeURIComponent });
const container = document.getElementById('root');

if (module.hot || !matchFn(window.location.pathname)) {
  const root = createRoot(container);

  root.render(<ClientApp />);
} else {
  hydrateRoot(container, <ClientApp />, {
    onRecoverableError: (...args) => {
      CConsole.log(...args);
    },
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(metric => {
  alooma.sendWebVitalsReport(metric);
});
