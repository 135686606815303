import { all, call, put, select } from 'redux-saga/effects';
import { DEFAULT_RESPONSE_COUNT, JAVA_FILTERS } from 'consts/wallet';
import { handleServerError } from 'store/alerts/actions';

function* fetchMore({ entity, filterSelector, getMoreRequest, isJavaRequest, pageSelector, saveMoreAction }) {
  try {
    const [filter, currentPage] = yield all([select(filterSelector), select(pageSelector)]);
    const page = currentPage + 1;
    const parameters = {
      filter: isJavaRequest && filter === JAVA_FILTERS.all ? undefined : filter,
      page,
    };
    const { data } = yield call(getMoreRequest, parameters);

    yield put(
      saveMoreAction({
        [entity]: isJavaRequest ? data.list : data,
        hasMore: isJavaRequest ? !data['last-page'] : data.length === DEFAULT_RESPONSE_COUNT,
        page,
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchMore;
