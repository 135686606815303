import styled, { css } from 'styled-components';
import omitForwardedProps from '../utils/omitForwardedProps';
import { StyledButtonProps, IconContainerProps, ChildrenWrapperProps } from './Button.types';

const omittedButtonProps: Array<keyof StyledButtonProps> = [
  'cConf',
  'sConf',
  'active',
  'loading',
  'fullWidth',
];

const disabledState = css<StyledButtonProps>`
  color: ${({ cConf }) => cConf.disabled.text}!important;
  background: ${({ cConf }) => cConf.disabled.background};
  border-color: ${({ cConf }) => cConf.disabled.background};
`;

const normalState = css<StyledButtonProps>`
  color: ${({ cConf }) => cConf.normal.text}!important;
  background: ${({ cConf }) => cConf.normal.background};
  border-color: ${({ cConf }) => cConf.normal.background};
`;

const activeState = css<StyledButtonProps>`
  color: ${({ cConf }) => cConf.active.text}!important;
  background: ${({ cConf }) => cConf.active.background};
  border-color: ${({ cConf }) => cConf.active.background};
`;

const commonStyle = css<StyledButtonProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: 0.2s;
  max-width: 100%;

  font-family: ${({ sConf }) => sConf.fontFamily || 'Roboto'};
  font-weight: ${({ sConf }) => sConf.fontWeight || '500'};
  font-size: ${({ sConf }) => sConf.fontSize};
  letter-spacing: ${({ sConf }) => sConf.letterSpacing || 'normal'};

  min-width: ${({ sConf, fullWidth }) => (fullWidth ? '100%' : sConf.width)};
  height: ${({ sConf }) => sConf.height};
  pointer-events: ${({ loading, disabled }) => (loading || disabled ? 'none' : 'auto')};

  &:hover {
    color: ${({ cConf }) => cConf.hover.text}!important;
    background: ${({ cConf }) => cConf.hover.background};
    border-color: ${({ cConf }) => cConf.hover.background};
  }

  &:active {
    color: ${({ cConf }) => cConf.pressed.text}!important;
    background: ${({ cConf }) => cConf.pressed.background};
    border-color: ${({ cConf }) => cConf.pressed.background};
  }

  ${({ disabled, active }) => {
    if (disabled) return disabledState;
    if (active) return activeState;
    return normalState;
  }}
`;

export const ChildrenWrapper = styled.div.withConfig(
  omitForwardedProps<ChildrenWrapperProps>(['fixedWidth'])
)<ChildrenWrapperProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ fixedWidth }) => (fixedWidth ? '85%' : '100%')};
`;

const buttonWithOutlineStyle = css<StyledButtonProps>`
  background: transparent !important;
  border-style: solid;
  border-radius: 100px;
  padding: 0 20px;
  border-width: ${({ sConf }) => sConf.borderWidth || '2px'};
`;

const fillButtonStyle = css<StyledButtonProps>`
  border: none !important;
  border-radius: 100px;
`;

export const PrimaryButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  ${fillButtonStyle};
  padding: 0 20px;
`;

export const OutlineButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  ${buttonWithOutlineStyle};
`;

export const SocialButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  ${buttonWithOutlineStyle};
`;

export const IconButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  ${fillButtonStyle};
`;

export const TextButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  background: transparent !important;
`;

export const UnderlinedTextButton = styled.button.withConfig(
  omitForwardedProps<StyledButtonProps>(omittedButtonProps)
)<StyledButtonProps>`
  ${commonStyle};
  background: transparent !important;
  text-decoration: underline !important;
`;

export const ButtonIconWrap = styled.div.withConfig(
  omitForwardedProps<IconContainerProps>(['leftPosition', 'sConf'])
)<IconContainerProps>`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  left: ${({ leftPosition, sConf }) => (leftPosition ? sConf.iconPadding : null)};
  right: ${({ leftPosition, sConf }) => (!leftPosition ? sConf.iconPadding : null)};
`;

export const TextButtonIconWrap = styled.div.withConfig(
  omitForwardedProps<IconContainerProps>(['leftPosition', 'sConf'])
)<IconContainerProps>`
  display: flex;
  margin-right: ${({ leftPosition, sConf }) => (leftPosition ? sConf.iconPadding : 0)};
  margin-left: ${({ leftPosition, sConf }) => (!leftPosition ? sConf.iconPadding : 0)};
  order: ${({ leftPosition }) => (leftPosition ? 0 : 1)};
`;
