import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DEMO_PATHS } from 'consts/paths';
import * as userActions from 'store/user/actions';
import * as userSelectors from 'store/user/selectors';
import useAction from 'hooks/useAction';
import { setupStoreFeatures } from 'store/features/actions';

const useGetUserData = () => {
  const { pathname } = useLocation();
  const fetchUser = useAction(userActions.fetchUser);
  const initDemoFlow = useAction(userActions.initDemoFlow);
  const setupStoreFeaturesFunc = useAction(setupStoreFeatures);
  const userLoaded = useSelector(userSelectors.getLoaded);
  const userId = useSelector(userSelectors.getUserId);

  const isDemoFlow = DEMO_PATHS.includes(pathname);

  useEffect(() => {
    if (!userLoaded && !isDemoFlow) {
      fetchUser();
    }

    if (!userLoaded && isDemoFlow) {
      initDemoFlow();
    }
  }, [isDemoFlow, userLoaded]);

  useEffect(() => {
    if (userLoaded) {
      setupStoreFeaturesFunc();
    }
  }, [userLoaded, userId]);
};

export default useGetUserData;
