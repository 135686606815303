import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SearchIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4383 2.19265C6.60579 2.19265 2.68828 6.11015 2.68828 10.9426C2.68828 15.7751 6.60579 19.6926 11.4383 19.6926C16.2708 19.6926 20.1883 15.7751 20.1883 10.9426C20.1883 6.11015 16.2708 2.19265 11.4383 2.19265ZM0.988281 10.9426C0.988281 5.17127 5.66691 0.492645 11.4383 0.492645C17.2097 0.492645 21.8883 5.17127 21.8883 10.9426C21.8883 16.714 17.2097 21.3926 11.4383 21.3926C5.66691 21.3926 0.988281 16.714 0.988281 10.9426Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1249 18.5178L19.2673 17.5717L18.0078 18.7135L18.9 19.6977L22.8435 23.5506L24.0316 22.3346L20.1249 18.5178Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SearchIcon;
