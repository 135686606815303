import { call, put } from 'redux-saga/effects';
import { DEFAULT_PER_GROUP_SAVED_FROM_STORE } from 'consts/queries';
import { handleServerError } from 'store/alerts/actions';
import * as myItemsActions from 'store/myItems/store/actions';
import * as myItemsApi from 'store/myItems/store/api';

function* fetchSavedItemsFromStore({
  payload: { retailerId, page = 1, perGroup = DEFAULT_PER_GROUP_SAVED_FROM_STORE },
}) {
  try {
    const response = yield call(myItemsApi.fetchMyItemsRequest, {
      filters: {
        retailers: [retailerId],
      },
      page,
      per_page: perGroup,
    });

    const totalItems = perGroup * (page - 1) + response.data.data.length;
    const totalCount = response.data.meta.total_count;

    yield put(
      myItemsActions.receiveSavedItemsFromStore({
        page,
        tags: response.data.data,
        tagsLeft: totalCount > totalItems,
        totalCount,
      }),
    );
  } catch (error) {
    yield put(
      myItemsActions.receiveSavedItemsFromStore({
        page,
        tags: [],
        tagsLeft: true,
        totalCount: 0,
      }),
    );
    yield put(handleServerError(error));
  }
}

export default fetchSavedItemsFromStore;
