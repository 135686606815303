import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import { PATHS } from 'consts/paths';
import * as ALOOMA from 'consts/alooma';

const checkPath = (currentPath, routePath) => {
  return currentPath.match(pathToRegexp(routePath));
};

const getAloomaSource = pathname => {
  if (checkPath(pathname, PATHS.PROFILE)) {
    return ALOOMA.SOURCE_PAGES.PROFILE;
  }

  if (checkPath(pathname, PATHS.PROFILE_WALLET)) {
    return ALOOMA.SOURCE_PAGES.PROFILE_WALLET;
  }

  if (checkPath(pathname, PATHS.PROFILE_ACCOUNT_SETTINGS)) {
    return ALOOMA.SOURCE_PAGES.PROFILE_ACCOUNT_SETTINGS;
  }

  if (checkPath(pathname, PATHS.EXTENSION_DELETED)) {
    return ALOOMA.SOURCE_PAGES.EXTENSION_DELETED;
  }

  if (checkPath(pathname, PATHS.ITEMS)) {
    return ALOOMA.SOURCE_PAGES.ITEMS;
  }

  if (checkPath(pathname, PATHS.GLOBAL_SEARCH)) {
    return ALOOMA.SOURCE_PAGES.GLOBAL_SEARCH_RESULTS;
  }

  if (checkPath(pathname, PATHS.HOME)) {
    return ALOOMA.SOURCE_PAGES.HOME;
  }

  return null;
};

const useAloomaSource = () => {
  const { pathname } = useLocation();

  return useMemo(() => getAloomaSource(pathname), [pathname]);
};

export default useAloomaSource;
