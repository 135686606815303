import { Suspense } from 'react';
import { isClient } from 'functions/isClient';
import { ERROR_MESSAGE_CSR_ONLY } from 'consts/ssr';

export const CsrCheckerWorker = ({ children }) => {
  if (!isClient()) throw Error(ERROR_MESSAGE_CSR_ONLY);

  return children;
};

/**
 * Use this HOC to wrap components that should be rendered only on client side.
 */
const CsrChecker = WrapperComponent => props => {
  return (
    <Suspense fallback={null}>
      <CsrCheckerWorker>
        <WrapperComponent {...props} />
      </CsrCheckerWorker>
    </Suspense>
  );
};

export default CsrChecker;
