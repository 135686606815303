import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const FlashIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 80 80"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.351 0H64.4024L52.2811 26.6667H69.0022L30.043 78.6122L31.857 42.3333H15.957L31.351 0ZM35.3201 5.66667L24.0473 36.6667H37.8141L36.628 60.3878L57.6688 32.3333H43.4808L55.602 5.66667H35.3201Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default FlashIcon;
