import React, { useState } from 'react';
import InputBase from '../InputBase';
import InputLabel from '../InputLabel';
import useId from '../utils/useId';
import FieldSet from '../FieldSet';
import { TextFieldProps, ViewStateProps } from './TextField.types';
import HelperText from '../HelperText';
import FieldWrapper from '../FieldWrapper';
import InputContainer from '../InputContainer';

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(function TextField(
  {
    id: idOverride,
    name,
    value,
    placeholder,
    type = 'text',
    required = false,
    disabled = false,
    fullWidth = false,
    shrink = false,

    error = false,
    success = false,

    label,
    labelProps = {},

    inputRef = null,
    inputComponent = null,
    inputProps = {},

    inputContainerRef = null,

    helperText,
    helperTextProps = {},

    beforeInput,
    afterInput,
    afterInputContainer,

    onFocus = _e => {},
    onBlur = _e => {},
    onChange = _e => {},

    multiline = false,

    ...restContainerProps
  },
  ref
) {
  const id = useId(idOverride);
  const [focused, setFocused] = useState(false);
  const [autofilled, setAutofilled] = useState(false);
  const shrinkLabel = autofilled || focused || shrink || !!beforeInput || !!value;

  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
    onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false);
    onBlur(e);
  };

  const handleAutoFill = e => {
    if (['onAutoFillStart', 'onAutoFillCancel'].includes(e.animationName)) {
      setAutofilled(e.animationName === 'onAutoFillStart');
    }
  };

  const viewState: ViewStateProps = {
    disabled,
    success,
    error,
    focused,
    shrink: shrinkLabel,
  };

  return (
    <FieldWrapper {...restContainerProps} fullWidth={fullWidth} ref={ref}>
      {label != null && label !== '' && (
        <InputLabel id={inputLabelId} htmlFor={id} {...viewState} {...labelProps}>
          {label}
        </InputLabel>
      )}

      <InputContainer disabled={disabled} ref={inputContainerRef}>
        {beforeInput}
        <InputBase
          {...inputProps}
          id={id}
          name={name}
          value={value}
          type={type}
          ref={inputRef}
          aria-invalid={!!error}
          aria-describedby={helperTextId}
          placeholder={placeholder}
          hasPrefix={!!beforeInput}
          hasSuffix={!!afterInput}
          hasLabel={!!label}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={onChange}
          multiline={multiline}
          required={required}
          disabled={disabled}
          shrink={shrinkLabel}
          inputComponent={inputComponent}
          onAnimationStart={handleAutoFill}
        />
        {afterInput}
        <FieldSet label={label} {...viewState} />
      </InputContainer>
      {afterInputContainer}
      <HelperText {...helperTextProps} show={!!helperText} id={helperTextId} {...viewState}>
        {helperText}
      </HelperText>
    </FieldWrapper>
  );
});

export default TextField;
