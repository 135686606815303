import styled, { css } from 'styled-components';
import { getColor } from '../colors';
import { ICardMakerContainerProps, TRoundingType } from './CardMaker.types';

const getBorderRadius = (rounding: TRoundingType) => {
  switch (rounding) {
    case 'all':
      return '2px';
    case 'right':
      return '0px 2px 2px 0px';
    case 'left':
      return '2px 0px 0px 2px';
    default:
      return getBorderRadius('all');
  }
};

const getBorder = (rounding: TRoundingType) => {
  switch (rounding) {
    case 'all':
      return css`
        border: 1px solid ${getColor('neutral3')};
      `;
    case 'right':
      return css`
        border: 1px solid ${getColor('neutral3')};
        border-left: none;
      `;
    case 'left':
      return css`
        border: 1px solid ${getColor('neutral3')};
        border-right: none;
      `;
    default:
      return getBorder('all');
  }
};

export const CardMakerContainer = styled.div<ICardMakerContainerProps>`
  padding: 2px 4px;
  width: fit-content;
  color: ${getColor('primary1')};
  background-color: ${({ color }) => getColor(color)};
  border-radius: ${({ rounding }) => getBorderRadius(rounding)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ sConf }) => sConf.gap};
  ${({ border, rounding }) => border && getBorder(rounding)};
`;
