import symbols from 'consts/specialSymbols';

const cyrillicPattern = /[а-яА-ЯЁё]/;

const transliterate = retailer => {
  function convertSymbol(word) {
    return word
      .split('')
      .map(char => {
        return symbols[char] || char;
      })
      .join('');
  }

  return cyrillicPattern.test(retailer.name) ? { ...retailer, name: convertSymbol(retailer.name) } : retailer;
};

export default transliterate;
