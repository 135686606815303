import { newApiInstance } from 'functions/request';

export const tagsBulkAddToLists = data => {
  return newApiInstance.put('/tags/bulk/add_to_lists', data);
};

export const tagsBulkDelete = data => {
  return newApiInstance.delete('/tags/bulk/delete', { data });
};

export const tagsBulkFulfill = data => {
  return newApiInstance.put('/tags/bulk/fulfill', data);
};

export const tagsBulkMute = data => {
  return newApiInstance.put('/tags/bulk/mute', data);
};
