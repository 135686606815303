import PropTypes from 'prop-types';
import { CDN_URL } from '@karma/utils/constants/env';
import { ThemeProvider } from 'styled-components';
import { COLOR_THEMES } from '@karma/components/themes';
import { SignUpContent, SignUpImage } from './SignUpWrapperNew.style';

const SignUpWrapperNew = ({ children, showImage = true }) => {
  return (
    <ThemeProvider theme={{ color: COLOR_THEMES.DARK }}>
      <SignUpContent $showImage={showImage}>
        {children}
        {showImage && <SignUpImage src={`${CDN_URL}/images/karma/images/demoPage/authenticate-bg.jpg`} />}
      </SignUpContent>
    </ThemeProvider>
  );
};

SignUpWrapperNew.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showImage: PropTypes.bool,
};

SignUpWrapperNew.defaultProps = {
  children: null,
  showImage: true,
};

export default SignUpWrapperNew;
