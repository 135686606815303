import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CookieIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2488 0.974365L6.60573 2.09552C7.00866 3.36114 8.1829 4.26874 9.55898 4.26874C9.5911 4.26874 9.62307 4.26825 9.65489 4.26728L10.5906 4.2387L10.529 5.17285C10.5209 5.29537 10.5168 5.41908 10.5168 5.54387C10.5168 8.62631 13.0396 11.1378 16.1672 11.1378C16.8775 11.1378 17.5553 11.0084 18.1791 10.773L18.8936 10.5034L19.2379 11.185C19.7059 12.1115 20.6648 12.7437 21.7699 12.7437C22.0097 12.7437 22.2413 12.7142 22.4619 12.6589L23.8012 12.3232L23.4978 13.6702C22.3272 18.8674 17.6842 22.75 12.1324 22.75C5.6983 22.75 0.482422 17.5341 0.482422 11.1C0.482422 7.21893 2.3811 3.78124 5.29654 1.66544L6.2488 0.974365ZM5.48602 3.69526C3.45686 5.51774 2.18242 8.16002 2.18242 11.1C2.18242 16.5953 6.63719 21.05 12.1324 21.05C16.4567 21.05 20.1381 18.2908 21.5095 14.4364C20.1106 14.3572 18.8822 13.6437 18.1061 12.5811C17.4876 12.7486 16.8374 12.8378 16.1672 12.8378C12.239 12.8378 9.01976 9.77594 8.82603 5.91282C7.41584 5.69549 6.21054 4.86092 5.48602 3.69526Z"
        fill="currentColor"
      />
      <path
        d="M7.05039 10.9313C7.05039 11.4387 6.63905 11.85 6.13164 11.85C5.62423 11.85 5.21289 11.4387 5.21289 10.9313C5.21289 10.4239 5.62423 10.0125 6.13164 10.0125C6.63905 10.0125 7.05039 10.4239 7.05039 10.9313Z"
        fill="currentColor"
      />
      <path
        d="M16.5191 14.7187C16.5191 15.2261 16.1078 15.6375 15.6004 15.6375C15.093 15.6375 14.6816 15.2261 14.6816 14.7187C14.6816 14.2113 15.093 13.8 15.6004 13.8C16.1078 13.8 16.5191 14.2113 16.5191 14.7187Z"
        fill="currentColor"
      />
      <path
        d="M17.4391 6.99376C17.4391 7.50117 17.0277 7.91251 16.5203 7.91251C16.0129 7.91251 15.6016 7.50117 15.6016 6.99376C15.6016 6.48635 16.0129 6.07501 16.5203 6.07501C17.0277 6.07501 17.4391 6.48635 17.4391 6.99376Z"
        fill="currentColor"
      />
      <path
        d="M15.6016 2.75623C15.6016 3.11866 15.3077 3.41248 14.9453 3.41248C14.5829 3.41248 14.2891 3.11866 14.2891 2.75623C14.2891 2.39379 14.5829 2.09998 14.9453 2.09998C15.3077 2.09998 15.6016 2.39379 15.6016 2.75623Z"
        fill="currentColor"
      />
      <path
        d="M22.3516 4.25623C22.3516 4.61866 22.0577 4.91248 21.6953 4.91248C21.3329 4.91248 21.0391 4.61866 21.0391 4.25623C21.0391 3.89379 21.3329 3.59998 21.6953 3.59998C22.0577 3.59998 22.3516 3.89379 22.3516 4.25623Z"
        fill="currentColor"
      />
      <path
        d="M9.53477 17.5599C9.53477 18.2227 8.99751 18.7599 8.33477 18.7599C7.67202 18.7599 7.13477 18.2227 7.13477 17.5599C7.13477 16.8972 7.67202 16.3599 8.33477 16.3599C8.99751 16.3599 9.53477 16.8972 9.53477 17.5599Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CookieIcon;
