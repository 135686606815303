import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import * as FEATURES from 'consts/featureFlags';
import jaroWinklerSearch from 'functions/jaroWinklerSearch';
import { DEFAULT_RETAILERS_IDS } from 'consts/defaultFavRetailers';
import tryFunction from 'functions/tryFunction';

const DEFAULT_TRENDING_NOW_LIST = [];

const getFeaturesEntity = ({ features }) => features;

/**
 * @typedef {Any} Feature
 * @description
 * - if features store is not loaded, value will be undefined
 * - if feature is exist, value will be feature flag value
 * - if feature is not exist, value will be false
 */

/**
 * @returns Feature
 */
const getFeature = featureName => {
  return ({ features, isLoaded }) => {
    if (!features || !isLoaded) return undefined;

    return features[featureName] ? features[featureName] : false;
  };
};

export const getIsFeaturesLoaded = createSelector(getFeaturesEntity, ({ isLoaded }) => isLoaded);

export const getProductModalFeature = createSelector(getFeaturesEntity, getFeature(FEATURES.PRODUCT_MODAL));

export const getUninstallInterviewFeature = createSelector(getFeaturesEntity, getFeature(FEATURES.UNINSTALL_INTERVIEW));

export const getTrendingNowFeatureList = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  if (features[FEATURES.TRENDING_NOW_LIST]) {
    const list = tryFunction(() => features[FEATURES.TRENDING_NOW_LIST]?.list?.toLowerCase()?.split(','));

    if (Array.isArray(list)) return list;
  }

  return DEFAULT_TRENDING_NOW_LIST;
});

export const getPopularRetailersListIds = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  if (features[FEATURES.POPULAR_RETAILER_LIST]) {
    const list = tryFunction(() =>
      features[FEATURES.POPULAR_RETAILER_LIST]?.[FEATURES.POPULAR_RETAILER_LIST]?.split(','),
    );

    if (Array.isArray(list)) return list.map(elem => parseInt(elem, 10));
  }

  return DEFAULT_RETAILERS_IDS;
});

export const getTrendingNowFeatureListFiltered = createSelector(getTrendingNowFeatureList, trendingNowList =>
  memoize(query => {
    if (!trendingNowList?.length) return trendingNowList;

    return trendingNowList.filter(trendingNowElem => query?.length < 3 || !!jaroWinklerSearch(trendingNowElem, query));
  }),
);

export const getShowXPlatformBannerOnSearchPageFeature = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  return !!features[FEATURES.X_PLATFORM_ON_SEARCH_PAGE];
});

export const getShowXPlatformBannerOnHomePageFeature = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  return !!features[FEATURES.X_PLATFORM_ON_HOME_PAGE];
});

export const getShowOnboardingLandingPageFeature = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  return !!features[FEATURES.ONBOARDING_LANDING_PAGE];
});

export const getIsSisOnboardingFeature = createSelector(getFeaturesEntity, ({ features, isLoaded }) => {
  if (!features || !isLoaded) return undefined;

  return !!features[FEATURES.SIS_ONBOARDING];
});
