import LogInPasswordContainer from '../LogInPasswordContainer/LogInPasswordContainer';
import SignUpPasswordContainer from '../SignUpPasswordContainer/SignUpPasswordContainer';

const EmailAuthorization = props => {
  const { isSignUpState } = props;

  return isSignUpState ? <SignUpPasswordContainer {...props} /> : <LogInPasswordContainer {...props} />;
};

export default EmailAuthorization;
