import { Trans } from 'react-i18next';

const GoogleTarsAndConditions = () => {
  return (
    <Trans i18nKey="app:reCaptchaMessage">
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href="https://policies.google.com/privacy" rel="noopener noreferrer">
        Privacy Policy
      </a>
      and&nbsp;
      <a href="https://policies.google.com/terms" rel="noopener noreferrer">
        Terms of Service
      </a>
      apply.
    </Trans>
  );
};

export default GoogleTarsAndConditions;
