export const formatRetailers = retailers =>
  retailers?.map((retailer, index) => {
    const absolutePosition = index + 1;
    const firstItemPosition = retailers.findIndex(item => item.priority === retailer.priority);

    return {
      ...retailer,
      priority: retailer.priority,
      relativePosition: absolutePosition - firstItemPosition,
      absolutePosition,
    };
  }) || [];
