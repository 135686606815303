import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Loader } from '@karma/components';
import * as userSelectors from 'store/user/selectors';
import { isClient } from 'functions/isClient';
import isUnauthorizedUrl from 'functions/isUnauthorizedUrl';

const UnauthorizedRoute = ({ component: Component, relatedAuthorizedLink, render, ...otherProps }) => {
  const isUserLoaded = useSelector(userSelectors.getLoaded);
  const isUserAuthorized = Boolean(useSelector(userSelectors.getUserId));

  const redirect = useCallback(
    basePath => {
      const pathMatches = basePath.match(/(\$[^/]+)/g);
      const params = { search: otherProps.location.search, ...otherProps.computedMatch.params };
      const path = pathMatches?.length
        ? pathMatches.reduce((result, pathMatch) => {
            return result.replace(pathMatch, params[pathMatch.substring(1)]);
          }, basePath)
        : basePath;

      return <Redirect to={path} />;
    },
    [otherProps.location, otherProps.computedMatch],
  );

  const renderUnauthorizedContent = renderProps => {
    if (Component) {
      return <Component {...renderProps} />;
    }

    if (render) {
      return render(renderProps);
    }

    return null;
  };

  const renderHandler = renderProps => {
    if (!isClient()) {
      return renderUnauthorizedContent(renderProps);
    }

    if (!isUserLoaded) {
      return <Loader absolute />;
    }

    if (isUserAuthorized) {
      if (isUnauthorizedUrl(renderProps.history.location.search)) {
        return renderUnauthorizedContent(renderProps);
      }

      return redirect(relatedAuthorizedLink);
    }

    return renderUnauthorizedContent(renderProps);
  };

  return <Route {...otherProps} render={renderHandler} />;
};

UnauthorizedRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  relatedAuthorizedLink: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  render: PropTypes.func,
};

export default UnauthorizedRoute;
