import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, RadioGroup, Radio, Divider } from '@karma/components';
import useAction from 'hooks/useAction';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { updateTagRequest } from 'store/myItems/store/actions';
import { Container } from './NotifyWhenComponent.styled';

const RadioWithDivider = ({ showDivider, ...rest }) => {
  return (
    <>
      {showDivider && <Divider color="neutral3" fullWidth bMargin={4} tMargin={4} />}
      <Radio {...rest} />
    </>
  );
};

const NotifyWhenComponent = ({ closeModal, tag }) => {
  const { t } = useTranslation();
  const updateTag = useAction(updateTagRequest);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const notifyWhenOptions = [
    { value: 'any', label: t('app:anyPriceChange') },
    { value: '25', label: t('app:priceOff', { value: 25 }) },
    { value: '50', label: t('app:priceOff', { value: 50 }) },
    { value: '0', label: t('app:muteNotifications') },
  ];

  const [valueNotifyWhen, setValueNotifyWhen] = useState(
    (tag.prevent_notifications ? notifyWhenOptions[3].value : tag.notify_when) || notifyWhenOptions[0].value,
  );

  const isPreventNotifications = notifyWhenOptions[3].value === valueNotifyWhen;

  const notifyWhenSubmit = () => {
    updateTag({
      id: tag.id,
      data: isPreventNotifications
        ? { prevent_notifications: isPreventNotifications }
        : { notify_when: valueNotifyWhen, prevent_notifications: isPreventNotifications },
    });
    closeModal();
  };

  return (
    <Container>
      <Typography variant="h4">{t('app:notifyWhen')}</Typography>
      <RadioGroup value={valueNotifyWhen} onChange={setValueNotifyWhen} spacing={16}>
        {notifyWhenOptions.map(({ value: itemValue, label }) => (
          <RadioWithDivider key={itemValue} value={itemValue} label={label} showDivider={itemValue === '0'} />
        ))}
      </RadioGroup>
      <Button
        size="small"
        onClick={notifyWhenSubmit}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.SAVE}
        fullWidth
      >
        {t('app:save')}
      </Button>
    </Container>
  );
};

export default NotifyWhenComponent;
