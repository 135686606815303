import { put } from 'redux-saga/effects';
import { COOKIES } from 'consts/cookies';
import { ITEMS } from 'consts/localStorage';
import { getCookieValue } from 'functions/cookies';
import { handleServerError } from 'store/alerts/actions';
import * as featuresActions from '../actions';

function* setupStoreFeatures() {
  try {
    const featuresUpToDate = getCookieValue(COOKIES.featuresUpToDate);

    if (!featuresUpToDate || !localStorage.getItem(ITEMS.features)) {
      yield put(featuresActions.fetchFeatures());
    } else {
      const featuresFromLS = JSON.parse(localStorage.getItem(ITEMS.features));

      yield put(featuresActions.setFeatures(featuresFromLS));
    }
  } catch (error) {
    yield put(featuresActions.setFeatures({}));
    yield put(handleServerError(error));
  }
}

export default setupStoreFeatures;
