import styled from 'styled-components';
import { Stack, Typography } from '@karma/components';
import { getColor } from '@karma/components/colors';

export const HoverContainer = styled(Stack)`
  visibility: hidden;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${getColor('neutral1')}80;
  color: ${getColor('neutral8')};
  padding: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  &:hover {
    ${HoverContainer} {
      visibility: visible;
      z-index: 10;
    }
  }
`;

export const HeightController = styled.div`
  margin-top: 100%;
  width: 100%;
`;

export const CardImage = styled.img`
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const Name = styled(Typography)`
  flex: 0 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const PriceBlock = styled(Stack)`
  min-height: 44px;
  text-align: center;
`;
