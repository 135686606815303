import React from 'react';
import { RadioProps } from '../Radio/Radio.types';
import Stack from '../Stack';
import { RadioGroupProps } from './RadioGroup.types';

const RadioGroup: React.FC<RadioGroupProps> = ({ children, value, name, onChange, ...props }) => {
  const clickHandler = (e: any) => {
    if (e.target.value && (e.target.name === name || e.currentTarget.name === name)) {
      onChange(e.target.value, name);
    }
  };

  return (
    <Stack
      direction="column"
      spacing={16}
      alignItems="flex-start"
      justifyContent="center"
      onClick={clickHandler}
      {...props}
    >
      {React.Children.map(children, (child: React.ReactElement<RadioProps>) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(
            child,
            { ...child.props, name, checked: value === child.props.value },
            null
          );
        }

        return null;
      })}
    </Stack>
  );
};

export default RadioGroup;
