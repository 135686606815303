import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  padding: 20px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  gap: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`;

export const RoundButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  padding: 12px;
  border-radius: 50%;
`;

export const ListWrapper = styled.ul`
  margin: 0;
  padding: 5px 0;
  max-height: 145px;
  overflow-y: auto;
  width: 100%;

  label {
    margin: 0;
  }

  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background: transparent;

    &:hover {
      width: 25px;
      background: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${getColor('neutral4')};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral4')};
  }
`;
