import { oldApiInstance } from 'functions/request';

export const getCouponsAutocomplite = (value = '') =>
  oldApiInstance.get('/shop_offers/autocomplete', {
    params: {
      shop_name: value,
    },
  });

export const getCoupons = ({ page, search, sort_by }) =>
  oldApiInstance.get('/shop_offers', {
    params: {
      page,
      sort_by,
      search,
    },
  });

export const getRetailerCoupons = ({ id }) => oldApiInstance.get(`/shop_offers/${id}`);

export const getLatestOffers = ({ coupon_ids, timestamp }) =>
  oldApiInstance.get('/latest/shop_offers', {
    params: {
      coupon_ids,
      timestamp,
    },
  });
