import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SmileyIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6383 2.19286C7.14305 2.19286 2.68828 6.64763 2.68828 12.1429C2.68828 17.6381 7.14305 22.0929 12.6383 22.0929C18.1335 22.0929 22.5883 17.6381 22.5883 12.1429C22.5883 6.64763 18.1335 2.19286 12.6383 2.19286ZM0.988281 12.1429C0.988281 5.70874 6.20416 0.492859 12.6383 0.492859C19.0724 0.492859 24.2883 5.70874 24.2883 12.1429C24.2883 18.577 19.0724 23.7929 12.6383 23.7929C6.20416 23.7929 0.988281 18.577 0.988281 12.1429Z"
        fill="currentColor"
      />
      <path
        d="M10.2387 9.14287C10.2387 10.137 9.43278 10.9429 8.43867 10.9429C7.44456 10.9429 6.63867 10.137 6.63867 9.14287C6.63867 8.14875 7.44456 7.34286 8.43867 7.34286C9.43278 7.34286 10.2387 8.14875 10.2387 9.14287Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51747 14.0324C9.45848 15.2852 10.9539 16.0929 12.6383 16.0929C14.3226 16.0929 15.8181 15.2852 16.7591 14.0324L18.1183 15.0534C16.8701 16.7152 14.8799 17.7929 12.6383 17.7929C10.3967 17.7929 8.40649 16.7152 7.1582 15.0534L8.51747 14.0324Z"
        fill="currentColor"
      />
      <path
        d="M18.6391 9.14287C18.6391 10.137 17.8332 10.9429 16.8391 10.9429C15.845 10.9429 15.0391 10.137 15.0391 9.14287C15.0391 8.14875 15.845 7.34286 16.8391 7.34286C17.8332 7.34286 18.6391 8.14875 18.6391 9.14287Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SmileyIcon;
