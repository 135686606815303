import PropTypes from 'prop-types';
import { ALL_MY_ITEMS_GROUPS } from './myItemsGroups';

const bonus = PropTypes.shape({
  id: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
});

const list = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  shorten_url: PropTypes.string,
});

const reviews = PropTypes.shape({
  average_rating: PropTypes.string.isRequired,
  grouped_ratings: PropTypes.shape({
    1: PropTypes.number.isRequired,
    2: PropTypes.number.isRequired,
    3: PropTypes.number.isRequired,
    4: PropTypes.number.isRequired,
    5: PropTypes.number.isRequired,
  }).isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
    }),
  ).isRequired,
});

const similarTag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  main_img_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tag_url: PropTypes.string.isRequired,
});

const commonTypesForTag = {
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  price: PropTypes.object.isRequired,
  rule_name: PropTypes.string.isRequired,
  marker: PropTypes.string,
  properties_l: PropTypes.object,
  stake: PropTypes.number,
};

const tag = PropTypes.shape({
  ...commonTypesForTag,
  favorites: PropTypes.bool,
  fulfilled: PropTypes.bool,
  lists: PropTypes.arrayOf(list),
  main_img_url: PropTypes.string.isRequired,
  notify_when: PropTypes.string,
  properties: PropTypes.shape(),
  retailer_logo: PropTypes.string,
  rule_id: PropTypes.number.isRequired,
  seo_link: PropTypes.string,
  user_id: PropTypes.number,
});

const tagSeo = PropTypes.shape({
  ...commonTypesForTag,
  coupon_text: PropTypes.string,
  item_desc: PropTypes.string,
  tag_url: PropTypes.string,
  logo_image_uri: PropTypes.string,
  no_karma_gives: PropTypes.bool.isRequired,
});

const tagGroup = PropTypes.oneOf(ALL_MY_ITEMS_GROUPS);

const tagDisplayCheckbox = PropTypes.bool;

export const ENTITIES = {
  bonus,
  list,
  reviews,
  tag,
  tagGroup,
  tagSeo,
  tagDisplayCheckbox,
  similarTags: PropTypes.arrayOf(similarTag),
};
