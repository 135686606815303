import { put } from 'redux-saga/effects';
import { resetGlobalItems } from 'store/myItems/store/actions';
import { fetchGlobalItems } from '../actions';

function* restartGlobalSearchHandler() {
  yield put(resetGlobalItems());

  yield put(fetchGlobalItems({ includeGlobal: true, includeUser: true }));
}

export default restartGlobalSearchHandler;
