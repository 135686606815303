import ModalBodyPaypalDisconnect from 'components/molecules/ModalBodyPaypalDisconnect/ModalBodyPaypalDisconnect';
import * as ALOOMA from 'consts/alooma';
import Modal from 'components/templates/Modal/Modal';

const ModalPaypalDisconnect = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.PAYPAL_DISCONNECT} onClose={closeModal}>
      <ModalBodyPaypalDisconnect {...props} onClose={closeModal} />
    </Modal>
  );
};

export default ModalPaypalDisconnect;
