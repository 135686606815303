import { newApiInstance } from 'functions/request';

export const createNotificationRemindIn = (uuid, { minutes }) => {
  return newApiInstance.post(`my/notifications/${uuid}/remind_in`, { minutes });
};

export const deleteNotification = uuid => {
  return newApiInstance.delete(`my/notifications/${uuid}`);
};

export const deleteNotifications = () => {
  return newApiInstance.delete(`my/notifications`);
};

export const fetchUnseenCount = () => {
  return newApiInstance.get('my/notifications/unseen_count');
};

export const fetchSortByDateNotifications = () => {
  return newApiInstance.get('my/notifications/recent');
};

export const updateMarkAsSeen = () => {
  return newApiInstance.put('my/notifications/mark_as_seen');
};
