import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@karma/components/Typography';
import { BagIcon, CopyIcon } from '@karma/components/icons';
import { Button } from '@karma/components';
import { useSelector } from 'react-redux';
import { getRedirectLinkToPackage, getRedirectLinkToRetailer } from '@karma/utils/functions/redirectLink';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import useAction from 'hooks/useAction';
import { getUserId } from 'store/user/selectors';
import copyToClipboard from 'functions/copyToClipboard';
import { showSuccessAlert } from 'store/alerts/actions';
import { OFFER_TYPES } from 'consts/coupons';
import { Container } from './CouponCTASection.style';

const CouponCTASection = ({ coupon, source }) => {
  const { t } = useTranslation();

  const {
    ad_package_id: adPackageId,
    code,
    retailer_id: retailerId,
    retailer_name: retailerName,
    retailer_stake: retailerStake,
    stake,
    plus_stake: plusStake,
    offer_type: offerType,
  } = coupon || {};

  const [clicked, setClicked] = useState(false);

  const userId = useSelector(getUserId);
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const isCoupon = offerType === OFFER_TYPES.COUPON;
  const contextSource = useContext(ReferralSourceContext);

  const showSuccessAlertFunc = useAction(showSuccessAlert);

  const defaultOpenReferralLink = useCallback(() => {
    const linkProps = {
      ...(adPackageId
        ? {
            packageId: adPackageId,
            source: source || contextSource,
          }
        : {
            source: source || contextSource,
            retailerId,
          }),
      cashback: !!(userId && retailerStake),
      userId,
      ruleName: retailerName,
      isCoupon,
      isCouponDeal: !stake && !plusStake && !retailerStake,
    };

    const referrerLink = (adPackageId ? getRedirectLinkToPackage : getRedirectLinkToRetailer)(linkProps);

    window.open(referrerLink);
  }, [adPackageId, contextSource, isCoupon, plusStake, retailerId, retailerName, retailerStake, source, stake, userId]);

  const couponsClickHandler = useCallback(() => {
    setClicked(true);
    copyToClipboard(code);
    showSuccessAlertFunc({
      message: t('app:couponCopied'),
    });
  }, [code, showSuccessAlertFunc, t]);

  if (adPackageId || offerType === OFFER_TYPES.KARMA_CASH)
    return (
      <Container alignItems="flex-start">
        <Button
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.SHOP_NOW}
          type="underlinedText"
          size="verySmall"
          Icon={BagIcon}
          iconPosition="right"
          onClick={defaultOpenReferralLink}
        >
          {t('app:shopNow')}
        </Button>
      </Container>
    );

  return (
    <Container justifyContent="space-between" fullWidth>
      {clicked ? (
        <Typography variant="labelSmall">{`${t('app:code')}: ${code}`}</Typography>
      ) : (
        <Button
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.GET_COUPON}
          type="underlinedText"
          size="verySmall"
          Icon={CopyIcon}
          iconPosition="right"
          onClick={couponsClickHandler}
        >
          {t('app:getCoupon')}
        </Button>
      )}
      <Button
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.GO_TO_STORE}
        type="underlinedText"
        size="verySmall"
        onClick={defaultOpenReferralLink}
      >
        {t('app:goToStore')}
      </Button>
    </Container>
  );
};

export default CouponCTASection;
