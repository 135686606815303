import styled from 'styled-components';
import { ConeIcon } from '@karma/components/icons';
import { Typography, Stack } from '@karma/components';

export const Wrapper = styled(Stack)`
  text-align: center;
  max-width: 205px;
  margin: 0 auto;
`;

export const Icon = styled(ConeIcon)`
  margin-bottom: 20px;
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const Text = styled(Typography)``;
