import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import SignUpTab from 'components/atoms/SignUpTab/SignUpTab';
import { StyledTabs } from './SignUpTabs.style';

const SignUpTabs = ({ isSignUpState, changeTab }) => {
  const { t } = useTranslation();

  return (
    <StyledTabs fullWidth>
      <SignUpTab selected={!isSignUpState} onClick={changeTab} aloomaName={ALOOMA.ELEMENTS.LOG_IN}>
        {t('app:logIn')}
      </SignUpTab>
      <SignUpTab selected={isSignUpState} onClick={changeTab} aloomaName={ALOOMA.ELEMENTS.SIGN_UP}>
        {t('app:signUp')}
      </SignUpTab>
    </StyledTabs>
  );
};

export default SignUpTabs;
