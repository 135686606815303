import React from 'react';
import { ListItemProps } from './ListItem.types';
import {
  ListItemWrapper,
  SecondaryActionRoot,
  ListItemRoot,
  ListItemSubtitle,
} from './ListItem.styles';

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(function ListItem(
  {
    role: roleProp,
    tabIndex: tabIndexProp,
    disabled = false,
    selected = false,
    secondaryText,
    secondaryAction,
    component,
    value,
    children,
    ...props
  },
  ref
) {
  const role = roleProp || value ? 'option' : 'menu-item';
  const tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;

  const handleKeyDown = (event: React.KeyboardEvent<unknown>) => {
    if (event.target === event.currentTarget && event.key === 'Enter' && !disabled) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props.onClick?.(event as any);
      event.preventDefault();
    }
  };

  return (
    <ListItemWrapper
      {...props}
      as={component}
      role={role}
      tabIndex={!disabled ? tabIndex : undefined}
      aria-selected={selected}
      data-value={value}
      selected={selected}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      hasSecondaryText={!!secondaryText}
      ref={ref}
    >
      <ListItemRoot>
        {children}
        {secondaryText && <ListItemSubtitle disabled={disabled}>{secondaryText}</ListItemSubtitle>}
      </ListItemRoot>
      {secondaryAction && <SecondaryActionRoot>{secondaryAction}</SecondaryActionRoot>}
    </ListItemWrapper>
  );
});

export default ListItem;
