import { useMemo } from 'react';
import { createThemeConfig, DEVICE_THEMES } from '@karma/components/themes';
import { getColor } from '@karma/components/colors';
import { CDN_URL } from '@karma/utils/constants/env';

const useThemeConfig = () => {
  return useMemo(() => {
    const config = createThemeConfig({
      lightConfig: {
        Popper: {
          background: getColor('neutral8'),
          text: getColor('primary1'),
        },
      },
    });

    return {
      ...config,
      device: DEVICE_THEMES.WEBSITE,
      staticPath: CDN_URL,
    };
  }, []);
};

export default useThemeConfig;
