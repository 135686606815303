import styled from 'styled-components';
import { Button, Stack } from '@karma/components';

export const ButtonsContainer = styled(Stack)`
  width: 100%;
  padding: 0 20px;
`;

export const StyledButton = styled(Button)`
  min-width: 155px;
`;
