/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Избранное',
  popular: 'Популярные',
  couponsAndDeals: 'Купоны и предложения',
  yourFavorites: 'Ваше избранные',
  coupons: 'Купоны',
  headerCoupons: 'Karma | Купоны',
  lastSaved: 'Последнее сохраненное',
  favorites: 'Избранное',
  trending: 'Набирает популярность',
  weAreLoving: 'Мы любим',
  karmaHome: 'Karma | Главная',
  goodMorning: 'Доброе утро',
  goodAfternoon: 'Добрый день',
  goodEvening: 'Добрый вечер',
  addFavoriteStores: 'Добавить избранные магазины',
  nothingFound: 'Ничего не найдено',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Сохранить настройки',
  enableBrand: 'Включить {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Удалить',
  sponsored: 'Спонсируется',
  seeAll: 'Смотреть все',
  favorite: 'Избранное',
  onSale: 'Распродажа',
  lowInStock: 'Запасы на исходе',
  backInStock: 'Снова в наличии',
  coupon: 'Купон',
  soldOut: 'Распродано',
  fulfilled: 'Выполнено',
  deal: 'Промо',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: 'Остался {{count, number}} день',
  dayRemaining_plural: 'Осталось {{count, number}} дня (дней)',
  karmaStores: 'Магазины Karma Cash',
  priceLeftToWithdraw: 'Накопите еще всего {{currency}}{{value, number}}, а затем выводите свои награды!',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Ожидающие наличные',
  payouts: 'Выплаты',
  connectWithPayPal: 'Подключиться с PayPal',
  selectCause: 'Выберите доброе дело',
  dayBetweenPayouts: 'Деньги можно вывести через {{count, number}} день после подтверждения покупки.',
  dayBetweenPayouts_plural: 'Деньги можно вывести через {{count, number}} дня (дней) после подтверждения покупки.',
  list: 'Список:',
  emptyWallet: 'Ваш кошелек пуст',
  startShoppingToEarn: 'Начните покупать, чтобы заработать бонусы!',
  withdraw: 'Вывести',
  yourDonations: 'Ваши пожертвования',
  outOfAmount: 'Из {{currency}}{{value, number}}',
  gettingCloser: 'Вы все ближе!',
  canWithdrawEarnings: 'Вы уже можете вывести свой заработок.',
  workedAt: 'Работал(-а) {{date}}',
  copied: 'Скопирован!',
  getCoupon: 'Получить купон',
  shopNow: 'Купить сейчас',
  karmaLogo: 'Логотип Karma',
  myItems: 'Мои товары',
  stores: 'Магазины',
  myLists: 'Мои списки',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Все',
  lastWeek: 'Прошлая неделя',
  lastMonth: 'Прошлый месяц',
  lastThreeMonths: 'Последние 3 месяца',
  clickID: 'Click ID',
  date: 'Дата',
  time: 'Время',
  retailer: 'Ритейлер',
  orderID: 'Ид. заказа',
  startShoppingToEarnKarmaCash: 'Начните покупать сейчас, чтобы заработать Karma Cash,',
  browseStores: 'просмотрите магазины',
  doNotHaveClicks: 'У вас нет кликов в этот период.',
  doNotHaveTransactions: 'У вас нет транзакций в этот период.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: 'Приветственный бонус!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Возврат Karma Gives!',
  completed: 'Завершено',
  notQualified: 'Не удовлетворяет требованиям',
  pending: 'В ожидании',
  cancelled: 'Отменен',
  paid: 'Оплачен',
  empty: 'Пусто',
  expired: 'Срок истек',
  discoverAndShop: 'Найти и купить',
  contactUs: 'Наши контакты',
  news: 'Новости',
  faq: 'Вопросы и ответы',
  aboutUs: 'О нас',
  karmaItem: 'Товар Karma',
  email: 'Эл. почта',
  congrats: 'Поздравляем!',
  congratulations: 'Поздравляем!',
  getKarmaButton: 'Получите кнопку Karma!',
  saveThingsToBuy: 'Сохраняйте на потом товары, которые хотите купить, с Karma!',
  savedFirstItem: 'Вы сохранили свой первый товар!',
  getTheButton: 'Получить кнопку',
  savedFirstItem_plural: 'Вы уже сохранили несколько товаров!',
  iWantToSeeOther: 'Я хочу увидеть другие <1>поддерживаемые магазины',
  neverMissProducts: 'Никогда не пропускайте момент, когда товары поступят в продажу или снова появятся на складе!',
  shareByEmail: 'Поделиться по эл. почте',
  whenIFindSmth: 'Когда я нахожу что-то, что я хочу, я сохраняю это в Karma!',
  completeSignUp: 'Завершите регистрацию',
  addToList: 'Добавить в список',
  chat: 'Чат',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Нажмите для сохранения товара',
  createListAndPressEnter: 'Создайте список и нажмите «Ввод»',
  confirmDeleteProduct: 'Вы уверены, что хотите удалить этот продукт?',
  shareOnFacebook: 'Поделиться на Facebook',
  selectAll: 'Выбрать все',
  selectProp: 'Выберите {{name}}',
  delete: 'Удалить',
  fulfill: 'Выполнить',
  mute: 'Отключить звук',
  anyPriceChange: 'Любое изменение цены',
  priceOff: '{{value, number}}% скидки',
  notifyWhen: 'Уведомить, когда',
  tweetOnTwitter: 'Твит на Twitter',
  shareProduct: 'Поделиться продуктом',
  markAsFulfilled: 'Отметить как выполненное',
  unmuteNotifications: 'Включить звук уведомлений',
  muteNotifications: 'Отключить звук уведомлений',
  priceEvolution: 'Изменение цены',
  logOut: 'Выйти',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Политикой конфиденциальности',
  confirmDelete: 'Уверены, что хотите удалить эти продукты?',
  termsAndConditions: 'Положения и условия',
  logosProvidedBy: 'Логотипы предоставлены {{name}}',
  copyright:
    'Авторское право © {{year, clearNumber}} компании Karma Shopping Ltd., ведущей деятельность под наименованием Karma. Все права защищены.',
  noNotifications: 'Нет уведомлений',
  clearAllNotifications: 'Удалить все уведомления',
  areYouSureRemoveNotifications: 'Уверены, что хотите удалить все уведомления?',
  deleteProducts: 'Удалить продукты',
  no: 'Нет',
  yes: 'Да',
  headerStores: 'Karma | Магазины',
  clearNotification: 'Удалить уведомление',
  clear: 'Очистить',
  bell: 'колокольчик',
  remindInMinute: 'Напомнить мне через {{value, number}} минуту',
  remindInMinute_plural: 'Напомнить мне через {{value, number}} минут(-ы)',
  remindInHour: 'Напомнить мне через {{value, number}} час',
  remindInHour_plural: 'Напомнить мне через {{value, number}} часа(-ов)',
  retailers: 'Ритейлеры',
  claimKarmaCash: 'Получите до {{percent, number}}% Karma Cash',
  clickOnClaimButton: 'Нажмите на кнопку «Получите до {{percent, number}}% Karma Cash».',
  goShopping: 'Отправиться за покупками',
  rackUpCash: 'Накопить наличные',
  getPaidDirectly: 'Получайте оплату напрямую',
  earnRealMoney: 'Зарабатывайте реальные деньги буквально с каждой покупки.',
  trackYourEarnings: 'Отслеживайте свои доходы и выводите деньги каждый сезон.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Связанные магазины',
  defaultWhatHappensNext:
    'Чтобы автоматически заработать Karma Cash при следующей покупке, нажмите на одного из наших удовлетворяющих требованиям Karma Cash продавцов на Karma, у которого вы можете делать покупки как обычно! Помните, что вы можете заработать Karma Cash, только посетив подходящего продавца в приложении или на панели управления Karma (включая push-уведомления) или взаимодействуя с расширением браузера Karma на персональном компьютере/мобильном устройстве перед покупкой. Как только продавец проверит вашу покупку, Karma подтвердит зачисление вашего дохода в кошелек Karma.',
  defaultTermsAndConditions:
    'Karma Cash недоступны при покупке подарочных сертификатов или оплаты любой части покупки с помощью подарочного сертификата, кредита магазина, баллов лояльности или другой программы, предусматривающей начисление баллов. Использование промокодов, не указанных на сайте Karma, может привести к аннулированию Karma Cash. Дополнительные ограничения могут применяться к определенным товарам или категориям.',
  whatHappensNext: 'Что будет дальше?',
  about: 'О нас',
  readMore: 'Подробнее',
  frequentlyAskedQuestions: 'Вопросы и ответы',
  loadMore: 'Загрузить еще',
  questionButton: 'кнопка вопроса',
  headerKarmaCash: 'Karma | Денежные бонусы',
  recommended: 'Рекомендуется',
  highToLow: 'От высоких до низких',
  lowToHigh: 'От низких до высоких',
  sortBy: 'Сортировать по:',
  exploreStores: 'Обзор магазинов',
  weHaveNotFoundStores: 'Мы не нашли ни одного магазина по вашим запросам',
  weHaveNotFoundCoupons: 'Мы не нашли ни одного купона по вашим запросам',
  headerApps: 'Karma | Приложения',
  karmaApps: 'Получайте хорошую карму повсюду.',
  stayUpdated:
    'Будьте в курсе новостей на всех своих устройствах. Наш цифровой помощник по покупкам позволяет сохранять товары из более чем {{number, number}} тыс. магазинов.',
  getFaster: 'Сделайте взаимодействие более быстрым и плавным, загрузив Karma на свой Mac.',
  headerProfile: 'Karma | Профиль',
  headerWallet: 'Karma | Кошелек',
  totalBalance: 'Общий остаток',
  editYourAccount: 'Измените свой аккаунт',
  emailAddress: 'Адрес эл. почты',
  enterEmailAddress: 'Введите адрес эл. почты',
  enterEmail: 'Укажите эл. почту',
  enterEmailLower: 'Укажите эл. почту',
  phoneNumber: 'Номер телефона',
  enterPhoneNumber: 'Укажите номер телефона',
  fullName: 'Имя и фамилия',
  enterFullName: 'Введите имя и фамилию',
  enterPlaceholder: 'Укажите {{name}}',
  birthday: 'День рождения',
  year: 'Год',
  month: 'Месяц',
  day: 'День',
  gender: 'Пол',
  language: 'Язык',
  currency: 'Валюта',
  multiCurrency: 'Мультивалюта',
  male: 'Мужчина',
  female: 'Женщина',
  notWantShare: 'Я не хочу сообщать',
  mobilePush: 'Мобильное push-сообщение',
  webPush: 'Веб-push',
  disconnectFromPayPal: 'Отключить от PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Сначала выберите год и месяц',
  notSureWhereToStart: 'Не знаете, с чего начать?',
  youDidntSaveItems: 'У вас пока нет сохраненных товаров.',
  searchItems: 'Поиск товаров',
  headerDashboard: 'Karma | Панель управления',
  headerSales: 'Karma | Распродажи',
  couldNotFindStores: 'Ой, мы не смогли найти распродажи',
  karmaGivesValue: '{{value, number}} $',
  karmaGivesModalTitle: 'Вот вам {{value, number}} $ БЕСПЛАТНО для начала',
  hereIsABitOfGoodKarma: 'Вот немного хорошей кармы за регистрацию у нас!',
  collectAndStartSaving: 'Получите и начните экономить',
  termsAndConditionsAppersant: 'Положения и условия',
  mayApply: 'могут применяться',
  termsAndConditionsError: 'Сначала согласитесь с нашими условиям и политикой конфиденциальности.',
  validEmailError: 'Введите действительную эл. почту.',
  completeSignUpCapital: 'Завершите регистрацию',
  or: 'ИЛИ',
  password: 'Пароль',
  joinUs: 'Зарегистрируйтесь у нас',
  reviewsPlus: 'Более {{amount, number}} тыс. отзывов',
  logIn: 'Вход',
  termsAgreement: 'Зарегистрировавшись, вы соглашаетесь с нашими <1>Условиями и <3>Политикой конфиденциальности',
  termsAgreementWithCheckbox: 'Я соглашаюсь с <1>Положениями и условиями и <3>Политикой конфиденциальности',
  createANewList: 'Создать новый список',
  listCreateBlickError: 'Имя списка не может быть пустым',
  enterListName: 'Укажите название списка',
  listDescriptionOptional: 'Описание списка (необязательно)',
  listName: 'Название списка',
  enterDescriptionForList: 'Введите описание вашего списка',
  save: 'Сохранить',
  deleteList: 'Удалить список',
  areYouSureRemoveList: 'Вы действительно хотите удалить этот список?',
  editList: 'Редактировать список',
  listNameCanNotBeBlanck: 'Имя списка не может быть пустым',
  shareList: 'Поделиться списком',
  notAMemberCreateAccount: 'Не зарегистрированы? <1>Создайте аккаунт!',
  backToLogin: 'Назад ко входу',
  enterYourEmail: 'Введите свой адрес эл. почты',
  resetPassword: 'Сбросить пароль',
  forgotPasswordMessage:
    'Через несколько минут вы получите электронное письмо с инструкциями по восстановлению пароля.',
  forgotPassword: 'Забыли пароль?',
  reCaptchaMessage:
    'Этот сайт защищен reCAPTCHA, и применяются <1>Политика конфиденциальности и <3>Условия обслуживания Google',
  pleaseScan: 'Пожалуйста, отсканируйте',
  saveAnItem: 'Сохранить товар',
  areYouSureFulfilled: 'Уверены, что хотите пометить все заказы на эти товары как выполненные?',
  muteTagDesc: 'Уверены, что хотите отключить звук уведомлений об этих товарах?',
  muteTagTitle: 'Отключить звук уведомлений',
  ok: 'ОК',
  canAccessEarningsInPayPal: 'Вы можете получить доступ к этим заработкам в своем счете PayPal',
  ccpaTitle:
    'Закон штата Калифорния о конфиденциальности потребителей (CCPA) с поправками, внесенными Законом штата Калифорния о правах на конфиденциальность (CPRA)',
  ccpaText: ' Отправляя эту форму, я использую свое право отказаться от любой продажи моей личной информации.',
  submit: 'Отправить',
  joinKarmaAndGetRewards: 'Присоединяйтесь к Karma и получайте бонусы {{stack, number}} в {{name}}',
  doNotMissOut: 'Не пропустите! Возможно, вы сможете сэкономить на покупке товара!',
  signUpAndGetMoney: 'Зарегистрируйтесь и получите {{value, number}} $',
  itemAlert: 'Получено оповещение о товаре!',
  accessKarmaCash: 'Доступ к вашим Karma Cash',
  youEarnedKarmaCash: 'Вы только что заработали Karma Cash!',
  verifyYourPaypalAccount: 'Подтвердите счет<1/>Paypal',
  weHaveSentAnEmailTo: 'Мы отправили письмо на {email}',
  installed: 'Установлен',
  comingSoon: 'Скоро появится',
  'Fall Favorites': 'Популярное осенью',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Одежда и роскошь',
  'Home Decor & Furniture': 'Домашний декор и мебель',
  'Electronics & Office Supplies': 'Электроника и канцелярские товары',
  Luxury: 'Роскошь',
  Clothing: 'Одежда ',
  'Handbags & Shoes': 'Сумки и обувь',
  'Jewelry & Watches': 'Ювелирные изделия и наручные часы',
  Accessories: 'Аксессуары',
  'Beauty Essentials': 'Базовая косметика',
  'Health & Wellness': 'Здоровье и велнес',
  Lifestyle: 'Образ жизни',
  'Sports & Outdoors': 'Спорт и туризм',
  Travel: 'Путешествия',
  'Electronics & Office supplies': 'Электроника и канцелярские товары',
  'Entertainment & Online Services': 'Развлечения и онлайн-услуги',
  Education: 'Образование',
  'Finance & FinTech': 'Финансы и финтех',
  'Pet Supplies': 'Товары для питомцев',
  'Food & Beverages': 'Еда и напитки',
  Men: 'Мужчины',
  Women: 'Женщины',
  Unisex: 'Унисекс',
  Kids: 'Дети',
  Baby: 'Малыш',
  notifications: 'Уведомления',
  changesSaved: 'Изменения сохранены!',
  headerRetailer: 'Karma | Ритейлер',
  headerRedirect: 'Karma | Перенаправление...',
  karma: 'Karma',
  goBackToKarma: 'Назад в Karma',
  authentication: 'Аутентификация',
  authSuccess: 'Поздравляем! Вы успешно вошли.',
  authFailure: 'Ой, мы не смогли авторизовать вас. Попробуйте позже.',
  mayNotQualify: 'Некоторые товары могут не предоставлять право на Karma Cash',
  checkOutRetailers: 'Посмотрите поддерживаемых нами ритейлеров Karma Cash.',
  headerHowItWorks: 'Karma | Как это работает',
  accept: 'Принять',
  headerError: 'Karma | Ошибка',
  pageNotFound: 'Страница не найдена',
  pageDoesNotExist: 'Ой, похоже, этой страницы не существует.',
  backHome: 'Назад на главную',
  startShopping: 'Начать покупки',
  illustration: 'Иллюстрация',
  dashboard: 'Панель управления',
  allRightsReserved: 'Все права защищены.',
  product: 'Товар',
  disclaimer:
    'Компания Karma Shopping Ltd. ведет деятельность под названием Karma. Karma не владеет сторонними товарными знаками и не претендует на них. Сторонние товарные знаки, отображаемые на платформе Karma или доступные прямо или косвенно через нее, не обязательно имеют отношение к Karma, третьим сторонам, связанными с Karma, или поставщиками услуг Karma.',
  help: 'Справка',
  team: 'Команда',
  howItWorks: 'Как это работает',
  supportedStores: 'Поддерживаемые магазины',
  company: 'Компания',
  planYourPurchase: 'Спланируйте следующую покупку в нашем бесплатном приложении!',
  itSitsInBrowser: 'Она отображается в вашем браузере, когда вы посещаете все любимые магазины.',
  seeSmthYouLike:
    'Вам что-то понравилось? Нажмите кнопку и сохраните товар в своем списке, и Karma будет следить за его ценой и наличием вместо вас.',
  youWillGetNotified:
    'Вы сразу же получите уведомление, как только ваши сохраненные товары поступят в продажу, подешевеют или снова появятся на складе в выбранном вами размере и цвете.',
  everythingYouWant:
    'Все, что вы хотите купить, будет в одном удобном месте, чтобы у вас все было упорядочено и не нужно было тасовать несколько списков пожеланий.',
  headerContactUs: 'Karma | Наши контакты',
  needToGetInTouch: 'Нужно связаться?',
  dropALine: 'Напишите нам',
  comeHangOut: 'Давайте потусуемся',
  joinOurFacebookCommunity: 'Присоединяйтесь к нашему сообществу на Facebook',
  followOnInstagram: 'Подпишись на нас в Instagram',
  headerAboutUs: 'Karma | О нас',
  addTheItems:
    'Добавляйте товары, которые хотите купить, — вы также можете указать их размер и цвет, и приложение уведомит вас, когда они появятся в продаже.',
  jonathanSay:
    'Джонатан: хотя ритейлеры уже много лет используют искусственный интеллект, теперь потребители смогут с выгодой воспользоваться инструментами машинного обучения и автоматизации для повышения эффективности покупок.',
  justDownload:
    'Просто скачайте «кнопку» и нажмите ее, когда вы выберете товар, который хотите сохранить в своем списке. На мобильном устройстве вы можете «делиться» товарами, отправляя их в же списки.',
  ifYouFind:
    'Если вы найдете в Интернете понравившийся вам товар, который выходит за рамки вашего бюджета, вы можете настроить оповещение о распродаже и получать уведомления о снижении цены на него.',
  onlyAHandful:
    'Лишь немногие компаний, предлагающих уведомления о распродажах для онлайн-участников, все еще продолжают работу. К их числу относится Karma.',
  alwaysBuy: 'Всегда покупайте что-нибудь на распродаже с Karma.',
  headerCareers: 'Karma | Карьера',
  getInTouch: 'Свяжитесь с нами:',
  shop: 'Делайте покупки',
  weCouldNotFindResults: "Не смогли найти результатов для '{{name}}'",
  couldNotFindResults: 'Мы не смогли найти результаты',
  shopAllStores: 'Покупайте во всех поддерживаемых магазинах',
  popularStores: 'Популярные магазины',
  shops: 'Магазины',
  headerDiscoverAndShop: 'Karma | Находите и покупайте последние сохраненные товары',
  prev: 'Пред.',
  next: 'Далее',
  headerDemo: 'Karma | Демо',
  getTheKarmaButton: 'Получить кнопку Karma',
  installedKarma: 'Вы успешно установили Karma!',
  settingUpAccount: 'Теперь давайте создадим аккаунт.',
  signUpNow: 'Зарегистрироваться сейчас',
  logInNow: 'Войти',
  signUpLater: 'Зарегистрироваться позже',
  wePartnerWith: 'Мы сотрудничаем с более чем {{number, number}} тыс. интернет-магазинов!',
  pickStoresToSee: 'Выберите один из магазинов ниже, чтобы увидеть, как это работает:',
  leaveKarma:
    'Обратите внимание, что вы покинете Karma и перейдете на страницу магазина, чтобы воспользоваться всеми функциями.',
  searchFavoriteStores: 'Поиск в любимых магазинах',
  clickBelowToStart: 'Нажмите ниже, чтобы начать',
  chooseOneOfTheOptions: 'Выберите один из вариантов ниже',
  clickToBegin: 'Нажмите на панель поиска, чтобы начать',
  preferNotToSay: 'Предпочитаю не отвечать',
  done: 'Готово',
  headerUnsubscribe: 'Karma | Отписаться',
  changeMind: 'Передумали?',
  resubscribeList: 'Снова подпишитесь на вашу рассылку.',
  resubscribeList_plural: 'Подпишитесь на свою рассылку здесь.',
  changePreferencesAtAnyTime: 'Вы также можете изменить свои предпочтения в любое время в <1>настройках аккаунта.</1>',
  preferencesUpdated: 'Мы обновили настройки коммуникаций по эл. почте.',
  sorryToSeeYouGo: 'Нам жаль, что вы уходите!',
  reSubscribed: 'Вы переподписались',
  allowHours: 'Изменения вступят в силу не позже чем через {{hours, number}} часа(-ов).',
  missWhenYouGone:
    'Мы будем скучать по вам после вашего ухода! Изменения вступят в силу не позже чем через {{hours, number}} часа(-ов).',
  headerDisablingSubscribe: 'Karma | Отказ от подписки',
  noLongerAlerts: 'Вы больше не будете получать оповещения об этом товаре.',
  turnAlertsBack: 'Снова включить оповещения.',
  moveAnimations: 'Переместить анимацию кнопки',
  headerExtensionDeleted: 'Karma | Расширение удалено',
  areSorryToSeeYouGo: 'Нам жаль, что вы уходите!',
  youHaveGotInYourWallet: 'У вас в кошельке {{value}} $.',
  toWithdraw: ' чтобы вывести свой заработок!',
  toContinue: ' чтобы и дальше получать бонусы!',
  reasonUninstalled: 'Сообщите нам, почему вы удалили:',
  getInTouchBtn: 'Свяжитесь с нами',
  howWeCanIncrease: 'Вот как вы можете повысить осведомленность и увеличить продажи с помощью рекламы с Karma',
  helpingShoppers:
    'Помогать покупателям открывать для себя бренды, которые они полюбят на долгие годы, — один из наших главных приоритетов как личного помощника по покупкам.',
  whyWeOfferBrands:
    'Вот почему мы предлагаем брендам, которые неизменно обеспечивают высокий уровень удовлетворенности клиентов, несколько способов связи с нашей аудиторией. Вы достигнете целей своей кампании, а наша аудитория познакомится со своим новым любимым брендом.',
  winWin:
    'Мы предлагаем брендам, которые неизменно обеспечивают высокий уровень удовлетворенности клиентов, несколько способов связи с нашей аудиторией. Вы достигнете целей своей кампании, а наша аудитория познакомится со своим новым любимым брендом — это поистине выгодно всем сторонам!',
  getFeaturedRight: 'Попадите в избранное прямо в нашем приложении и панели управлении расширением браузера',
  getFeaturedDashboard: 'Попадите в избранное на панели инструментов Karma',
  putYourHand:
    'Поместите свой образ, товары или промоакции именно туда, куда приходят наши пользователи, когда готовы к покупкам. Мы можем создать разнообразный индивидуальный брендированный контент, который будет привлекать трафик заинтересованных покупателей, куда бы вы ни хотели его направить.',
  exactPlace:
    'Поместите свой бренд именно в то место, куда приходят наши пользователи, когда готовы совершить покупку.',
  dedicatedInAppPlacement: 'Выделенное место в приложении',
  dashboardFeaturedBanners: 'Баннеры избранного на панели управления',
  dashboardPopUpMessages: 'Всплывающие сообщения в панели управления',
  customEmailsAndNewsLetters:
    'Персонализированные письма и включение в рассылку с боле чем {{subscribers, number}} адресатов',
  customEmails: 'Персонализированные письма более чем {{subscribers, number}} подписчикам',
  getMoreEyes:
    'Привлеките большее внимание к своему последнему предложению, коду купона или линейке продуктов с помощью специального электронного письма или рассылки покупателям.',
  putYourBrand:
    'Поместите свой бренд именно в то место, куда приходят наши пользователи, когда готовы совершить покупку.',
  newsletterInclusion: 'Включение в рассылку',
  dedicatedCustomEmails: '{{percent, number}}% специальных настраиваемых писем',
  bannerPlacementInEmails: 'Размещение баннера в письмах с уведомлениями',
  pushNotificationsToShoppers: 'Push-уведомления в адрес более чем {{shoppers, number}} покупателей',
  pushNotificationsGiveTouchPoint:
    'Push-уведомления предоставляют вашей кампании еще одну точку соприкосновения с аудиторией и обеспечивают высокие показатели открытия и кликов.',
  featureBrandClients: 'Избранные клиенты бренда',
  growingPerMonth: 'Рост более чем на {{number, number}} тыс. в месяц',
  members: 'Участники',
  percent: '{{value, number}}%',
  kindOfReachYouExpect: 'Какого охвата вы ожидаете?',
  ourAudience: 'Наша аудитория',
  tabIntoNewSource: 'Найдите новый источник подходящих клиентов для следующей кампании',
  tellUsAboutYourCompany:
    'Расскажите нам немного о своей компании и целях, и мы покажем вам, как мы можем помочь вам превзойти их.',
  openAndTap:
    'Откройте Safari, перейдите на страницу товара, который хотите сохранить, и нажмите кнопку «Поделиться» (Share).',
  shareIllustration: 'Поделиться иллюстрацией',
  tapMore: 'Нажмите кнопку «Еще» (More) в правой части панели.',
  moreIllustration: 'Еще иллюстрация',
  enableIllustration: 'Включить иллюстрацию',
  tapEdit: 'Нажмите на кнопку «Изменить» (Edit) в верхней части панели.',
  enableApp:
    'Включите Karma и нажмите на зеленую кнопку «добавить» (add), чтобы добавить товар в избранное, а затем нажмите на «Готово» (Done).',
  goBackToTheProduct:
    'Вернитесь на страницу товара, снова нажмите кнопку «Поделиться» (Share), а затем на кнопку Karma, чтобы сохранить товар.',
  recommendedBy: 'Рекомендуется',
  ourMembersSavingMoney: 'Наши участники ❤️ экономят с Karma. Вот почему',
  addToBrowser: 'Добавьте в {{browser}} — это бесплатно',
  planPurchases: 'Спланируйте очередные покупки',
  karmaMakesItEasy: 'Karma позволяет легко упорядочить ваши очередные покупки в одном месте.',
  buyAtRightTime: 'Покупайте в нужное время',
  automaticallyAlert: 'Karma автоматически оповестит вас о лучшем времени для покупки!',
  cashOnVacation: 'Karma Cash для следующего отпуска',
  karmaPartners: 'Karma сотрудничает со многими туристическими и гостиничными сайтами.',
  headerRewards: 'Karma | Лучшие купоны и денежные бонусы',
  mission: 'Наша миссия',
  empowerYou:
    'Мы предоставляем вам возможность делать правильный выбор в нужное время при совершении покупок в Интернете.',
  weAreSeriousAboutPrivacy: 'Мы серьезно относимся к вашей конфиденциальности и безопасности',
  weDoNotSellData: 'Мы не продаем ваши данные',
  karmaIsSerious: 'Karma очень серьезно относится к вашим данным и никогда не продает их третьим лицам. Никогда.',
  karmaIsSecure: 'Karma безопасна',
  weUseBankSecurity:
    'Мы используем средства безопасности банковского уровня ({{bit, number}}-битовое шифрование), чтобы гарантировать безопасность и надежную защиту вашей информации.',
  helpsSaveMore: 'Karma помогает вам больше экономить!',
  alertMe: 'Оповещения',
  buyWithKarmaEarn: 'Покупайте с Karma — зарабатывайте {{number, number}} $',
  productSaved:
    'Поздравляем! Этот товар сохранен в вашем списке товаров. Вы получите уведомление, когда он подешевеет.',
  willApplyTheBestCoupons: 'Karma автоматически применит лучшие купоны к вашей покупке!',
  downloadAndAutoApply: 'Скачайте Karma и применяйте автоматически',
  whatIsKarma: 'Что такое Karma?',
  claimUpToRewards: 'Получите до {{number, number}}% денежного бонуса',
  youMightAlsoLike: 'Вам также может понравиться',
  weAreSorry: 'Сожалеем, но что-то пошло не так, и не удается показать страницу.',
  tryToReloadPage: 'Попробуйте перезагрузить страницу.',
  Color: 'Цвет',
  Size: 'Размер',
  latestCoupons: '{{name}} | Последние купоны и кешбэк Karma {{year, clearNumber}}',
  neverPayFullPrice: "Никогда не платите полную цену. Получите купоны и кешбэк Karma's {{name}}",
  neverPayFullPriceForTag: 'Никогда не платите полную цену за {{name}}',
  saveMoneyWhenShoppingAt: 'Экономьте деньги, делая покупки в {{name}}. Регистрируйтесь в Karma бесплатно',
  saveMoneyWhenShoppingFor: 'Экономьте деньги, покупая {{name}}. Регистрируйтесь в Karma бесплатно',
  tagByBrand: 'Karma | {{tag}} от {{brand}}',
  typeToSearch: 'Введите текст для поиска',
  getUSDWhenJoinToday: 'Получите {{value, number}} $ за регистрацию в Karma сегодня!',
  buyProduct: 'Купить товар',
  userWishList: 'Karma | Список пожеланий {{name}}',
  byWhom: 'от',
  copiedToClipboard: 'Скопировано в буфер обмена!',
  send: 'Отправить',
  cause: 'Выберите доброе дело:',
  aCause: 'Выберите доброе дело:',
  close: 'Закрыть',
  selectThisCause: 'Выбрать доброе дело',
  habitatForHumanity:
    '{{name}} занимается сокращением бедности в долгосрочной перспективе с помощью доступного социального жилья. Миссия организации — создать безопасную среду, в которой могут процветать семьи и сообщества.',
  casaDescription:
    'Национальная ассоциация CASA/GAL поддерживает назначенных судом волонтеров-адвокатов и содействует такой работы, чтобы каждый ребенок, подвергшийся жестокому обращению или оставленный без внимания, был в безопасности, имел постоянный дом и преуспел в жизни.',
  endHomelessnessDescription:
    'Национальный альянс {{name}} — это независимая организация, занимающаяся профилактикой бездомности в Соединенных Штатах Америки и стремящаяся положить ей конец.',
  pathDescription: 'PATH борется с бездомностью отдельных лиц, семей и сообществ.',
  feedingAmericaDescription: 'Feeding America — крупнейшая национальная организация по борьбе с голодом.',
  mealsOnWheelsDescription:
    '{{name}} помогает местным общественным программам улучшать здоровье и качество жизни пожилых людей, которым они служат, чтобы никто не остался голодным или одиноким.',
  actionAgainstHunger:
    '{{name}} — глобальная гуманитарная организация, которая принимает решительные меры против причин и последствий голода.',
  supportMedicalAdvancement: 'Поддерживайте развитие медицины',
  saveChildsHeartDescription:
    "Save A Child's Heart занимается лечением болезней сердца независимо от расы, религии, пола, национальности или финансового положения.",
  su2c: 'Stand Up To Cancer (SU2C) финансирует и разрабатывает новейшие и наиболее многообещающие методы лечения рака, чтобы помочь пациентам сегодня.',
  drWithoutBorders:
    '{{name}} — это независимое глобальное движение, оказывающее медицинскую помощь там, где она больше всего нужна.',
  activeMinds:
    '{{name}} — ведущая некоммерческая организация страны, поддерживающая осведомленность о психическом здоровье и просвещение молодежи.',
  cleanAirTaskForceDesc:
    '{{name}} содействует изменениям в технологиях и политике, необходимым для достижения нулевых выбросов на планете и высокому энергопотреблению по доступной цене.',
  wwfDesc: 'Миссия {{name}} — сохранить природу и уменьшить самые серьезные угрозы разнообразию жизни на Земле.',
  waterkeepsDesc:
    '{{name}} борется за чистоту воду. Waterkeeper Alliance стремиться обеспечить каждому сообществу в мире наличие питьевой, пригодной для рыбной ловли и купания воды.',
  xprice:
    'Каждый день XPrize трудится над созданием лучшего мира, в котором каждый человек имеет доступ к чистой воде, питательной пище, доступному жилью, эффективному обучению, высококлассному медицинскому обслуживанию и экологически чистым источникам энергии.',
  globalActDesc: '{{name}} стремится положить конец сексуальной и коммерческой эксплуатации.',
  pcaa: '{{name}} прилагает все усилия, чтобы воплотить в жизнь наше видение мира, в котором все дети растут счастливыми, здоровыми и способными добиться успеха в семьях и сообществах, где их поддерживают.',
  endCAN:
    'EndCAN повышает осведомленность о воздействии жестокого обращения на здоровье, психическое здоровье и общественное здоровье, стремясь положить конец жестокому обращению с детьми и оставлению их без внимания.',
  futuresDesc:
    'FUTURES предлагает новаторские программы, стратегии и кампании, которые расширяют возможности отдельных лиц и организаций, работающих над прекращением насилия в отношении женщин и детей во всем мире.',
  thornDesc: '{{name} создает технологии для защиты детей от сексуального насилия.',
  'Eradicate Homelessness': 'Искореняйте бездомность',
  'Help End Hunger': 'Помогите ликвидировать голод',
  'Support Medical Advancement': 'Поддерживайте развитие медицины',
  'Combat Climate Change': 'Боритесь с изменением климата',
  'Act To End Abuse': 'Помогите прекратить насилие',
  itemsYouSaved: 'Товары, которые вы сохранили, по более доступной цене',
  beta: 'БЕТА-ВЕРСИЯ',
  alsoAvailableAt: 'Также доступно в',
  atName: 'у {{name}}',
  bestPrice: 'Лучшая цена',
  passwordWasSuccessfullyReset: 'Пароль успешно сброшен',
  paidWith: 'Оплачено с помощью',
  infoFirstNumber: 'Более {{number, number}}{{unit}}',
  infoFirstTitle: 'Участников',
  infoSecondNumber: 'Более {{number, number}}{{unit}}',
  infoSecondTitle: 'Ежемесячных визитов',
  infoThirdNumber: 'Более {{number, number}}{{unit}}',
  infoThirdTitle: 'Адресатов рассылки',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Миллениала(-ов)',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Женщин',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Расположенны в США',
  infoSeventhNumber: 'Более {{number, number}}{{unit}}',
  infoSeventhTitle: 'Согласий с push-уведомлениями на мобильные устройства',
  infoEighthNumber: 'Более {{number, number}}{{unit}}',
  infoNinthPreNumber: '',
  infoNinthNumber: 'Более {{number, number}}{{unit}}$',
  infoNinthTitle: 'Будет потрачено пользователями до конца {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma всегда с вами, когда вы делаете покупки',
  saveItemsToBuy: 'Сохраняйте товары, и мы сообщим вам, когда покупать',
  weDoTheWork: 'Мы делаем все возможное, чтобы найти вам лучшие купоны',
  earnRealCashThatAdds: 'Зарабатывайте реальные деньги, которые накапливаются в вашем кошельке',
  setUpStrongPassword: 'Задайте надежный пароль',
  settingEmailAccount:
    'Последний шаг к экономии с Karma? Зарегистрируйтесь, чтобы мы могли отправлять вам важные оповещения о товарах, например, о снижении цен.',
  settingPasswordAccount:
    'Пароль должен состоять как минимум из {{number, number}} символов и не должен включать ваши учетные данные электронной почты.',
  countWaysToUse: '{{number, number}} способа(-ов) использовать Karma',
  goBack: 'Назад',
  pleaseChooseStrongerPassword: 'Выберите более надежный пароль',
  pleaseEnterValidEmailAddress: 'Введите действительный адрес эл. почты',
  illSignUpLater: 'Я зарегистрируюсь позже',
  enterYourEmailAddress: 'Введите свой адрес эл. почты, и мы отправим вам ссылку для сброса пароля.',
  headerOnboardingIosExtension: 'Karma | Расширение IOS для начала работы',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Мы автоматически применим купоны, где бы вы ни покупали',
  continue: 'Продолжить',
  changeLanguage: 'Изменить язык',
  selectedLanguage: 'Выбранный язык',
  headerMuteItem: 'Karma | Не уведомлять о товаре',
  itemHasBeenMuted: 'Готово! Об этом товаре не будет уведомлений.',
  toUnmuteItem: 'Для включения уведомлений о товаре:',
  headToMyItems: 'Перейдите на вкладку «Мои товары»',
  clickThreeButtonsInItem: 'Нажмите на кнопку с тремя точками в правом нижнем углу товара"',
  clickUnmute: 'Нажмите «Включить уведомления»',
  weveJustRaisedInFunding:
    'Мы только что привлекли {{value, number}} млн $ на этапе финансирования A, чтобы помочь вам делать покупки умнее!',
  weveJustRaised: 'Мы привлекли {{value, number}} млн $',
  landingReview1: 'Отличное приложение! Я получил предложение о снижении цены и сэкономил много денег!',
  landingReview2: 'Оно сэкономило мне {{value, number}} $ при первой покупке. Это произвело сильное впечатление!',
  landingReview3: 'Это полезное приложение, мне очень нравятся уведомления и бонусы.',
  landingReview4: 'Мне еще предстоит попробовать беспроблемные покупки!',
  landingReview5: 'Я сэкономил {{value, number}}%! Иначе бы я не узнал о распродаже!',
  landingReview6: 'Легкое отслеживание любимых товаров... Настоятельно рекомендую...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Сбросить мой пароль',
  repeatPassword: 'Повтор пароля',
  continueAsGuest: 'Продолжить в качестве гостя',
  viewAt: 'Посмотреть в {{- name}}',
  signUp: 'Зарегистрироваться',
  youMayAlsoLike: 'Вам также может понравиться',
  buyOnShop: 'Купить на',
  viewOn: 'Посмотреть на {{- name}}',
  quickView: 'Быстрый просмотр',
  newList: 'Новый список',
  undo: 'Отмена',
  productDeleted: 'Товар удален',
  linkCopiedToClipboard: 'ссылка скопирована в буфер обмена',
  wishlists: 'Списки пожеланий',
  headerPartnerships: 'Karma | Партнерства',
  letsJoinForces: 'Давайте объединим усилия',
  bePartOfTheSmartShoppingRevolution:
    'Станьте частью революции умных покупок. Мы познакомим с вашим брендом более {{number, number}} миллионов покупателей, готовых оформить заказ.',
  seeKarmaInAction: 'Посмотрите Karma в действии',
  discoverHowKarma:
    'Узнайте, как решения Karma на основе искусственного интеллекта и автоматизации улучшают весь процесс совершения покупок, привлекая целевой трафик к вашим предложениям.',
  stepUpYourSales: 'Наращивайте свои продажи',
  averageROAS: 'Средняя рентабельность<br/>затрат на рекламу',
  increaseInAOV: 'Увеличение средней<br/>суммы заказа',
  useKarmaToPlanTheirNextPurchase: 'Используйте Karma,<br/>чтобы спланировать их следующую покупку',
  meetOurShoppers: 'Познакомьтесь с нашими покупателями',
  meetOurShoppersDescription:
    'Воспользуйтесь нашими маркетинговыми решениями, ориентированными на эффективность, и произведите впечатление на покупателей, которые твердо намерены совершить покупку. Размещение нами рекламы на разных платформах позволяет вам обращаться к пользователям, где бы они ни находились.',
  audienceInfoFirstNumber: 'Более {{number, number}}{{unit}}',
  audienceInfoFirstTitle: 'пользователей',
  audienceInfoSecondNumber: 'Более {{number, number}}{{unit}}',
  audienceInfoSecondTitle: 'ежемесячных визитов',
  audienceInfoThirdNumber: 'Более {{number, number}}{{unit}}',
  audienceInfoThirdTitle: 'сохраненных товаров',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'пользователей в США',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'представителей поколения Z и миллениалы',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'женщин-покупателей',
  ourUsersLoveUs: 'Наши пользователи обожают нас!',
  partnershipsReview1:
    'Отличное расширение! Это отличное расширение, которое я использую в течение многих лет. Оно позволяет хранить множество товаров в одном месте. Это делает покупки в Интернете намного менее напряженными!',
  partnershipsReviewAuthor1: 'Милли Робинсон',
  partnershipsReview2:
    'Как человек, который ОЧЕНЬ МНОГО покупает в Интернете, я могу честно сказать, что Karma — лучший друг покупателей по многим причинам…',
  partnershipsReviewAuthor2: 'Сэди Мэй',
  partnershipsReview3:
    'Лучшее приложение для покупок в Play Store. Очень легкое в использовании. Все мои сохраненные товары из моих любимых магазинов в одном приложении! 👍🏻',
  partnershipsReviewAuthor3: 'Кимберли Роджерс',
  performanceBasis: 'На основе эффективности',
  fixedFee: 'Фиксированная плата',
  thanksForSubmitting: 'Спасибо за сообщение!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly:
    'Представитель нашей команды по работе с партнерами свяжется с вами в ближайшее время.',
  letsKickStartOurPartnership: 'Давайте станем партнерами!',
  emailOrPhoneNumber: 'Адрес эл. почты или номер телефона',
  retailerName: 'Имя/название розничного продавца',
  affiliateNetwork: 'Партнерская сеть',
  budgetStructure: 'Структура бюджета',
  budget: 'Бюджет',
  tellUsMore: 'Расскажите нам подробней',
  pleaseFillInAllRequiredFields: 'Заполните все обязательные поля',
  partnerships: 'Партнерства',
  weCannotSaveThisItem: 'Мы не можем сохранить этот товар,<1/> так как этот магазин не поддерживается.',
  theresCoupon: 'Есть купон!',
  yourItemIsOffWithCode: 'На ваш товар предоставляется скидка {{value, number}}% по коду {{code}} у {{- name}}',
  muteAllNotification: 'Отключить все уведомления',
  unMuteAllNotification: 'Включить все уведомления',
  notificationSettings: 'Настройки уведомлений',
  deleteNotification: 'Удалить уведомление',
  notificationDeleted: 'Уведомление удалено',
  itemMuted: 'Товар деактивирован',
  itemUnMuted: 'Товар активирован',
  priceDrop: 'Снижение цены',
  superDeal: '🔥 Супер сделка',
  notSureWhenToBuy: 'Не знаете, когда покупать? Создайте бесплатный аккаунт, и мы сообщим, когда начнется промоакция.',
  tcMayApply: 'Могут применяться условия и положения',
  earnKarmaCash: 'Заработайте до {{percent, number}}% Karma Cash.',
  saveToKarma: 'Сохранить товар в Karma',
  getKarmaToTrackItem: 'Отслеживать товар с помощью Karma',
  itemSaved: 'Товар сохранен!',
  youCanNowFindItem: 'Теперь вы можете найти свой товар в Панели управления Karma.',
  getKarmaExtension: 'Получите расширение Karma, чтобы <1/>отслеживать эту необходимую вещь',
  notifyYou: 'Мы сообщим, когда наступит лучшее время для покупки!',
  downloadTheExtension: 'Скачать расширение',
  outOfStock: 'Нет в наличии',
  invalidEmailError: 'Неверный адрес электронной почты',
  collapse: 'Свернуть',
  expand: 'Развернуть',
  lists: 'Списки',
  organizeYourItemsIntoWishListsWithJustAClick: 'Расположите свои товары в списках желаний одним щелчком мыши.',
  newItem: 'Новый товар',
  helpCenter: 'Справочный центр',
  priceHighToLow: 'Цена от высокой к низкой',
  priceLowToHigh: 'Цена от низкой к высокой',
  sale: 'Распродажа',
  filterBy: 'Фильтровать по:',
  karmaExclusive: 'Эксклюзив K',
  noItemsFound: 'Товары не найдены',
  select: 'Выбрать',
  likeSneakersOrBlackFriday: 'Например, «Кроссовки» или «Черная пятница»',
  updateList: 'Обновить список',
  firstName: 'Имя, фамилия',
  lastName: 'Фамилия',
  enterFirstName: 'Введите имя',
  enterLastName: 'Введите фамилию',
  recentSearches: 'Недавние поисковые запросы',
  addYourFavoriteStores: 'Добавьте любимые магазины,',
  toAccessTheirLatestCoupons: 'чтобы получить доступ к своим последним купонам',
  logosProvidedByClearbit: 'Логотипы, предоставленные Clearbit',
  dateOfBirth: 'Дата рождения',
  weHaveEmailedYouALinkToResetYourPassword: 'Мы отправили вам ссылку для сброса пароля.',
  didNotGetIt: 'Не получили ее?',
  profile: 'Профиль',
  promotionsAndActivity: 'Промоакции и мероприятия',
  couponsForFavoriteStores: 'Купоны для любимых магазинов',
  accountActivity: 'Действия с аккаунтом',
  powerShopper: 'Вы продвинутый покупатель? 💪',
  enterLinkToAnyItemPage: 'Введите ссылку на любую страницу товара',
  saveStuffWithZeroFuss: '<0>Получите расширение браузера</0> и сохраняйте товары без хлопот.',
  viewMore: 'Смотреть еще',
  tooHotToMiss: 'Слишком горячо, чтобы пропустить',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Присоединяйтесь к более чем {{number, number}} миллионам покупателей',
  waysToUse: 'Способы использования Karma',
  youCanUseKarma:
    'Вы можете использовать Karma по-разному, например, чтобы убедиться, что получаете лучшую цену в нужное время, а также для планирования очередных покупок. Вот некоторые примеры того, что мы рекомендуем:',
  livesInBrowser: 'Karma находится в браузере',
  browserExtension: 'Расширение для браузера Karma встретит вас повсюду, где вы совершаете покупки в Интернете.',
  helpsYouPlan: 'Помогает вам спланировать очередные покупки',
  notReadyToBuyJust:
    'Еще не готовы купить? Сохраните товар на потом с помощью расширения для браузера Karma и делайте покупки более осознанно. Вы можете следить за всеми сохраненными товарами в одном месте. ',
  automaticallyFindsCouponCodes: 'Автоматически находит коды купонов',
  findsAndAppliesCodes:
    'Karma автоматически находит и применяет лучший купон вместо вас, поэтому вы всегда будете знать, что делаете самую выгодную покупку.',
  soYouCanBuyAtTheRightTime: 'Вы сможете делать покупки в нужный момент',
  usesCleverTechnologies:
    'Karma использует интеллектуальные технологии, чтобы отслеживать снижение цен и повторное появление товаров на складе, чтобы вы могли сделать покупку в нужное время. ',
  notOnlySave:
    'Karma не только экономит ваши деньги, но и позволяет вам их зарабатывать! Никаких баллов. Никаких уловок. Вы зарабатываете реальные деньги всякий раз, когда делаете покупки.',
  makeImpact: 'Приносите пользу!',
  donate:
    'Делайте покупки, как обычно, и смотрите, как Karma делает пожертвования. При каждой покупке на сумму более {{over, number}} $ мы жертвуем {{dollars, number}} $ на выбранное вами доброе дело.',
  accessToOffers: 'Доступ к эксклюзивным предложениям',
  accessItems:
    'Получайте доступ к своим сохраненным товаром в одном месте, находите эксклюзивные предложения и узнавайте, что происходит в мире, на Karma.',
  hereIsHowItWorks: 'Вот как это работает',
  learnMoreAboutGives: 'Подробнее о Karma Gives',
  alwaysKnowWhenPricesDrop: 'Всегда знайте, когда падают цены',
  getNotifiedWhenBackInStock: 'Получайте уведомление, когда товар вновь появляется в продаже',
  findsYouBestCoupons: 'Находит вам лучшие купоны',
  getKarmaCashWhenYouShop: 'Получайте Karma Cash за покупки',
  easilySaveAllThings: 'Легко экономьте на всех ваших любимых товарах в более чем {{value, number}} тыс. магазинов',
  karmaProvidesGreatTechnology:
    'Karma предоставляет вам отличные технологии и гибкость, чтобы делать покупки умнее, и помогает вам избежать ненужных импульсивных покупок. Все это позволяет сэкономить время и деньги.',

  accountActivityAndImportantInformation: 'Активность в аккаунте и важная информация',
  addKarmaToYourDesktop: 'Добавьте Karma на свой рабочий стол, чтобы делать покупки<br/>в рабочее время.',
  addNumberItemsToList: 'Добавить {{value}} товара(-ов) в список',
  addOneItemToList: 'Добавить 1 товар в список',
  addStuffYouFindToThisListToKeepItOrganized:
    'Добавьте найденные товары в этот список, чтобы он остался упорядоченным.',
  alrightBut: 'Хорошо, но так вы будете очень боятся пропустить что-то важное!',
  areYouSureYouWantToDisconnectFromPaypal: 'Уверены, что хотите отключиться от Paypal?',
  balance: 'Баланс',
  buyWhateverYouWant: 'Покупайте, что хочешь, и не расстраивайте своего сотрудника в банке.',
  cashIsBack: '<1>Деньги</1> возвращаются',
  cashIsBackColoredRight: 'Деньги <1>возвращаются</1>',
  cashOutRewards: 'Вознаграждения за вывод средств',
  checkItOffAsYouBuy: 'Отмечайте галочкой после покупки',
  congratulationsOnCashout: 'Поздравляем! Теперь вы можете вывести свои вознаграждения.',
  connectToPayPalToCashOut: 'Подключите счет PayPal для вывода своих вознаграждений',
  connectYourPaypalAccountToCashOutRewards: 'Подключите счет PayPal для получения вознаграждений за вывод средств.',
  downloadOurApp: 'Загрузите наше приложение и возьмите с собой <1>Karma</1>',
  dropSomeShoppingInspo: 'Вдохновитесь свою семью на покупки.',
  earnKarmaCashShort: 'До {{percent, number}}% K Cash',
  earned: 'Заработано',
  enterYourPassword: 'Введите пароль',
  firstAddToList: 'Во-первых, добавьте в список',
  followOnLinkedin: 'Подпишитесь на нас в LinkedIn',
  freshOutCoupons: 'Свежие купоны',
  getBuzzedWhenYourWishes:
    'Получайте звуковое оповещение 🐝, когда ваши «желания» падают в цене или снова появляются в продаже.',
  getIt: 'Получите их',
  getTheApp: 'Получите приложение',
  gotIt: 'Понятно',
  gotStuck: 'Застряли? Нажмите <1>здесь</1>, чтобы двигаться дальше.',
  happyShoppers: '👉 Более {{count, number}} млн счастливых покупателей',
  howElseCanWeImproveKarma: 'Как еще мы можем улучшить Karma?',
  iNeedThisInMyLife: 'Мне это нужно в моей жизни',
  info: 'Информация',
  itemUpdatesUnsubscribe: 'Актуальная информация о товарах (падение цен, пополнение запасов и т.д.)',
  itsAGhostTown: 'Это город-призрак',
  joinTheTeam: 'Присоединяйтесь к команде',
  karmaGetsYouTheLowestPossiblePrice: 'Karma каждый раз дает вам самую низкую 👏возможную👏 цену.',
  karmaIsAnApp:
    'Karma — это приложение и расширение, которое поможет вам сэкономить время и деньги во время покупок в Интернете.',
  karmaYourFavoriteBrands: '<1>Karma</1> ваши любимые бренды',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> ваши любимые бренды',
  knowHowToShopBetter: 'Узнайте, как <1>делать покупки лучше</1>, чем все остальные',
  letsPlay: 'Давайте поиграем',
  lolWereAVerbNow: '(Лол, мы теперь глагол)',
  lookAtYouLittleScroller: 'Взгляните на себя, любитель скроллинга. Респект вам за то, что зашли так далеко.',
  lookAtYouLittleScrollerMobile: 'Взгляните на себя, любитель скроллинга.',
  lookingForInfluencer: 'Ищете возможности для рекламного партнерства?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Отметьте товары, которые вы приобрели. Он так хорошо справляется!',
  markNumberItemsAsFullfilled: 'Вы собираетесь пометить {{value}} позиции(-ий) как выполненные',
  meetYouAtIn: 'Встретимся в {{retailer}} через 3 секунды...',
  missOut: 'не упускайте',
  missingYouAlready: 'Уже скучаю по вам!',
  money: 'денег',
  never: 'Никогда',
  nevermind: 'Не обращайте внимания',
  noFomoHere:
    'Никакого страха упустить, что-то важное. Мы сигнализируем вам, когда ваши «пожелания» упадут в цене или снова появятся на складе.',
  noNotificationsYet: 'У вас актуальные данные',
  noNotificationsYet2: 'Как только цены упадут или появятся новые, вы найдете их здесь.',
  nowLetsTalPrivacy: 'Теперь давайте поговорим о <1>конфиденциальности<1/>',
  nowLetsTalkAboutPrivacy: 'Теперь давайте поговорим о конфиденциальности 🤫',
  onlineShoppingButBetter: 'Онлайн-покупки, но <1>лучше</1>',
  orCopyTheLinkBelowAndSend: 'Или скопируйте ссылку ниже и отправьте ее членам семьи в старом стиле.',
  other: 'Другое',
  passwordValidationNote:
    'Должен содержать не менее 8 символов и включать минимум одну заглавную букву, строчную букву и цифру.',
  payLess: 'Платите меньше',
  playFavesWithYourItems: 'Добавляйте свои товары в избранное',
  preparingYourKarmaCash: 'Готовим Karma к оплате.',
  savingMoneyIsHotLetsGetOnThat: 'Экономить деньги — это здорово. Давайте займёмся этим ✌️',
  search: 'Поиск',
  seeHowToSave: 'Узнайте, как сэкономить',
  skipForNow: 'Пропустить сейчас',
  soFarWeSavedYou: 'До данного момента мы сэкономили вам',
  sorryForInconvenience: 'Извините за неудобства — обещаем, что оно того стоит.',
  starReviews: '👉 Более {{count, number}} тыс. отзывов со средней оценкой 4,7',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Отмечайте звездочкой все, что вам нужно в жизни, для быстрого доступа.',
  stillWannaUnsubscribe: 'Все еще хотите <1>отписаться от всех эл. писем Karma</1>?',
  storePromotionsAndOtherMarketingEmails: 'Промоакции магазинов и другие маркетинговые эл. письма',
  storesWorldwide: '👉 Более {{count, number}} тыс. магазинов по всему миру',
  stuffWorthSavingGoesHere: 'Товары, которые стоит сохранить, отправляются сюда 👇',
  sureYouWantToDeleteTheseNumberItems: 'Уверены, что хотите удалить эти {{value}} товара(-ов)?',
  sureYouWantToDeleteThisList: 'Уверены, что хотите удалить этот список?',
  takeControlOfYourInbox: 'Возьмите под контроль свой почтовый ящик',
  theMotherOfAllWishLists: 'Лучший из всех <1>списков желаний<1/>',
  thisFieldIsRequired: 'Это поле обязательно для заполнения',
  toCashOutRewards: 'Чтобы получить бонусы, вам нужно достичь минимума в ${{minimum, number}}.',
  toCashOutYouMustReach: 'Чтобы получить бонусы, вам нужно достичь минимальной суммы ${{currency}}{{value, number}}.',
  tryAgain: 'Попробуйте еще раз',
  trySearchingForSomethingElse: 'Попробуйте поискать что-то другое.',
  updatePreferences: 'Обновить настройки',
  wantIt: 'Хочу это',
  weAreBusySelfImproving: 'Мы заняты самосовершенствованием',
  weAreVerySeriousAboutPrivacy:
    'Мы очень серьезно относимся к конфиденциальности и никогда не будем продавать ваши данные третьим лицам. Никогда.',
  weDontSellYourData: 'Мы не продаем ваши данные',
  weGotStuckOnOurWay: 'Мы застряли на пути к {{retailer}}!',
  weHitYouUp: 'Мы свяжемся с вами, когда найдем промокод для этого магазина.',
  weHustleForCoupons: 'Мы охотимся за купонами',
  weUseBankLevelSecurity: 'Мы используем меры безопасности банковского уровня',
  wellApplyCodesThatSaveYouMoney: 'Мы применим коды, которые помогут вам сэкономить $$$.',
  wellTrackAndScanForCoupons:
    'Мы будем отслеживать и сканировать купоны, чтобы вам не пришлось делать это.  <br/> (Хорошо, мы не будем, но наш ИИ будет)',
  wellTrackAndScanForCouponsMobile: 'Мы будем отслеживать и сканировать купоны, чтобы вам не пришлось делать это.',
  wereAVerbNowLol: '(теперь мы глагол ЛОЛ)',
  wereGoingShopping: 'Мы идем за покупками',
  wereHereToMakeItYours: 'Мы здесь, чтобы <1>#сделать_его_вашим </1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'Зачем отписываться от всех писем с экономией денег, если можно отключить только те, которые неактуальны?',
  withKarmaYouCanBuy:
    'С помощью Karma вы можете купить <br />все, что захотите, и не расстроить <br /> вашего менеджера в банке.',
  withKarmaYourCash:
    'Благодаря Karma ваши деньги возвращаются к вам. Это волшебные деньги, как будто заколдованные магией вуду.',
  yesDisconnect: 'Да, отключиться',
  yesIDoThat: 'Да, я так делаю',
  youHaveToReconnectToWithdraw: 'Чтобы вывести Karma Cash, вам нужно будет подключиться заново.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Вам нужно сохранить товары, прежде чем вы сможете поделиться списком.',
  youReATen:
    'У вас 10, и вы платите с помощью Karma? Покупайте онлайн и получайте 👏каждый👏 раз получайте самую низкую возможную цену.',
  youReAboutToMuteNumberItems: 'Вы собираетесь отключить уведомления для {{value}} товаров',
  yourCashWillComeBackToYou: 'Ваши деньги вернутся к вам. Это волшебные деньги, как будто заколдованные магией вуду. ',
  yourInformationIsSecured: 'Ваша информация полностью защищена с помощью новейшего 256-битного шифрования.',
  yourSearchCameUpEmpty: 'Ваш поиск не принес результатов',
  total: 'Итого',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'Следите за обновлениями статуса, которые мы отправим на {{email}}.',
  psaDidYouKnow: 'PSA: Знаете ли вы, что вы можете',
  addWishItemsFromAnyBrand:
    'Добавьте «желаемые» товары любого бренда и ждите, пока мы не скажем, что пришло время купить их.',
  capturePurchaseReadyShoppers:
    'Привлеките покупателей, готовых к покупке, продемонстрировав свои самые популярные купоны.',
  gainFullFunnelInsights:
    'Получайте во всех каналах представление о намерениях покупателя при каждом сохранении товара',
  boostCartEngagement:
    'Повысьте привлекательность корзины с помощью наших новостей о товарах в режиме реального времени.',
  promoteLongTermGrowth:
    'Содействуйте долгосрочному росту с помощью адаптированных кампаний в рамках экосистемы Karma.',
  reachNewAudiences: 'Охватите новые аудитории с помощью различных кампаний, адаптированных к вашему бренду',
  influencerCollaborations: 'Сотрудничество с инфлюэнсерами',
  teamUpWithTopInfluences:
    'Объединитесь с лучшими инфлюэнсерами, которые лучше всего подходят к идентичности вашего бренда.',
  socialPosts: 'Посты в соцсетях',
  giveANewFollowing: 'Привлеките подписчиков в ходе заказных, богатых контентом кампаний.',
  brandFocusedNewsletters: 'Информационные бюллетени, ориентированные на бренд',
  letYourOffersShine: 'Пусть ваши предложения выделяются в долгожданных курируемых эл. письмах.',
  driveTrafficWhenYouNeedIt:
    'Привлекайте трафик туда, где вам нужно, с помощью размещения товаров на нашей главной странице, доступных для покупок',
  dashboardBanners: 'Баннеры инфопанели',
  flauntYourLatestOfferings:
    'Продемонстрируйте свои последние предложения с помощью привлекающих внимание визуальных эффектов.',
  dailyFeatured: 'Ежедневные рекомендуемые',
  highlightDeals: 'Выделите предложения там, где это имеет наибольшее значение.',
  dedicatedPopUps: 'Целевые всплывающие окна',
  activelyServeYourPromos: 'Активно подавайте промоакции, чтобы их нельзя было пропустить.',
  createAnotherTouchpointForYourBrand: 'Создайте еще одну точку соприкосновения для своего бренда',
  increaseEngagement:
    'Повышайте уровень вовлеченности и конверсии с помощью целевых push-уведомлений на мобильных устройствах и в Интернете.',
  karmaIsOnlineShoppingButBetter: 'Karma — это онлайн-шопинг, но лучше.',
  transactionsNumber: '👉 Более {{count, number}} млн выгодных предложений',
  partnershipsReview4: 'Это потрясающее средство создания списков желаний и экономии денег!',
  partnershipsReviewAuthor4: 'Эмили Смит',
  partnershipsReview5:
    'Это буквально лучшее расширение, которое я когда-либо скачивала. Это лучший способ сэкономить деньги и одновременно получить их!',
  partnershipsReviewAuthor5: 'Селена Роблокс',
  noNeedToSlideIntoOurDMs:
    'Нет необходимости искать нас в мессенджерах (хотя вы могли бы😏). Наша команда готова помочь во всем, что вам нужно.',
  supportIssues: 'Вопросы поддержки',
  adsAndPartnerships: 'Реклама и партнерство',
  jobOpportunities: 'Возможности трудоустройства',
  publicRelations: 'Связи с общественностью',
  letsChat: 'Давайте пообщаемся в чате',
  earnings: 'Доходы',
  allTime: 'За все время',
  ordersMayTakeHoursToAppear: 'Появление заказов может занять до {{value, number}} часов.',
  youHaventReceivedAnyPayoutsYet: 'Вы еще не получили ни одной выплаты ',
  alerts: 'Оповещения',
  featuresReview1:
    'Я добавила кроссовки в свой список желаний и буквально на следующий день получила оповещение о скидке 50%. Как я вообще покупала что-либо до того, как нашла вас, ребята?',
  featuresReviewAuthor1: 'Сандра К. Мартинес, автор отзыва на App Store',
  featuresReview2:
    'Это действительно лучшее приложение для умных покупок, а я знаю и несколько других. Мне кажется, что я немного обманываю систему, потому что экономлю настоящие деньги! Раньше я всегда платил полностью и чувствовал себя полным лохом',
  featuresReviewAuthor2: 'Герти Д. Акерман, автор отзыва на App Store',
  featuresReview3:
    'Я покупала новый ноутбук со скидкой 25%. Когда я нажала «Оплатить с Karma», я заметил, что общая сумма к оплате стала еще меньше. Это происходит каждый раз, когда я плачу с помощью приложения',
  featuresReviewAuthor3: 'Ирэн Дж. Кокс, автор отзыва на App Store',
  downloadNow: 'Скачать сейчас',
  wannaBeDeskBuddies: 'Хотите вкусить плоды нашего успеха?',
  seeOpenPositions: 'Посмотрите вакансии',
  weLiveAndSwearBy: 'Мы живем и безгранично верим в...',
  goodKarma: 'Хорошую Karma (карму)',
  weBelieveThatGoodVibes: 'Мы верим, что ~хорошие вибрации~ — это образ жизни, и мы придерживаемся его.',
  beingTrue: 'Справедливость',
  theresNoUSvsU: 'Нет НАС против ВАС. Наши столы всегда круглые, и мы оставляем вам место за ними.',
  passion: 'Страстное увлечение',
  weHaveThatFireYouKnow: 'У нас пожар, понимаете? Мы работаем с драйвом, настойчивостью и любовью к тому, что делаем.',
  entrepreneurship: 'Предпринимательство',
  weWantItWeGetIt:
    'Если мы чего-то хотим, мы этого добиваемся. У нас нет времени на бесконечные встречи в Zoom. Мы стремимся к тому, чего хотим.',
  mutuality: 'Взаимность',
  wereMadeUpOfAwesomePeople:
    'Наш коллектив состоит из потрясающих людей, которые понимают, что командной работы недостаточно. Мы действительно дорожим друг другом.',
  buildCustomerLoyalty: 'Повышайте лояльность клиентов с помощью мгновенного кешбэка',
  itemSaving: 'Сохранение товара',
  itemUpdates: 'Новости о товаре',
  backInStockAlerts: 'Снова в наличии',
  lowInStockAlerts: 'Запасы на исходе',
  storePromotions: 'Акции в магазине',
  exclusive: 'эксклюзив',
  couponCopied: 'Купон скопирован',
  completedDesc:
    'Магазин подтвердил ваш заказ. Любые заработанные Karma Cash могут быть выведены в течение {{days, number}} дней с даты покупки и только после того, как вы достигнете минимума в {{amount, number}} {{currency}}.',
  notQualifiedDesc: 'К заказанным товарам могут применяться дополнительные ограничения.',
  pendingDesc:
    'Магазин получил ваш заказ. Он может отображаться как ожидающий до {{days, number}} дней, в зависимости от политики возврата магазина.',
  cancelledDesc: 'Магазин сообщил нам, что ваш заказ был либо отменен, либо возвращен в полном объеме.',
  downloadNowItsFree: 'Скачайте сейчас, это бесплатно',
  weLiveToMakeOurShoppersHappy: 'Мы живем ради того, чтобы сделать наших покупателей счастливыми',
  moneySavedForYou: 'Деньги, сэкономленные для вас',
  happyShoppers2: 'Счастливые покупатели',
  starReviews2: '{{value, number}}-звездочные отзывы',
  storesWorldwide2: 'Магазины по всему миру',
  whyYouWill: 'Почему вы',
  it: 'it',
  multiBrandWishLists:
    'Мультибрендовые списки желаний? Реально круто. Находите то, что вам нравится, среди товаров любого бренда, добавляйте это в свой список Karma и ждите, пока мы не скажем, что пора купить товар.',
  addKarmaToYourMobile: 'Загрузите<br />наше приложение<br />и возьмите<br />Karma с собой в путь',
  appStoreReview1:
    'Я добавила палитру теней для век в свой список и на следующий день получила уведомление, что она предлагается со скидкой 50%. Спасибо!!',
  appStoreReview2: 'Тот факт, что самая низкая цена всегда у меня в кармане, очень опасен. Но он мне также нравится',
  appStoreReview3:
    'Только что получил самую низкую цену на новые наушники, пока ждал автобуса. Это просто слишком просто',
  appStoreReviewBy: 'Отзыв в App Store от: {{name}}',
  yourInfoSafeWithUs: 'Ваша информация в безопасности у нас',
  whyGiveOutYourCCDeets: 'Зачем раздавать документы направо и налево, если можно хранить их в одном месте?',
  partyForOne: 'Вечеринка для одного',
  wellNeverSellYourDataToAnyThirdParties:
    'Мы никогда не будем продавать ваши данные третьим лицам, и точка. Благодаря банковской безопасности ваши данные защищены от посторонних глаз.',
  buyWithoutATrace: 'Покупайте без следов',
  weWontLetAnyoneTrackYourShoppingHabits: 'Мы не позволим никому отслеживать ваши привычки. Мы вовсе вас не осуждаем.',
  spotSomethingYouLoveFromAnyBrand:
    'Находите то, что вам нравится, среди товаров любого бренда, добавляйте это в свой список Karma и ждите, пока мы не скажем, что пора купить товар.',
  needIt: 'Нужно это',
  appStoreReviewer: 'Автор отзыва на App Store',
  appStoreReview4:
    'Я так полагаюсь на это приложение, которое отслеживает все мои «желаемые» товары в одном месте, чтобы я смог купить их по наилучшей цене.',
  appStoreReview5: 'Это приложение помогло мне сэкономить кучу денег.',
  wishlist: 'Список желаний',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Получите самые низкие цены на все ваши любимые бренды.',
  getTheAppForFree: 'Получите приложение бесплатно',
  addKarmaSpaced: 'Добавьте Karma<br />на свой ПК,<br />чтобы делать покупки<br />в рабочее время.',
  spotSomethingYouLoveFromAnywhere:
    'Находите то, что вам нравится, откуда угодно, добавляйте это в свой список Karma и тусуйтесь, пока мы не скажем, что пора покупать.',
  getCouponsCashbackWithPeriod:
    'Получайте купоны, кешбэк и оповещения о снижении цен.<br />Всегда платите самую низкую цену.',
  getCouponsCashbackWithAnd:
    'Получайте купоны, кешбэк, оповещения о снижении цен<br />и всегда платите самую низкую цену.',
  clickTheLoginLinkFromYourPhone: 'Нажмите на ссылку входа с телефона, чтобы получить доступ к приложению Karma.',
  getItOn: 'Получите его на',
  downloadOnThe: 'Скачайте на',
  likeShoppingFromYourComputer: 'Нравится делать покупки с компьютера?',
  getTheKarmaExtensionToShopEvenBetter: 'Получите расширение Karma, чтобы делать покупки еще лучше.',
  headerMobileLogin: 'Karma | Установить',
  noFomoHereMobile:
    'Никакого страха упустить, что-то важное. Мы сигнализируем вам, когда ваши «желания» упадут в цене или снова появятся на складе.',
  withKarmaYourCashMobile:
    'Благодаря Karma ваши деньги возвращаются к вам. Это волшебные деньги, как будто заколдованные магией вуду.',
  spotSomethigYouLoveMobile:
    'Находите то, что вам нравится, откуда угодно, добавляйте это в свой список Karma и тусуйтесь, пока мы не скажем, что пора покупать.',
  wellTrackAndScanForCouponsMobile2:
    'Мы будем отслеживать и сканировать купоны, чтобы вам не пришлось делать это. (Хорошо, мы не будем, но наш ИИ будет)',
  weHustle: 'Мы активно ищем',
  forCoupons: '<1>купоны</1>',
  theMotherOfAll: 'Лучший из всех',
  wishlists2: 'списков желаний',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by instantly comparing products from different retailers.',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOnSafari: 'Enable Karma on Safari to access our coupon scanner',
  alwaysAllowOnSites: 'Always allow on all sites to get access to coupons',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} {{text}}',
  extension: 'extension',
  howToEnable: 'How to enable',
  howToGetCoupons: 'How to get coupons',
  sweOnboardingStep1_1: 'Tap the <1>A</1><2>A</2> in the URL bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the URL bar',
  sweOnboardingStep1_2: 'Select “Manage Extensions”',
  sweOnboardingStep1_3: 'Toggle on',
  sweOnboardingStep2_1: 'Tap the <1>A</1><2>A</2> in the URL bar again',
  sweOnboardingStep2_2: 'Tap the',
  sweOnboardingStep2_3: 'Select “Always allow”',
  sweOnboardingStep2_4: 'Select “Always allow on every website”',

  sweOnboardingStep2_5_1: 'Go back to the',
  sweOnboardingStep2_5_2: 'Karma app',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  makeYourInspo: 'Make your inspo\na reality',
  easilyBringYourFavorite:
    'Easily bring your favorite social media looks\ninto your life, just share the posts to Karma and\nour AI will scan the image and find the items\nthat match.',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  couponsHandled: 'Coupons? Handled',
  skipCouponHunt: 'Skip the coupon hunt. We’ll automatically apply the best discounts for you at checkout.',
  smartAlerts: 'Smart alerts for your wishlist',
  saveItemsGetAlerted: 'Save items and get alerted when prices drop or items are back in stock.',
  cashbackMadeSimple: 'Cashback made simple',
  earnMoneyBack: 'Earn money back on your purchases, turning shopping into savings.',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  theHardPartIsOverNowLetsMakeTheMostOfYourShopping: "The hard part's over — now let’s make the most of your shopping!",
  goToYourFavoriteRetailerToSeeKarmaInAction: 'Go to your favorite retailer to see Karma in action.',
  howToSaveItemsWithKarma: 'How to Save Items with Karma',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitThe:
    'Just go to any product page on your favorite site and hit the bookmark icon next to the "K".',
  peekAtYourWishlistAnytime: 'Peek at Your Wishlist Anytime',
  tapTheKAndHeadToTheMyKarmaTabToSeeYourPicks: 'Tap the "K" and head to the “My Karma” tab to see your picks.',
  watchOurCouponScannerInAction: 'Watch Our Coupon Scanner in Action',
  atCheckoutSeeOurCouponScannerTryOutEveryCodeWeveFoundForYou:
    'At checkout, see our coupon scanner try out every code we’ve found for you.',
  hereToHelpNotToHinder: 'Here to Help, Not to Hinder',
  wantACleanerLook: 'Want a cleaner look? Easily hide any feature by turning it off\nfrom your settings.',
  takeKarmaWithYou: 'Take Karma with You',
  downloadTheKarmaAppToBuildYourWishlistAndReceiveAlertsWhereverYouAre:
    'Download the Karma app to build your wishlist and receive alerts wherever you are. Never miss a beat!',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitTheSaveButtonInTheKarmaExtension:
    'Just go to any product page on your favorite site and hit the save button in the Karma extension.',
  tapTheKAndHeadToTheSavedTabToSeeYourPicks: 'Tap the “K" and head to the “Saved” tab to see your picks.',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
};
