import { createSlice } from '@reduxjs/toolkit';
import { COUPONS_GROUPS } from 'consts/couponsGroups';

export const initialState = {
  [COUPONS_GROUPS.popular]: {
    coupons: [],
    loaded: false,
    page: 1,
    isFetching: false,
    couponsLeft: true,
    isDirty: false,
  },
  [COUPONS_GROUPS.newest]: {
    coupons: [],
    loaded: false,
    page: 1,
    isFetching: false,
    couponsLeft: true,
    isDirty: false,
  },
  [COUPONS_GROUPS.custom]: {
    coupons: [],
    loaded: false,
    page: 1,
    isFetching: false,
    couponsLeft: true,
    isDirty: false,
  },
  [COUPONS_GROUPS.mixed]: {
    coupons: [],
    loaded: false,
    page: 1,
    isFetching: false,
    couponsLeft: true,
    isDirty: false,
  },
  mixedForRetailer: {
    coupons: [],
    loaded: false,
    page: 1,
    isFetching: false,
    couponsLeft: true,
    isDirty: false,
  },
};

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    fetchCouponsGroup: (state, { payload: { group } }) => ({
      ...state,
      [group]: {
        ...state[group],
        isFetching: true,
      },
    }),
    receiveCouponsGroup: (state, { payload: { group, coupons, page, couponsLeft, isDirty } }) => ({
      ...state,
      [group]: {
        coupons: page === 1 ? coupons : [...state[group].coupons, ...coupons],
        loaded: true,
        page,
        isFetching: false,
        couponsLeft,
        isDirty,
      },
    }),
    resetCouponsGroup: (state, action) => {
      return {
        ...state,
        [action.payload]: {
          ...initialState[action.payload],
        },
      };
    },
  },
});

export default couponsSlice.reducer;
