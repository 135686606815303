import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as userActions from '../actions';
import fetchUser from './worker.fetchUser';
import saveUser from './worker.saveUser';
import updateUser from './worker.updateUser';
import unsubscribePP from './worker.unsubscribePP';
import unsubscribe from './worker.unsubscribe';
import loginAsUser from './worker.loginAsUser';
import updateDonationCauseWorker from './worker.updateDonationCause';
import loginWithTokenHandler from './worker.loginWithToken';
import initDemoFlowHandler from './worker.initDemoFlow';
import deleteAccount from './worker.deleteAccount';

export default function* () {
  yield all([
    takeLatest(userActions.saveUser.type, saveUser),
    takeLatest(userActions.fetchUser.type, fetchUser),
    takeLatest(userActions.initDemoFlow.type, initDemoFlowHandler),
    takeEvery(userActions.updateUser.type, updateUser),
    takeLatest(userActions.unsubscribePP.type, unsubscribePP),
    takeLatest(userActions.unsubscribe.type, unsubscribe),
    takeLatest(userActions.loginAsUser.type, loginAsUser),
    takeLatest(userActions.loginWithToken.type, loginWithTokenHandler),
    takeLatest(userActions.updateDonationCause, updateDonationCauseWorker),
    takeLatest(userActions.deleteAccount, deleteAccount),
  ]);
}
