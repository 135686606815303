import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ImageIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15039 3.00002C1.15039 1.9783 1.97866 1.15002 3.00039 1.15002H21.0004C22.0221 1.15002 22.8504 1.9783 22.8504 3.00002V21C22.8504 22.0218 22.0221 22.85 21.0004 22.85H3.00039C1.97866 22.85 1.15039 22.0218 1.15039 21V3.00002ZM3.00039 2.85002C2.91755 2.85002 2.85039 2.91718 2.85039 3.00002V21C2.85039 21.0829 2.91755 21.15 3.00039 21.15H21.0004C21.0832 21.15 21.1504 21.0829 21.1504 21V3.00002C21.1504 2.91718 21.0832 2.85002 21.0004 2.85002H3.00039Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89218 11.2889L2.64882 17.4798L1.35156 16.3811L7.08008 9.61734C7.38794 9.25384 7.92421 9.18972 8.3091 9.47036C10.3359 10.9482 12.0593 12.7982 13.389 14.9187L16.1524 12.4804C16.4211 12.2433 16.808 12.1965 17.1255 12.3626C19.2039 13.4502 20.9818 15.0339 22.3015 16.9734L22.7029 17.5634L21.2974 18.5198L20.896 17.9297C19.8407 16.3788 18.4527 15.0862 16.836 14.1444L12.9573 17.5668L12.3629 16.5167C11.2218 14.5007 9.70308 12.7262 7.89218 11.2889Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7573 5.97502C15.9803 5.97502 15.3504 6.60494 15.3504 7.38197C15.3504 8.159 15.9803 8.78891 16.7573 8.78891C17.5344 8.78891 18.1643 8.159 18.1643 7.38197C18.1643 6.60494 17.5344 5.97502 16.7573 5.97502ZM13.6504 7.38197C13.6504 5.66605 15.0414 4.27502 16.7573 4.27502C18.4733 4.27502 19.8643 5.66605 19.8643 7.38197C19.8643 9.09789 18.4733 10.4889 16.7573 10.4889C15.0414 10.4889 13.6504 9.09789 13.6504 7.38197Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ImageIcon;
