import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAction from 'hooks/useAction';
import useQueryParams from 'hooks/useQueryParams';
import setUpUserLanguage from 'functions/language';
import * as userActions from 'store/user/actions';
import { getLoaded, getUserId } from 'store/user/selectors';

const useSetLanguageFromQueryParam = () => {
  const { lg } = useQueryParams();
  const updateUser = useAction(userActions.updateUser);
  const isLoaded = useSelector(getLoaded);
  const isUserAuthorized = Boolean(useSelector(getUserId));

  useEffect(() => {
    if (isLoaded && isUserAuthorized && lg) {
      updateUser({ language: lg });
    }

    if (isLoaded && !isUserAuthorized && lg) {
      setUpUserLanguage(lg);
    }
  }, [isLoaded, isUserAuthorized, lg, updateUser]);
};

export default useSetLanguageFromQueryParam;
