import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  entities: {},
  ids: [],
  loaded: false,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    receiveCategories: (state, action) => {
      const { entities, ids } = action.payload;

      return {
        ...state,
        entities,
        ids,
        loaded: true,
      };
    },
  },
});

export default categoriesSlice.reducer;
