import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const LocationIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.04998C7.83025 2.04998 4.45 5.43023 4.45 9.59998C4.45 10.564 4.84177 11.8091 5.52814 13.1957C6.20514 14.5633 7.12711 15.9865 8.092 17.2819C9.05617 18.5765 10.0483 19.7236 10.8539 20.5395C11.2584 20.9492 11.6029 21.2621 11.864 21.4661C11.915 21.506 11.9603 21.5397 12 21.5679C12.0397 21.5397 12.085 21.506 12.136 21.4661C12.3971 21.2621 12.7416 20.9492 13.1461 20.5395C13.9517 19.7236 14.9438 18.5765 15.908 17.2819C16.8729 15.9865 17.7949 14.5633 18.4719 13.1957C19.1582 11.8091 19.55 10.564 19.55 9.59998C19.55 5.43023 16.1697 2.04998 12 2.04998ZM11.814 21.6821C11.8141 21.6818 11.8185 21.6797 11.8269 21.6766C11.818 21.6809 11.8139 21.6825 11.814 21.6821ZM12.1731 21.6766C12.1815 21.6797 12.1859 21.6818 12.186 21.6821C12.1862 21.6825 12.182 21.6809 12.1731 21.6766ZM2.75 9.59998C2.75 4.49134 6.89137 0.349976 12 0.349976C17.1086 0.349976 21.25 4.49134 21.25 9.59998C21.25 10.9555 20.7217 12.4827 19.9954 13.9499C19.2598 15.4359 18.2766 16.9478 17.2714 18.2974C16.2655 19.648 15.2226 20.856 14.3558 21.7339C13.9241 22.1712 13.5229 22.5399 13.1826 22.8058C13.0137 22.9377 12.8427 23.0579 12.6782 23.1492C12.546 23.2226 12.2946 23.35 12 23.35C11.7054 23.35 11.454 23.2226 11.3218 23.1492C11.1573 23.0579 10.9863 22.9377 10.8174 22.8058C10.4771 22.5399 10.0759 22.1712 9.64423 21.7339C8.77744 20.856 7.73454 19.648 6.72861 18.2974C5.7234 16.9478 4.74021 15.4359 4.00459 13.9499C3.27833 12.4827 2.75 10.9555 2.75 9.59998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 6.7C10.813 6.7 9.85039 7.66259 9.85039 8.85C9.85039 10.0374 10.813 11 12.0004 11C13.1878 11 14.1504 10.0374 14.1504 8.85C14.1504 7.66259 13.1878 6.7 12.0004 6.7ZM8.15039 8.85C8.15039 6.7237 9.87409 5 12.0004 5C14.1267 5 15.8504 6.7237 15.8504 8.85C15.8504 10.9763 14.1267 12.7 12.0004 12.7C9.87409 12.7 8.15039 10.9763 8.15039 8.85Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LocationIcon;
