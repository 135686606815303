import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import { getMainGroupTags, getMainGroupTotalCount } from 'store/myItems/store/selectors';

export const getMultiEditEntity = ({ multiEdit }) => multiEdit;

export const getMultiEditStatus = createSelector([getMultiEditEntity], ({ all, included }) => {
  return all || !!included.length;
});

export const getTagStatus = createSelector([getMultiEditEntity], ({ all, excluded, included }) =>
  memoize(id => {
    return all ? !excluded.includes(id) : included.includes(id);
  }),
);

export const getHeadTag = createSelector(
  [getMultiEditEntity, getMainGroupTags],
  ({ all, excluded, included }, tags) => {
    return all ? tags.find(({ id }) => !excluded.includes(id)) : tags.find(({ id }) => included.includes(id));
  },
);

export const getSelectedTagsCount = createSelector(
  [getMultiEditEntity, getMainGroupTotalCount],
  ({ all, excluded, included }, totalCount) => {
    return all ? totalCount - excluded.length : included.length;
  },
);
