import { all, takeEvery } from 'redux-saga/effects';
import { ENTITIES } from 'consts/wallet';
import { QUERY_PARAMS } from 'consts/queryParams';
import { getEarnings, getPayouts } from '../api';
import {
  fetchEarnings,
  fetchPayouts,
  fetchMoreEarnings,
  fetchMorePayouts,
  saveEarnings,
  savePayouts,
  saveMoreEarnings,
  saveMorePayouts,
  setEarningsFilter,
  setPayoutsFilter,
} from '../actions';
import { getEarningsFilter, getPayoutsFilter, getEarningsPage, getPayoutsPage } from '../selectors';
import fetchWorker from './worker.fetch';
import fetchMoreWorker from './worker.fetchMore';
import setFilterWorker from './worker.setFilter';

function* walletSaga() {
  yield all([
    takeEvery(fetchEarnings, fetchWorker, {
      entity: ENTITIES.earnings,
      filterSelector: getEarningsFilter,
      getRequest: getEarnings,
      isJavaRequest: true,
      saveAction: saveEarnings,
    }),

    takeEvery(fetchPayouts, fetchWorker, {
      entity: ENTITIES.payouts,
      filterSelector: getPayoutsFilter,
      getRequest: getPayouts,
      isJavaRequest: false,
      saveAction: savePayouts,
    }),

    takeEvery(fetchMoreEarnings, fetchMoreWorker, {
      entity: ENTITIES.earnings,
      filterSelector: getEarningsFilter,
      getMoreRequest: getEarnings,
      isJavaRequest: true,
      pageSelector: getEarningsPage,
      saveMoreAction: saveMoreEarnings,
    }),

    takeEvery(fetchMorePayouts, fetchMoreWorker, {
      entity: ENTITIES.payouts,
      filterSelector: getPayoutsFilter,
      getMoreRequest: getPayouts,
      isJavaRequest: false,
      pageSelector: getPayoutsPage,
      saveMoreAction: saveMorePayouts,
    }),

    takeEvery(setEarningsFilter, setFilterWorker, {
      elementId: ENTITIES.earnings,
      fetchAction: fetchEarnings,
      queryParam: QUERY_PARAMS.filter.earnings,
    }),

    takeEvery(setPayoutsFilter, setFilterWorker, {
      elementId: ENTITIES.payouts,
      fetchAction: fetchPayouts,
      queryParam: QUERY_PARAMS.filter.payouts,
    }),
  ]);
}

export default walletSaga;
