import { all, takeEvery } from 'redux-saga/effects';
import * as allCouponsActions from '../actions';
import fetchAllCoupons from './worker.fetchAllCoupons';
import fetchLiveCoupons from './worker.fetchLiveCoupons';

function* allCouponsSaga() {
  yield all([
    takeEvery([allCouponsActions.fetchAllCoupons.type], fetchAllCoupons),
    takeEvery([allCouponsActions.fetchLiveCoupons.type], fetchLiveCoupons),
  ]);
}

export default allCouponsSaga;
