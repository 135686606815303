export const handleErrorFromServer = error => {
  const errors = error?.response?.data?.error;

  if (errors) {
    const serverError = errors[0];

    throw serverError;
  }

  return null;
};
