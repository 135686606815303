import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const StoreIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 21 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39108 1.54101C3.67474 1.09975 4.16332 0.833008 4.68789 0.833008H16.278C16.8026 0.833008 17.2911 1.09975 17.5748 1.54101L19.5933 4.68097C20.6371 6.30467 20.3431 8.44666 18.9004 9.72906C18.706 9.9019 18.4956 10.0461 18.2746 10.1619V17.4997H19.6496V18.9163H1.31627V17.4997H2.69127V10.1619C2.47025 10.0461 2.25988 9.9019 2.06543 9.72906C0.622731 8.44666 0.328721 6.30467 1.37253 4.68097L3.39108 1.54101L3.98691 1.92404L3.39108 1.54101ZM4.10793 10.5161V17.4997H16.8579V10.5161C16.0493 10.5231 15.2402 10.217 14.6267 9.60347L13.5719 8.54872L13.3078 9.03926C12.0964 11.2889 8.86946 11.2889 7.65809 9.03926L7.39395 8.54872L6.33921 9.60347C5.72568 10.217 4.91658 10.5231 4.10793 10.5161ZM4.68789 2.24967C4.64536 2.24967 4.60575 2.2713 4.58275 2.30708L2.5642 5.44704C1.89773 6.48377 2.08546 7.85142 3.00662 8.67023C3.67918 9.26807 4.70117 9.23803 5.33747 8.60174L6.66941 7.2698C7.20228 6.73692 8.09816 6.86841 8.45544 7.53193L8.90542 8.36762C9.5819 9.62394 11.384 9.62394 12.0604 8.36762L12.5104 7.53193C12.8677 6.86841 13.7636 6.73692 14.2965 7.2698L15.6284 8.60173C16.2647 9.23803 17.2867 9.26806 17.9593 8.67023C18.8804 7.85142 19.0681 6.48377 18.4017 5.44704L16.3831 2.30708C16.3601 2.2713 16.3205 2.24967 16.278 2.24967H4.68789Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StoreIcon;
