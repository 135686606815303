import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CalendarIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.349609 5.20004C0.349609 4.17831 1.17788 3.35004 2.19961 3.35004H21.3496C22.3713 3.35004 23.1996 4.17831 23.1996 5.20004V15.4801C23.1996 15.9495 22.819 16.3301 22.3496 16.3301C21.8802 16.3301 21.4996 15.9495 21.4996 15.4801V5.20004C21.4996 5.11719 21.4325 5.05004 21.3496 5.05004H2.19961C2.11677 5.05004 2.04961 5.11719 2.04961 5.20004V21.4102H16.0121C16.4816 21.4102 16.8621 21.7907 16.8621 22.2602C16.8621 22.7296 16.4816 23.1102 16.0121 23.1102H0.349609V5.20004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75195 1.40002C5.30424 1.40002 5.75195 1.84774 5.75195 2.40002V6.00002C5.75195 6.55231 5.30424 7.00002 4.75195 7.00002C4.19967 7.00002 3.75195 6.55231 3.75195 6.00002V2.40002C3.75195 1.84774 4.19967 1.40002 4.75195 1.40002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32812 1.40002C9.88041 1.40002 10.3281 1.84774 10.3281 2.40002V6.00002C10.3281 6.55231 9.88041 7.00002 9.32812 7.00002C8.77584 7.00002 8.32812 6.55231 8.32812 6.00002V2.40002C8.32812 1.84774 8.77584 1.40002 9.32812 1.40002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9023 1.40002C14.4546 1.40002 14.9023 1.84774 14.9023 2.40002V6.00002C14.9023 6.55231 14.4546 7.00002 13.9023 7.00002C13.3501 7.00002 12.9023 6.55231 12.9023 6.00002V2.40002C12.9023 1.84774 13.3501 1.40002 13.9023 1.40002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4785 1.40002C19.0308 1.40002 19.4785 1.84774 19.4785 2.40002V6.00002C19.4785 6.55231 19.0308 7.00002 18.4785 7.00002C17.9262 7.00002 17.4785 6.55231 17.4785 6.00002V2.40002C17.4785 1.84774 17.9262 1.40002 18.4785 1.40002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 9.67505C0.875 9.12276 1.32272 8.67505 1.875 8.67505L21.9 8.67505C22.4523 8.67505 22.9 9.12276 22.9 9.67505C22.9 10.2273 22.4523 10.675 21.9 10.675L1.875 10.675C1.32272 10.675 0.875 10.2273 0.875 9.67505Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9661 23.0523C15.6394 22.9253 15.4242 22.6107 15.4242 22.2601L15.4242 14.78L22.3492 14.78C22.6862 14.78 22.9915 14.9792 23.1272 15.2877C23.263 15.5962 23.2036 15.9558 22.9759 16.2043L16.9009 22.8344C16.6641 23.0928 16.2929 23.1794 15.9661 23.0523ZM20.4175 16.48L17.1242 16.48L17.1242 20.0743L20.4175 16.48Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CalendarIcon;
