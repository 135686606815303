import { useState, useEffect } from 'react';
import calcResolutionType from 'functions/calcResolutionType';

const useResolutionType = () => {
  const [resolutionType, setResolutionType] = useState(calcResolutionType());

  useEffect(() => {
    const resizeHandler = () => {
      setResolutionType(calcResolutionType());
    };

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return resolutionType;
};

export default useResolutionType;
