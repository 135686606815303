import { put, select } from 'redux-saga/effects';
import { getMainGroupTotalCount } from 'store/myItems/store/selectors';
import { reset } from '../actions';
import { getMultiEditEntity } from '../selectors';

function* tagsChanges() {
  const { all, excluded } = yield select(getMultiEditEntity);
  const currentGroupTotalCount = yield select(getMainGroupTotalCount);

  if (currentGroupTotalCount && all && currentGroupTotalCount === excluded.length) {
    yield put(reset());
  }

  if (!currentGroupTotalCount && all) {
    yield put(reset());
  }
}

export default tagsChanges;
