import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useLocation, matchPath } from 'react-router-dom';
import { COLOR_THEMES } from '@karma/components/themes';
import WebComponentsFonts from '@karma/components/GlobalStyle';
import HeadFavicon from 'components/atoms/HeadFavicon/HeadFavicon';
import GlobalStyle from 'components/molecules/GlobalStyle/GlobalStyle';
import ModalsPortal from 'components/organisms/ModalsPortal/ModalsPortal';
import { PATHS } from 'consts/paths';
import AlertsPortal from 'components/organisms/AlertsPortal/AlertsPortal';

const DARK_PAGES = [PATHS.WEBSITE_LANDING_JOIN];

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();
  const isDarkPage = DARK_PAGES.some(path => matchPath(pathname, { path, exact: true, strict: false }));

  return (
    <ThemeProvider theme={{ color: isDarkPage ? COLOR_THEMES.DARK : COLOR_THEMES.LIGHT }}>
      <HeadFavicon />
      <GlobalStyle isDarkPage={isDarkPage} />
      <WebComponentsFonts />
      <AlertsPortal />
      <ModalsPortal />
      {children}
    </ThemeProvider>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
