import { call, put } from 'redux-saga/effects';
import { COOKIES } from 'consts/cookies';
import { ITEMS } from 'consts/localStorage';
import { HOUR_IN_MILLISECONDS } from 'consts/time';
import { setCookie } from 'functions/cookies';
import { handleServerError } from 'store/alerts/actions';
import * as featuresActions from '../actions';
import * as featuresApi from '../api';

function setFeaturesToLS(features) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(ITEMS.features, JSON.stringify(features));

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 4 * HOUR_IN_MILLISECONDS;

    now.setTime(expireTime);
    setCookie(COOKIES.featuresUpToDate, true, now.toGMTString());
  }
}

function* fetchFeatures() {
  try {
    const response = yield call(featuresApi.getFeatures);

    setFeaturesToLS(response.data);
    yield put(featuresActions.setFeatures(response.data));
  } catch (error) {
    yield put(featuresActions.setFeatures({}));
    yield put(handleServerError(error));
  }
}

export default fetchFeatures;
