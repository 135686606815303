import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MessengerIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.155 23.068C4.176 23.7489 4.878 24.1929 5.502 23.9169L7.884 22.867C8.085 22.777 8.313 22.762 8.526 22.819C9.621 23.119 10.785 23.2809 12 23.2809C18.759 23.2809 24 18.3284 24 11.642C24 4.95556 18.759 0 12 0C5.241 0 0 4.95256 0 11.639C0 15.1367 1.434 18.1604 3.768 20.2482C3.963 20.4222 4.083 20.6682 4.089 20.9321L4.155 23.068ZM5.08863 20.9046L5.1527 22.978L7.47536 21.9543C7.47637 21.9538 7.47737 21.9534 7.47837 21.9529C7.89778 21.766 8.36092 21.7396 8.78449 21.853L8.79022 21.8545C9.79916 22.1309 10.8743 22.2809 12 22.2809C18.2433 22.2809 23 17.7402 23 11.642C23 5.54336 18.2429 1 12 1C5.75675 1 1 5.54069 1 11.639C1 14.8521 2.31279 17.6048 4.43471 19.5029C4.82346 19.8502 5.07466 20.3512 5.08863 20.9046Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79413 15.0436L8.31913 9.45212C8.88013 8.5612 10.0801 8.34222 10.9231 8.97216L13.7281 11.075C13.9861 11.267 14.3401 11.267 14.5951 11.072L18.3811 8.19823C18.8851 7.81426 19.5451 8.42021 19.2091 8.95716L15.6811 14.5457C15.1201 15.4366 13.9201 15.6556 13.0771 15.0256L10.2721 12.9228C10.0141 12.7308 9.66013 12.7308 9.40513 12.9258L5.61913 15.7996C5.11513 16.1835 4.45513 15.5806 4.79413 15.0436Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MessengerIcon;
