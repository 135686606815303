import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import deleteList from './worker.deleteList';
import fetchLists from './worker.fetchLists';
import addList from './worker.addList';
import editList from './worker.editList';
import setCurrentListAndFetchLists from './worker.setCurrentListAndFetchLists';

function* listsSaga() {
  yield all([
    takeLatest(actions.fetchLists.type, fetchLists),
    takeEvery(actions.addList.type, addList),
    takeEvery(actions.editList.type, editList),
    takeEvery(actions.deleteListRequest.type, deleteList),
    takeEvery(actions.setCurrentListAndFetchLists.type, setCurrentListAndFetchLists),
  ]);
}

export default listsSaga;
