import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import saga from './sagas';

const devMode = process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();

const configureMiddleware = getDefaultMiddleware => {
  const middleware = getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware);

  if (devMode) {
    middleware.push(logger);
  }

  return middleware;
};

export const createServerStore = (preloadedState = {}) => {
  return configureStore({
    reducer,
    preloadedState,
  });
};

export default (preloadedState = {}) => {
  const store = configureStore({
    reducer,
    middleware: configureMiddleware,
    preloadedState,
    devTools: devMode,
  });

  sagaMiddleware.run(saga);

  return store;
};
