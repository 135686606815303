import { createContext, Component } from 'react';
import random from 'lodash/random';
import { withRouter } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import Dexie from 'dexie';
import { ITEMS } from 'consts/localStorage';
import { DASHBOARD_PATHS, PATHS } from 'consts/paths';
import { DAY_IN_MILLISECONDS } from 'consts/time';
import getGroupKey from 'functions/getGroupKey';
import { newApiInstance } from 'functions/request';
import getIsoFromTimestamp from 'functions/getIsoFromTimestamp';
import checkNextTime from 'functions/checkNextTime';
import transliterate from 'functions/transliterate';
import jaroWinklerSearch from 'functions/jaroWinklerSearch';
import ADVERTISED_RETAILERS from 'consts/advertisedRetailers';

const permittedPaths = [...DASHBOARD_PATHS, PATHS.KARMA_CASH_SINGLE, PATHS.KARMA_CASH];
const permittedRegexps = permittedPaths.map(path => pathToRegexp(path));
const checkIsPermittedPath = path => permittedRegexps.some(regexp => path.match(regexp));

const NUMERIC_GROUP_KEY = '123';

export const RetailersDBContext = createContext();

export const RetailersDBContextProvider = () => WrappedComponent => {
  class ContextComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isConfigured: false,
      };

      this.retailersDB = new Dexie('retailersDB');
      this.isInited = false;
    }

    componentDidMount() {
      this.init();
    }

    componentDidUpdate() {
      if (!this.isInited) {
        setTimeout(this.init, 0);
      }
    }

    // eslint-disable-next-line class-methods-use-this
    findRetailersByQuery = (retailers, query) => {
      const retailersWithDistance = this.filterRetailersByDistanceToQuery(retailers, query);

      return retailersWithDistance.sort((a, b) => b.distance - a.distance).map(({ retailer }) => retailer);
    };

    // eslint-disable-next-line class-methods-use-this
    getOrderedRetailerGroups = (retailers, query) => {
      const firstLetter = query.trim().toUpperCase().charAt(0);
      const queryGroupKey = firstLetter ? getGroupKey(firstLetter) : null;

      const entries = [];

      Object.keys(retailers)
        .sort((a, b) => {
          // Sort numeric group key to the end
          if (a === NUMERIC_GROUP_KEY) return 1;

          if (b === NUMERIC_GROUP_KEY) return -1;

          return a.localeCompare(b);
        })
        .forEach(key => {
          if (key === queryGroupKey) {
            entries.unshift([key, retailers[key]]);
          } else {
            entries.push([key, retailers[key]]);
          }
        });

      return entries;
    };

    // eslint-disable-next-line class-methods-use-this
    filterRetailersByDistanceToQuery = (retailers, query) => {
      return retailers.reduce((accumulator, currentItem) => {
        let distance;

        const advertisedRetailer = ADVERTISED_RETAILERS.find(({ id, matchRegex }) => {
          return id === currentItem.self_id && matchRegex.test(query);
        });

        if (advertisedRetailer) {
          distance = 1.1;
        } else {
          distance = jaroWinklerSearch(currentItem.name, query);
        }

        return distance ? [...accumulator, { retailer: currentItem, distance }] : accumulator;
      }, []);
    };

    // eslint-disable-next-line class-methods-use-this
    getLettersAndLettersWithRetailers = (retailerGroups, withoutLetters, query, initialSlice) => {
      const orderedRetailerGroups = this.getOrderedRetailerGroups(retailerGroups, query);

      return orderedRetailerGroups.reduce(({ letters, lettersAndRetailers }, [groupKey, retailerGroup]) => {
        const letter = {
          symbol: groupKey,
          index: lettersAndRetailers.length,
        };

        return {
          letters: [...letters, letter],
          lettersAndRetailers: withoutLetters
            ? [...lettersAndRetailers, ...retailerGroup]
            : [...lettersAndRetailers, letter, ...retailerGroup],
        };
      }, initialSlice);
    };

    getRetailersSlice = async (query = '', cashback = false, withoutLetters = false) => {
      let retailers = await this.retailersDB.readyShortList.orderBy('name').toArray();

      if (cashback) {
        retailers = retailers.filter(retailer => retailer.stake);
      }

      if (query) {
        retailers = this.findRetailersByQuery(retailers, query);
      }

      const retailerGroups = retailers.reduce((groups, retailer) => {
        const groupKey = getGroupKey(retailer.name);

        return {
          ...groups,
          [groupKey]: (groups[groupKey] || []).concat(retailer),
        };
      }, {});

      return this.getLettersAndLettersWithRetailers(retailerGroups, withoutLetters, query, {
        letters: [],
        lettersAndRetailers: [],
      });
    };

    getRetailersOrderedByDistanceToQuery = async (query = '') => {
      const retailers = await this.retailersDB.readyShortList.orderBy('name').toArray();

      return this.findRetailersByQuery(retailers, query);
    };

    getFavoriteRetailersSlice = async (query = '', favoriteRetailersIds) => {
      const initialRetailers = await this.retailersDB.readyShortList.orderBy('name').toArray();

      let retailers = initialRetailers.filter(retailer => !favoriteRetailersIds.includes(retailer.self_id));
      let favoriteRetailers = initialRetailers.filter(retailer => favoriteRetailersIds.includes(retailer.self_id));

      if (query) {
        retailers = this.findRetailersByQuery(retailers, query);

        favoriteRetailers = this.findRetailersByQuery(favoriteRetailers, query);
      }

      const retailerGroups = retailers.reduce((groups, retailer) => {
        const groupKey = getGroupKey(retailer.name);

        return {
          ...groups,
          [groupKey]: (groups[groupKey] || []).concat(retailer),
        };
      }, {});

      const favoriteRetailersSlice = {
        letters: [],
        lettersAndRetailers: favoriteRetailersIds.length
          ? [{ symbol: 'Favorites', index: 0 }, ...favoriteRetailers]
          : [],
      };

      return this.getLettersAndLettersWithRetailers(retailerGroups, false, query, favoriteRetailersSlice);
    };

    bulkGetRetailers = async ids => {
      const retailers = await this.retailersDB.readyShortList.bulkGet(ids);

      return retailers.filter(retailer => retailer);
    };

    bulkGetRetailersBySelfId = async ids => {
      const retailers = await this.retailersDB.readyShortList.toArray();

      return retailers
        .filter(({ self_id }) => ids.includes(self_id))
        .sort((a, b) => ids.indexOf(a.self_id) - ids.indexOf(b.self_id));
    };

    setRetailers = async retailers => {
      await this.retailersDB.transaction('rw', this.retailersDB.readyShortList, () => {
        retailers.forEach(retailer => {
          this.retailersDB.readyShortList.put({
            ...retailer,
            name: retailer.name.trim(),
          });
        });
      });
    };

    // eslint-disable-next-line class-methods-use-this
    fetchRetailers = async ({ location, timestamp }) => {
      const params = { location_status: location, timestamp };
      const {
        data: { data },
      } = await newApiInstance.get('/retailers/difference', { params });

      return data;
    };

    refreshRetailersTable = async refreshNumber => {
      const targetCounter = +localStorage.getItem(ITEMS.targetRetailersTableRefreshNumber);
      const lastCounter = +localStorage.getItem(ITEMS.lastRetailersTableRefreshNumber);

      if (
        (targetCounter ?? refreshNumber) < (lastCounter ?? 0) ||
        localStorage.getItem(ITEMS.targetRetailersTableRefreshNumber) === null
      ) {
        if (await this.retailersDB.readyShortList.count()) {
          await this.retailersDB.readyShortList.clear();
          localStorage.removeItem('fetchRetailersTimestamp');
        }

        localStorage.setItem(ITEMS.lastRetailersTableRefreshNumber, 0);
        localStorage.setItem(ITEMS.targetRetailersTableRefreshNumber, refreshNumber);
      } else {
        localStorage.setItem(ITEMS.lastRetailersTableRefreshNumber, lastCounter + 1);
      }
    };

    resetRetailersTable = () => {
      localStorage.removeItem('fetchRetailersTimestamp');
      this.setState({ isConfigured: false }, this.init);
    };

    checkNeedToUpdateRetailersTable = async () => {
      const count = await this.retailersDB.readyShortList.count();
      const retailersDBUpdatedToV2 = localStorage.getItem('retailersDBUpdated_v2') === 'true';
      const isTimestampExpired = checkNextTime('fetchRetailersTimestamp', DAY_IN_MILLISECONDS * 3);

      const timestamp =
        count && retailersDBUpdatedToV2
          ? getIsoFromTimestamp(localStorage.getItem('fetchRetailersTimestamp'))
          : undefined;

      return { needToUpdate: !count || !retailersDBUpdatedToV2 || isTimestampExpired, timestamp };
    };

    init = async () => {
      const { location } = this.props;

      if (!checkIsPermittedPath(location.pathname)) {
        return;
      }

      this.isInited = true;

      if (this.retailersDB.isOpen()) {
        // TODO understand why this line is blocking the whole process on localhost
        if (window.location.hostname !== 'localhost') await this.refreshRetailersTable(random(2, 4));

        const { needToUpdate, timestamp } = await this.checkNeedToUpdateRetailersTable();

        if (needToUpdate) {
          const data = { location: 'any', timestamp };

          let retailers = await this.fetchRetailers(data);

          retailers = retailers.map(retailer => transliterate(retailer));

          await this.setRetailers(retailers);
          localStorage.setItem('fetchRetailersTimestamp', String(new Date().getTime()));
          localStorage.setItem('retailersDBUpdated_v2', 'true');
        }

        this.setState({ isConfigured: true });
      } else {
        this.retailersDB.on('ready', this.init);
        this.retailersDB.version(1).stores({ rawShortList: 'id,name', readyShortList: 'id,name' });
        this.retailersDB
          .version(2)
          .stores({ rawShortList: 'id,name', readyShortList: 'id,name' })
          .upgrade(tx => {
            return tx
              .table('readyShortList')
              .toCollection()
              .modify((retailer, ref) => {
                // eslint-disable-next-line no-param-reassign
                ref.value = transliterate(retailer);
              });
          });
        this.retailersDB
          .version(3)
          .stores({ rawShortList: 'id,name', readyShortList: 'id,name' })
          .upgrade(tx => {
            return tx
              .table('readyShortList')
              .toCollection()
              .modify((retailer, ref) => {
                // eslint-disable-next-line no-param-reassign
                ref.value = transliterate(retailer);
              });
          });
        this.retailersDB.open();
      }
    };

    render() {
      const { isConfigured } = this.state;
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      const publicAPI = { isConfigured };

      if (isConfigured) {
        publicAPI.bulkGetRetailers = this.bulkGetRetailers;
        publicAPI.getRetailersSlice = this.getRetailersSlice;
        publicAPI.getRetailersOrderedByDistanceToQuery = this.getRetailersOrderedByDistanceToQuery;
        publicAPI.bulkGetRetailersBySelfId = this.bulkGetRetailersBySelfId;
        publicAPI.getFavoriteRetailersSlice = this.getFavoriteRetailersSlice;
        publicAPI.resetRetailersTable = this.resetRetailersTable;
      }

      return (
        <RetailersDBContext.Provider value={publicAPI}>
          <WrappedComponent {...this.props} />
        </RetailersDBContext.Provider>
      );
    }
  }

  return withRouter(ContextComponent);
};
