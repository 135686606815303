import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const KarmaIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M2 19.3996V2.79999C2 2.31987 2.31979 2 2.79978 2H6.39878C6.87877 2 7.19856 2.31987 7.19856 2.79999V13.0798L17.5555 2.76008C18.5554 1.80016 20.1948 1.72003 21.2346 2.76008C22.3143 3.84003 22.1943 5.47992 21.2346 6.44015L14.7563 12.9199C15.676 15.2799 18.1951 16.9999 21.1545 16.9999H21.1944C21.6744 16.9999 21.9942 17.3198 21.9942 17.7999V21.2C21.9942 21.6798 21.6744 22 21.1944 22H20.7147C16.4359 22 12.7568 19.9601 10.7175 16.8403L6.63847 21.1598C6.11858 21.7197 5.4388 21.9997 4.59913 21.9997C3.07967 21.9997 2 20.9197 2 19.3996Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default KarmaIcon;
