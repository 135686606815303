import { SCRIPT_ID } from 'consts/kustomer';
import CConsole from 'functions/CConsole';

async function initKustomer({ onLoaded }) {
  if (document.getElementById(SCRIPT_ID)) {
    return null;
  }

  try {
    const kustomerScript = document.createElement('script');

    kustomerScript.id = SCRIPT_ID;
    kustomerScript.async = true;
    kustomerScript.addEventListener('load', onLoaded);
    kustomerScript.dataset.kustomerApiKey = process.env.REACT_APP_KUSTOMER;
    kustomerScript.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    document.body.appendChild(kustomerScript);
  } catch (e) {
    CConsole.error(e);
  }

  return null;
}

export default initKustomer;
