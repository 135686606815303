/**
 * Serialize params to be sent with the request as query string
 *
 * @param {Object} params - Is an object of parameters to be sent with the request
 *
 * @returns {String} Returns query string
 */

import qs from 'qs';

const paramsSerializer = params =>
  qs.stringify(params, {
    arrayFormat: 'comma',
    delimiter: '&',
    encode: true,
    skipNulls: true,
  });

export const useQueryParser = str => qs.parse(str, { ignoreQueryPrefix: true });

export default paramsSerializer;
