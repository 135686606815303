import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';
import { IIconProps } from '../BaseIcon';
import { getColor } from '../colors';
import { SelectArrowIcon } from '../icons';
import { InputBaseRoot } from '../InputBase/InputBase.styles';
import Stack from '../Stack';
import { SelectInputProps, SelectIconContainerProps } from './Select.types';

export const SelectInputButton = styled<
  StyledComponent<'div', DefaultTheme, SelectInputProps, never>
>(InputBaseRoot.withComponent('div'))`
  min-height: 19px;
  padding: 15px 32px 16px ${({ hasPrefix }) => (hasPrefix ? '0' : '16px')};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      user-select: none;
    `}
`;

export const OptionsWrapper = styled(Stack)`
  padding: 8px 0px;
  width: 100%;
  margin: 0;
  max-height: 300px;
  border-radius: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background: transparent;

    &:hover {
      width: 25px;
      background: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${getColor('neutral4')};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral4')};
  }
`;

export const PopoverWrapper = styled.div`
  display: block;
  position: relative;
  height: 0px;
  width: 100%;
`;

export const HiddenInput = styled.input`
  bottom: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
`;

export const SelectIconContainer = styled.div`
  display: inline-flex;
  position: absolute;
  right: 16px;
  top: 17px;
  user-select: none;
  pointer-events: none;
`;

export const SelectArrow = styled<React.FC<IIconProps & SelectIconContainerProps>>(SelectArrowIcon)`
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : 'rotate(0)')};
`;
