import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ListIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 71 71"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9895 5.45833H11.6341V47.1667C11.6341 52.8066 11.6446 56.5377 12.017 59.3075C12.3703 61.9347 12.9652 62.9431 13.5989 63.5768C14.2327 64.2106 15.2411 64.8055 17.8683 65.1587C20.6381 65.5311 24.3692 65.5417 30.0091 65.5417H59.3645V23.8333C59.3645 18.1934 59.354 14.4623 58.9816 11.6925C58.6284 9.06534 58.0334 8.05689 57.3997 7.42316C56.766 6.78943 55.7575 6.19447 53.1303 5.84126C50.3605 5.46886 46.6294 5.45833 40.9895 5.45833ZM6.67578 0.5V47.1667C6.67578 58.1661 6.67578 63.6658 10.0929 67.0829C13.51 70.5 19.0097 70.5 30.0091 70.5H64.3228V23.8333C64.3228 12.8339 64.3228 7.33418 60.9058 3.91709C57.4887 0.5 51.9889 0.5 40.9895 0.5H6.67578Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8524 23.5674H23.1465V18.6091H47.8524V23.5674Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8524 40.038H23.1465V35.0797H47.8524V40.038Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6171 56.5086H23.1465V51.5502H39.6171V56.5086Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ListIcon;
