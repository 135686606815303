import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@karma/components';
import { HidePasswordIcon, RightArrowIcon, ShowPasswordIcon } from '@karma/components/icons';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import InputFormField from 'components/molecules/InputFormField/InputFormField';
import { IconWrapper } from './FormEmailPassword.style';

const emptyPrimaryButton = {};
const emptySecondaryButton = {};

const FormEmailPassword = ({
  isFetching,
  formProps,
  onSubmit,
  children,
  primaryButton = emptyPrimaryButton,
  secondaryButton = emptySecondaryButton,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const defaultProps = {
    control: formProps.control,
    disabled: isFetching,
    loading: isFetching,
    fullWidth: true,
  };

  const { text: primaryText, onClick: primaryOnClick = () => {}, ...primaryProps } = primaryButton;
  const { text: secondaryText, onClick: secondaryOnClick = () => {}, ...secondaryProps } = secondaryButton;

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Stack direction="column" spacing={20} fullWidth>
        <InputFormField
          id="email"
          name="email"
          label={t('app:email')}
          placeholder={t('app:enterEmailAddress')}
          type="email"
          {...defaultProps}
        />

        <InputFormField
          id="password"
          name="password"
          label={t('app:password')}
          type={showPassword ? 'text' : 'password'}
          replaceCharacters={/\s/g}
          AfterInput={
            <>
              <IconWrapper role="button" onClick={() => setShowPassword(prev => !prev)}>
                {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              </IconWrapper>
            </>
          }
          {...defaultProps}
        />
      </Stack>
      <Stack direction="column" spacing={20} style={{ marginTop: 32 }}>
        <Button
          size="medium"
          buttonType="submit"
          fullWidth
          Icon={RightArrowIcon}
          iconPosition="right"
          onClick={primaryOnClick}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.LOG_IN}
          loading={isFetching}
          {...primaryProps}
        >
          {primaryText}
        </Button>

        {secondaryText && (
          <Button
            type="underlinedText"
            buttonType="button"
            size="small"
            {...contextDataAttrs}
            onClick={secondaryOnClick}
            disabled={isFetching}
            {...secondaryProps}
          >
            {secondaryText}
          </Button>
        )}
      </Stack>
      <Stack direction="column" spacing={28} style={{ marginTop: 28 }}>
        {children}
      </Stack>
    </form>
  );
};

export default FormEmailPassword;
