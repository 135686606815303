import { createContext } from 'react';

export const ReferralSourceContext = createContext();

export const referralSourceContextProvider = source => WrappedComponent => props =>
  (
    <ReferralSourceContext.Provider value={source}>
      <WrappedComponent {...props} />
    </ReferralSourceContext.Provider>
  );

export const referralSourceContextConsumer = () => WrappedComponent => props =>
  (
    <ReferralSourceContext.Consumer>
      {source => <WrappedComponent {...props} source={source} />}
    </ReferralSourceContext.Consumer>
  );
