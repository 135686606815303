/**
 * Build new URL based on baseURL and searchParams
 *
 * @param {String} baseURL - Is a String representing an absolute URL.
 * @param {Object[]} searchParams - Is an array of searchParam
 * @param {String} searchParams[].name - The name of the parameter to append.
 * @param {String} searchParams[].value - The value of the parameter to append.
 *
 * @returns {String} Returns the constructed URL based on baseURL and searchParams.
 */

function buildUrl(baseURL, searchParams = []) {
  if (typeof baseURL !== 'string' || !baseURL) throw new Error('baseURL is not defined');

  const url = new URL(baseURL);

  searchParams
    .filter(({ value }) => value || typeof value === 'boolean' || (typeof value === 'number' && value === 0))
    .forEach(({ name, value }) => {
      url.searchParams.append(name, typeof value === 'object' ? JSON.stringify(value) : value);
    });

  return url.href.replace(/%5B/g, '[').replace(/%5D/g, ']').replace(/%2C/g, ',').replace(/%40/g, '@');
}

export default buildUrl;
