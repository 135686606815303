import { useTranslation } from 'react-i18next';
import { KarmaLogo, Typography } from '@karma/components';
import { PATHS } from 'consts/paths';
import { Modal, Body, LogoWrapper, TypographyAsLink, BottomContainer } from './ErrorModal.style';

const ErrorModal = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = PATHS.FAQ;
  };

  return (
    <Modal show>
      <Body>
        <LogoWrapper>
          <KarmaLogo width={81} />
        </LogoWrapper>
        <Typography variant="paragraph" tMargin={24}>
          {t('app:weAreSorry')}
        </Typography>
        <Typography variant="paragraph">{t('app:tryToReloadPage')}</Typography>
        <br />
        <BottomContainer>
          <Typography variant="paragraph">{t('app:ifThisWontHelp')}</Typography>
          <TypographyAsLink variant="paragraph" onClick={handleClick}>
            {t('app:contactSupport')}
          </TypographyAsLink>
        </BottomContainer>
      </Body>
    </Modal>
  );
};

export default ErrorModal;
