import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@karma/components';
import { CheckmarkIcon } from '@karma/components/icons';
import { ButtonsContainer } from './ForgotPasswordSent.style';

const ForgotPasswordSent = ({ email, retryForgot }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing={20}>
      <CheckmarkIcon width={74} height={74} />
      <Typography variant="h3" align="center">
        {t('app:onItsWay')}
      </Typography>
      <Typography variant="paragraphLarge" align="center" bMargin={8}>
        {t('app:weVeEmailedInstructionsForResettingYourPassword', { email })}
      </Typography>
      <ButtonsContainer direction="column" spacing={16}>
        <Button type="underlinedText" size="small" onClick={retryForgot}>
          {t('app:wrongEmailTryAgain')}
        </Button>
      </ButtonsContainer>
    </Stack>
  );
};

export default ForgotPasswordSent;
