import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as myItemsActions from 'store/myItems/store/actions';
import * as myItemsApi from 'store/myItems/store/api';
import * as myItemsGroups from 'consts/myItemsGroups';

function* fetchMyItemsPreview() {
  try {
    const groups = [myItemsGroups.LAST_SAVED];
    const per_group = 20;
    const response = yield call(myItemsApi.fetchMyItemsPreviewRequest, { groups, per_group });

    yield put(
      myItemsActions.receiveMyItemsPreview({
        group: myItemsGroups.LAST_SAVED,
        tags: response.data.data[myItemsGroups.LAST_SAVED],
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchMyItemsPreview;
