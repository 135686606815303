import { getLinkToMobileApp } from './getLinkToMobileApp';

export const redirectToMobileApp = (appLink = 'karma://') => {
  const initTime = Date.now();

  setTimeout(() => {
    if (Date.now() - initTime < 5500) {
      window.location = getLinkToMobileApp();
    }
  }, 5000);

  window.location = appLink;
};
