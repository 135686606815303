import * as React from 'react';

let globalId = 0;
function useGlobalId(idOverride?: string): string | undefined {
  const [defaultId, setDefaultId] = React.useState(idOverride);
  const id = idOverride || defaultId;

  React.useEffect(() => {
    if (defaultId == null) {
      globalId += 1;
      setDefaultId(`karma-${globalId}`);
    }
  }, [defaultId]);

  return id;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- for React v18
// const maybeReactUseId: undefined | (() => string) = (React as any).useId;
export default function useId(idOverride?: string): string | undefined {
  // if (maybeReactUseId !== undefined) {
  //   const reactId = maybeReactUseId();
  //   return idOverride ?? reactId;
  // }

  return useGlobalId(idOverride);
}
