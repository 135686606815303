import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const ListWrapper = styled.ul`
  margin: 0;
  padding: 5px 0;
  max-height: 190px;
  overflow-y: auto;
  width: 100%;

  label {
    margin: 0;
  }

  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background: transparent;

    &:hover {
      width: 25px;
      background: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${getColor('neutral4')};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral4')};
  }
`;

export const ListItem = styled.li`
  list-style: none;
`;
