import { createSlice } from '@reduxjs/toolkit';

const initialState = { titles: [], marketingCopy: '', loaded: false };

export const retailersGroupsTitlesSlice = createSlice({
  name: 'retailersGroupsTitle',
  initialState,
  reducers: {
    receiveRetailersGroupsTitles: (state, action) => ({
      ...state,
      titles: action.payload,
      loaded: true,
    }),
    receiveMarketingCopy: (state, action) => ({
      ...state,
      marketingCopy: action.payload,
    }),
  },
});

export default retailersGroupsTitlesSlice.reducer;
