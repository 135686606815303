import { useEffect } from 'react';
import { Stack } from '@karma/components';
import { logContentCardClick, logContentCardImpression } from 'services/braze';
import LoadableIcon from 'components/atoms/LoadableIcon/LoadableIcon';
import { CardContainer, CTAContainer, CTAText, CTAButton } from './CouponContentCard.style';

const CouponContentCard = ({ contentCard }) => {
  const { title, description, linkText, url, extras, viewed } = contentCard || {};

  const onContentCardClick = () => {
    logContentCardClick(contentCard);
  };

  useEffect(() => {
    if (!viewed) {
      logContentCardImpression(contentCard);
    }
  }, [contentCard, viewed]);

  return (
    <CTAContainer as="a" href={url} target="_blank" onClick={onContentCardClick}>
      <CardContainer $backgroundColor={extras?.backgroundColor}>
        <LoadableIcon iconName={extras?.iconName || 'GiftIcon'} width={37} height={37} />
        <Stack fullWidth direction="column" alignItems="flex-start">
          <CTAText variant="h5" bMargin={8} tMargin={16}>
            {title}
          </CTAText>
          <CTAText variant="paragraphLarge">{description}</CTAText>
        </Stack>
        <CTAButton fullWidth size="small">
          {linkText}
        </CTAButton>
      </CardContainer>
    </CTAContainer>
  );
};

export default CouponContentCard;
