import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const StarIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M3.89844 10.0991L9.49369 9.22182L11.999 3.89941L14.5044 9.22182L20.0996 10.0991L16.0632 14.2225L17.0097 20.1006L11.999 17.3224L6.98835 20.1006L7.96265 14.2225L3.89844 10.0991Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 1.90356L15.0821 8.45188L21.8616 9.5149L16.9713 14.5107L18.1275 21.6919L11.9997 18.2943L5.86283 21.6969L7.05394 14.5108L2.13086 9.51598L8.9173 8.45188L11.9997 1.90356ZM11.9997 5.8952L10.0714 9.99169L5.66735 10.6822L8.87269 13.9343L8.11521 18.5042L11.9997 16.3505L15.8932 18.5092L15.1565 13.9343L18.3389 10.6833L13.928 9.99169L11.9997 5.8952Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StarIconFilled;
