import { createAction } from '@reduxjs/toolkit';
import alertsSlice from './slice';

export const {
  hideAlert,
  showLanguageAlert,
  showErrorAlert,
  showSuccessAlert,
  showDeleteTagAlert,
  showNotificationSuccessAlert,
  showNotificationUnsuccessfulAlert,
  showDeleteNotificationAlert,
  showMuteNotificationAlert,
} = alertsSlice.actions;

export const handleServerError = createAction('alerts/handleServerError');
