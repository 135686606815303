import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import styled, { css } from 'styled-components';

export const SignUpContent = styled(Stack)`
  ${({ $showImage }) => {
    if (!$showImage)
      return css`
        width: 500px;
        max-width: 100%;
        height: 568px;
      `;

    return css`
      width: 840px;
      height: 568px;
      max-width: 100%;
    `;
  }}

  background-color: ${getColor('primary1')};
  color: ${getColor('primary2')};
  border-radius: 10px;
`;

export const SignUpImage = styled.img`
  width: 41%;
  max-width: 41%;
  min-width: 41%;
  object-fit: cover;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
`;
