import { HIGH_TO_LOW, LAST_SAVED, LOW_TO_HIGH } from 'consts/staticFilterKeys';

export const myItemsGetSortBy = staticFilter => {
  if (staticFilter[LAST_SAVED]) return 'created_at';
  if (staticFilter[HIGH_TO_LOW] || staticFilter[LOW_TO_HIGH]) return 'price';

  return null;
};

export const myItemsGetSortOrder = staticFilter => {
  if (staticFilter[HIGH_TO_LOW]) return 'desc';
  if (staticFilter[LOW_TO_HIGH]) return 'asc';

  return null;
};

export const getQueryParamFromUrl = (url, key) => {
  const link = new URL(url);

  return link.searchParams.get(key);
};

export const getQueryParam = key => {
  const url = new URL(window.location.href);

  return url.searchParams.get(key);
};

export const getQueryParams = keys => {
  const url = new URL(window.location.href);

  return keys.reduce((acc, key) => ({ ...acc, [key]: url.searchParams.get(key) }), {});
};

export const setQueryParam = (key, value) => {
  if (key !== undefined && value !== undefined) {
    const url = new URL(window.location.href);

    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }

    window.history.pushState('', '', url.href);
  }
};

export const deleteQueryParams = keys => {
  if (keys !== undefined) {
    const url = new URL(window.location.href);

    if (typeof keys === 'string') {
      url.searchParams.delete(keys);
    } else if (typeof keys === 'object') {
      keys.forEach(key => {
        url.searchParams.delete(key);
      });
    }

    window.history.pushState('', '', url.href);
  }
};

export const getHashFromUrl = () => {
  const { hash } = window.location;

  return hash ? hash.substring(1) : null;
};
