import PropTypes from 'prop-types';
import { COLOR_THEMES } from '@karma/components/themes';
import ModalBodyOnboarding from 'components/molecules/ModalBodyOnboarding/ModalBodyOnboarding';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';

const ModalOnboarding = props => {
  const { closeModal, sourcePage } = props;

  return (
    <Modal
      source={sourcePage}
      aloomaModalType={ALOOMA.MODAL_TYPES.POPUP}
      aloomaModalName={ALOOMA.MODAL_NAMES.ONBOARDING}
      onClose={closeModal}
      colorTheme={COLOR_THEMES.LIGHT}
    >
      <ModalBodyOnboarding {...props} />
    </Modal>
  );
};

ModalOnboarding.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onCloseCallback: PropTypes.func,
  sourcePage: PropTypes.string.isRequired,
};

ModalOnboarding.defaultProps = {
  onCloseCallback: null,
};

export default ModalOnboarding;
