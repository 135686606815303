import { useTranslation } from 'react-i18next';
import { QuestionMarkIcon } from '@karma/components/icons';
import { Stack, Typography } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import * as userActions from 'store/user/actions';
import useAction from 'hooks/useAction';
import { ButtonsContainer, StyledButton } from './ModalBodyPaypalDisconnect.style';

const ModalBodyPaypalDisconnect = ({ onClose }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const unsubscribePP = useAction(userActions.unsubscribePP);

  const handleDisconnect = () => {
    unsubscribePP();
    onClose();
  };

  return (
    <Stack direction="column" spacing={20}>
      <QuestionMarkIcon width={60} height={60} />
      <Stack direction="column" spacing={8} alignItems="center">
        <Typography variant="h3" align="center">
          {t('app:areYouSureYouWantToDisconnectFromPaypal')}
        </Typography>
        <Typography variant="paragraphLarge" align="center">
          {t('app:youHaveToReconnectToWithdraw')}
        </Typography>
      </Stack>

      <ButtonsContainer spacing={16}>
        <StyledButton
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.CLOSE_PANEL}
          type="outline"
          size="medium"
          onClick={onClose}
        >
          {t('app:nevermind')}
        </StyledButton>
        <StyledButton
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.DISCONNECT_FROM_PAYPAL}
          size="medium"
          onClick={handleDisconnect}
        >
          {t('app:yesDisconnect')}
        </StyledButton>
      </ButtonsContainer>
    </Stack>
  );
};

export default ModalBodyPaypalDisconnect;
