import { Typography } from '@karma/components';
import { LeftArrowIcon } from '@karma/components/icons';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import useGetSignUpFormProps from 'hooks/useGetSignUpFormProps';
import FormEmailPassword from '../FormEmailPassword/FormEmailPassword';
import { ArrowButton, ContentContainer } from './SignUpPasswordContainer.style';

const SignUpPasswordContainer = ({ goBack, isCompleteLater, closeModal }) => {
  const { t } = useTranslation();

  const { formProps, fetching, onSubmit } = useGetSignUpFormProps(closeModal, isCompleteLater);

  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <ContentContainer fullWidth direction="column" spacing={28}>
      <ArrowButton onClick={goBack} {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.LINK_GO_BACK}>
        <LeftArrowIcon size="large" />
      </ArrowButton>
      <Typography variant="h3">{t('app:joinKarma')}</Typography>
      <FormEmailPassword
        onSubmit={onSubmit}
        formProps={formProps}
        primaryButton={{
          text: t('app:letsDoThis'),
          'data-alooma-element': ALOOMA.ELEMENTS.JOIN_US,
          loading: fetching,
          size: 'small',
        }}
      />
    </ContentContainer>
  );
};

export default SignUpPasswordContainer;
