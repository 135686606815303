import styled from 'styled-components';

export const PopoverRoot = styled.div`
  position: fixed;
  z-index: 1000;
  inset: 0px;
  width: 100%;
`;

export const PopoverWrapper = styled.div`
  display: inline-flex;
  background: ${({ theme }) => theme[theme.color].Popover.background};
  box-shadow: ${({ theme }) => theme[theme.color].Popover.shadow};
  border: ${({ theme }) => theme[theme.color].Popover.border};
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
`;
