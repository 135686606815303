import { useEffect } from 'react';
import moment from 'moment';
import useQueryParams from 'hooks/useQueryParams';
import { setCookie, setCookieForLp } from 'functions/cookies';

const useSetCjeInCookies = () => {
  const { cjevent } = useQueryParams();

  useEffect(() => {
    if (cjevent) {
      const expireTime = moment().add(13, 'month').toDate().toUTCString();

      setCookie('cje', cjevent, expireTime, { domain: `.karmanow.com`, attributes: 'SameSite=None; Secure;' });
    }
  }, [cjevent]);
};

export const setCjeInCookiesForLp = () => `
  (function setCjeInCookiesForLp() {
    const cjevent = new URLSearchParams(window.location.search).get('cjevent');
    
    ${setCookieForLp()}

    if (cjevent) {
      const MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;
      const expireTime = new Date(new Date().getTime() + 13 * MONTH_IN_MILLISECONDS).toUTCString();

      setCookieForLp('cje', cjevent, expireTime, { domain: '.karmanow.com', attributes: 'SameSite=None; Secure;' });
    }
  })();
`;

export default useSetCjeInCookies;
