import styled from 'styled-components';
import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';

export const ButtonContainer = styled(Stack)`
  width: 100%;
  background: ${getColor('neutral4')};

  border-radius: 10px;
  padding: 20px 12px;
`;
