import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { BackArrowIcon, ForwardArrowIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useResizeListener from 'hooks/useResizeListener';
import 'swiper/css/swiper.css';
import { SliderContainer, LeftShadow, RightShadow } from './BaseSwiperSlider.style';
import { getSettings } from './config';

const BaseSwiperSlider = ({
  v2,
  className,
  arrowSize,
  RightIcon,
  LeftIcon,
  alwaysShowArrows,
  isMultipleLinesSlider,
  children,
  sliderSettings,
  ...other
}) => {
  const swiperRef = useRef(null);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(true);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const resizeSwiper = () => {
    if (swiperRef?.current?.swiper) {
      setTimeout(() => {
        if (swiperRef?.current?.swiper) {
          swiperRef.current.swiper.resize.resizeHandler();
        }
      }, 300);
    }
  };

  const updateArrows = () => {
    setLeftArrow(!swiperRef?.current?.swiper.isBeginning);
    setRightArrow(!swiperRef?.current?.swiper.isEnd);
  };

  const settings = useMemo(() => getSettings({ updateArrows, ...sliderSettings }), [sliderSettings]);

  useResizeListener(resizeSwiper);

  useEffect(() => {
    const navigation = swiperRef?.current?.swiper?.navigation;

    if (navigation) {
      Object.entries(contextDataAttrs).forEach(([key, value]) => {
        if (value) {
          navigation.nextEl?.setAttribute(key, value);
          navigation.prevEl?.setAttribute(key, value);
        }
      });

      navigation.nextEl?.setAttribute(ALOOMA.ATTRS.ELEMENT, ALOOMA.ELEMENTS.RIGHT_ARROW);
      navigation.prevEl?.setAttribute(ALOOMA.ATTRS.ELEMENT, ALOOMA.ELEMENTS.LEFT_ARROW);
    }
  });

  useEffect(() => {
    if (children?.length) {
      updateArrows();
    }
  }, [children?.length]);

  return (
    <SliderContainer
      className={className}
      isMultipleLinesSlider={isMultipleLinesSlider}
      $v2={v2}
      showNextButton={alwaysShowArrows || rightArrow}
      showPrevButton={alwaysShowArrows || leftArrow}
    >
      <Swiper
        ref={swiperRef}
        renderPrevButton={() => <LeftIcon className="swiper-button-prev" size={arrowSize} />}
        renderNextButton={() => <RightIcon className="swiper-button-next" size={arrowSize} />}
        {...settings}
        {...other}
      >
        {children}
      </Swiper>
      {alwaysShowArrows || (leftArrow && <LeftShadow />)}
      {alwaysShowArrows || (rightArrow && <RightShadow />)}
    </SliderContainer>
  );
};

BaseSwiperSlider.propTypes = {
  v2: PropTypes.bool,
  className: PropTypes.string,
  arrowSize: PropTypes.string,
  RightIcon: PropTypes.elementType,
  LeftIcon: PropTypes.elementType,
  alwaysShowArrows: PropTypes.bool,
  isMultipleLinesSlider: PropTypes.bool,
  sliderSettings: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

BaseSwiperSlider.defaultProps = {
  v2: false,
  className: '',
  arrowSize: 'medium',
  RightIcon: ForwardArrowIcon,
  LeftIcon: BackArrowIcon,
  alwaysShowArrows: false,
  isMultipleLinesSlider: false,
  sliderSettings: {},
};

export default BaseSwiperSlider;
