const hasDiscount = (currentPrice, originalPrice, currency) => {
  try {
    if (!currentPrice || !originalPrice || !currency) return false;

    const fullCurrentPrice = parseFloat(currentPrice?.full);
    const fullOriginalPrice = parseFloat(originalPrice?.full);

    const validCurrentPrice = currentPrice?.integer !== null && !Number.isNaN(fullCurrentPrice);
    const validOriginalPrice = originalPrice?.integer !== null && !Number.isNaN(fullOriginalPrice);

    return !!(
      validCurrentPrice &&
      validOriginalPrice &&
      currentPrice?.integer !== originalPrice?.integer &&
      fullCurrentPrice < fullOriginalPrice &&
      ((fullOriginalPrice - fullCurrentPrice) / fullOriginalPrice) * 100 > 2
    );
  } catch (e) {
    return false;
  }
};

export default hasDiscount;
