import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as favoriteRetailersActions from '../actions';
import * as favoriteRetailersApi from '../api';

function* deleteFavoriteRetailers(action) {
  try {
    yield call(favoriteRetailersApi.deleteFavoriteRetailers, action.payload);
    yield put(favoriteRetailersActions.successDeleteFavoriteRetailers(action.payload));
    yield put(favoriteRetailersActions.requestFetchFavoriteRetailers());
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default deleteFavoriteRetailers;
