/* eslint-disable react/require-default-props */
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloseIcon } from '@karma/components/icons';
import Typography from '@karma/components/Typography';
import { ThemeProvider } from 'styled-components';
import { COLOR_THEMES } from '@karma/components/themes';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { Body, CloseIconButton, TopGradient } from './Modal.style';

const Modal = ({
  children,
  onClose,
  showClose,
  source,
  title,
  withGradient,
  aloomaModalName,
  aloomaModalType,
  colorTheme,
  sendPageView,
}) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const dataAttrs = {
    ...contextDataAttrs,
    [ALOOMA.ATTRS.SOURCE]: source,
    [ALOOMA.ATTRS.MODAL_TYPE]: aloomaModalType,
    [ALOOMA.ATTRS.MODAL_NAME]: aloomaModalName,
  };

  Alooma.usePageViewTracker(source, sendPageView);
  Alooma.useImpressionTracker({ modalType: aloomaModalType, modalName: aloomaModalName, source }, !!aloomaModalName);

  return (
    <ThemeProvider theme={{ color: colorTheme }}>
      <Alooma.ModalTypeContext.Provider value={aloomaModalType}>
        <Alooma.ModalNameContext.Provider value={aloomaModalName}>
          <Alooma.SourceContext.Provider value={source}>
            {withGradient && <TopGradient />}

            {!!(showClose && onClose) && (
              <CloseIconButton
                {...dataAttrs}
                data-alooma-element={ALOOMA.ELEMENTS.CROSS}
                name="close"
                onClick={onClose}
                withGradient={withGradient}
              >
                <CloseIcon size="medium" />
              </CloseIconButton>
            )}

            {!!title && (
              <Typography variant="h3" bMargin={16}>
                {t(title)}
              </Typography>
            )}
            {children ? <Body>{children}</Body> : null}
          </Alooma.SourceContext.Provider>
        </Alooma.ModalNameContext.Provider>
      </Alooma.ModalTypeContext.Provider>
    </ThemeProvider>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  source: PropTypes.string.isRequired,
  aloomaModalName: PropTypes.string,
  aloomaModalType: PropTypes.string,
  title: PropTypes.node,
  colorTheme: PropTypes.string,
  withGradient: PropTypes.bool,
  sendPageView: PropTypes.bool,
};

Modal.defaultProps = {
  showClose: true,
  aloomaModalName: '',
  aloomaModalType: ALOOMA.MODAL_TYPES.POPUP,
  colorTheme: COLOR_THEMES.LIGHT,
  withGradient: false,
  sendPageView: true,
};

export default Modal;
