import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const UpArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7402 11.4814L22.5404 12.6857L12.6607 2.84324L2.7408 12.7445L1.53986 11.5412L12.6596 0.442474L23.7402 11.4814Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 1.64285L13.4891 22.6429L11.7891 22.6429L11.7891 1.64285L13.4891 1.64285Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UpArrowIcon;
