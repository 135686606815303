import { TextField } from '@karma/components';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormatInput from 'react-number-format';

const NumberFormat = forwardRef(function NumberFormat({ onChange, format, mask, ...other }, ref) {
  return (
    <NumberFormatInput
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: other.name,
            value: values.formattedValue,
          },
        });
      }}
      format={format}
      mask={mask}
    />
  );
});

const InputFormField = ({
  control,
  name,
  label,
  placeholder,
  helperText,
  type = 'text',

  inputProps,

  isMask = false,
  maskProps,

  disabled,

  BeforeInput = null,
  AfterInput = null,
  AfterInputContainer = null,

  replaceCharacters,
  ...otherProps
}) => {
  const additionalProps = {
    ...(isMask && {
      inputComponent: NumberFormat,
      inputProps: {
        ...inputProps,
        ...maskProps,
      },
    }),
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        const interceptOnChange = e => {
          e.preventDefault();
          e.target.value = e.target.value?.replace(replaceCharacters, '');
          onChange(e);
        };

        return (
          <TextField
            onBlur={onBlur}
            onChange={replaceCharacters ? interceptOnChange : onChange}
            value={value}
            inputRef={ref}
            error={!!error}
            placeholder={placeholder}
            type={type}
            label={label}
            name={name}
            helperText={error ? error.message : helperText}
            disabled={disabled}
            beforeInput={BeforeInput}
            afterInput={AfterInput}
            afterInputContainer={AfterInputContainer}
            {...otherProps}
            {...additionalProps}
          />
        );
      }}
    />
  );
};

export default InputFormField;
