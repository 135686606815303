import { Stack, Typography } from '@karma/components';

const StoreInfoBadge = ({ Icon, text, color, maxWidth = '130px' }) => (
  <Stack spacing={12}>
    <Icon size="large" color={color} />
    <Typography variant="paragraphSmall" style={{ maxWidth }} color={color}>
      {text}
    </Typography>
  </Stack>
);

export default StoreInfoBadge;
