import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as userActions from 'store/user/actions';
import useAction from 'hooks/useAction';
import isTablet from 'functions/isTablet';
import useGetRedirectAfterAuthorization from 'hooks/useGetRedirectAfterAuthorization';
import { guestSignUpUser } from 'functions/authentication/signUpFunctions';
import { getExtInstalled } from 'store/extension/selectors';
import { PATHS } from 'consts/paths';
import { showErrorAlert } from 'store/alerts/actions';
import { convertUserFromGuest } from 'functions/authentication/guestConvertFunctions';
import getIsSeoPage from 'functions/getIsSeoPage';
import { trackUserSignUpOnboarding } from 'functions/trackGoogleTagEvent';
import getEmailPasswordFormSchema from 'functions/getEmailPasswordFormSchema';

const useGetSignUpFormProps = (callback = () => {}, isCompleteLater) => {
  const { t } = useTranslation();
  const fetchUser = useAction(userActions.fetchUser);
  const [fetching, setFetching] = useState(false);
  const isExtensionInstalled = useSelector(getExtInstalled);
  const showErrorAlertFunc = useAction(showErrorAlert);
  const isSeoPage = getIsSeoPage();
  const redirectAfterAuthorization = useGetRedirectAfterAuthorization();

  const schema = getEmailPasswordFormSchema({ t, signUp: true });

  const formProps = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = formProps;

  const showError = err => showErrorAlertFunc({ message: err });

  const signUp = ({ email, password }) => {
    setFetching(true);

    if (isCompleteLater) {
      return convertUserFromGuest({ email, password })
        .then(() => {
          fetchUser();
          window.location.reload();
        })
        .catch(error => {
          showError(error);
          setFetching(false);
        });
    }

    return guestSignUpUser({ email, password })
      .then(async ({ isGuest }) => {
        if (!isGuest) {
          if (isSeoPage) {
            fetchUser();
            callback();
          } else if (!isTablet()) {
            fetchUser();
            trackUserSignUpOnboarding(email);
            redirectAfterAuthorization(isExtensionInstalled);
          } else window.location.href = PATHS.DASHBOARD;
        }
      })
      .catch(showError)
      .finally(() => setFetching(false));
  };

  const onSubmit = handleSubmit(signUp);

  return {
    formProps,
    fetching,
    onSubmit,
  };
};

export default useGetSignUpFormProps;
