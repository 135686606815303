import moment from 'moment';
import { removeCookie, setCookie } from 'functions/cookies';
import getBrowser from 'functions/getBrowser';
import { getReferralLinkParams } from 'functions/redirectLink';
import { ITEMS } from 'consts/localStorage';
import BROWSERS from 'consts/browsers';

const authenticateUser = data => {
  const id = data.user_id;
  const { token } = data;
  const userToken = data.user_token;
  const influencerSlug = data.influencer;
  const influencerImage = data.influencer_image;
  const influencerName = data.influencer_name;
  const isConvertedFromGuest = data.is_converted_from_guest;
  const expireTime = moment().add(5, 'years').toDate().toUTCString();

  if (typeof KM !== 'undefined') {
    // eslint-disable-next-line no-undef
    KM.identify(data.email);
  }

  setCookie('logged', 'true', expireTime);
  removeCookie('features_up_to_date');

  // window.postMessage('start_receive_token', '*');
  window.postMessage('removeToken', '*');

  if (getBrowser() !== BROWSERS.SAFARI) {
    setCookie('extension_token', token, expireTime, { attributes: 'SameSite=None; Secure;' });
    window.postMessage('receiveToken', '*');
  } else {
    setCookie('extension_token', token, moment().add(15, 'minutes').toDate().toUTCString(), {
      attributes: 'SameSite=None; Secure;',
    });
    window.postMessage(`receiveToken_${token || ''}`, '*');
  }

  Object.keys(getReferralLinkParams()).forEach(param => {
    localStorage.removeItem(param);
    removeCookie(param);
  });

  localStorage.removeItem(ITEMS.features);
  localStorage.removeItem('oauth_token');
  localStorage.removeItem('oauth_token_secret');
  localStorage.removeItem('isGuest');
  if (!isConvertedFromGuest) {
    localStorage.removeItem('features');
  }
  localStorage.removeItem(ITEMS.completeSignUpModalDelay);
  localStorage.removeItem(ITEMS.congratulateSignupModal);

  localStorage.setItem('userToken', userToken);
  localStorage.setItem('token', token);
  localStorage.setItem('user_id', id);

  if (influencerSlug) localStorage.setItem('influencer', influencerSlug);
  if (influencerImage) localStorage.setItem('influencerImage', influencerImage);
  if (influencerName) localStorage.setItem('influencerName', influencerName);
};

export { authenticateUser };
