import { createSelector } from '@reduxjs/toolkit';

export const getWalletEntity = ({ wallet }) => wallet;

export const getEarningsEntity = createSelector(getWalletEntity, ({ earnings }) => earnings);

export const getEarnings = createSelector(getEarningsEntity, ({ earnings }) => earnings);

export const getEarningsFilter = createSelector(getEarningsEntity, ({ filter }) => filter);

export const getEarningsHasMore = createSelector(getEarningsEntity, ({ hasMore }) => hasMore);

export const getEarningsIsFetching = createSelector(getEarningsEntity, ({ isFetching }) => isFetching);

export const getEarningsIsLoaded = createSelector(getEarningsEntity, ({ isLoaded }) => isLoaded);

export const getEarningsPage = createSelector(getEarningsEntity, ({ page }) => page);

export const getPayoutsEntity = createSelector(getWalletEntity, ({ payouts }) => payouts);

export const getPayouts = createSelector(getPayoutsEntity, ({ payouts }) => payouts);

export const getPayoutsFilter = createSelector(getPayoutsEntity, ({ filter }) => filter);

export const getPayoutsHasMore = createSelector(getPayoutsEntity, ({ hasMore }) => hasMore);

export const getPayoutsIsFetching = createSelector(getPayoutsEntity, ({ isFetching }) => isFetching);

export const getPayoutsIsLoaded = createSelector(getPayoutsEntity, ({ isLoaded }) => isLoaded);

export const getPayoutsPage = createSelector(getPayoutsEntity, ({ page }) => page);
