import { Button, Stack } from '@karma/components';
import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

const WIDTH = 1070;
const HEIGHT = 434;

export const Container = styled.div``;

export const Slide = styled(Stack).attrs({
  alignItems: 'flex-start',
})`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`;

export const SlideContent = styled(Stack).attrs({
  direction: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
})`
  flex: 1 1 50%;
  height: ${HEIGHT}px;
  padding: 100px 62px 80px;
`;

export const SlideImage = styled.img`
  flex: 1 1 50%;
  height: ${HEIGHT}px;
`;

export const StyledButton = styled(Button).attrs({
  size: 'medium',
})`
  min-width: 146px;
`;

export const SlidePagination = styled(Stack).attrs({
  spacing: 16,
})``;

export const SlidePaginationItem = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ $active }) => ($active ? getColor('primary1') : getColor('primary2'))};
`;
