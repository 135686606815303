import styled from 'styled-components';
import { CheckboxRoot } from '../Checkbox/Checkbox.styles';
import { LabelLargeStyles, LabelMediumStyles } from '../Typography/Typography.styles';
import { ListItemWrapperProps, ListItemSecondaryTextProps } from './ListItem.types';

export const ListItemWrapper = styled.li<ListItemWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  cursor: pointer;

  background: ${({ selected, theme }) => {
    const colors = theme[theme.color].ListItem.color;

    return selected ? colors.focused : colors.normal;
  }};
  outline: none;

  &:hover,
  &:focus {
    background: ${({ selected, theme }) => {
      const colors = theme[theme.color].ListItem.color;

      return selected ? colors.focused : colors.hover;
    }};
  }

  color: ${({ disabled, theme }) => {
    const colors = theme[theme.color].ListItem.text;

    return disabled ? colors.disabled : colors.normal;
  }};

  transition: background 0.2s ease-in-out;
  box-sizing: border-box;

  &[disabled] {
    pointer-events: none;
    user-select: none;
  }

  ${LabelLargeStyles}
  padding: ${({ hasSecondaryText }) => (hasSecondaryText ? '20px 16px' : '8px 16px')};
`;

export const ListItemRoot = styled.div`
  display: inline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${CheckboxRoot} {
    pointer-events: none;
    margin-right: 12px;
  }
`;

export const SecondaryActionRoot = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: auto;
  ${LabelMediumStyles}
`;

export const ListItemSubtitle = styled.span<ListItemSecondaryTextProps>`
  ${LabelMediumStyles}
  display: block;
  margin-top: 5px;
  color: ${({ theme, disabled }) => {
    const colors = theme[theme.color].ListItem.subtitle;

    return disabled ? colors.disabled : colors.normal;
  }};
`;
