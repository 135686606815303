import { useState } from 'react';
import { ListItem, Select } from '@karma/components';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import { LANGUAGE_OPTIONS } from 'consts/locales';
import setUpUserLanguage from 'functions/language';
import useDefaultLanguageOption from 'hooks/useDefaultLanguageOption';

const UnauthorizedLanguageSelect = () => {
  const { t } = useTranslation();
  const defaultValue = useDefaultLanguageOption();

  const [language, setLanguage] = useState(defaultValue);

  const handleChange = selected => {
    if (selected !== language.value) {
      const selectedLanguage = LANGUAGE_OPTIONS.find(opt => opt.value === selected);

      setLanguage(selectedLanguage);
      setUpUserLanguage(selected);
    }
  };

  return (
    <Select
      id="language"
      name="language"
      label={t('app:selectedLanguage')}
      placeholder={t('app:language')}
      value={language.value}
      renderValue={() => language.label}
      onChange={handleChange}
      data-alooma-element={ALOOMA.ELEMENTS.LANGUAGE}
      fullWidth
    >
      {LANGUAGE_OPTIONS.map(({ label, value }) => (
        <ListItem key={value} value={value} tabIndex={0}>
          {label}
        </ListItem>
      ))}
    </Select>
  );
};

export default UnauthorizedLanguageSelect;
