import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SmileyIcon } from '@karma/components/icons';
import { TextField, Typography, Button } from '@karma/components';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useAction from 'hooks/useAction';
import { showErrorAlert } from 'store/alerts/actions';
import { addList } from 'store/lists/actions';
import { Container } from './ModalBodyListCreate.style';

const ModalBodyListCreate = ({ closeModal }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const showErrorAlertFunc = useAction(showErrorAlert);
  const addNewList = useAction(addList);

  const changeName = event => setName(event.target.value);

  const changeDesc = event => setDescription(event.target.value);

  const saveHandler = useCallback(() => {
    if (!name.trim()) {
      showErrorAlertFunc({ message: 'app:listCreateBlickError' });
    } else {
      const list = {
        name: name.trim(),
        description: description.trim() || null,
      };

      addNewList(list);
      closeModal();
    }
  }, [name, description, showErrorAlertFunc, addNewList, closeModal]);

  return (
    <Container>
      <SmileyIcon width={60} height={60} />
      <Typography variant="h3">{t('app:createANewList')}</Typography>
      <Alooma.SectionContext.Provider value={ALOOMA.SECTIONS.NAME}>
        <TextField
          value={name}
          label={t('app:listName')}
          placeholder={t('app:likeSneakersOrBlackFriday')}
          onChange={changeName}
          fullWidth
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.INPUT}
        />
      </Alooma.SectionContext.Provider>

      <Alooma.SectionContext.Provider value={ALOOMA.SECTIONS.DESCRIPTION}>
        <TextField
          multiline
          inputProps={{ rows: 2 }}
          value={description}
          label={t('app:listDescriptionOptional')}
          onChange={changeDesc}
          fullWidth
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.INPUT}
        />
      </Alooma.SectionContext.Provider>

      <Button
        size="small"
        onClick={saveHandler}
        fullWidth
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.SAVE}
      >
        {t('app:save')}
      </Button>
    </Container>
  );
};

ModalBodyListCreate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalBodyListCreate;
