import styled from 'styled-components';
import { getColor } from '@karma/components/colors';
import { Button, Stack, Typography } from '@karma/components';

export const CTAContainer = styled.a`
  all: unset;
  cursor: pointer;
`;

export const CardContainer = styled(Stack).attrs(props => ({
  ...props,
  fullWidth: true,
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}))`
  padding: 20px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || getColor('shades11')};
  border-radius: 10px;
  width: 100%;
  height: 310px;
`;

export const CTAText = styled(Typography)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
`;

export const CTAButton = styled(Button)`
  margin-top: auto;
`;
