import { useEffect } from 'react';

const useMarkUrlParam = (key, value) => {
  const markUrl = (set = true) => {
    const url = new URL(window.location);

    if (set) url.searchParams.set(key, value);
    else url.searchParams.delete(key);

    window.history.pushState('', '', url.href);
  };

  useEffect(() => {
    markUrl();

    return () => {
      markUrl(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useMarkUrlParam;
