import React from 'react';
import { BadgeContainer, StyledBadge } from './Badge.styles';
import { IBadgeProps } from './Badge.types';

const Badge: React.FC<IBadgeProps> = ({
  children,
  color = 'secondary2',
  positionX = 'right',
  positionY = 'top',
  show = true,
  ...props
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!show) return <>{children}</>;

  return (
    <BadgeContainer {...props}>
      {children}
      <StyledBadge color={color} positionX={positionX} positionY={positionY} />
    </BadgeContainer>
  );
};

export default Badge;
