import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CardMaker from '@karma/components/CardMaker';
import { StarIcon, StarIconFilled } from '@karma/components/icons';
import { Stack } from '@karma/components';
import { getRedirectLinkToRetailer } from '@karma/utils/functions/redirectLink';
import { showSuccessAlert } from 'store/alerts/actions';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import * as userSelectors from 'store/user/selectors';
import * as favoriteRetailersSelectors from 'store/favoriteRetailers/selectors';
import useAction from 'hooks/useAction';
import * as favoriteRetailersActions from 'store/favoriteRetailers/actions';
import { InputBox, StarWrapper, StyledButton, StyledStack, Text } from './RetailersCatalogueRecord.style';

const RetailersCatalogueRecord = ({ data, index, onRecordClick, record }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const isLastInGroup = Boolean(data.records[index + 1]?.symbol);

  const source = useContext(ReferralSourceContext);

  const { id: userId } = useSelector(userSelectors.getUser);
  const favoriteRetailersIds = useSelector(favoriteRetailersSelectors.getIds);

  const isFavoriteRetailer = favoriteRetailersIds.some(id => id === record.id);

  const showSuccessAlertFunc = useAction(showSuccessAlert);
  const requestCreateFavoriteRetailers = useAction(favoriteRetailersActions.requestCreateFavoriteRetailers);
  const requestDeleteFavoriteRetailers = useAction(favoriteRetailersActions.requestDeleteFavoriteRetailers);

  const handleClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      if (!isFavoriteRetailer) {
        requestCreateFavoriteRetailers([record.id]);
        showSuccessAlertFunc({ message: t('app:favStoresUpdated') });
      } else {
        requestDeleteFavoriteRetailers([record.id]);
        showSuccessAlertFunc({ message: t('app:favStoresUpdated') });
      }
    },
    [requestCreateFavoriteRetailers, requestDeleteFavoriteRetailers, isFavoriteRetailer, record.id],
  );

  const redirectToShop = e => {
    e.stopPropagation();

    const url = getRedirectLinkToRetailer({
      retailerId: record.self_id,
      userId,
      ruleName: record.name,
      source,
      cashback: userId && !!record.stake,
    });

    window.open(url, '_blank');
  };

  return (
    <InputBox
      {...contextDataAttrs}
      data-alooma-element={ALOOMA.ELEMENTS.RETAILER}
      $isLastInGroup={isLastInGroup}
      onClick={onRecordClick}
      data-index={index}
    >
      <StyledStack>
        <Stack direction="row" spacing={16}>
          <Text variant="labelLarge" noWrap>
            {record.name}
          </Text>
          {!!record.stake && <CardMaker>{t('app:earnKarmaCashShort', { percent: record.stake })}</CardMaker>}
        </Stack>
        <Stack>
          <StyledButton
            type="underlinedText"
            size="small"
            className="visit-store-button"
            onClick={redirectToShop}
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.VISIT_STORE}
          >
            {t('app:visitStorePage')}
          </StyledButton>
          <StarWrapper>
            {isFavoriteRetailer ? (
              <StarIconFilled color="secondary1" onClick={handleClick} />
            ) : (
              <StarIcon color="secondary1" onClick={handleClick} />
            )}
          </StarWrapper>
        </Stack>
      </StyledStack>
    </InputBox>
  );
};

export default RetailersCatalogueRecord;
