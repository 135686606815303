import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyItemSaved from 'components/molecules/ModalBodyItemSaved/ModalBodyItemSaved';
import Modal from 'components/templates/Modal/Modal';

const ModalItemSaved = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.ITEM_SAVED} onClose={closeModal}>
      <ModalBodyItemSaved {...props} />
    </Modal>
  );
};

ModalItemSaved.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalItemSaved;
