import React from 'react';
import Transition from 'react-transition-group/Transition';
import useForkRef from '../../utils/useForkRef';
import { GrowProps } from './Grow.types';

const getScale = value => `scale(${value}, ${value ** 2})`;

const Grow: React.FC<GrowProps> = ({
  in: inProp = false,
  timeout = 300,
  children,

  style,
  ref,

  onEnter,
  onEntered,
  onEntering,
  onExit,
  onExited,
  onExiting,

  ...props
}) => {
  const nodeRef = React.useRef(null);
  const foreignRef = useForkRef(children.props.ref, ref);
  const handleRef = useForkRef(nodeRef, foreignRef);

  const defaultTransition = `opacity ${
    (timeout / 100) * 60
  }ms cubic-bezier(0.4, 0, 0.2, 1), transform ${timeout}ms cubic-bezier(0.4, 0, 0.2, 1)`;
  const styles = {
    entering: {
      opacity: 1,
      transform: getScale(1),
      transition: defaultTransition,
    },
    entered: {
      opacity: 1,
      transform: 'none',
      transition: defaultTransition,
    },
  };

  return (
    <Transition
      {...props}
      in={inProp}
      onEnter={onEnter}
      onEntered={onEntered}
      onEntering={onEntering}
      onExit={onExit}
      onExited={onExited}
      onExiting={onExiting}
      timeout={timeout}
    >
      {(state, childProps) =>
        React.cloneElement(children, {
          style: {
            opacity: 0,
            transform: getScale(0.75),
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms ease-in-out`,
            ...styles[state],
            ...style,
            ...children.props.style,
          },
          ref: handleRef,
          ...childProps,
        })
      }
    </Transition>
  );
};

export default Grow;
