import PropTypes from 'prop-types';
import useMarkUrlParam from 'functions/useMarkUrlParam';
import SignUpFormNew from 'components/organisms/SignUpFormNew/SignUpFormNew';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';

const ModalBodyAuthenticate = props => {
  const { authState } = props;

  useMarkUrlParam(authState, 'true');

  return <SignUpFormNew {...props} />;
};

ModalBodyAuthenticate.propTypes = {
  closeModal: PropTypes.func.isRequired,
  authState: PropTypes.oneOf(Object.values(AUTHENTICATE_TYPE)).isRequired,
  setAuthState: PropTypes.func.isRequired,
};

export default ModalBodyAuthenticate;
