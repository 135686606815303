import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const LeafIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 13 12"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9686 1.20729C11.2421 1.17447 11.474 1.40644 11.4412 1.6799C11.3833 2.16224 11.3527 2.70682 11.3195 3.29768C11.316 3.36133 11.3124 3.42552 11.3087 3.49023C11.2713 4.14875 11.2272 4.85319 11.1346 5.54705C10.9511 6.9218 10.5684 8.33799 9.58441 9.32199C8.68909 10.2173 7.74916 10.8938 6.67666 10.9824C5.67781 11.0649 4.67457 10.6315 3.60104 9.6485L2.88751 10.362L2.28647 9.76099L3 9.04746C2.01698 7.97393 1.58364 6.97069 1.66612 5.97184C1.75469 4.89934 2.4312 3.95941 3.32651 3.06409C4.31046 2.08014 5.7267 1.69744 7.10147 1.51396C7.79533 1.42136 8.49978 1.37724 9.15831 1.33985C9.22297 1.33617 9.28711 1.33257 9.35072 1.329C9.94163 1.29579 10.4862 1.26519 10.9686 1.20729ZM4.20266 9.04688L8.08475 5.1648L7.4837 4.56376L3.60162 8.44584C2.72769 7.48309 2.45812 6.70927 2.51324 6.04179C2.57584 5.28376 3.06459 4.5281 3.92755 3.66513C4.706 2.88669 5.88407 2.53397 7.21391 2.35649C7.87234 2.26862 8.54777 2.22589 9.2065 2.18848C9.27157 2.18478 9.33656 2.18114 9.4014 2.1775C9.79296 2.15553 10.1791 2.13387 10.545 2.10354C10.5147 2.46935 10.493 2.85551 10.471 3.24704C10.4674 3.31192 10.4637 3.37695 10.4601 3.44205C10.4227 4.10078 10.3799 4.77619 10.2921 5.43461C10.1146 6.76441 9.76186 7.94245 8.98337 8.72095C8.1204 9.58391 7.36474 10.0727 6.60671 10.1353C5.93923 10.1904 5.16541 9.92081 4.20266 9.04688Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LeafIcon;
