function clearUrl(url) {
  return url && url.endsWith('/') ? url.slice(0, -1) : url;
}

export const ENV = process.env;
export const BASE_URL = clearUrl(
  ENV.REACT_APP_BASE_URL || (typeof window !== 'undefined' ? window.location.host : 'www.karmanow.com')
);
export const API_URL = clearUrl(ENV.API_URL || ENV.REACT_APP_API_URL || `https://${BASE_URL}/api/v2`);
export const NEW_API_URL = clearUrl(ENV.NEW_API_URL || ENV.REACT_APP_NEW_API_URL || `https://${BASE_URL}/api/v3`);
export const JAVA_API_URL = clearUrl(ENV.JAVA_API_URL || ENV.REACT_APP_JAVA_API_URL || `https://${BASE_URL}/ws`);
export const LAMBDA_API_URL = clearUrl(ENV.LAMBDA_API_URL || ENV.REACT_APP_LAMBDA_API_URL || `https://${BASE_URL}/api`);
export const WEBSITE_PATH = clearUrl(ENV.WEBSITE_PATH || ENV.REACT_APP_WEBSITE_PATH || `https://${BASE_URL}`);
export const CDN_URL = clearUrl(ENV.CDN_URL || ENV.REACT_APP_CDN_URL || `https://${BASE_URL}`);
export const WEBSOCKETS_PATH = clearUrl(
  ENV.WEBSOCKETS_PATH || ENV.REACT_APP_WEBSOCKETS_PATH || `wss://${BASE_URL}/cable`
);
