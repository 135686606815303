import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const DeliveryIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 16 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51335 1.27344L12.045 1.27344L12.7218 3.98086C12.9547 4.91257 13.5588 5.70837 14.3936 6.18321L15.9141 7.04807L15.9141 11.5782L1.84668 11.5782L1.84668 10.6068L14.9427 10.6068L14.9427 7.61309L13.9133 7.02759C12.8478 6.4215 12.0767 5.40573 11.7794 4.21646L11.2865 2.24487L4.51335 2.24487L4.51335 1.27344Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84668 4.75915C2.84668 4.4909 3.06414 4.27344 3.33239 4.27344L7.81811 4.27344L7.81811 5.24487L3.33239 5.24487C3.06414 5.24487 2.84668 5.0274 2.84668 4.75915Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.181641 8.00134C0.181641 7.73309 0.399102 7.51562 0.667355 7.51562L5.81974 7.51562L5.81974 8.48705L0.667355 8.48705C0.399102 8.48705 0.181641 8.26959 0.181641 8.00134Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3332 12.8699C10.8651 12.8699 10.4856 13.2494 10.4856 13.7175C10.4856 14.1856 10.8651 14.5651 11.3332 14.5651C11.8013 14.5651 12.1808 14.1856 12.1808 13.7175C12.1808 13.2494 11.8013 12.8699 11.3332 12.8699ZM9.51416 13.7175C9.51416 12.7129 10.3286 11.8984 11.3332 11.8984C12.3378 11.8984 13.1523 12.7129 13.1523 13.7175C13.1523 14.7221 12.3378 15.5365 11.3332 15.5365C10.3286 15.5365 9.51416 14.7221 9.51416 13.7175Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66573 12.8699C4.1976 12.8699 3.81811 13.2494 3.81811 13.7175C3.81811 14.1856 4.1976 14.5651 4.66573 14.5651C5.13385 14.5651 5.51335 14.1856 5.51335 13.7175C5.51335 13.2494 5.13385 12.8699 4.66573 12.8699ZM2.84668 13.7175C2.84668 12.7129 3.6611 11.8984 4.66573 11.8984C5.67036 11.8984 6.48478 12.7129 6.48478 13.7175C6.48478 14.7221 5.67036 15.5365 4.66573 15.5365C3.6611 15.5365 2.84668 14.7221 2.84668 13.7175Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DeliveryIcon;
