import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ConeIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.376 3.08923C12.3595 3.0995 12.3394 3.11717 12.3219 3.15747L10.9776 6.25495C11.9632 6.35141 12.9558 6.35141 13.9413 6.25495L12.5971 3.15747C12.5796 3.11717 12.5594 3.0995 12.5429 3.08923C12.5227 3.07664 12.4938 3.06719 12.4595 3.06719C12.4252 3.06719 12.3963 3.07664 12.376 3.08923ZM14.6488 7.885C13.1954 8.07472 11.7236 8.07472 10.2702 7.885L8.66293 11.5886C11.178 11.9897 13.7409 11.9897 16.256 11.5886L14.6488 7.885ZM16.9533 13.1952C13.9805 13.7208 10.9385 13.7208 7.9657 13.1952L6.3684 16.8758C10.3781 17.7858 14.5409 17.7858 18.5506 16.8758L16.9533 13.1952ZM10.7624 2.48069C11.4067 0.996027 13.5122 0.996016 14.1566 2.48069L20.2325 16.4811C20.59 17.3048 20.1168 18.2514 19.2435 18.4599C14.7835 19.5245 10.1355 19.5245 5.6755 18.4599C4.80219 18.2514 4.32902 17.3048 4.68646 16.4811L10.7624 2.48069Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5063 15.8218L23.8684 17.0571C24.7421 17.3045 24.8039 18.5194 23.9598 18.8542L13.2388 23.1062C13.0189 23.1934 12.7744 23.1954 12.5531 23.1119L1.26891 18.8537C0.421957 18.5341 0.459564 17.3234 1.32472 17.0569L5.3176 15.8273L5.81794 17.452L3.91915 18.0368L12.8818 21.4189L21.2759 18.0898L19.0431 17.4575L19.5063 15.8218Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ConeIcon;
