import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Loader } from '@karma/components';
import * as userSelectors from 'store/user/selectors';
import { PATHS } from 'consts/paths';
import { isMobile } from 'functions/isMobile';
import { redirectToMobileApp } from 'functions/redirectToMobileApp';
import useQueryParams from 'hooks/useQueryParams';
import storage from 'services/storage';
import { handleServerError } from 'store/alerts/actions';
import { newApiInstance } from 'functions/request';
import useAction from 'hooks/useAction';
import { MODAL_TYPES } from 'consts/modals';

const AuthorizedRoute = props => {
  const { component: Component, relatedUnauthorizedLink, render, ...otherProps } = props;

  const history = useHistory();
  const queryParams = useQueryParams();

  const shouldDoActionBefore = otherProps.path === PATHS.ITEMS && queryParams.action === 'save_tag';
  const [isInitialActionCompleted, setIsInitialActionCompleted] = useState(!shouldDoActionBefore);

  const dispatchHandleServerError = useAction(handleServerError);

  const isUserLoaded = useSelector(userSelectors.getLoaded);
  const isUserAuthorized = Boolean(useSelector(userSelectors.getUserId));

  const redirect = useCallback(
    basePath => {
      const pathMatches = basePath.match(/(\$[^/]+)/g);
      const params = { search: otherProps.location.search, ...otherProps.computedMatch.params };
      const path = pathMatches?.length
        ? pathMatches.reduce((result, pathMatch) => {
            return result.replace(pathMatch, params[pathMatch.substring(1)]);
          }, basePath)
        : basePath;

      return <Redirect to={path} />;
    },
    [otherProps.location, otherProps.computedMatch],
  );

  useEffect(() => {
    const createTag = async () => {
      try {
        const { tag: tag_uid, user: user_uid, token } = queryParams;

        await newApiInstance.post('/tags/create_from_uid', { tag_uid, user_uid, token });
      } catch (error) {
        dispatchHandleServerError(error);
      }
    };

    const initialAction = async () => {
      await createTag();

      if (isMobile()) {
        redirectToMobileApp('karma://items');
      }

      if (!storage.getToken()) {
        history.replace(`${PATHS.WEBSITE_LANDING_PATH}${isMobile() ? '' : `?modals=${MODAL_TYPES.logIn}`}`);
      } else {
        history.replace({ search: '' });
      }

      setIsInitialActionCompleted(true);
    };

    if (!isInitialActionCompleted) {
      initialAction();
    }
  }, [isInitialActionCompleted]);

  return (
    <Route
      {...otherProps}
      render={renderProps => {
        if (isInitialActionCompleted && isUserLoaded) {
          if (isUserAuthorized) {
            if (Component) {
              return <Component {...renderProps} />;
            }

            if (render) {
              return render(renderProps);
            }
          }

          // Redirects unauthorized users
          return redirect(relatedUnauthorizedLink);
        }

        return <Loader absolute />;
      }}
    />
  );
};

AuthorizedRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  relatedUnauthorizedLink: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  render: PropTypes.func,
};

AuthorizedRoute.defaultProps = {
  relatedUnauthorizedLink: PATHS.WEBSITE_LANDING_PATH,
};

export default AuthorizedRoute;
