import { javaApiInstance, oldApiInstance } from 'functions/request';

export const getEarnings = ({ filter, page }) =>
  javaApiInstance.get('/api/my/v1/earnings', {
    params: {
      filter,
      page,
    },
  });

export const getPayouts = ({ filter, page }) =>
  oldApiInstance.get('/plus/payouts', {
    params: {
      filter,
      page,
    },
  });

export const confirmPayPal = ({ token }) => oldApiInstance.post('/plus/paypal_confirm', { token });
