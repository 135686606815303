import styled, { css } from 'styled-components';
import omitForwardedProps from '../utils/omitForwardedProps';
import { StackBaseProps } from './Stack.types';

export const StackContainer = styled.div.withConfig(
  omitForwardedProps<StackBaseProps>([
    'direction',
    'justifyContent',
    'alignItems',
    'spacing',
    'fullWidth',
  ])
)<StackBaseProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ spacing }) => spacing && `${spacing}px`};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  margin-bottom: ${({ bMargin }) => bMargin || 0}px;
  margin-left: ${({ lMargin }) => lMargin || 0}px;
  margin-right: ${({ rMargin }) => rMargin || 0}px;
  margin-top: ${({ tMargin }) => tMargin || 0}px;
`;
