import { DEFAULT_LANGUAGE, I18N_CODE_TO_LOCALE } from 'consts/locales';
import { isClient } from 'functions/isClient';

const getLanguage = () => {
  if (isClient()) {
    const language = localStorage.getItem('language');

    return I18N_CODE_TO_LOCALE[language] || DEFAULT_LANGUAGE;
  }

  return DEFAULT_LANGUAGE;
};

export default getLanguage;
