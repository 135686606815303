import { getRedirectLinkToRetailer } from '@karma/utils/functions/redirectLink';
import * as ALOOMA from 'consts/alooma';
import { getCookieValue } from 'functions/cookies';

const getRetailerLinkProps = ({ retailer, source, userId }) => {
  const { store_page_available, self_id: id, name, stake } = retailer;

  const aloomaAttrs = {
    [ALOOMA.ATTRS.ELEMENT]: ALOOMA.ELEMENTS.RETAILER,
    [ALOOMA.ATTRS.PRIORITY]: retailer.priority,
    [ALOOMA.ATTRS.ABSOLUTE_POSITION]: retailer.absolutePosition,
    [ALOOMA.ATTRS.RELATIVE_POSITION]: retailer.relativePosition,
  };

  if (store_page_available && stake) {
    // ! Forwards to designated store

    return getCookieValue('logged')
      ? // ! Defines link for authorized user
        {
          href: `/retailer/${encodeURIComponent(name)}/${id}`,
          as: 'a',
          target: '_blank',
          rel: 'noreferrer noopener',
          ...aloomaAttrs,
        }
      : // ! Defines link for unauthorized user
        {
          href: `/karma-cash/${encodeURIComponent(name)}/${id}`,
          as: 'a',
          target: '_blank',
          rel: 'noreferrer noopener',
          ...aloomaAttrs,
        };
  }

  // ! Redirects to retailer's site
  return {
    ...aloomaAttrs,
    as: 'a',
    target: '_blank',
    rel: 'noreferrer noopener nofollow',
    href: getRedirectLinkToRetailer({
      retailerId: id,
      ruleName: name,
      userId,
      cashback: !!(userId && stake),
      source,
    }),
  };
};

export default getRetailerLinkProps;
