import styled, { css } from 'styled-components';
import { getColor } from '../colors';
import { TabRootProps } from './Tab.types';

export const StyledTab = styled.button<TabRootProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 17px;
  width: 50%;
  height: 32px;
  border-radius: 100px;
  gap: 8px;

  background-color: ${({ active, isTabChild }) =>
    active ? getColor('primary1') : getColor(isTabChild ? 'neutral4' : 'neutral5')};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active }) => (active ? getColor('neutral6') : getColor('primary1'))};

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        background-color: ${getColor('primary2')};
      }
    `}
`;
