import { createSelector } from '@reduxjs/toolkit';

const getDashboardInterface = state => state.dashboardInterface;

export const getKustomer = createSelector(
  [getDashboardInterface],
  dashboardInterface => dashboardInterface.showKustomerOpened,
);

export const showSearchTooltipSelector = createSelector(
  getDashboardInterface,
  ({ showSearchTooltip }) => showSearchTooltip,
);
