import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AccountIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M12.9961 12.1426C16.1909 12.1426 18.7809 9.68015 18.7809 6.64258C18.7809 3.60501 16.1909 1.14258 12.9961 1.14258C9.80124 1.14258 7.2113 3.60501 7.2113 6.64258C7.2113 9.68015 9.80124 12.1426 12.9961 12.1426Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9961 23.1426C23.2488 20.9271 21.7809 18.9945 19.804 17.6234C17.827 16.2522 15.4432 15.5134 12.9961 15.5134C10.549 15.5134 8.16518 16.2522 6.18823 17.6234C4.21127 18.9945 2.74335 20.9271 1.99609 23.1426H12.9961H23.9961Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AccountIcon;
