import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AdjustIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 16 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5974 5.56701C14.3442 6.58183 13.4266 7.33366 12.3333 7.33366C11.2401 7.33366 10.3225 6.58183 10.0693 5.56701H0V4.43368H10.0693C10.3225 3.41884 11.2401 2.66699 12.3333 2.66699C13.4266 2.66699 14.3442 3.41884 14.5974 4.43368L16 4.43368V5.56701L14.5974 5.56701ZM12.3333 6.20033C12.9961 6.20033 13.5333 5.66307 13.5333 5.00033C13.5333 4.33758 12.9961 3.80033 12.3333 3.80033C11.6706 3.80033 11.1333 4.33758 11.1333 5.00033C11.1333 5.66307 11.6706 6.20033 12.3333 6.20033Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.93071 11.567C5.67752 12.5818 4.75989 13.3337 3.66667 13.3337C2.57344 13.3337 1.65581 12.5818 1.40262 11.567L0 11.567V10.4337L1.40262 10.4337C1.65579 9.41884 2.57343 8.66699 3.66667 8.66699C4.75991 8.66699 5.67754 9.41884 5.93072 10.4337H16V11.567H5.93071ZM3.66667 12.2003C4.32941 12.2003 4.86667 11.6631 4.86667 11.0003C4.86667 10.3376 4.32941 9.80033 3.66667 9.80033C3.00393 9.80033 2.46667 10.3376 2.46667 11.0003C2.46667 11.6631 3.00393 12.2003 3.66667 12.2003Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AdjustIcon;
