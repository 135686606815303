import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CollapseArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7402 17.4812L22.5404 18.6856L12.6607 8.84309L2.7408 18.7443L1.53986 17.5411L12.6596 6.44232L23.7402 17.4812Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CollapseArrowIcon;
