export const ITEMS = {
  clickDealsClickId: 'clickDeals_clickID',
  connectPPClickExpires: 'connectPPClickExpires',
  features: 'features-store',
  lastRetailersTableRefreshNumber: 'last_retailers_table_refresh_number',
  targetRetailersTableRefreshNumber: 'target_retailers_table_refresh_number',
  token: 'token',
  userId: 'user_id',
  walletButtonClicked: 'wallet-button-clicked',
  xuid: 'xuid',
  isLoggingEnabled: 'logger',
  lpTest: 'lp_test',
  isBrazeLoggingEnabled: 'braze_logger',
  webPushResubscribed: 'web_push_resubscribed',
  guestBalance: 'guestBalance',
  isDownloadAppBannerShown: 'isDownloadAppBannerShown',
  isSignUpBannerShown: 'isSignUpBannerShown',
  isDownloadExtensionBannerShown: 'isDownloadExtensionBannerShown',
  storage: 'storage',
  completeSignUpModalDelay: 'completeSignUpModalDelay',
  congratulateSignupModal: 'congratulateSignupModal',
  sessionPair: 'sessionPair',
  showListsTooltip: 'showListsTooltip',
  isUserResubscribed: 'isUserResubscribed',
  globalRecentSearches: 'globalRecentSearches',
  brazeToken: 'braze_token',
  isXPlatformOnSearchClosed: 'isXPlatformOnSearchClosed',
  isXPlatformOnHomeClosed: 'isXPlatformOnHomeClosed',
  sweOnboardingUserId: 'sweOnboardingUserId',
  sweOnboardingEventSent: 'sweOnboardingEventSent',
};
