import * as yup from 'yup';

const emailValidation = t =>
  yup.string().email(t('app:pleaseEnterValidEmailAddress')).required(t('app:enterYourEmail'));

const passwordValidation = t =>
  yup
    .string()
    .test(
      'pass',
      t('app:passwordValidationNote'),
      value => value?.length >= 8 && /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value),
    )
    .required(t('app:enterYourPassword'));

const getEmailPasswordFormSchema = ({ t, signUp = false }) =>
  yup
    .object({
      email: emailValidation(t),
      password: signUp ? passwordValidation(t) : yup.string().required(t('app:enterYourPassword')),
    })
    .required();

export default getEmailPasswordFormSchema;
