import { useTranslation } from 'react-i18next';
import { Icon, Title, Text, Wrapper } from './ModalBodyUnderMaintenance.style';

const ModalBodyUnderMaintenance = () => {
  const { t } = useTranslation();

  return (
    <Wrapper direction="column" alignItems="center">
      <Icon width="60" height="60" />
      <Title variant="h3">{t('app:weAreBusySelfImproving')}</Title>
      <Text variant="paragraphMedium">{t('app:sorryForInconvenience')}</Text>
    </Wrapper>
  );
};

export default ModalBodyUnderMaintenance;
