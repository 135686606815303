import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ArrowInCircleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0027 13.4845L5.27539 13.4845L5.27539 11.7845L20.0027 11.7845L20.0027 13.4845Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9598 6.74265C14.9598 9.52666 17.2167 11.7836 20.0007 11.7836V13.4836C16.2778 13.4836 13.2598 10.4655 13.2598 6.74265H14.9598Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 13.4837C17.2179 13.4837 14.961 15.7406 14.961 18.5246L13.261 18.5246C13.261 14.8017 16.2791 11.7837 20.002 11.7837L20.002 13.4837Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6383 2.19265C7.14305 2.19265 2.68828 6.64741 2.68828 12.1426C2.68828 17.6379 7.14305 22.0926 12.6383 22.0926C18.1335 22.0926 22.5883 17.6379 22.5883 12.1426C22.5883 6.64741 18.1335 2.19265 12.6383 2.19265ZM0.988281 12.1426C0.988281 5.70853 6.20416 0.492645 12.6383 0.492645C19.0724 0.492645 24.2883 5.70853 24.2883 12.1426C24.2883 18.5768 19.0724 23.7926 12.6383 23.7926C6.20416 23.7926 0.988281 18.5768 0.988281 12.1426Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ArrowInCircleIcon;
