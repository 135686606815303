import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CheckInCircleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <g clipPath="url(#clip0_16893_187779)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9996 2.04979C6.50438 2.04979 2.04961 6.50456 2.04961 11.9998C2.04961 17.495 6.50438 21.9498 11.9996 21.9498C17.4948 21.9498 21.9496 17.495 21.9496 11.9998C21.9496 6.50456 17.4948 2.04979 11.9996 2.04979ZM0.349609 11.9998C0.349609 5.56568 5.56549 0.349792 11.9996 0.349792C18.4337 0.349792 23.6496 5.56568 23.6496 11.9998C23.6496 18.4339 18.4337 23.6498 11.9996 23.6498C5.56549 23.6498 0.349609 18.4339 0.349609 11.9998Z"
          fill="currentColor"
        />
        <g clipPath="url(#clip1_16893_187779)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0982 8.89336L18.0177 8.96293L18.0101 8.96918C15.4734 11.0589 13.1524 13.397 11.0777 15.9525L10.372 16.8217L9.67989 15.8456C8.59009 14.4073 7.30005 13.1184 5.85938 12.0199L6.89016 10.6681C8.1952 11.6632 9.3875 12.8034 10.4354 14.0639C12.4034 11.7401 14.573 9.59886 16.9215 7.66336L16.9872 7.60663L18.0982 8.89336Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16893_187779">
          <rect width={24} height={24} fill="transparent" />
        </clipPath>
        <clipPath id="clip1_16893_187779">
          <rect width={12} height={12} fill="transparent" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default CheckInCircleIcon;
