import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as braze from '@braze/web-sdk';
import { getModals } from 'store/modals/selectors';
import { getLoaded } from 'store/user/selectors';
import { getLoaded as getLoadedOnboarding } from 'store/onboarding/selectors';
import { WEB_READY_TO_INAPP_MESSAGE } from 'consts/braze';
import { PATHS } from 'consts/paths';

export default () => {
  const isEventSent = useRef(false);
  const { pathname } = useLocation();
  const modals = useSelector(getModals);
  const isUserLoaded = useSelector(getLoaded);
  const isOnboardingLoaded = useSelector(getLoadedOnboarding);

  useEffect(() => {
    if (!isEventSent.current) {
      const isAvailableLocation = [PATHS.HOME, PATHS.DASHBOARD, PATHS.ITEMS].includes(pathname);

      if (isAvailableLocation && !modals.length && braze.initialize() && isUserLoaded && isOnboardingLoaded) {
        isEventSent.current = true;
        braze.logCustomEvent(WEB_READY_TO_INAPP_MESSAGE);
      }
    }
  }, [pathname, modals, isUserLoaded, isOnboardingLoaded]);
};
