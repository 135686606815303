import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as userActions from '../actions';
import * as userApi from '../api';

function* unsubscribePP() {
  try {
    yield call(userApi.unsubscribePP);
    yield put(userActions.removePPAccount());
  } catch (error) {
    yield put(handleServerError(error?.response));
  }
}

export default unsubscribePP;
