import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import { requestTagsBulkDelete } from 'store/multiEdit/actions';
import useAction from 'hooks/useAction';
import ModalBodyTags from 'components/molecules/ModalBodyTags/ModalBodyTags';
import Modal from 'components/templates/Modal/Modal';

const ModalTagsDelete = props => {
  const { closeModal } = props;

  const requestTagsBulkDeleteFunc = useAction(requestTagsBulkDelete);

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.TAGS_DELETE} onClose={closeModal}>
      <ModalBodyTags
        title="app:sureYouWantToDeleteTheseNumberItems"
        primaryButtonText="app:deleteProducts"
        onSubmit={requestTagsBulkDeleteFunc}
        {...props}
      />
    </Modal>
  );
};

ModalTagsDelete.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalTagsDelete;
