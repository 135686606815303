import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BackCircleArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 14 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.07874 0.601563L4.88164 1.40144L1.80022 4.49451L4.90104 7.60119L4.09889 8.40182L0.199707 4.49526L4.07874 0.601563Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.9375L8 3.9375C11.0744 3.9375 13.5667 6.42978 13.5667 9.50417C13.5667 12.5786 11.0744 15.0708 8 15.0708L1 15.0708L1 13.9375L8 13.9375C10.4485 13.9375 12.4333 11.9526 12.4333 9.50417C12.4333 7.0557 10.4485 5.07083 8 5.07083L1 5.07083L1 3.9375Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BackCircleArrowIcon;
