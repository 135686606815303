import { Stack, Typography } from '@karma/components';
import { useTranslation } from 'react-i18next';
import { BookmarkIcon } from '@karma/components/icons';
import GoToStoreButton from 'components/atoms/GoToStoreButton/GoToStoreButton';
import { EmptyStateTitlesContainer } from './StoreInfoEmptyState.style';

const StoreInfoEmptyState = ({ retailer }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" fullWidth spacing={24}>
      <BookmarkIcon width={60} height={60} />
      <EmptyStateTitlesContainer direction="column" spacing={8}>
        <Typography variant="h4">{t('app:nothingSavedYet')}</Typography>
        <Typography variant="paragraphLarge">
          {t('app:useOurBrowserExtension', { retailerName: retailer.name })}
        </Typography>
      </EmptyStateTitlesContainer>
      <GoToStoreButton
        retailer={retailer}
        buttonProps={{
          size: 'small',
        }}
      />
    </Stack>
  );
};

export default StoreInfoEmptyState;
