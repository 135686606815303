import { call, put } from 'redux-saga/effects';
import { COUPONS_GROUPS } from 'consts/couponsGroups';
import { DEFAULT_PER_PAGE_COUPONS } from 'consts/queries';
import { handleServerError } from 'store/alerts/actions';
import * as couponsActions from '../actions';
import * as couponsApi from '../api';

const SORT_BY = {
  popular: 'popularity',
  newest: 'newest',
  mixed: 'newest',
};

function* fetchCouponsGroup({ payload: { group, page = 1, perPage = DEFAULT_PER_PAGE_COUPONS, retailers } }) {
  try {
    const request = [COUPONS_GROUPS.mixed, COUPONS_GROUPS.custom, COUPONS_GROUPS.mixedForRetailer].includes(group)
      ? couponsApi.getCouponsWithAds
      : couponsApi.getCouponsGroup;

    const response = yield call(request, {
      sortBy: SORT_BY[group],
      page,
      perPage,
      retailers,
    });

    yield put(
      couponsActions.receiveCouponsGroup({
        group,
        coupons: response.data.data,
        page,
        couponsLeft: response.data.data.length === DEFAULT_PER_PAGE_COUPONS,
        isDirty: !!retailers,
      }),
    );
  } catch (error) {
    yield put(
      couponsActions.receiveCouponsGroup({
        group,
        coupons: [],
        page,
        couponsLeft: true,
        isDirty: !!retailers,
      }),
    );
    yield put(handleServerError(error));
  }
}

export default fetchCouponsGroup;
