import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AccountIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <g clipPath="url(#clip0_6893_151950)">
        <path
          d="M11.9992 12.0003C15.0762 12.0003 17.5706 9.50587 17.5706 6.42885C17.5706 3.35184 15.0762 0.857422 11.9992 0.857422C8.92215 0.857422 6.42773 3.35184 6.42773 6.42885C6.42773 9.50587 8.92215 12.0003 11.9992 12.0003Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5938 23.1423C21.8741 20.8981 20.4604 18.9404 18.5563 17.5514C16.6523 16.1625 14.3564 15.4141 11.9996 15.4141C9.64275 15.4141 7.34683 16.1625 5.44279 17.5514C3.53875 18.9404 2.12497 20.8981 1.40527 23.1423H22.5938Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6893_151950">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default AccountIconFilled;
