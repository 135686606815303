import styled from 'styled-components';
import { getColor } from '../colors';
import Typography from '../Typography';
import { RadioBaseProps } from './Radio.types';

export const RadioButtonContainer = styled.label<RadioBaseProps>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: fit-content;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const RadioContainer = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
`;

export const RadioButton = styled.div<RadioBaseProps>`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ checked, disabled }) => {
    if (checked) return disabled ? getColor('neutral4') : getColor('secondary1');

    return getColor('neutral6');
  }};

  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? getColor('neutral4') : getColor('secondary1'))};
  border-radius: 50%;
  transition: 200ms background-color ease-in-out;
`;

export const RadioButtonPoint = styled.span<RadioBaseProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${getColor('neutral6')};
  opacity: ${({ checked }) => (checked ? 1 : 0)};
`;

export const RadioButtonInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 0;
  width: 0;
`;

export const StyledLabel = styled(Typography)`
  padding-left: 8px;
`;
