import { useSelector } from 'react-redux';
import { getAlerts } from 'store/alerts/selectors';
import Alert from 'components/molecules/Alert/Alert';
import { AlertsContainer } from './AlertsPortal.style';

const AlertsPortal = () => {
  const alerts = useSelector(getAlerts);

  return (
    <AlertsContainer>
      {alerts.map(alert => (
        <Alert key={alert.id} alert={alert} />
      ))}
    </AlertsContainer>
  );
};

export default AlertsPortal;
