import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: false,
  excluded: [],
  included: [],
};

const multiEditSlice = createSlice({
  name: 'multiEdit',
  initialState,
  reducers: {
    deleteTag: (state, { payload: id }) => ({
      ...state,
      excluded: state.excluded.filter(i => i !== id),
      included: state.included.filter(i => i !== id),
    }),

    reset: () => initialState,

    resetExcluded: state => ({
      ...state,
      excluded: [],
    }),

    toggleAll: state => ({
      excluded: [],
      included: [],
      all: !state.all,
    }),

    toggleTag: (state, { payload: id }) => {
      return {
        ...state,
        included: state.included.includes(id) ? state.included.filter(i => i !== id) : [...state.included, id],
      };
    },

    toggleIncludedTags: (state, { payload }) => {
      return {
        ...state,
        included: [...payload.items],
      };
    },
  },
});

export default multiEditSlice;
