import { createSelector } from '@reduxjs/toolkit';

const getRootEntity = ({ retailers }) => {
  return retailers;
};

export const getFilters = createSelector(
  [getRootEntity],
  ({ activeCategories: categories, search, sortBy, sortOrder }) => {
    const filters = { categories, search, sortOrder };

    if (sortBy) {
      filters.sortBy = sortBy;
    }

    return filters;
  },
);

export const getLoadMoreData = createSelector([getRootEntity], ({ isFetching, page, retailersLeft }) => {
  return {
    isFetching,
    page,
    retailersLeft,
  };
});

export const getLoaded = createSelector([getRootEntity], ({ loaded }) => {
  return loaded;
});

export const getRetailersList = createSelector([getRootEntity], ({ retailers }) => {
  return retailers;
});

export const getRetailersLeft = createSelector([getRootEntity], ({ retailersLeft }) => {
  return retailersLeft;
});

export const getSectionName = createSelector([getRootEntity], ({ sectionName }) => {
  return sectionName;
});
