export const DEFAULT_RESPONSE_COUNT = 20;

export const ENTITIES = {
  earnings: 'earnings',
  payouts: 'payouts',
};

export const FILTERS = {
  all: 'all',
  lastWeek: 'last_week',
  lastMonth: 'last_month',
  lastThreeMonths: 'last_three_months',
};

export const JAVA_FILTERS = {
  all: 'all',
  lastWeek: 'last-week',
  lastMonth: 'last-month',
  lastThreeMonths: 'last-three-months',
};

export const OPTIONS = [
  { value: FILTERS.all, label: 'app:allTime' },
  { value: FILTERS.lastWeek, label: 'app:lastWeek' },
  { value: FILTERS.lastMonth, label: 'app:lastMonth' },
  { value: FILTERS.lastThreeMonths, label: 'app:lastThreeMonths' },
];

export const JAVA_OPTIONS = [
  { value: JAVA_FILTERS.all, label: 'app:allTime' },
  { value: JAVA_FILTERS.lastWeek, label: 'app:lastWeek' },
  { value: JAVA_FILTERS.lastMonth, label: 'app:lastMonth' },
  { value: JAVA_FILTERS.lastThreeMonths, label: 'app:lastThreeMonths' },
];

export const TRANSACTION_TYPES = {
  bonus: 'Plus::Bonus',
  karmaCash: 'Plus::KarmaCash',
  karmaPayRedemption: 'Plus::Redemption',
};
