import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const IosShareIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7936 8.95841L20.1328 9.21284L19.1128 10.5728L18.7736 10.3184C16.5737 8.66852 14.6043 6.73193 12.9178 4.56008L12.6208 4.17771L11.7366 5.25714C10.3353 6.96776 8.7541 8.52276 7.02034 9.89533L6.18166 10.5593L5.12646 9.2264L5.96514 8.56245C7.60337 7.26552 9.0974 5.79621 10.4215 4.17985L12.6563 1.45172L14.2604 3.51739C15.8541 5.56956 17.7149 7.39944 19.7936 8.95841Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 3.2366L13.4891 17.8147L11.7891 17.8147L11.7891 3.2366L13.4891 3.2366Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98906 11.6666V20.6353C2.98906 21.2705 3.50394 21.7853 4.13906 21.7853H21.1391C21.7742 21.7853 22.2891 21.2705 22.2891 20.6353V11.6666H23.9891V20.6353C23.9891 22.2094 22.7131 23.4853 21.1391 23.4853H4.13906C2.56505 23.4853 1.28906 22.2094 1.28906 20.6353V11.6666H2.98906Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default IosShareIcon;
