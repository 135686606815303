import { createSelector } from '@reduxjs/toolkit';
import { ON_SALE, BACK_IN_STOCK, LOW_IN_STOCK } from 'consts/staticFilterKeys';

const rootSelector = state => state.filters;

export const getFilters = createSelector([rootSelector], filters => filters);

export const staticFilter = createSelector([getFilters], filters => filters.staticFilter);

export const getListsFilters = createSelector([getFilters], filters => filters.lists);

export const getFiltersAreDirty = createSelector([getFilters], filters => {
  return [ON_SALE, BACK_IN_STOCK, LOW_IN_STOCK].some(filter => filters.staticFilter[filter]);
});
