import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadedUnseenCount: false,
  unseenCount: 0,
  isLoadedSortedNotifications: false,
  sortByDateNotifications: [],
  showNotificationsMenu: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    successCreateNotificationRemindIn(state, action) {
      const { notifications: oldNotifications } = state.entities;
      const notifications = {};

      Object.entries(oldNotifications).forEach(([uuid, notification]) => {
        if (uuid === String(action.payload)) {
          notifications[uuid] = { ...notification, in_reminder_queue: true };
        } else {
          notifications[uuid] = notification;
        }
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          notifications,
        },
      };
    },

    successDeleteNotification(state, action) {
      const { sortByDateNotifications } = state;

      return {
        ...state,
        sortByDateNotifications: [...sortByDateNotifications.filter(({ uuid }) => uuid !== action.payload)],
      };
    },

    successMuteNotification(state, { payload: { uuid, prevent_notifications } }) {
      const { sortByDateNotifications } = state;

      return {
        ...state,
        sortByDateNotifications: [
          ...sortByDateNotifications.map(elem => {
            return elem.uuid === uuid
              ? {
                  ...elem,
                  tag: {
                    ...elem.tag,
                    prevent_notifications,
                  },
                }
              : elem;
          }),
        ],
      };
    },

    successDeleteNotifications() {
      return {
        ...initialState,
        isLeft: false,
        isLoadedNotifications: true,
        isLoadedUnseenCount: true,
      };
    },

    successFetchUnseenCount(state, action) {
      return {
        ...state,
        isLoadedUnseenCount: true,
        unseenCount: action.payload,
      };
    },

    successUpdateMarkAsSeen(state) {
      return {
        ...state,
        unseenCount: 0,
      };
    },

    successFetchSortByDateNotifications(state, action) {
      return {
        ...state,
        isLoadedSortedNotifications: true,
        sortByDateNotifications: action.payload,
      };
    },

    setShowNotificationsMenu(state, action) {
      return {
        ...state,
        showNotificationsMenu: action.payload,
      };
    },

    toggleShowNotificationsMenu(state) {
      return {
        ...state,
        showNotificationsMenu: !state.showNotificationsMenu,
      };
    },
  },
});

export default notificationsSlice.reducer;
