import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { getColor } from '@karma/components/colors';
import { Typography } from '@karma/components';

export const Modal = styled(BootstrapModal)`
  & .modal-dialog {
    width: 650px;
    margin: 30px auto 0;
    transition: all 0.3s;

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  & .modal-content {
    min-height: 236px;
    border-radius: 10px;
    padding: 43px 24px;
    background-color: ${getColor('neutral6')};
    border: 0;
  }
`;

export const Body = styled(BootstrapModal.Body)`
  position: relative;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
`;

export const Logo = styled.img`
  max-width: 90px;
  user-select: none;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TypographyAsLink = styled(Typography)`
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;

export const BottomContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
`;
