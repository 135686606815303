import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const HomeIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08867 7.34262V20.2426C5.08867 20.6016 5.37969 20.8926 5.73867 20.8926H19.5387C19.8977 20.8926 20.1887 20.6016 20.1887 20.2426V7.34262H21.8887V20.2426C21.8887 21.5405 20.8365 22.5926 19.5387 22.5926H5.73867C4.4408 22.5926 3.38867 21.5405 3.38867 20.2426V7.34262H5.08867Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6387 1.4541L14.4844 2.93072C17.3666 5.23646 20.9477 6.49263 24.6387 6.49263V8.19263C20.5617 8.19263 16.6061 6.80508 13.4225 4.2582L12.6387 3.63116L11.8549 4.2582C8.67128 6.80508 4.71567 8.19263 0.638672 8.19263V6.49263C4.32965 6.49263 7.91073 5.23646 10.7929 2.93072L12.6387 1.4541Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38867 14.3426C9.38867 13.3209 10.2169 12.4926 11.2387 12.4926H14.0387C15.0604 12.4926 15.8887 13.3209 15.8887 14.3426V21.7426H14.1887V14.3426C14.1887 14.2598 14.1215 14.1926 14.0387 14.1926H11.2387C11.1558 14.1926 11.0887 14.2598 11.0887 14.3426V21.7426H9.38867V14.3426Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HomeIcon;
