import { Typography } from '@karma/components';
import { getColor } from '@karma/components/colors';
import styled from 'styled-components';

export const ListTag = styled(Typography).attrs(props => ({ ...props, variant: 'labelMedium' }))`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${getColor('neutral3')};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const ListTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
