import * as React from 'react';

export default function setRef<T>(
  ref: React.MutableRefObject<T | null> | ((instance: T | null) => void) | null | undefined,
  value: T | null
): void {
  if (typeof ref === 'function') ref(value);
  // eslint-disable-next-line no-param-reassign
  else if (ref) ref.current = value;
}
