import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as favoriteRetailersActions from '../actions';
import * as favoriteRetailersApi from '../api';

function* fetchFavoriteRetailers() {
  try {
    const response = yield call(favoriteRetailersApi.fetchFavoriteRetailers, { perPage: 9999 });

    yield put(favoriteRetailersActions.successFetchFavoriteRetailers(response.data.retailers));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchFavoriteRetailers;
