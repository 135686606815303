import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TrendDownIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 16 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6119 6.06384L14.4862 6.06172L14.4733 10.9146L9.58545 10.9211L9.58651 12.0469L15.5969 12.0393L15.6119 6.06384Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54809 5.75178L14.6836 11.7891L15.4785 10.9812L8.53907 4.1529L5.41947 7.29262L0.780017 2.88597L-0.000488281 3.70771L5.44241 8.87749L8.54809 5.75178Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrendDownIcon;
