import styled from 'styled-components';
import Divider from '@karma/components/Divider';
import { getColor } from '@karma/components/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  & > div {
    &::-webkit-scrollbar {
      border-radius: 8px;
      width: 4px;
      background: transparent;

      &:hover {
        width: 8px;
        background: transparent;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: ${getColor('neutral4')};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${getColor('neutral4')};
    }
  }
`;

export const LoadingContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  background: ${getColor('neutral6')};
`;

export const StyledDivider = styled(Divider)`
  opacity: 0.75;
`;
