import { all, takeLatest } from 'redux-saga/effects';
import * as featuresActions from '../actions';
import setupStoreFeatures from './worker.setupStoreFeatures';
import fetchFeatures from './worker.fetchFeatures';

function* featuresSaga() {
  yield all([
    takeLatest([featuresActions.setupStoreFeatures.type], setupStoreFeatures),
    takeLatest([featuresActions.fetchFeatures.type], fetchFeatures),
  ]);
}

export default featuresSaga;
