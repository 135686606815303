import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MailIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.613281 3.83362H23.4633V17.4062C23.4633 18.9803 22.1873 20.2562 20.6133 20.2562H3.46328C1.88927 20.2562 0.613281 18.9803 0.613281 17.4062V3.83362ZM2.31328 5.53362V17.4062C2.31328 18.0414 2.82815 18.5562 3.46328 18.5562H20.6133C21.2484 18.5562 21.7633 18.0414 21.7633 17.4062V5.53362H2.31328Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0668 13.7892L0.810547 5.2174L1.96563 3.97009L11.2219 12.5419C11.671 12.9578 12.3672 12.949 12.8057 12.5219L21.57 3.98487L22.7562 5.20263L13.9919 13.7397C12.9052 14.7982 11.1798 14.82 10.0668 13.7892Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54935 12.7423L1.93685 19.1923L0.837891 17.8953L8.45039 11.4453L9.54935 12.7423Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4506 12.7423L22.0631 19.1923L23.1621 17.8953L15.5496 11.4453L14.4506 12.7423Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MailIcon;
