import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const PuzzleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M0.0561523 3.13271C0.0561523 2.66327 0.43671 2.28271 0.906152 2.28271H18.6403C19.1097 2.28271 19.4903 2.66327 19.4903 3.13271V8.22388C19.7624 8.15616 20.047 8.12019 20.3398 8.12019C22.2784 8.12019 23.8499 9.69173 23.8499 11.6303C23.8499 13.5689 22.2784 15.1404 20.3398 15.1404C20.047 15.1404 19.7624 15.1045 19.4903 15.0367V20.8668C19.4903 21.3363 19.1097 21.7168 18.6403 21.7168H0.906152C0.43671 21.7168 0.0561523 21.3363 0.0561523 20.8668V14.4187C0.0561523 14.1291 0.203549 13.8595 0.447282 13.7032C0.691015 13.5469 0.997555 13.5254 1.26071 13.6462C1.48915 13.751 1.74381 13.8099 2.01453 13.8099C3.01423 13.8099 3.82465 12.9995 3.82465 11.9998C3.82465 11.0001 3.01423 10.1897 2.01453 10.1897C1.7438 10.1897 1.48915 10.2485 1.26071 10.3534C0.997555 10.4742 0.691015 10.4527 0.447282 10.2964C0.203549 10.14 0.0561523 9.87041 0.0561523 9.58086V3.13271ZM1.75615 3.98271V8.49906C1.84153 8.49282 1.92769 8.48965 2.01453 8.48965C3.95312 8.48965 5.52465 10.0612 5.52465 11.9998C5.52465 13.9384 3.95312 15.5099 2.01453 15.5099C1.92769 15.5099 1.84153 15.5067 1.75615 15.5005V20.0168H17.7903V13.6768C17.7903 13.3472 17.9809 13.0473 18.2793 12.9073C18.5777 12.7673 18.9302 12.8125 19.1837 13.0232C19.4976 13.2842 19.8993 13.4404 20.3398 13.4404C21.3395 13.4404 22.1499 12.63 22.1499 11.6303C22.1499 10.6306 21.3395 9.82019 20.3398 9.82019C19.8993 9.82019 19.4976 9.97644 19.1837 10.2374C18.9302 10.4482 18.5777 10.4933 18.2793 10.3533C17.9809 10.2133 17.7903 9.91343 17.7903 9.5838V3.98271H1.75615Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PuzzleIcon;
