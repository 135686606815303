import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PlayerControls from 'components/organisms/PlayerControls/PlayerControls';
import { PlayerContainer, StyledReactPlayer } from './VideoPlayerWithControls.style';

const VideoPlayerWithControls = ({ url, ...rest }) => {
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [showControls, setShowControls] = useState(true);

  const playerRef = useRef(null);
  const controlsRef = useRef(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleEnded = () => {
    setPlaying(false);
    setShowControls(true);
  };

  const handleProgress = changeState => {
    setPlayed(parseFloat(changeState.played));
  };

  const handleSeek = e => {
    setPlayed(parseFloat(e / 100));
    playerRef.current.seekTo(e / 100);
  };

  const handleMouseMove = () => {
    setShowControls(true);
  };

  const hanldeMouseLeave = () => {
    if (playing) {
      setShowControls(false);
    }
  };

  return (
    <PlayerContainer onMouseMove={handleMouseMove} onMouseLeave={hanldeMouseLeave} {...rest}>
      <StyledReactPlayer
        ref={playerRef}
        height="100%"
        width="100%"
        progressInterval={1}
        url={url}
        muted={false}
        playing={playing}
        onProgress={handleProgress}
        onEnded={handleEnded}
      />
      <PlayerControls
        showControls={showControls}
        played={played}
        onSeek={handleSeek}
        playing={playing}
        ref={controlsRef}
        onPlayPause={handlePlayPause}
      />
    </PlayerContainer>
  );
};

VideoPlayerWithControls.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayerWithControls;
