import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ListsIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37617 2.04998C3.53896 2.04998 2.04961 3.53933 2.04961 5.37654V21.95H20.7996C21.4347 21.95 21.9496 21.4351 21.9496 20.8V15.1307H23.6496V20.8C23.6496 22.374 22.3736 23.65 20.7996 23.65H0.349609V5.37654C0.349609 2.60045 2.60008 0.349976 5.37617 0.349976H14.4878V2.04998H5.37617Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3531 4.37943L23.2363 4.47958L23.2287 4.4858C19.4128 7.60653 15.9213 11.098 12.8006 14.9139L12.0969 15.7743L11.3805 14.7713C9.72644 12.6033 7.76849 10.6611 5.58203 9.00604L6.60805 7.65057C8.67002 9.21137 10.5412 11.0156 12.1644 13.0183C15.1794 9.43562 18.519 6.14217 22.1449 3.17604L22.2468 3.08868L23.3531 4.37943Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ListsIcon;
