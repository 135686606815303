import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SuitcaseIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 21 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M17.375 4.375H14.25V3.75C14.25 3.25272 14.0525 2.77581 13.7008 2.42417C13.3492 2.07254 12.8723 1.875 12.375 1.875H8.625C8.12772 1.875 7.65081 2.07254 7.29917 2.42417C6.94754 2.77581 6.75 3.25272 6.75 3.75V4.375H3.625C3.29348 4.375 2.97554 4.5067 2.74112 4.74112C2.5067 4.97554 2.375 5.29348 2.375 5.625V15.625C2.375 15.9565 2.5067 16.2745 2.74112 16.5089C2.97554 16.7433 3.29348 16.875 3.625 16.875H17.375C17.7065 16.875 18.0245 16.7433 18.2589 16.5089C18.4933 16.2745 18.625 15.9565 18.625 15.625V5.625C18.625 5.29348 18.4933 4.97554 18.2589 4.74112C18.0245 4.5067 17.7065 4.375 17.375 4.375ZM8 3.75C8 3.58424 8.06585 3.42527 8.18306 3.30806C8.30027 3.19085 8.45924 3.125 8.625 3.125H12.375C12.5408 3.125 12.6997 3.19085 12.8169 3.30806C12.9342 3.42527 13 3.58424 13 3.75V4.375H8V3.75ZM13 5.625V15.625H8V5.625H13ZM3.625 5.625H6.75V15.625H3.625V5.625ZM17.375 15.625H14.25V5.625H17.375V15.625Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SuitcaseIcon;
