import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  extInstalled: null,
  iosExtInstalled: null,
};

export const extensionSlice = createSlice({
  name: 'extension',
  initialState,
  reducers: {
    setExtInstalled: state => ({
      extInstalled: true,
      iosExtInstalled: state.iosExtInstalled,
    }),
    setNotExtInstalled: state => ({
      extInstalled: false,
      iosExtInstalled: state.iosExtInstalled,
    }),
    setIOSInstalled: state => ({
      extInstalled: state.extInstalled,
      iosExtInstalled: true,
    }),
    setNotIOSInstalled: state => ({
      extInstalled: state.extInstalled,
      iosExtInstalled: false,
    }),
  },
});

export default extensionSlice.reducer;
