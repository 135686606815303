import React, { useRef, useState, useMemo } from 'react';
import useId from '../utils/useId';
import {
  OptionsWrapper,
  SelectInputButton,
  PopoverWrapper,
  HiddenInput,
  SelectIconContainer,
  SelectArrow,
  FilterWrapper,
} from './Filter.styles';
import { FilterProps } from './Filter.types';
import Popover from '../Popover';
import useOnClickOutside from '../utils/useOnClickOutside';
import InputContainer from '../InputContainer';
import ListItem from '../ListItem';
import Typography from '../Typography';
import { setMultipleRef } from '../utils/setMultipleRef';

const Filter = React.forwardRef<HTMLDivElement, FilterProps>(function Filter(
  {
    id: idOverride,
    name,
    value,
    placeholder,
    disabled = false,
    active = false,
    fullWidth = false,
    preventIconAnimation = false,
    options = [],

    inputRef = null,
    inputProps = {},

    inputContainerRef = null,

    popoverProps = {},

    onFocus = _e => null,
    onKeyDown = _e => null,
    onBlur = _e => null,
    onChange = _e => null,
    onClick = _e => null,

    renderPopover = null,

    ...restContainerProps
  },
  ref
) {
  const id = useId(idOverride);
  const selectedOptionRef = useRef<HTMLElement>(null);
  const [selectContainerRef, setSelectContainerRef] = useState(null);
  const [opened, setOpened] = useState(false);

  const menuId = options.length > 0 && id ? `${id}-menu` : undefined;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpened(v => !v);
    onClick(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') setOpened(true);
    if (e.key === 'Escape') setOpened(false);

    onKeyDown(e);
  };

  const clickOutsideCallback = () => {
    setOpened(false);
  };

  const onEnter = () => {
    if (selectedOptionRef.current) selectedOptionRef.current.focus();
  };

  const onClose = () => {
    setOpened(false);
  };

  const handleItemClick = option => event => {
    const newValue = option.value;

    if (option.value && onChange) onChange(newValue);
    if (option.onClick) option.onClick(event);

    onClose();
  };

  const selectedOption = useMemo(
    () => options?.find(option => option.value === value) || null,
    [value, options]
  );

  useOnClickOutside(selectContainerRef, clickOutsideCallback, { capture: true });

  return (
    <FilterWrapper {...restContainerProps} fullWidth={fullWidth} ref={ref}>
      <InputContainer
        disabled={disabled}
        ref={setMultipleRef(inputContainerRef, setSelectContainerRef)}
      >
        <SelectInputButton
          disabled={disabled}
          aria-disabled={disabled}
          hasPrefix={false}
          tabIndex={disabled ? -1 : 0}
          role="button"
          aria-expanded={opened}
          aria-haspopup="listbox"
          onClick={handleClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          active={active}
        >
          {selectedOption?.label || (
            <Typography variant="labelMedium" color="currentColor">
              {placeholder}
            </Typography>
          )}
        </SelectInputButton>
        <HiddenInput
          aria-hidden
          id={id}
          name={name}
          disabled={disabled}
          tabIndex={-1}
          value={Array.isArray(value) ? value.join(',') : value}
          ref={inputRef}
          required={false}
          onChange={() => null}
          {...inputProps}
        />
        <SelectIconContainer>
          <SelectArrow
            isOpened={opened}
            disabled={disabled}
            size="small"
            preventIconAnimation={preventIconAnimation}
          />
        </SelectIconContainer>
      </InputContainer>

      <PopoverWrapper id={menuId}>
        {renderPopover ? (
          renderPopover(opened, onClose)
        ) : (
          <Popover
            {...popoverProps}
            style={popoverProps.style || {}}
            wrapperStyle={{
              overflow: 'hidden',
              ...(popoverProps.wrapperStyle || {}),
            }}
            anchorElement={selectContainerRef}
            isOpened={opened}
            onEnter={onEnter}
          >
            <OptionsWrapper
              direction="column"
              component="ul"
              role="listbox"
              tabIndex={-1}
              justifyContent="flex-start"
            >
              {options.map((option, index) => (
                <ListItem
                  key={option.value}
                  role="option"
                  tabIndex={index}
                  disabled={false}
                  selected={option.value === value}
                  value={option.value}
                  onClick={handleItemClick(option)}
                >
                  {option.label}
                </ListItem>
              ))}
            </OptionsWrapper>
          </Popover>
        )}
      </PopoverWrapper>
    </FilterWrapper>
  );
});

export default Filter;
