import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyPartnershipsContact from 'components/molecules/ModalBodyPartnershipsContact/ModalBodyPartnershipsContact';
import Modal from 'components/templates/Modal/Modal';

const ModalPartnershipsContact = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.PARTNERSHIPS_CONTACT} onClose={closeModal}>
      <ModalBodyPartnershipsContact {...props} />
    </Modal>
  );
};

ModalPartnershipsContact.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalPartnershipsContact;
