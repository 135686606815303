import { put, select } from 'redux-saga/effects';
import { reset } from '../actions';
import { getMultiEditStatus } from '../selectors';

function* filtersChanges() {
  const isActive = yield select(getMultiEditStatus);

  if (isActive) {
    yield put(reset());
  }
}

export default filtersChanges;
