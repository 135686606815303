import styled from 'styled-components';
import { InputContainerProps } from './InputContainer.types';

export const InputContainer = styled.div<InputContainerProps>`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;

  color: ${({ theme, disabled }) => {
    const colors = theme[theme.color].InputBase;

    return disabled ? colors.disabled.text : colors.normal.text;
  }};

  transition: color 0.3s ease-in-out;
`;
