import { isClient } from 'functions/isClient';

let serverUserAgent = null;

function getUserAgent() {
  if (isClient()) {
    return navigator?.userAgent;
  }

  return serverUserAgent;
}

export function setServerUserAgent(userAgent) {
  serverUserAgent = userAgent;
}

export default getUserAgent;
