import { createSelector } from '@reduxjs/toolkit';
import head from 'lodash/head';

const getModalsEntity = ({ modals }) => modals;

export const getModals = createSelector(getModalsEntity, ({ modals }) => modals);

const getFrontModal = createSelector(getModals, head);

export const getFrontModalData = createSelector(getFrontModal, modal => modal?.data);

export const getFrontModalType = createSelector(getFrontModal, modal => modal?.type);
