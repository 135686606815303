import { ITEMS } from 'consts/localStorage';

export const setRecentGlobalSearch = query => {
  try {
    const sanitizedQuery = query.toLowerCase();

    let prevOptions = JSON.parse(localStorage.getItem(ITEMS.globalRecentSearches)) || [];

    if (!prevOptions.some(el => el === sanitizedQuery)) {
      prevOptions.unshift(sanitizedQuery);

      prevOptions = prevOptions.slice(0, 5);

      localStorage.setItem(ITEMS.globalRecentSearches, JSON.stringify(prevOptions));
    }

    return prevOptions;
  } catch (error) {
    return [];
  }
};

export const removeRecentGlobalSearch = query => {
  try {
    let prevOptions = JSON.parse(localStorage.getItem(ITEMS.globalRecentSearches)) || [];

    prevOptions = prevOptions.filter(el => el.toLowerCase() !== query.toLowerCase());

    localStorage.setItem(ITEMS.globalRecentSearches, JSON.stringify(prevOptions));

    return prevOptions;
  } catch (error) {
    return [];
  }
};

export const getRecentGlobalSearches = () => {
  try {
    return JSON.parse(localStorage.getItem(ITEMS.globalRecentSearches)) || [];
  } catch (error) {
    return [];
  }
};

export const clearRecentGlobalSearches = () => {
  localStorage.removeItem(ITEMS.globalRecentSearches);
};
