import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tag: {
    loaded: false,
    data: {},
  },
  coupons: {
    loaded: false,
    data: [],
  },
  similar: {
    loaded: false,
    data: [],
  },
  alsoLike: {
    loaded: false,
    data: [],
  },
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    recieveTag: (state, action) => ({
      ...state,
      tag: {
        loaded: true,
        data: action.payload,
      },
    }),
    recieveCoupons: (state, action) => ({
      ...state,
      coupons: {
        loaded: true,
        data: action.payload,
      },
    }),
    recieveSimilar: (state, action) => ({
      ...state,
      similar: {
        loaded: true,
        data: action.payload,
      },
    }),
    recieveAlsoLike: (state, action) => ({
      ...state,
      alsoLike: {
        loaded: true,
        data: action.payload,
      },
    }),
  },
});

export default tagSlice.reducer;
