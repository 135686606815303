import { createGlobalStyle } from 'styled-components';

const fontFace = (name: string, src: string, fontWeight: number, fontStyle: string) =>
  /* eslint-disable */
  `
    @font-face{
      font-family: "${name}";
      src: url(${require(`../fonts/${name}/${src}.woff`)}) format("woff"),
           url(${require(`../fonts/${name}/${src}.woff2`)}) format("woff2"),
           url(${require(`../fonts/${name}/${src}.ttf`)}) format("truetype");

      font-style: ${fontStyle};
      font-weight: ${fontWeight};
      font-display: swap;
    }
  `;
/* eslint-enable */
const FontStyles = createGlobalStyle`
  ${fontFace('Roboto', 'roboto-100', 100, 'normal')}
  ${fontFace('Roboto', 'roboto-300', 300, 'normal')}
  ${fontFace('Roboto', 'roboto-400', 400, 'normal')}
  ${fontFace('Roboto', 'roboto-500', 500, 'normal')}
  ${fontFace('Roboto', 'roboto-700', 700, 'normal')}
  ${fontFace('Roboto', 'roboto-900', 900, 'normal')}

  ${fontFace('Rebond', 'rebond-400', 400, 'normal')}
  ${fontFace('Rebond', 'rebond-500', 500, 'normal')}
  ${fontFace('Rebond', 'rebond-600', 600, 'normal')}
  ${fontFace('Rebond', 'rebond-700', 700, 'normal')}
`;

export default FontStyles;
