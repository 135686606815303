const regexp = /(\d+).(\d+)/;

function parsePrice(price) {
  if (!price) {
    return {
      integer: 0,
      fraction: 0,
      full: 0,
    };
  }

  const fixedPrice = parseFloat(price).toFixed(2);
  const matchedPrice = fixedPrice.match(regexp);
  const integer = parseInt(matchedPrice[1], 10);
  const fraction = parseInt(matchedPrice[2], 10);

  return {
    integer: fixedPrice.indexOf('-') >= 0 ? `-${integer}` : integer,
    fraction: fraction ? matchedPrice[2] : 0,
    full:
      fixedPrice.indexOf('-') >= 0
        ? `-${integer}.${fraction ? matchedPrice[2] : '00'}`
        : `${integer}.${fraction ? matchedPrice[2] : '00'}`,
  };
}

export default parsePrice;
