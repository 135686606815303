import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  retailer: undefined,
  related_retailers: [],
  shop_offers: [],
  retailerLoaded: false,
};

export const retailerSlice = createSlice({
  name: 'retailer',
  initialState,
  reducers: {
    setRetailer: (state, action) => {
      const { related_retailers, retailer, shop_offers } = action.payload;

      return {
        ...state,
        related_retailers,
        shop_offers,
        retailer,
        retailerLoaded: true,
      };
    },
    fetchRetailerRequest: state => ({
      ...state,
      retailerLoaded: false,
    }),
    resetRetailer: () => initialState,
  },
});

export default retailerSlice.reducer;
