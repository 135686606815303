import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRedirectLinkToTag } from '@karma/utils/functions/redirectLink';
import { Typography } from '@karma/components';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { getProductModalFeature } from 'store/features/selectors';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import useAction from 'hooks/useAction';
import onErrorImageHandler from 'functions/onErrorImageHandler';
import hasDiscount from 'functions/hasDiscount';
import checkItemHasPrice from 'functions/checkItemHasPrice';
import { pushBackProductModal } from 'store/modals/actions';
import { DEFAULT_TAG } from 'consts/defaultImages';
import useTagIsOutOfStock from 'hooks/useTagIsOutOfStock';
import {
  Wrapper,
  CardImage,
  HeightController,
  HoverContainer,
  Name,
  PriceBlock,
} from 'components/molecules/CardTagSquare/CardTagSquare.style';

const MIN_CONTAINER_HEIGHT_TO_SHOW_BRAND = 115;

const CardTagSquare = ({ group, tag, forceRedirectToTag = false }) => {
  const [containerRef, setContainerRef] = useState();
  const [showBrand, setShowBrand] = useState(false);

  const { t } = useTranslation();
  const productModalFeature = useSelector(getProductModalFeature);
  const pushBackProductModalFunc = useAction(pushBackProductModal);
  const source = useContext(ReferralSourceContext);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const { main_img_url, marker, stake, id, rule_name, user_id, original_url: originalUrl, price } = tag;
  const currentPrice = price?.current_price;
  const originalPrice = price?.original_price;
  const currency = price?.currency.symbol;

  const isOutOfStock = useTagIsOutOfStock(marker);

  const handleClick = async () => {
    if (productModalFeature && !forceRedirectToTag) {
      pushBackProductModalFunc({ group, tagId: tag.id });
    } else {
      window.open(
        getRedirectLinkToTag({
          tagId: id,
          ruleName: rule_name,
          userId: user_id,
          singleShare: true,
          cashback: !!stake,
          source,
          originalUrl,
        }),
        '_blank',
      );
    }
  };

  const checkContentFit = () => {
    if (containerRef.clientHeight >= MIN_CONTAINER_HEIGHT_TO_SHOW_BRAND) setShowBrand(true);
  };

  const resetContentFit = () => {
    setShowBrand(false);
  };

  return (
    <Wrapper
      {...contextDataAttrs}
      data-alooma-element={ALOOMA.ELEMENTS.TAG}
      onClick={handleClick}
      role="button"
      onMouseEnter={checkContentFit}
      onMouseLeave={resetContentFit}
      ref={setContainerRef}
    >
      <HeightController />

      <CardImage onError={onErrorImageHandler} data-error-src={DEFAULT_TAG} src={main_img_url || DEFAULT_TAG} />

      <HoverContainer fullWidth direction="column">
        <PriceBlock fullWidth direction="column" spacing={4} bMargin={2}>
          {!(isOutOfStock || !checkItemHasPrice(currentPrice?.full)) && (
            <>
              {hasDiscount(currentPrice, originalPrice, currency) && (
                <Typography variant="labelMedium" fontWeight={400} textDecoration="line-through" noWrap>
                  {t('app:price', {
                    currency,
                    value: Number(originalPrice?.full).toFixed(2),
                  })}
                </Typography>
              )}
              <Typography variant="labelMedium" noWrap>
                {t('app:price', {
                  currency,
                  value: Number(currentPrice?.full).toFixed(2),
                })}
              </Typography>
            </>
          )}
          {isOutOfStock && <Typography variant="labelMedium">{t('app:outOfStock')}</Typography>}
        </PriceBlock>

        <Name variant="labelSmall">{tag.name}</Name>

        {showBrand && (
          <Typography variant="labelExtraSmallUppercase" color="neutral5" tMargin={10} noWrap>
            {tag.brand}
          </Typography>
        )}
      </HoverContainer>
    </Wrapper>
  );
};

export default CardTagSquare;
