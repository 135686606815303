import { useState, useEffect } from 'react';
import { oldApiInstance } from 'functions/request';
import CConsole from 'functions/CConsole';

const useSimilarTagsGetter = tagId => {
  const [loading, setLoading] = useState(true);
  const [similarTags, setSimilarTags] = useState([]);

  useEffect(() => {
    async function fetchSimilarTags() {
      try {
        const params = { params: { p: true } };
        const similarTagsData = await oldApiInstance
          .get(`/shop/tags/${tagId}/similar_tags`, params)
          .then(({ data }) => data);

        setSimilarTags(similarTagsData);
      } catch (error) {
        CConsole.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchSimilarTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { similarTags, loading };
};

export default useSimilarTagsGetter;
