import { newApiInstance } from 'functions/request';

export const getRetailers = ({ categories, group, page, perPage, search, seed, sortBy, sortOrder, cashback } = {}) =>
  newApiInstance('/retailers', {
    params: {
      filter: {
        categories: categories.length ? categories : null,
        group: group || null,
        search: search || null,
        cashback: cashback || null,
      },
      page: page || null,
      per_page: perPage || null,
      seed: seed || null,
      sort_by: sortBy || null,
      sort_order: sortOrder || null,
    },
  });
