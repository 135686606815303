import { useEffect } from 'react';
import { isClient } from 'functions/isClient';

const useInitHotJar = () => {
  useEffect(() => {
    if (isClient()) {
      window.hj =
        window.hj ||
        function (...args) {
          (window.hj.q = window.hj.q || []).push(args);
        };
      window._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 };
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
      head.appendChild(script);
    }
  }, []);
};

export default useInitHotJar;
