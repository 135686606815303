import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import getBrowser from 'functions/getBrowser';
import getExtensionLink from 'functions/getExtensionLink';
import trackGoogleTagEvent from 'functions/trackGoogleTagEvent';
import { showErrorAlert } from 'store/alerts/actions';

const useConnectExtensionHandler = (openInNewTab = true) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const browser = getBrowser();
    const extensionLink = getExtensionLink(browser);

    if (!extensionLink) {
      dispatch(showErrorAlert({ message: 'Your browser is not supported' }));
    } else {
      // Google analytics
      trackGoogleTagEvent('getextension');
      window.open(extensionLink, openInNewTab ? '_blank' : '_self');
    }
  }, [dispatch, openInNewTab]);
};

export default useConnectExtensionHandler;
