/* eslint-disable no-useless-computed-key */
import { oldApiInstance } from 'functions/request';

export const fetchTags = ({ retailers, brands, sort_by, search, min_price, page } = {}) =>
  oldApiInstance.get('/shop/tags', {
    params: {
      ['retailers[]']: retailers,
      ['brands[]']: brands,
      sort_by: sort_by || 'last_saved',
      search,
      min_price,
      page: page || 1,
    },
  });
