import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loaded: false,
  tags: [],
  user_name: '',
  list_name: '',
  tagsLeft: true,
  page: 1,
  isFetching: false,
};

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    receiveTagsData: (state, { payload: { list_name, tags, user_name, total_count: totalCount } }) => ({
      ...state,
      tags,
      loaded: true,
      list_name,
      user_name,
      tagsLeft: state.tags.length + tags.length < totalCount,
    }),
    enableFetching: state => ({
      ...state,
      isFetching: true,
    }),
    receiveTags: (state, { payload: { tags, total_count: totalCount } }) => ({
      ...state,
      tags: [...state.tags, ...tags],
      isFetching: false,
      page: state.page + 1,
      tagsLeft: state.tags.length + tags.length < totalCount,
    }),
  },
});

export default shareSlice.reducer;
