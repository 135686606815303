import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GooglePlayStoreIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 22 26"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M2.13127 0.425049L13.2 11.4917L16.0563 8.63546C12.1533 6.26747 6.92596 3.09082 4.57502 1.66255L3.00002 0.708382C2.72162 0.538782 2.42354 0.447449 2.13127 0.425049ZM0.441691 1.75005C0.419291 1.87165 0.400024 1.99385 0.400024 2.12505V23.9959C0.400024 24.0876 0.418474 24.173 0.431274 24.2605L11.6917 13L0.441691 1.75005ZM17.9334 9.77505L14.7084 13L17.8771 16.1688C19.556 15.1512 20.7189 14.4446 20.8479 14.3667C21.4186 14.0179 21.7409 13.4916 21.7313 12.9167C21.7227 12.3524 21.4004 11.8479 20.8521 11.5375C20.7294 11.4671 19.5824 10.7745 17.9334 9.77505ZM13.2 14.5084L2.16461 25.5438C2.37901 25.5065 2.59314 25.4425 2.79794 25.3188C3.08167 25.146 10.7467 20.4956 16 17.3084L13.2 14.5084Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GooglePlayStoreIcon;
