import { useTranslation } from 'react-i18next';
import { TwitterIcon } from '@karma/components/icons';
import { Button } from '@karma/components';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';

const TwitterShareButton = ({ description, url, wideButton }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const shareLink = encodeURIComponent(url);
  const text = description || `app:whenIFindSmth`;
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${t(text)
    .replace(/%/g, '%25')
    .replace(/\s/g, '%20')}&url=${shareLink}&via=shopwithkarma`;

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: twitterShareLink,
    'data-alooma-element': ALOOMA.ELEMENTS.TWITTER,
    ...contextDataAttrs,
  };

  return wideButton ? (
    <Button as="a" Icon={TwitterIcon} size="small" fullWidth {...linkProps}>
      {t('app:tweetOnTwitter')}
    </Button>
  ) : (
    <a {...linkProps}>
      <TwitterIcon role="button" size="medium" />
    </a>
  );
};

export default TwitterShareButton;
