import { call, put } from 'redux-saga/effects';
import { DEFAULT_PER_GROUP } from 'consts/queries';
import { formatRetailers } from 'functions/formatRetailerData';
import { handleServerError } from 'store/alerts/actions';
import * as retailersGroupsActions from '../actions';
import * as retailersGroupsApi from '../api';

function* fetchRetailersGroups() {
  const queryGroups = ['featured', 'cashback', 'trending', 'we-re-loving'];

  try {
    const response = yield call(retailersGroupsApi.getRetailersGroups, {
      groups: queryGroups,
      perGroup: DEFAULT_PER_GROUP,
      sortBy: 'priority',
    });

    yield put(
      retailersGroupsActions.receiveRetailersGroups({
        container: 'featured',
        retailers: formatRetailers(response.data.data.featured),
      }),
    );
    yield put(
      retailersGroupsActions.receiveRetailersGroups({
        container: 'cashback',
        retailers: formatRetailers(response.data.data.cashback),
      }),
    );
    yield put(
      retailersGroupsActions.receiveRetailersGroups({
        container: 'trending',
        retailers: formatRetailers(response.data.data.trending),
      }),
    );
    yield put(
      retailersGroupsActions.receiveRetailersGroups({
        container: 'loving',
        retailers: formatRetailers(response.data.data['we-re-loving']),
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchRetailersGroups;
