import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import { SelectArrowIcon } from '@karma/components/icons';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
`;

export const PopoverBodyWrapper = styled.div`
  display: block;
  width: fit-content;
  min-width: 200px;
  margin-left: -20px;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: ${getColor('neutral6')};

  padding: 8px 0px;
  margin: 0;
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background: transparent;

    &:hover {
      width: 25px;
      background: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${getColor('neutral4')};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral4')};
  }
`;

export const PopoverWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
`;

export const ToggleButtonWrapper = styled.div`
  position: relative;
`;

export const SelectContainer = styled(Stack)`
  cursor: pointer;
  color: ${({ $hasSelectedValue }) => ($hasSelectedValue ? getColor('neutral2') : 'currentColor')};
`;

export const StyledSelectArrow = styled(SelectArrowIcon)`
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : 'rotate(0)')};
`;
