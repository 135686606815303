import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import UnauthorizedLanguageSelect from 'components/molecules/UnauthorizedLanguageSelect/UnauthorizedLanguageSelect';
import Modal from 'components/templates/Modal/Modal';

const ModalChangeLanguage = props => {
  const { closeModal } = props;
  const { t } = useTranslation();

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.MODAL_CHANGE_LANGUAGE} title={t('app:changeLanguage')} onClose={closeModal}>
      <UnauthorizedLanguageSelect {...props} />
    </Modal>
  );
};

ModalChangeLanguage.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalChangeLanguage;
