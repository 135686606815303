import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@karma/components/Popover';
import useOnClickOutside from '@karma/components/utils/useOnClickOutside';
import Alooma from 'services/alooma';
import ToggleModal from 'components/organisms/ToggleModal/ToggleModal';
import { ButtonWrapper, Button, ToggleButtonWrapper, PopoverWrapper, PopoverBodyWrapper } from './ToggleButton.styles';

const ToggleButton = ({ setting, tag }) => {
  const containerRef = useRef(null);
  const [activeBtnId, setActiveBtnId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const closeToggleModal = () => setShowModal(false);

  const handleActiveButton = id => {
    if (activeBtnId !== id) {
      setActiveBtnId(id);
      setShowModal(true);
    } else closeToggleModal();
  };

  useOnClickOutside(containerRef, closeToggleModal, { capture: true });

  return (
    <ToggleButtonWrapper ref={containerRef}>
      <ButtonWrapper>
        {setting.map(({ Icon, tooltip, id, alooma, isActive }) => (
          <Button
            title={tooltip}
            key={id}
            onClick={() => handleActiveButton(id)}
            isActive={isActive}
            {...contextDataAttrs}
            data-alooma-element={alooma}
          >
            <Icon size="small" />
          </Button>
        ))}
      </ButtonWrapper>
      <PopoverWrapper>
        <Popover
          isOpened={showModal}
          anchorElement={containerRef.current}
          WrapperComponent={PopoverBodyWrapper}
          onExited={() => setActiveBtnId(null)}
        >
          <ToggleModal setting={setting} tag={tag} activeBtnId={activeBtnId} closeToggleModal={closeToggleModal} />
        </Popover>
      </PopoverWrapper>
    </ToggleButtonWrapper>
  );
};

ToggleButton.propTypes = {
  tag: PropTypes.shape({}).isRequired,
  setting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      tooltip: PropTypes.string.isRequired,
      Icon: PropTypes.func.isRequired,
      alooma: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      callback: PropTypes.func,
      Component: PropTypes.func,
    }),
  ).isRequired,
};

export default ToggleButton;
