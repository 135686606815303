import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRedirectLinkToTag, getRedirectLinkToShop } from '@karma/utils/functions/redirectLink';
import { Typography, Loader } from '@karma/components';
import { getTag } from 'store/myItems/store/selectors';
import PriceEvolution from 'components/molecules/PriceEvolution/PriceEvolution';
import usePriceEvolutionGetter from 'hooks/usePriceEvolutionGetter';
import TemplateSimilarItems from 'components/molecules/TemplateSimilarItems/TemplateSimilarItems';
import ModalProductImagesSection from 'components/molecules/ModalProductImagesSection/ModalProductImagesSection';
import ModalProductInfoSection from 'components/molecules/ModalProductInfoSection/ModalProductInfoSection';
import useSimilarTagsGetter from 'hooks/useSimilarTagsGetter';
import { Row, Container, Column, LoaderWrapper } from './ModalProductBody.style';

const ModalProductBody = ({ closeModal, group, tagId }) => {
  const { t } = useTranslation();

  const tag = useSelector(getTag)(tagId, group);
  const { id, rule_name, user_id, stake, rule_id, original_url } = tag || {};

  const { similarTags, loading: loadingSimilarTags } = useSimilarTagsGetter(tagId);
  const {
    isDisplayChart,
    receivedData,
    isFetching: isFetchingPriceEvolution,
  } = usePriceEvolutionGetter(id, { params: { p: true } });

  const commonData = useMemo(
    () => ({
      ruleName: rule_name,
      userId: user_id,
      cashback: !!stake,
      source: 'product_modal',
      originalUrl: original_url,
    }),
    [rule_name, user_id, stake, original_url],
  );

  const redirectToTag = useMemo(
    () =>
      getRedirectLinkToTag({
        tagId: id,
        ...commonData,
      }),
    [id, commonData],
  );

  const redirectToShop = useMemo(
    () =>
      getRedirectLinkToShop({
        ruleId: rule_id,
        ...commonData,
      }),
    [rule_id, commonData],
  );

  const hasSimilarTags = similarTags.length >= 3;

  const loaded = !loadingSimilarTags && !isFetchingPriceEvolution;

  if (!tag) return null;

  return (
    <Container>
      <Row>
        <ModalProductImagesSection redirectToTagLink={redirectToTag} tag={tag} closeModal={closeModal} />
        <ModalProductInfoSection
          tag={tag}
          redirectToTagLink={redirectToTag}
          redirectToShopLink={redirectToShop}
          closeModal={closeModal}
        />
      </Row>
      <Row>
        {loaded ? (
          <>
            {!!hasSimilarTags && (
              <Column primary={isDisplayChart}>
                <Typography bMargin={20} variant="h4">
                  {t('app:youMayAlsoLike')}
                </Typography>
                <TemplateSimilarItems items={similarTags} slidesView={isDisplayChart ? 3 : 5} />
              </Column>
            )}

            {isDisplayChart && (
              <Column>
                <Typography bMargin={20} variant="h4">
                  {t('app:priceEvolution')}
                </Typography>

                <PriceEvolution data={receivedData} fetch={isFetchingPriceEvolution} tag={tag} height={212} />
              </Column>
            )}
          </>
        ) : (
          <Column primary>
            <LoaderWrapper>
              <Loader absolute />
            </LoaderWrapper>
          </Column>
        )}
      </Row>
    </Container>
  );
};

ModalProductBody.propTypes = {
  closeModal: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  tagId: PropTypes.number.isRequired,
};

export default ModalProductBody;
