import { useRef } from 'react';
import getDisplayName from 'functions/getDisplayName';
import { isIOS as checkIsIos } from 'functions/isIOS';

const withIosBugFixCaret = () => WrappedComponent => {
  const WithIosBugFixCaret = props => {
    const isIos = useRef(checkIsIos());
    const scrollTop = useRef(0);

    const showHandler = () => {
      if (isIos.current) {
        scrollTop.current = window.pageYOffset || document.documentElement.scrollTop;
        document.body.classList.add('iosBugFixCaret');
      }
    };

    const hideHandler = () => {
      if (isIos.current) {
        setTimeout(window.scrollTo, 0, 0, scrollTop.current);
        document.body.classList.remove('iosBugFixCaret');
      }
    };

    return <WrappedComponent {...props} iosBugHideHandler={hideHandler} iosBugShowHandler={showHandler} />;
  };

  WithIosBugFixCaret.displayName = `WithIosBugFixCaret(${getDisplayName(WrappedComponent)})`;

  return WithIosBugFixCaret;
};

export default withIosBugFixCaret;
