import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showKustomerOpened: false,
  showSearchTooltip: false,
  isKustomerLoaded: false,
};

export const dashboardInterfaceSlice = createSlice({
  name: 'dashboardInterface',
  initialState,
  reducers: {
    setShowKustomerOpened: (state, { payload }) => ({
      ...state,
      showKustomerOpened: payload,
    }),
    setKustomerLoaded: (state, { payload }) => ({
      ...state,
      isKustomerLoaded: payload,
    }),
    setShowSearchTooltip: (state, { payload }) => ({
      ...state,
      showSearchTooltip: payload,
    }),
  },
});

export default dashboardInterfaceSlice.reducer;
