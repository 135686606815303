import PropTypes from 'prop-types';
import { COLOR_THEMES } from '@karma/components/themes';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import ModalBodyStoreInfo from 'components/molecules/ModalBodyStoreInfo/ModalBodyStoreInfo';

const ModalStoreInfo = props => {
  const { closeModal, onCloseCallback, retailer } = props;

  const onClose = () => {
    closeModal();
    if (onCloseCallback && onCloseCallback instanceof Function) onCloseCallback();
  };

  return (
    <Modal
      source={ALOOMA.SOURCE_PAGES.HOME}
      aloomaModalType={ALOOMA.MODAL_TYPES.POPUP}
      aloomaModalName={ALOOMA.MODAL_NAMES.STORE_INFO}
      onClose={onClose}
      colorTheme={COLOR_THEMES.LIGHT}
      withGradient
    >
      <ModalBodyStoreInfo retailer={retailer} {...props} />
    </Modal>
  );
};

ModalStoreInfo.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onCloseCallback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  retailer: PropTypes.object.isRequired,
};

ModalStoreInfo.defaultProps = {
  onCloseCallback: null,
};

export default ModalStoreInfo;
