import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE } from 'consts/locales';

const initialState = {
  user: {},
  loaded: false,
  password: {
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  },
  accountDelete: {
    isFetching: false,
    success: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUser: (state, action) => {
      const user = action.payload
        ? {
            ...action.payload,
            language: action.payload.language || DEFAULT_LANGUAGE,
            email: action.payload.email.replace('@shoptagr.com', '@karmanow.com'),
          }
        : {};

      return {
        ...state,
        user,
        loaded: true,
      };
    },

    clearPassword: state => {
      return {
        ...state,
        password: initialState.password,
      };
    },
    removePPAccount: state => {
      return {
        ...state,
        user: {
          ...state.user,
          wallet: {
            ...state.user.wallet,
            paypal_account: null,
          },
        },
      };
    },
    setAccountDeleteState: (state, { payload: { isFetching, success } }) => {
      return {
        ...state,
        accountDelete: {
          isFetching,
          success,
        },
      };
    },
  },
});

export default userSlice.reducer;
