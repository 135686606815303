import CConsole from 'functions/CConsole';
import { isClient } from 'functions/isClient';

export default async () => {
  if (!isClient() || !window.caches) {
    return null;
  }

  try {
    const cacheKeys = await window.caches.keys();

    return Promise.all(cacheKeys.map(cacheKey => window.caches.delete(cacheKey)));
  } catch (e) {
    CConsole.error(e);

    return null;
  }
};
