import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import TextBubbleIcon from '@karma/components/icons/TextBubbleIcon';
import { Popper } from '@karma/components';
import { useTranslation } from 'react-i18next';
import useAction from 'hooks/useAction';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { getKustomer } from 'store/dashboardInterface/selectors';
import { setShowKustomerOpened } from 'store/dashboardInterface/actions';
import { KustomerStyledButton } from './KustomerButton.style';

const KustomerButton = () => {
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const closeButtonRef = useRef();
  const buttonRef = useRef();
  const isKustomerOpened = useSelector(getKustomer);
  const setIsKustomerOpened = useAction(setShowKustomerOpened);
  const { t } = useTranslation();

  const [showToolTip, setShowToolTip] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClose = useCallback(() => {
    setIsKustomerOpened(false);
    setDisabled(false);
  }, [setIsKustomerOpened]);

  const toggleKustomerHandler = () => {
    if (!disabled) {
      if (window.Kustomer && isKustomerOpened) {
        window.Kustomer.close();
        setIsKustomerOpened(false);
      } else if (window.Kustomer && !isKustomerOpened) {
        window.Kustomer.open();
        setIsKustomerOpened(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (window.Kustomer) {
        window.Kustomer.stop();
        window.Kustomer.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isKustomerOpened) setDisabled(true);
    const disableClick = setTimeout(() => setDisabled(false), 500);

    const addListener = setTimeout(() => {
      if (isKustomerOpened) {
        const iframe = document.getElementById('kustomer-ui-sdk-iframe');
        const iWindow = iframe?.contentWindow;
        const iDocument = iWindow?.document;

        if (closeButtonRef.current) {
          closeButtonRef.current = iDocument.body.querySelector('[aria-label="minimize chat widget"]');
          closeButtonRef.current.addEventListener('click', handleClose);
        }
      }
    }, 500);

    return () => {
      clearTimeout(disableClick);
      clearTimeout(addListener);
      if (closeButtonRef.current) closeButtonRef.current.removeEventListener('click', handleClose);
    };
  }, [handleClose, isKustomerOpened]);

  return (
    <>
      <KustomerStyledButton
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.CHAT}
        onClick={toggleKustomerHandler}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
        ref={buttonRef}
      >
        <TextBubbleIcon size="medium" color="primary1" />
      </KustomerStyledButton>
      <Popper open={showToolTip} referenceElement={buttonRef.current} defaultPlacement="top" content={t('app:chat')} />
    </>
  );
};

export default KustomerButton;
