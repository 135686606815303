import { call, put, select } from 'redux-saga/effects';
import { handleServerError, showSuccessAlert } from 'store/alerts/actions';
import { getUserId } from 'store/user/selectors';
import * as userActions from '../actions';
import * as userApi from '../api';

function* unsubscribe(action) {
  try {
    const userId = yield select(getUserId);
    const response = yield call(userApi.unsubscribe, action.payload);

    if (userId) {
      yield put(userActions.saveUser(response.data));
    }

    yield put(showSuccessAlert({ message: 'app:changesSaved' }));
  } catch (error) {
    yield put(handleServerError(error?.response));
  }
}

export default unsubscribe;
