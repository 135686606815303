import moment from 'moment';

const getCookies = () => {
  const cookies = {};

  if (typeof document !== 'undefined') {
    document.cookie.split(';').forEach(cookie => {
      const splittedCookie = cookie.split('=');
      const key = splittedCookie[0].replace(' ', '');

      // eslint-disable-next-line prefer-destructuring
      cookies[key] = splittedCookie[1];
    });
  }

  return cookies;
};

const getCookieValue = cookieName => {
  const cookies = getCookies();

  if (typeof cookies[cookieName] !== 'undefined') {
    return cookies[cookieName];
  }

  return null;
};

const removeCookie = (cookieName, { domain, path } = {}) => {
  let cookie = `${cookieName}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  cookie += typeof domain !== 'undefined' ? `Domain=${domain};` : '';
  cookie += typeof path !== 'undefined' ? `Path=${path};` : '';

  document.cookie = cookie;
};

const setCookie = (
  cookieName,
  value,
  expires = moment().add(5, 'years').toDate().toUTCString(),
  { domain, path, attributes } = {},
) => {
  let cookie = `${cookieName}=${value}; Expires=${expires};`;

  cookie += typeof domain !== 'undefined' ? `Domain=${domain};` : '';
  cookie += typeof path !== 'undefined' ? `Path=${path};` : 'Path=/;';
  cookie += typeof attributes !== 'undefined' ? attributes : '';

  document.cookie = cookie;
};

export const setCookieForLp = () => {
  return `
    function setCookieForLp(cookieName, cookieValue, expires, rest = {}) {
      const { domain, path, attributes } = rest;
      const YEAR_IN_MILLISECONDS = 365 * 24 * 60 * 60 * 1000;
      const expireTime = expires || new Date(new Date().getTime() + 5 * YEAR_IN_MILLISECONDS).toUTCString();

      let cookie = cookieName + '=' + cookieValue + '; Expires=' + expireTime + ';';

      cookie += typeof domain !== 'undefined' ? 'Domain=' + domain + ';' : '';
      cookie += typeof path !== 'undefined' ? 'Path=' + path + ';' : 'Path=/;';
      cookie += typeof attributes !== 'undefined' ? attributes : '';

      document.cookie = cookie;
    };
  `;
};

const ABTestCookie = ({ name, domain, path }, values = ['on', 'off'], splitting = [0.5, 0.5]) => {
  const randomValue = Math.random();

  const existingCookie = document.cookie.match(new RegExp(`;?\\s?${name}=([^;]+)`));
  let value = existingCookie ? existingCookie[1] : null;

  if (!value) {
    const minTrigger = Math.min(...splitting);
    const maxTrigger = Math.max(...splitting);

    if (minTrigger === maxTrigger) {
      value = randomValue > 0.5 ? values[0] : values[1];
    } else if (randomValue < minTrigger) {
      value = values[splitting.indexOf(minTrigger)];
    } else {
      value = values[splitting.indexOf(maxTrigger)];
    }

    let cookie = `${name}=${value};`;

    cookie += typeof domain !== 'undefined' ? `Domain=${domain};` : '';
    cookie += typeof path !== 'undefined' ? `Path=${path};` : 'Path=/;';

    document.cookie = cookie;
  }

  return value;
};

export { getCookies, getCookieValue, removeCookie, ABTestCookie, setCookie };
