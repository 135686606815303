/* eslint-disable padding-line-between-statements */
import {
  CHROME_EXTENSION_PATH,
  FIREFOX_EXTENSION_PATH,
  SAFARI_EXTENSION_PATH,
  EDGE_EXTENSION_PATH,
} from 'consts/extensionPaths';
import BROWSERS from 'consts/browsers';

const getExtensionLink = browser => {
  switch (browser) {
    case BROWSERS.OPERA:
    case BROWSERS.CHROME: {
      return CHROME_EXTENSION_PATH;
    }

    case BROWSERS.OLD_EDGE:
    case BROWSERS.EDGE: {
      return EDGE_EXTENSION_PATH;
    }

    case BROWSERS.SAFARI: {
      return SAFARI_EXTENSION_PATH;
    }

    case BROWSERS.FIREFOX: {
      return FIREFOX_EXTENSION_PATH;
    }

    default: {
      return null;
    }
  }
};

export default getExtensionLink;
