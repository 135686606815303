import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@karma/components';
import { PayPalIcon } from '@karma/components/icons';
import { getUserEmail } from 'store/user/selectors';
import { oldApiInstance } from 'functions/request';

const ModalBodyPaypalConfirm = () => {
  const { t } = useTranslation();

  const email = useSelector(getUserEmail);

  useEffect(() => {
    oldApiInstance.post('/plus/resend_paypal_confirmation_email');
  }, []);

  return (
    <Stack direction="column" spacing={20}>
      <PayPalIcon width={60} height={60} />
      <Stack direction="column" spacing={8}>
        <Typography variant="h3" align="center">
          <Trans i18nKey="app:verifyYourPaypalAccount">
            Verify your Paypal
            <br />
            account
          </Trans>
        </Typography>

        <Typography variant="paragraphLarge" align="center">
          {t('app:weHaveSentAnEmailTo')} {email}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ModalBodyPaypalConfirm;
