import { D_PUBLIC_PAGE_BASE_WIDTH, MAX_SCREEN_WIDTH, MAX_TABLET_WIDTH } from 'consts/common';

export function currentDesktopSize(size, units = true) {
  const value = ((size / D_PUBLIC_PAGE_BASE_WIDTH) * 100).toFixed(2);

  return units ? `${value}vw` : value;
}

function maxDesktopSize(size) {
  return `${Math.round((currentDesktopSize(size, false) / 100) * MAX_SCREEN_WIDTH)}px`;
}

function minDesktopSize(size) {
  return `${Math.round((currentDesktopSize(size, false) / 100) * (MAX_TABLET_WIDTH + 1))}px`;
}

export function clampLandingSize(size) {
  return `clamp(${minDesktopSize(size)}, ${currentDesktopSize(size)}, ${maxDesktopSize(size)})`;
}

export function clampLandingSizeMaxSize(size) {
  return `clamp(0px, ${currentDesktopSize(size)}, ${size}px)`;
}
