import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MoreIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path d="M2.13867 10.6429H5.13867V13.6429H2.13867V10.6429Z" fill="currentColor" />
      <path d="M11.1387 10.6429H14.1387V13.6429H11.1387V10.6429Z" fill="currentColor" />
      <path d="M20.1387 10.6429H23.1387V13.6429H20.1387V10.6429Z" fill="currentColor" />
    </BaseIcon>
  );
};

export default MoreIcon;
