import { useEffect, useState } from 'react';
import getUserCountry from 'functions/getUserCountry';
import isGDPR from 'functions/isGDPR';

export default () => {
  const [isGDPRCountry, setIsGDPRCountry] = useState(false);

  useEffect(() => {
    async function init() {
      const country = await getUserCountry();
      const isGDPRCountryResult = isGDPR(country);

      setIsGDPRCountry(isGDPRCountryResult);
    }

    init();
  }, []);

  return isGDPRCountry;
};
