import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as notificationsActions from '../actions';
import * as notificationsApi from '../api';

function* fetchSortByDateNotifications() {
  try {
    const response = yield call(notificationsApi.fetchSortByDateNotifications);

    yield put(notificationsActions.successFetchSortByDateNotifications(response.data.data));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchSortByDateNotifications;
