import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import { requestTagsBulkMute } from 'store/multiEdit/actions';
import useAction from 'hooks/useAction';
import ModalBodyTags from 'components/molecules/ModalBodyTags/ModalBodyTags';
import Modal from 'components/templates/Modal/Modal';

const ModalTagsMute = props => {
  const { closeModal } = props;
  const requestTagsBulkMuteFunc = useAction(requestTagsBulkMute);

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.TAGS_MUTE} onClose={closeModal}>
      <ModalBodyTags
        title="app:youReAboutToMuteNumberItems"
        primaryButtonText="app:muteTagTitle"
        onSubmit={requestTagsBulkMuteFunc}
        {...props}
      />
    </Modal>
  );
};

ModalTagsMute.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalTagsMute;
