import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Deferred action allows us to bypass problems with the INP metric.
 * Zero timeout frees up the process to send the INP metrics first.
 */
export default action => {
  const dispatch = useDispatch();

  return useCallback(
    (...args) => {
      setTimeout(() => {
        dispatch(action(...args));
      }, 0);
    },
    [action, dispatch],
  );
};
