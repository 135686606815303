import React from 'react';
import { InputLabelRoot } from './InputLabel.styles';
import { InputLabelProps } from './InputLabel.types';

const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(function InputLabel(
  { children, disabled, error, focused, shrink, ...props },
  ref
) {
  return (
    <InputLabelRoot
      {...props}
      ref={ref}
      disabled={disabled}
      error={error}
      focused={focused}
      shrink={shrink}
    >
      {children}
    </InputLabelRoot>
  );
});

export default InputLabel;
