import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@karma/components';
import { CDN_URL } from '@karma/utils/constants/env';
import { PATHS } from 'consts/paths';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { getUser } from 'store/user/selectors';
import buildUrl from 'functions/buildUrl';
import { Container, GiftCardImage, LeftContainer, RightContainer } from './ModalBodyChatWithUs.style';

const GIFT_CARD_VALUE = 30;
const MEETING_TIME = 15;

const ModalBodyChatWithUs = () => {
  const { t } = useTranslation();
  const { id, guest, email } = useSelector(getUser);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const giftCardImg = `${CDN_URL}/images/karma/images/extensionDeleted/amazon-gift-card.png`;

  const url = useMemo(
    () =>
      buildUrl(PATHS.CHAT_WITH_US_MEETING, [
        { name: 'UUID', value: id },
        { name: 'EMAIL', value: guest ? null : email },
      ]),
    [id, guest, email],
  );

  return (
    <Container fullWidth>
      <LeftContainer direction="column" spacing={32} fullWidth>
        <Stack direction="column" fullWidth spacing={8}>
          <Typography variant="h3">
            <Trans i18nKey="chatWithUsAndGet" count={GIFT_CARD_VALUE}>
              Chat with us & get
              <br />${{ count: GIFT_CARD_VALUE }} Amazon Gift Card
            </Trans>
          </Typography>
          <Typography variant="paragraphLarge">
            <Trans i18nKey="scheduleAMeetingAndEarnGiftCard" count={GIFT_CARD_VALUE}>
              Schedule a {{ minutes: MEETING_TIME }}-minute interview and earn a <br />${{ count: GIFT_CARD_VALUE }}{' '}
              Amazon gift card.
            </Trans>
          </Typography>
        </Stack>
        <Button
          size="medium"
          style={{ width: '293px' }}
          as="a"
          href={url}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.SCHEDULE_AN_INTERVIEW}
        >
          {t('app:scheduleAnInterview')}
        </Button>
      </LeftContainer>
      <RightContainer fullWidth>
        <GiftCardImage src={giftCardImg} alt="amazon-gift-card" />
      </RightContainer>
    </Container>
  );
};

export default ModalBodyChatWithUs;
