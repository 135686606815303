import { Button, Stack } from '@karma/components';
import styled from 'styled-components';

export const Container = styled(Stack)`
  padding: 0 80px;

  @media (max-width: 767px) {
    padding: 0 30px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
`;
