import { useTranslation } from 'react-i18next';
import { CardMaker } from '@karma/components';

const names = {
  on_sale: 'app:onSale',
  low_in_stock: 'app:lowInStock',
  back_in_stock: 'app:backInStock',
  coupon: 'app:coupon',
  sold_out: 'app:soldOut',
  fulfilled: 'app:fulfilled',
  deal: 'app:deal',
  out_of_stock: 'app:outOfStock',
};

const Badge = ({ marker, ...rest }) => {
  const { t } = useTranslation();

  if (marker) {
    const type = marker.replace(/\s/g, '_').toLowerCase();
    const name = t(names[type]);

    return (
      <>
        {type === 'fulfilled' ? (
          <CardMaker color="shades2" {...rest}>
            {name}
          </CardMaker>
        ) : (
          <CardMaker {...rest}>{name}</CardMaker>
        )}
      </>
    );
  }

  return null;
};

export default Badge;
