import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const FiltersIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28906 0.792847H23.9891V6.30849L16.2415 14.2057V18.7978L13.1083 23.4928H9.03664V14.2057L1.28906 6.30849V0.792847ZM2.98906 2.49285V5.61383L10.7366 13.511V21.7928H12.199L14.5415 18.2827V13.511L22.2891 5.61383V2.49285H2.98906Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default FiltersIcon;
