import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const HeartIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'primary1',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.19352 3.48345C5.32914 1.33885 8.79167 1.33885 10.9273 3.48345L12.529 5.09191L14.1307 3.48345C16.2664 1.33885 19.7289 1.33885 21.8645 3.48345C24.0001 5.62806 24.0001 9.10515 21.8645 11.2498L12.529 20.6245L3.19352 11.2498C1.05789 9.10515 1.05789 5.62806 3.19352 3.48345Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.27019 15.8015L12.5965 20.345L21.4646 11.1831C23.2847 9.30261 23.2847 6.23152 21.4646 4.35105C19.6827 2.51015 16.8207 2.50073 15.0277 4.3287L12.5126 6.89295L9.97689 4.27324C8.20427 2.44186 5.35396 2.44186 3.58134 4.27324C1.76734 6.14736 1.78063 9.21187 3.60946 11.0689L8.27019 15.8015ZM12.5865 22.7997L7.04891 16.9842L2.39822 12.2618C-0.0785792 9.74676 -0.0958208 5.62795 2.35983 3.09091C4.80053 0.569307 8.7577 0.569306 11.1984 3.09091L12.5207 4.45698L13.8141 3.1383C16.2769 0.627452 20.2395 0.641042 22.6861 3.16872C25.1442 5.70832 25.1442 9.82582 22.6861 12.3654L12.5865 22.7997Z"
          fill={getColor(secondaryColor)}
        />
      </g>
    </BaseIcon>
  );
};

export default HeartIconFilled;
