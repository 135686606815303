import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import { ENTITIES } from 'consts/entities';
import ModalBodyBonusWelcome from 'components/molecules/ModalBodyBonusWelcome/ModalBodyBonusWelcome';
import Modal from 'components/templates/Modal/Modal';

const description = 'app:hereIsABitOfGoodKarma';

const ModalBonusWelcome = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.WELCOME_BONUS} onClose={closeModal}>
      <ModalBodyBonusWelcome description={description} {...props} />
    </Modal>
  );
};

ModalBonusWelcome.propTypes = {
  bonus: ENTITIES.bonus.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalBonusWelcome;
