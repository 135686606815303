import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import Typography from '../Typography';
import { CardMakerContainer } from './CardMaker.styles';
import { ICardMakerProps } from './CardMaker.types';

const CardMaker: React.FC<ICardMakerProps> = ({
  children,
  color = 'neutral5',
  rounding = 'all',
  Icon,
  size = 'medium',
  border = false,
  ...props
}) => {
  const { sizeConfig } = useContext(ThemeContext);

  const sConf = useMemo(
    () => sizeConfig.CardMaker?.[size] || sizeConfig.CardMaker?.medium,
    [sizeConfig, size]
  );

  return (
    <CardMakerContainer sConf={sConf} color={color} rounding={rounding} border={border} {...props}>
      {Icon && <Icon size={sConf.iconSize} color="currentColor" />}
      <Typography variant={sConf.textVariant}>{children}</Typography>
    </CardMakerContainer>
  );
};

export default CardMaker;
