import { put } from 'redux-saga/effects';
import setUpUserLanguage from 'functions/language';
import getLanguage from 'functions/getLanguage';
import { handleServerError } from 'store/alerts/actions';
import * as userActions from '../actions';

function* saveUser({ payload }) {
  try {
    yield put(userActions.receiveUser(payload));
    setUpUserLanguage(payload?.language || getLanguage());
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default saveUser;
