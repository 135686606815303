import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BulbIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 26"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35039 11.4688C6.35039 8.34837 8.87998 5.81877 12.0004 5.81877C15.1208 5.81877 17.6504 8.34837 17.6504 11.4688C17.6504 12.3857 17.4326 13.2493 17.0467 14.013C16.8901 14.3229 16.6416 14.6132 16.3162 14.9169C15.3149 15.8512 14.6091 17.0582 14.2859 18.389L14.19 18.7838L15.842 19.185L15.9379 18.7902C16.1827 17.782 16.7174 16.8676 17.4759 16.1598C17.8675 15.7945 18.2783 15.3451 18.564 14.7797C19.0673 13.7837 19.3504 12.6581 19.3504 11.4688C19.3504 7.40948 16.0597 4.11877 12.0004 4.11877C7.9411 4.11877 4.65039 7.40948 4.65039 11.4688C4.65039 12.6581 4.9335 13.7837 5.43677 14.7797C5.72247 15.3451 6.13328 15.7945 6.52483 16.1598C7.28337 16.8676 7.81805 17.782 8.06289 18.7902L8.15877 19.185L9.81076 18.7838L9.71487 18.389C9.39168 17.0582 8.68589 15.8512 7.68461 14.9169C7.35915 14.6132 7.11065 14.3229 6.95406 14.013C6.56817 13.2493 6.35039 12.3857 6.35039 11.4688Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 22.05H8V20.45H16V22.05Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 25.05H10V23.45H14V25.05Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6004 12.3H20.9004V10.7H22.6004V12.3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0659 4.56241L18.8638 5.76449L17.7324 4.63312L18.9345 3.43104L20.0659 4.56241Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41744 4.56241L5.21536 5.76449L4.08398 4.63312L5.28607 3.43104L6.41744 4.56241Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8504 2.55001H11.1504V0.950012H12.8504V2.55001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10039 12.3H1.40039V10.7H3.10039V12.3Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BulbIcon;
