import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import checkIsDashboardPath from 'functions/checkIsDashboardPath';

const useIsDashboardPath = () => {
  const { pathname } = useLocation();

  return useMemo(() => checkIsDashboardPath(pathname), [pathname]);
};

export default useIsDashboardPath;
