import { useTranslation } from 'react-i18next';
import { Typography } from '@karma/components';
import { GeneralShareIcon } from '@karma/components/icons';
import { ENTITIES } from 'consts/entities';
import FacebookShareButton from 'components/molecules/FacebookShareButton/FacebookShareButton';
import TwitterShareButton from 'components/molecules/TwitterShareButton/TwitterShareButton';
import EmailShareButton from 'components/atoms/EmailShareButton/EmailShareButton';
import CopyButton from 'components/molecules/CopyButton/CopyButton';
import { Container, TitleWrapper } from './ModalBodyListShare.style';

const ModalBodyListShare = ({ list }) => {
  const { t } = useTranslation();
  const { description, shorten_url } = list;

  return (
    <Container>
      <GeneralShareIcon width={68} height={68} />
      <TitleWrapper>
        <Typography variant="h3">{t('app:shareList')}</Typography>
        <Typography variant="labelLarge">{t('app:dropSomeShoppingInspo')}</Typography>
      </TitleWrapper>
      <FacebookShareButton wideButton url={shorten_url} />
      <TwitterShareButton wideButton description={description} url={shorten_url} />
      <EmailShareButton wideButton description={description} url={shorten_url} />
      <TitleWrapper>
        <Typography variant="labelMedium">{t('app:orCopyTheLinkBelowAndSend')}</Typography>
        <CopyButton value={shorten_url} />
      </TitleWrapper>
    </Container>
  );
};

ModalBodyListShare.propTypes = {
  list: ENTITIES.list.isRequired,
};

export default ModalBodyListShare;
