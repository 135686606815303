import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const WalletIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8867 10.3811C10.8867 8.80706 12.1627 7.53107 13.7367 7.53107H23.4992V16.4311H13.7367C12.1627 16.4311 10.8867 15.1551 10.8867 13.5811V10.3811ZM13.7367 9.23107C13.1016 9.23107 12.5867 9.74594 12.5867 10.3811V13.5811C12.5867 14.2162 13.1016 14.7311 13.7367 14.7311H21.7992V9.23107H13.7367Z"
        fill="currentColor"
      />
      <path
        d="M16.4 11.9811C16.4 12.6438 15.8627 13.1811 15.2 13.1811C14.5373 13.1811 14 12.6438 14 11.9811C14 11.3183 14.5373 10.7811 15.2 10.7811C15.8627 10.7811 16.4 11.3183 16.4 11.9811Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.650391 2.68988H21.6504C22.6721 2.68988 23.5004 3.51815 23.5004 4.53988V21.2723H3.50039C1.92638 21.2723 0.650391 19.9963 0.650391 18.4223V2.68988ZM2.35039 4.38988V18.4223C2.35039 19.0574 2.86526 19.5723 3.50039 19.5723H21.8004V4.53988C21.8004 4.45704 21.7332 4.38988 21.6504 4.38988H2.35039Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default WalletIcon;
