import { call, put, all } from 'redux-saga/effects';
import { deleteTag } from 'store/multiEdit/actions';
import { handleServerError } from 'store/alerts/actions';
import * as api from '../api';
import { setDeletedTag, setIsDeletingTag } from '../actions';

export default function* ({ payload: tagId }) {
  try {
    yield put(setIsDeletingTag(true));
    yield all([call(api.deleteTag, tagId), put(setDeletedTag(tagId)), put(deleteTag(tagId))]);
  } catch (error) {
    yield put(handleServerError(error));
  } finally {
    yield put(setIsDeletingTag(false));
  }
}
