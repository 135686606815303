import { createSelector } from '@reduxjs/toolkit';

const getRootEntity = ({ favoriteRetailers }) => {
  return favoriteRetailers;
};

export const getFavoriteRetailers = createSelector([getRootEntity], ({ favoriteRetailers }) => {
  return favoriteRetailers;
});

export const getIds = createSelector(getRootEntity, ({ ids }) => ids);

export const getIsLoaded = createSelector([getRootEntity], ({ isLoaded }) => {
  return isLoaded;
});

export const getWasInitialUpdate = createSelector(getRootEntity, ({ wasInitialUpdate }) => wasInitialUpdate);

export const getHasError = createSelector(getRootEntity, ({ hasError }) => hasError);
