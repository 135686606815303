import { useMemo } from 'react';
import useClientDimensions from 'hooks/useClientDimensions';

/**
 * @description Calculates the current breakpoint
 * @param {Object} breakpoints - swiper breakpoints https://github.com/nolimits4web/swiper/blob/f4afd9d0fec3be48c17ab75c462c23e69e98a571/src/types/swiper-options.d.ts#L707C11-L707C11
 * @returns {Number|null} - current breakpoint
 */
function useCurrentBreakPoint(breakpoints) {
  const { clientWidth } = useClientDimensions();

  return useMemo(() => {
    if (!clientWidth) return null;

    /**
     * @description the nearest lower resolution from the current one, otherwise the smallest possible
     * @type {Number}
     * @example
     * Object.keys(breakpoints): [1921, 1350, 1100, 768, 576, 390, 340, 320]
     * 2900 (clientWidth) -> 1921 (currentBreakpoint)
     * 1921 (clientWidth) -> 1921 (currentBreakpoint)
     * 1920 (clientWidth) -> 1350 (currentBreakpoint)
     * 1024 (clientWidth) -> 768 (currentBreakpoint)
     * 768 (clientWidth) -> 768 (currentBreakpoint)
     * 767 (clientWidth) -> 576 (currentBreakpoint)
     * 480 (clientWidth) -> 576 (currentBreakpoint)
     * 319 (clientWidth) -> 320 (currentBreakpoint)
     * 0 (clientWidth) -> 320 (currentBreakpoint)
     */
    const currentBreakpoint = Object.keys(breakpoints).reduce((prev, curr) => {
      const currWidth = parseInt(curr, 10);
      const prevWidth = parseInt(prev, 10);

      if (currWidth > clientWidth && prevWidth > clientWidth) return Math.min(currWidth, prevWidth);
      if (currWidth <= clientWidth && prevWidth <= clientWidth) return Math.max(currWidth, prevWidth);
      if (prevWidth <= clientWidth) return prevWidth;

      return currWidth;
    });

    return currentBreakpoint;
  }, [breakpoints, clientWidth]);
}

export default useCurrentBreakPoint;
