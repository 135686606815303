import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import styled, { css } from 'styled-components';

export const StyledTab = styled(Stack)`
  ${({ $selected }) =>
    $selected
      ? css`
          border-bottom: 4px solid ${getColor('shades1')};
          padding: 10px 0 6px;
        `
      : css`
          border-bottom: 1px solid ${getColor('neutral2')};
          padding: 10px 0 9px;
        `};
  width: 100%;
  height: 44px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
`;
