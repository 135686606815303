import CConsole from 'functions/CConsole';

async function getRecaptchaToken(action) {
  if (!window.grecaptcha || !window.grecaptcha.enterprise) {
    return null;
  }

  try {
    return await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
  } catch (error) {
    CConsole.error(error);

    return null;
  }
}

export default getRecaptchaToken;
