import React from 'react';
import { BaseIconRoot } from './BaseIcon.styles';
import { BaseIconProps } from './BaseIcon.types';

const BaseIcon: React.FC<BaseIconProps> = ({
  width,
  height,
  color,
  viewBox,
  children,
  ...props
}) => (
  <BaseIconRoot
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
  </BaseIconRoot>
);

export default BaseIcon;
