import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CheckmarkIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4412 5.28821L24.2864 5.42089L24.2788 5.42711C19.1238 9.64288 14.4072 14.3595 10.1915 19.5144L9.48782 20.3748L8.74427 19.3338C6.49934 16.3907 3.84208 13.7545 0.875 11.5086L1.90101 10.1531C4.75511 12.3135 7.33566 14.8223 9.55795 17.6126C13.668 12.6937 18.2317 8.17721 23.1949 4.1174L23.3348 3.99747L24.4412 5.28821Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CheckmarkIcon;
