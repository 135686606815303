import { useState } from 'react';
import { BookmarkIconFilled } from '@karma/components/icons';
import { useSelector } from 'react-redux';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { useDeleteTag, useIsSavedItem, useSaveGlobalItem } from 'hooks/useGlobalItemHooks';
import { getIsDeletingTag } from 'store/myItems/store/selectors';
import { SavedIconButtonContainer } from './SaveTagButton.style';

const SaveTagButton = ({ tag, closeModal, buttonStyled = true }) => {
  const [hovered, setHovered] = useState(false);
  const [saving, setSaving] = useState(false);
  const isDeletingTag = useSelector(getIsDeletingTag);

  const isSavedItem = useIsSavedItem(tag);

  const contextDataAttrs = Alooma.useContextDataAttrs();

  const loading = isDeletingTag || saving;

  const saveGlobalItem = useSaveGlobalItem();
  const deleteTag = useDeleteTag();

  const onClick = async () => {
    if (loading) return;

    if (isSavedItem) {
      deleteTag(tag, closeModal);
    } else {
      setSaving(true);
      await saveGlobalItem(tag);
      setSaving(false);
    }
  };

  const getIconProps = () => {
    let color = isSavedItem ? 'secondary1' : 'neutral6';
    let secondaryColor = isSavedItem ? 'secondary1' : 'neutral7';

    if (hovered && isSavedItem) {
      color = 'neutral6';
      secondaryColor = 'neutral6';
    }

    if (hovered && !isSavedItem) {
      color = 'secondary1';
      secondaryColor = 'neutral6';
    }

    return {
      size: 'medium',
      color,
      secondaryColor,
      onClick,
    };
  };

  return (
    <SavedIconButtonContainer
      {...contextDataAttrs}
      data-alooma-element={isSavedItem ? ALOOMA.ELEMENTS.DELETE_PRODUCT : ALOOMA.ELEMENTS.SAVE_TO_KARMA}
      onMouseEnter={() => buttonStyled && setHovered(true)}
      onMouseLeave={() => buttonStyled && setHovered(false)}
      $roundButton={buttonStyled}
    >
      <BookmarkIconFilled {...getIconProps()} />
    </SavedIconButtonContainer>
  );
};

export default SaveTagButton;
