import { newApiInstance } from 'functions/request';

export const getRetailersGroups = ({ groups, perGroup, sortBy, sortOrder } = {}) =>
  newApiInstance.get('/retailers/preview', {
    params: {
      groups: groups || null,
      per_group: perGroup || null,
      seed: null,
      sort_by: sortBy || null,
      sort_order: sortOrder || null,
    },
  });
