import locales from 'locales';

const REGULAR_LOCALES = {
  en: 'en',
  de: 'de',
  hi: 'hi',
  es: 'es',
  fr: 'fr',
  it: 'it',
  ru: 'ru',
};

export const NUMBER_LOCALES = {
  ...REGULAR_LOCALES,
  hi: 'hi-u-nu-deva',
};

export const I18N_CODE_TO_MOMENT_LOCALE = {
  ...REGULAR_LOCALES,
  esAR: 'es',
  ptBR: 'pt-br',
};

export const I18N_CODE_TO_LOCALE = {
  ...REGULAR_LOCALES,
  esAR: 'es-ar',
  ptBR: 'pt-br',
};

export const CODE_TO_I18N_LANGUAGE = {
  ...REGULAR_LOCALES,
  'es-ar': 'esAR',
  'pt-br': 'ptBR',
  esAR: 'esAR',
  ptBR: 'ptBR',
};

export const LANGUAGES = {
  en: 'English',
  ru: 'Русский',
  de: 'Deutsch',
  fr: 'Français',
  hi: 'हिंदी',
  'pt-br': 'Português - Brasil',
  it: 'Italiana',
  es: 'Español',
  'es-ar': 'Español - América Latina',
};

export const DEFAULT_LANGUAGE = 'en';

export const LANGUAGE_OPTIONS = Object.entries(LANGUAGES).reduce(
  (result, [value, label]) =>
    locales[CODE_TO_I18N_LANGUAGE[value]]
      ? [
          ...result,
          {
            value,
            label,
          },
        ]
      : result,
  [],
);

export const LANGUAGE_TO_COUNTRY = {
  de: 'de',
  en: 'us',
  es: 'es',
  'es-ar': 'es',
  esAR: 'es',
  fr: 'fr',
  hi: 'in',
  it: 'it',
  'pt-br': 'br',
  ptBR: 'br',
  ru: 'ru',
};
