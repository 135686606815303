import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loaded: false,
  isFetching: false,
  newSalesLeft: true,
  page: 1,
  newSales: [],
};

export const newSalesSlice = createSlice({
  name: 'newSales',
  initialState,
  reducers: {
    successGetNewSales: (state, action) => {
      const { page, newSales, newSalesLeft } = action.payload;

      return {
        ...state,
        loaded: true,
        isFetching: false,
        newSalesLeft,
        page,
        newSales: [...state.newSales, ...newSales],
      };
    },
    requestGetNewSales: state => {
      return {
        ...state,
        isFetching: true,
      };
    },
  },
});

export default newSalesSlice.reducer;
