import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TrashIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.34984V0.649841H16.5V2.34984H7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7971 6.09026H22.5V4.39026H1.5V6.09026H3.22461V21.3417C3.22461 22.5261 4.23592 23.3499 5.33461 23.3499H18.6871C19.7942 23.3499 20.7971 22.5013 20.7971 21.3417V6.09026ZM19.0971 6.09026H4.92461V21.3417C4.92461 21.4517 5.0333 21.6499 5.33461 21.6499H18.6871C18.98 21.6499 19.0971 21.4424 19.0971 21.3417V6.09026Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15039 19.5V9H9.85039V19.5H8.15039Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1504 19.5V9H15.8504V19.5H14.1504Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrashIcon;
