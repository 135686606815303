import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as allCouponsActions from '../actions';
import * as allCouponsApi from '../api';

function* fetchAllCoupons({ payload: { sort_by, page = 1, search = '', addCoupons = false } }) {
  yield put(allCouponsActions.toggleLoading());

  try {
    const response = yield call(allCouponsApi.getCoupons, {
      sort_by,
      page,
      search,
    });

    if (addCoupons) {
      yield put(
        allCouponsActions.addCoupons({
          coupons: response.data,
          page,
          couponsLeft: !!response.data.length,
        }),
      );
    } else {
      yield put(
        allCouponsActions.setCoupons({
          sort_by,
          coupons: response.data,
          page,
          couponsLeft: !!response.data.length,
          search,
        }),
      );
    }
  } catch (error) {
    yield put(handleServerError(error));
  }

  yield put(allCouponsActions.toggleLoading());
}

export default fetchAllCoupons;
