import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
`;

export const PopoverBodyWrapper = styled.div`
  display: block;
  width: calc(100% + 40px);
  margin-left: -20px;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: ${getColor('neutral6')};
`;

export const PopoverWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  margin: 0;
  padding: 0;

  background: transparent;
  transition: all 0.3s;

  color: ${({ isActive }) => (isActive ? getColor('secondary4') : getColor('primary1'))};
`;

export const ToggleButtonWrapper = styled.div`
  position: relative;
  z-index: 5;
`;
