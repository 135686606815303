import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import random from 'lodash/random';
import { DAY_IN_MILLISECONDS, HOUR_IN_MILLISECONDS } from 'consts/time';

const TWO_HOURS = HOUR_IN_MILLISECONDS * 2;
const THREE_DAYS = DAY_IN_MILLISECONDS * 3;

const useGetLastSuccessUseOfCoupon = lastSuccessUse => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!lastSuccessUse)
      return `${t('app:workedAt')} ${t('app:auxiliaryDate', {
        date: moment(Date.now() - TWO_HOURS).fromNow(),
      })}`;

    let lastSuccessUseDate = moment(lastSuccessUse);

    if (lastSuccessUseDate.isBefore(moment(Date.now() - THREE_DAYS))) {
      lastSuccessUseDate = moment(Date.now() - DAY_IN_MILLISECONDS * random(3, 6));
    }

    return t('app:workedAt', { date: lastSuccessUseDate.fromNow() });
  }, [lastSuccessUse, t]);
};

export default useGetLastSuccessUseOfCoupon;
