import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/hi';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/pt-br';
import 'moment/locale/it';
import 'moment/locale/es';
import upperCase from 'lodash/upperCase';
import { DEFAULT_LANGUAGE, I18N_CODE_TO_MOMENT_LOCALE, CODE_TO_I18N_LANGUAGE } from 'consts/locales';
import { localizeNumber, localizeNumberWithoutCommas, shortFromNowDateFormatter } from 'functions/language';
import getLanguage from 'functions/getLanguage';
import resources from '../locales';

const lng = CODE_TO_I18N_LANGUAGE[getLanguage()];

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: [DEFAULT_LANGUAGE],
  lng,
  ns: ['app'],
  defaultNS: 'app',
  interpolation: {
    format: (value, format) => {
      if (format === 'shortFromNowDate') {
        return shortFromNowDateFormatter(value);
      }
      if (['number', 'fraction'].includes(format)) {
        return localizeNumber(value, format === 'fraction');
      }
      if (format === 'uppercase') {
        return upperCase(value);
      }
      if (format === 'clearNumber') {
        return localizeNumberWithoutCommas(value);
      }

      return value;
    },
    escapeValue: false,
  },
  resources,
});

moment.locale(resources[lng] ? lng : DEFAULT_LANGUAGE);

i18n.on('languageChanged', language => {
  const momentLocale = I18N_CODE_TO_MOMENT_LOCALE[language];

  if (resources[language]) {
    moment.locale(momentLocale);
  }
});

export default i18n;
