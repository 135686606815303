import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cards: [],
};

export const contentCardsSlice = createSlice({
  name: 'contentCards',
  initialState,
  reducers: {
    setContentCards: (state, { payload }) => ({
      ...state,
      cards: payload,
    }),
  },
});

export default contentCardsSlice.reducer;
