import styled from 'styled-components';
import { LabelSmallStyles } from '../Typography/Typography.styles';
import { FieldSetRootProps, LegendRootProps } from './FieldSet.types';

export const FieldSetRoot = styled.fieldset<FieldSetRootProps>`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 12px;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;

  border-style: solid;
  border-width: ${({ disabled, focused, error, success }) => {
    if (disabled) return '1px';
    if (focused || error || success) return '2px';

    return '1px';
  }};

  border-color: ${({ disabled, focused, error, success, theme }) => {
    const colors = theme[theme.color].FieldSet;

    if (disabled) return colors.disabled;
    if (error) return colors.error;
    if (success) return colors.success;

    return focused ? colors.focused : colors.normal;
  }};

  border-radius: 8px;
`;

export const Legend = styled.legend<LegendRootProps>`
  float: unset;
  overflow: hidden;
  display: block;
  width: auto;
  padding: 0;
  height: 12px;
  visibility: hidden;
  max-width: ${({ shrink }) => (shrink ? '100%' : '0.01px')};
  transition: ${({ shrink }) =>
    `max-width ${shrink ? '0.2s' : '0.05s'} ease-in-out ${shrink ? '0.1s' : '0.05s'}`};
  white-space: nowrap;
`;

export const LabelSpan = styled.span`
  ${LabelSmallStyles}

  padding: 0 4px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
`;
