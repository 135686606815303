import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyTagsAddToList from 'components/molecules/ModalBodyTagsAddToList/ModalBodyTagsAddToList';
import Modal from 'components/templates/Modal/Modal';

const ModalTagsAddToList = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.TAGS_ADD_TO_LIST} onClose={closeModal}>
      <ModalBodyTagsAddToList {...props} />
    </Modal>
  );
};

ModalTagsAddToList.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalTagsAddToList;
