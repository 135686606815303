import { newApiInstance, oldApiInstance } from 'functions/request';

export const getUser = () => oldApiInstance.get('/user/profile');

export const putUser = data => oldApiInstance.put('/user/profile', data);

export const loginAsUser = data => oldApiInstance.post('/user/fet', data);

export const loginWithToken = data => oldApiInstance.post('/sessions/token_login', data);

export const unsubscribePP = () => oldApiInstance.delete('/plus/paypal_account');

export const unsubscribe = ({ link, uidToken }) => oldApiInstance.get(`/${link}?uid_token=${uidToken}`);

export const deleteAccount = () => newApiInstance.delete('/users/delete_account');
