import { normalize, schema } from 'normalizr';
import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as categoriesActions from '../actions';
import * as categoriesApi from '../api';

const categorySchema = new schema.Entity('categories');

export const categoryListSchema = new schema.Array(categorySchema);

function* fetchCategories() {
  try {
    const response = yield call(categoriesApi.getCategories);
    const normalized = normalize(response.data.data, categoryListSchema);

    yield put(
      categoriesActions.receiveCategories({
        entities: normalized?.entities?.categories || {},
        ids: normalized?.result || [],
      }),
    );
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchCategories;
