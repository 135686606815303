import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

export const getCoupons = ({ coupons }) => coupons;

export const getCouponsForGroup = createSelector(getCoupons, coupons => {
  return memoize(group => {
    return coupons[group].coupons;
  });
});

export const getCouponsLeftForGroup = createSelector(getCoupons, coupons => {
  return memoize(group => {
    return coupons[group].couponsLeft;
  });
});

export const getIsFetchingForGroup = createSelector(getCoupons, coupons => {
  return memoize(group => {
    return coupons[group].isFetching;
  });
});

export const getLoadedForGroup = createSelector(getCoupons, coupons => {
  return memoize(group => {
    return coupons[group].loaded;
  });
});

export const getPageForGroup = createSelector(getCoupons, coupons => {
  return memoize(group => {
    return coupons[group].page;
  });
});
