import { createSlice } from '@reduxjs/toolkit';
import tail from 'lodash/tail';

const initialState = { headerBanners: [] };

const headerBannersSlice = createSlice({
  name: 'headerBanners',
  initialState,
  reducers: {
    popFront: state => ({
      ...state,
      headerBanners: tail(state.headerBanners),
    }),
    pushBack: (state, { payload }) => ({
      ...state,
      headerBanners: [...state.headerBanners, payload],
    }),
    reset: () => initialState,
  },
});

export default headerBannersSlice;
