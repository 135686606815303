import * as ALOOMA from 'consts/alooma';
import ModalBodyPaypalConfirm from 'components/molecules/ModalBodyPaypalConfirm/ModalBodyPaypalConfirm';
import Modal from 'components/templates/Modal/Modal';

const ModalPaypalConfirm = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.PAYPAL_CONFIRM} onClose={closeModal}>
      <ModalBodyPaypalConfirm {...props} />
    </Modal>
  );
};

export default ModalPaypalConfirm;
