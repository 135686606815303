const parseError = error => {
  const result = [];

  if (error && error.data && error.data.error) {
    if (typeof error.data.error === 'object') {
      error.data.error
        .filter(e => !!e)
        .forEach(e => {
          result.push(e);
        });
    }

    if (typeof error.data.error === 'string') {
      result.push(error.data.error);
    }
  } else if (error && error.statusText) {
    result.push(`${error.status || ''} ${error.statusText}`);
  } else if (typeof error === 'string' && error) {
    result.push(error);
  }

  return result;
};

export default parseError;
