import React from 'react';
import HelperText from '../HelperText';
import useId from '../utils/useId';
import {
  CheckboxRoot,
  CheckmarkWrapper,
  StyledCheckmarkIcon,
  StyledInput,
  CheckboxLabel,
  CheckboxContainer,
} from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

const Checkbox: React.FC<CheckboxProps> = ({
  id: idOverride,
  name,
  disabled,
  label,
  onChange,
  value,
  checkboxProps = {},
  tabIndex: tabIndexProp,

  fullWidth = false,
  error = false,

  labelProps = {},

  helperText,
  helperTextProps = {},

  ...props
}) => {
  const id = useId(idOverride);
  const labelId = label && `${id}-label`;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const tabIndex = tabIndexProp || 0;

  return (
    <CheckboxRoot disabled={disabled} fullWidth={fullWidth} {...props}>
      <CheckboxContainer>
        <CheckmarkWrapper
          tabIndex={tabIndex}
          role="checkbox"
          disabled={disabled}
          checked={value}
          error={error}
        >
          <StyledInput
            {...checkboxProps}
            id={id}
            name={name}
            checked={!!value}
            tabIndex={-1}
            disabled={disabled}
            onChange={onChange}
            type="checkbox"
            aria-describedby={labelId}
          />
          <StyledCheckmarkIcon
            size="extraSmall"
            checked={value}
            disabled={disabled}
            error={error}
          />
        </CheckmarkWrapper>

        {label && (
          <CheckboxLabel {...labelProps} id={labelId}>
            {label}
          </CheckboxLabel>
        )}
      </CheckboxContainer>
      <HelperText disabled={disabled} error={error} show={!!helperText} id={helperTextId}>
        {helperText}
      </HelperText>
    </CheckboxRoot>
  );
};

export default Checkbox;
