import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextField, Typography, Button } from '@karma/components';
import { BagIcon } from '@karma/components/icons';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { newApiInstance } from 'functions/request';
import { validateUrl } from 'functions/validateUrl';
import useAction from 'hooks/useAction';
import { showNotificationSuccessAlert, showNotificationUnsuccessfulAlert } from 'store/alerts/actions';
import useConnectExtensionHandler from 'hooks/useConnectExtensionHandler';
import { getExtInstalled } from 'store/extension/selectors';
import { Container } from './ModalBodyTagCreate.style';

const ModalBodyTagCreate = props => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const { closeModal } = props;
  const [url, setUrl] = useState('');
  const [validUrl, setValidUrl] = useState(true);
  const isButtonClick = useRef(false);

  const showUnsuccessfulAlertFunc = useAction(showNotificationUnsuccessfulAlert);
  const showSuccessAlertFunc = useAction(showNotificationSuccessAlert);

  const isExtensionInstalled = useSelector(getExtInstalled);
  const connectExtensionHandler = useConnectExtensionHandler();

  const showStatusMessage = status => {
    if (status) {
      showSuccessAlertFunc({
        source: ALOOMA.SOURCE_PAGES.SUCCESS_NOTIFICATION_ALERT,
      });
    } else {
      showUnsuccessfulAlertFunc({
        source: ALOOMA.SOURCE_PAGES.NOT_SUPPORTED_NOTIFICATION_ALERT,
      });
    }
  };

  const onChangeHandler = e => {
    setUrl(e.target.value);
  };

  const saveTagFromUrl = async () => {
    if (!isButtonClick.current) {
      if (validateUrl(url)) {
        try {
          isButtonClick.current = true;
          const data = {
            url: /^http(s)?:\/\//.test(url) ? url : `https://${url}`,
            item_type: 'desktop_internal',
          };
          const response = await newApiInstance.post('/tags/create_from_url', data);

          showStatusMessage(response?.data?.success);
        } catch (error) {
          showStatusMessage(error?.response?.data?.success);
        }
        setUrl('');
        setValidUrl(true);
        closeModal();
      } else {
        setValidUrl(false);
      }
    }
  };

  const error = validUrl ? '' : 'error';

  return (
    <Container>
      {!isExtensionInstalled ? (
        <>
          <BagIcon width={60} height={60} />
          <Typography variant="h3">{t('app:powerShopper')}</Typography>
        </>
      ) : (
        <Typography variant="h3">{t('app:saveAnItem')}</Typography>
      )}
      <TextField
        placeholder={t('app:enterLinkToAnyItemPage')}
        onChange={onChangeHandler}
        error={error}
        fullWidth
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.INPUT}
      />
      <Button
        size="medium"
        fullWidth
        onClick={saveTagFromUrl}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.BUTTON_SAVE_ITEM}
      >
        {t('app:save')}
      </Button>
      {!isExtensionInstalled && (
        <Typography variant="labelMedium">
          <Trans i18nKey="saveStuffWithZeroFuss">
            <Button
              size="small"
              type="underlinedText"
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.GET_THE_BUTTON}
              onClick={connectExtensionHandler}
              fullWidth
            >
              Get the browser extension
            </Button>
            and save stuff with zero fuss.
          </Trans>
        </Typography>
      )}
    </Container>
  );
};

ModalBodyTagCreate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalBodyTagCreate;
