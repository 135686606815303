export const FAVORITES_LIST_OPTION = {
  id: 'favorites',
  name: 'app:favorites',
  enableTranslation: true,
};

export const FULFILLED_LIST_OPTION = {
  id: 'fulfilled',
  name: 'app:fulfilled',
  enableTranslation: true,
};

export const REGULAR_LISTS_OPTIONS = [FAVORITES_LIST_OPTION, FULFILLED_LIST_OPTION];
