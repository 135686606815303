import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ForwardArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99033 1.04239L19.0891 12.1621L8.05021 23.2428L6.84587 22.043L16.6883 12.1633L6.78711 2.24333L7.99033 1.04239Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ForwardArrowIcon;
