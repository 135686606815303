import styled from 'styled-components';
import { getColor } from '@karma/components/colors';

export const KustomerStyledButton = styled.button`
  all: unset;
  border-radius: 50%;
  z-index: 99;
  width: 60px;
  height: 60px;
  background-color: ${getColor('secondary4')};
  right: 30px;
  bottom: 40px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
