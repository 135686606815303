import styled from 'styled-components';
import { getColor } from '@karma/components/colors';
import { Typography } from '@karma/components';

export const RetailerName = styled(Typography)`
  text-transform: uppercase;
`;

export const Column = styled.div`
  width: 300px;
  position: relative;
`;

export const ColumnContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const BrandWrapperLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
`;

export const NameLink = styled.a`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OriginalPrice = styled(Typography).attrs(props => ({
  ...props,
  variant: 'paragraphMedium',
  color: 'neutral2',
}))`
  text-decoration-line: line-through;

  color: ${getColor('neutral2')};
`;
