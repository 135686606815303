import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ShieldIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3172 0.610432C11.6856 0.220222 12.3053 0.216823 12.6779 0.602964L14.0268 2.0007C15.6725 3.7061 18.0142 4.55505 20.3705 4.30058L20.425 4.2947C20.9358 4.23953 21.3816 4.63977 21.3816 5.15362V13.578C21.3816 18.6863 17.6155 23.0128 12.5559 23.7167C12.4814 23.7271 12.4058 23.7276 12.3311 23.7182C6.78205 23.0215 2.61914 18.3033 2.61914 12.7106V5.15338C2.61914 4.6405 3.06348 4.24053 3.57361 4.29443L3.62897 4.30027C6.03839 4.55482 8.42856 3.67025 10.0918 1.90848L11.3172 0.610432ZM12.0071 2.35602L11.3279 3.07548C9.50088 5.01081 6.95415 6.0777 4.31914 6.04247V12.7106C4.31914 17.4089 7.78876 21.3784 12.4319 22.0169C16.5956 21.3828 19.6816 17.8008 19.6816 13.578V6.04401C17.105 6.08129 14.6108 5.05397 12.8035 3.1812L12.0071 2.35602Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4295 10.8407L16.3713 10.8906L16.3636 10.8968C14.6168 12.3254 13.0185 13.9237 11.5899 15.6705L10.8863 16.5309L10.2118 15.5866C9.47084 14.6165 8.59348 13.7464 7.61328 13.0045L8.6393 11.649C9.4794 12.2849 10.2536 13.0056 10.9448 13.798C12.2673 12.2742 13.7169 10.8659 15.2798 9.58707L15.3231 9.54993L16.4295 10.8407Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ShieldIcon;
