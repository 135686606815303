import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GiftIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1499 8.00002C2.1499 6.9783 2.97818 6.15002 3.9999 6.15002H19.9999C21.0216 6.15002 21.8499 6.9783 21.8499 8.00002V22C21.8499 23.0218 21.0216 23.85 19.9999 23.85H3.9999C2.97818 23.85 2.1499 23.0218 2.1499 22V8.00002ZM3.9999 7.85002C3.91706 7.85002 3.8499 7.91718 3.8499 8.00002V22C3.8499 22.0829 3.91706 22.15 3.9999 22.15H19.9999C20.0827 22.15 20.1499 22.0829 20.1499 22V8.00002C20.1499 7.91718 20.0827 7.85002 19.9999 7.85002H3.9999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 15.85H3V14.15H21V15.85Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1499 23V5H12.8499V23H11.1499Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1499 4.00002C5.1499 1.87373 6.87361 0.150024 8.9999 0.150024C11.1262 0.150024 12.8499 1.87373 12.8499 4.00002V7.85002H8.9999C6.87361 7.85002 5.1499 6.12632 5.1499 4.00002ZM8.9999 1.85002C7.81249 1.85002 6.8499 2.81261 6.8499 4.00002C6.8499 5.18744 7.81249 6.15002 8.9999 6.15002H11.1499V4.00002C11.1499 2.81261 10.1873 1.85002 8.9999 1.85002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8499 4.00002C18.8499 1.87373 17.1261 0.150024 14.9999 0.150024C12.8736 0.150024 11.1499 1.87373 11.1499 4.00002V7.85002H14.9999C17.1261 7.85002 18.8499 6.12632 18.8499 4.00002ZM14.9999 1.85002C16.1873 1.85002 17.1499 2.81261 17.1499 4.00002C17.1499 5.18744 16.1873 6.15002 14.9999 6.15002H12.8499V4.00002C12.8499 2.81261 13.8124 1.85002 14.9999 1.85002Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GiftIcon;
