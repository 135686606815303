import { Typography } from '@karma/components';
import Alooma from 'services/alooma';
import { StyledTab } from './SignUpTab.style';

const SignUpTab = ({ children, onClick, selected, aloomaName }) => {
  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <StyledTab onClick={onClick} $selected={selected} {...contextDataAttrs} data-alooma-element={aloomaName}>
      <Typography variant={selected ? 'labelLarge' : 'paragraphLarge'}>{children}</Typography>
    </StyledTab>
  );
};

export default SignUpTab;
