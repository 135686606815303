import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CopyIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'neutral6',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 22 22"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1659 14V7.65404V6.0957H21.4021V17.8749C21.4021 19.824 19.822 21.404 17.8729 21.404H6.09375V15.1249H7.65208L10.4961 14L11.4961 14.5C12.5846 14.5 10.9961 14.5885 10.9961 13.5L15.1659 14Z"
        fill="currentColor"
      />
      <path
        d="M16.4021 0.595703V0.0957031H15.9021H4.12292C1.89767 0.0957031 0.09375 1.89962 0.09375 4.12487V15.904V16.404H0.59375H12.3729C14.5982 16.404 16.4021 14.6001 16.4021 12.3749V0.595703Z"
        fill="currentColor"
        stroke={getColor(secondaryColor)}
      />
    </BaseIcon>
  );
};

export default CopyIconFilled;
