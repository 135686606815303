import { put } from 'redux-saga/effects';
import { getQueryParam } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { changeFilter } from 'store/filters/actions';
import * as actions from '../actions';

function* setCurrentListAndFetchLists() {
  const listId = getQueryParam(QUERY_PARAMS.list);

  const parsedListId = Number(listId);

  if (parsedListId) {
    yield put(changeFilter({ filterKey: 'lists', filterValue: parsedListId, toggleMode: true }));
  }

  yield put(actions.fetchLists());
}

export default setCurrentListAndFetchLists;
