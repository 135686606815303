import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const PiggyBankIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3349 5.42715C16.7447 5.63211 16.2511 6.04481 15.9418 6.57953L15.6963 7.00392H9.58681C6.73257 7.00392 4.41875 9.31773 4.41875 12.172C4.41875 14.0778 5.44989 15.7437 6.98878 16.6407L7.54919 16.9673L7.08298 18.7156H8.07469L8.46105 17.2667L9.16758 17.3233C9.3057 17.3344 9.44551 17.34 9.58681 17.34H15.6049C15.7465 17.34 15.8867 17.3344 16.0252 17.3232L16.7318 17.2665L17.1183 18.7156H18.11L17.6436 16.9668L18.2038 16.6401C19.2542 16.0276 20.0688 15.0555 20.479 13.8948L20.6461 13.4221L22.0919 13.1811C22.1257 13.1755 22.1505 13.1462 22.1505 13.1119V11.232C22.1505 11.1977 22.1257 11.1685 22.0919 11.1628L20.6461 10.9219L20.479 10.4491C20.0199 9.15018 19.0545 8.08785 17.82 7.50116L17.3349 7.2706V5.42715ZM14.7506 5.30392C15.532 4.26123 16.779 3.58447 18.1849 3.58447H19.0349V6.22057C20.2949 6.94813 21.3021 8.06415 21.8931 9.40624L22.3713 9.48594C23.2249 9.6282 23.8505 10.3667 23.8505 11.232V13.1119C23.8505 13.9772 23.2249 14.7157 22.3713 14.858L21.8931 14.9377C21.3953 16.0682 20.6023 17.038 19.612 17.7504L20.3227 20.4156H15.8122L15.4454 19.04H9.74758L9.38076 20.4156H4.87024L5.58075 17.7512C3.84882 16.5054 2.71875 14.4711 2.71875 12.172C2.71875 8.37885 5.79369 5.30392 9.58681 5.30392H14.7506Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.88306H13.5821V9.58306H9V7.88306Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85039 10.1086V10.4053C1.85039 11.0405 2.36526 11.5553 3.00039 11.5553H3.19841V13.2553H3.00039C1.42638 13.2553 0.150391 11.9793 0.150391 10.4053V10.1086H1.85039Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PiggyBankIcon;
