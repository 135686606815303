import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SleepIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1716 7.6812C8.00599 7.6812 5.40318 10.3195 5.40318 13.6198C5.40318 13.6439 5.40332 13.6681 5.4036 13.6921L5.41178 14.399L4.71821 14.5359C3.11772 14.8517 1.91094 16.2644 1.91094 17.957C1.91094 19.8829 3.47218 21.4441 5.39808 21.4441H18.6013C20.5272 21.4441 22.0884 19.8829 22.0884 17.957C22.0884 16.0311 20.5272 14.4698 18.6013 14.4698H16.94V13.6198C16.94 10.3196 14.3372 7.6812 11.1716 7.6812ZM3.72398 13.0462C4.01031 9.1127 7.21713 5.9812 11.1716 5.9812C15.033 5.9812 18.1815 8.96704 18.5941 12.7698H18.6013C21.4661 12.7698 23.7884 15.0922 23.7884 17.957C23.7884 20.8217 21.4661 23.1441 18.6013 23.1441H5.39808C2.5333 23.1441 0.210938 20.8217 0.210938 17.957C0.210938 15.6774 1.6807 13.7426 3.72398 13.0462Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.730469 3.54156H3.34745C4.00718 3.54156 4.34779 4.32989 3.89564 4.81031L2.2188 6.59194H4.09477V7.59194H1.64667C0.986944 7.59194 0.646328 6.80362 1.09849 6.3232L2.77532 4.54156H0.730469V3.54156Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63477 0.148071H6.50938C7.16912 0.148071 7.50973 0.936396 7.05757 1.41681L6.1695 2.36039H7.3495V3.36039H5.59737C4.93764 3.36039 4.59702 2.57206 5.04918 2.09165L5.93725 1.14807H4.63477V0.148071Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62699 17.4892L9.58594 17.4752L10.1368 15.8669L10.1778 15.8809C11.1221 16.2044 12.1478 16.2002 13.0894 15.8691L13.6533 17.4729C12.3512 17.9307 10.9328 17.9365 9.62699 17.4892Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25785 17.4892L4.2168 17.4752L4.76764 15.8669L4.80869 15.8809C5.75297 16.2044 6.77867 16.2002 7.72029 15.8691L8.28415 17.4729C6.98203 17.9307 5.56364 17.9365 4.25785 17.4892Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SleepIcon;
