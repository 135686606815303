import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { ThemeContext } from 'styled-components';
import { SearchProps } from './Search.types';
import CloseIcon from '../icons/CloseIcon';
import { ClearButton, SearchIconStyled, SearchInput, SearchInputContainer } from './Search.styles';

const Search = React.forwardRef<HTMLDivElement, SearchProps>(function Search(
  {
    inputRef = null,
    inputProps = {},
    clearButtonProps = {},
    disabled = false,
    fullWidth = false,
    defaultValue = '',
    placeholder = 'Search',
    dropdown = false,
    noFocus = false,
    autoFocus = false,
    renderClearButton,
    renderSubmitButton,
    onChange,
    onKeyDown,
    onClear,
    onSubmit,
    onFocus,
    onBlur,
    ...props
  },
  ref
) {
  const { color: colorConfigKey, ...theme } = useContext(ThemeContext);

  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const debounced = useRef(null);

  const renderActionButtons = value && !disabled;

  const changeHandler = useCallback(
    s => {
      if (debounced.current) debounced.current.cancel();

      debounced.current = debounce(search => {
        onChange(search);
      }, 500);

      debounced.current(s);
    },
    [onChange]
  );

  const handleFocus = e => {
    if (disabled || noFocus) return;
    setFocused(true);
    if (onFocus instanceof Function) onFocus(e);
  };

  const handleBlur = e => {
    if (disabled) return;
    setFocused(false);
    if (onBlur instanceof Function) onBlur(e);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    setValue(event.target.value);
    changeHandler(event.target.value);
  };

  const handleClear = () => {
    if (disabled) return;
    setValue('');
    if (onClear instanceof Function) onClear();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      if (onSubmit instanceof Function) onSubmit(event);
    }

    if (onKeyDown instanceof Function) {
      onKeyDown(event);
    }
  };

  const cConf = useMemo(() => theme[colorConfigKey].Search, [colorConfigKey, theme]);

  const renderCorrespondingClearButton = () => {
    if (renderClearButton instanceof Function) return renderClearButton();

    return (
      <ClearButton onClick={handleClear} as="button" dropdown={dropdown} {...clearButtonProps}>
        <CloseIcon size="extraSmall" />
      </ClearButton>
    );
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <SearchInputContainer
      fullWidth={fullWidth}
      disabled={disabled}
      focused={focused}
      cConf={cConf}
      ref={ref}
      dropdown={dropdown}
      noFocus={noFocus}
      {...props}
    >
      <SearchIconStyled
        size="small"
        color={disabled ? cConf.disabled.icon : cConf.normal.icon}
        style={{ pointerEvents: 'none' }}
      />
      <SearchInput
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        focused={focused}
        value={value || ''}
        placeholder={placeholder}
        cConf={cConf}
        ref={inputRef}
        dropdown={dropdown}
        noFocus={noFocus}
        autoFocus={autoFocus}
        {...inputProps}
      />
      {renderActionButtons && renderCorrespondingClearButton()}
      {renderActionButtons && renderSubmitButton instanceof Function && renderSubmitButton()}
    </SearchInputContainer>
  );
});

export default Search;
