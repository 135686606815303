import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ITEMS } from 'consts/localStorage';
import * as headerBannersActions from 'store/headerBanners/actions';
import useAction from 'hooks/useAction';
import useIsDashboardPath from 'hooks/useIsDashboardPath';
import { getExtInstalled } from 'store/extension/selectors';
import checkNextTime from 'functions/checkNextTime';
import { DAY_IN_MILLISECONDS } from 'consts/time';
import { showSearchTooltipSelector } from 'store/dashboardInterface/selectors';

const useHeaderBannersPorter = () => {
  const isExtensionInstalled = useSelector(getExtInstalled);
  const showSearchTooltip = useSelector(showSearchTooltipSelector);
  const pushBackDownloadExtension = useAction(headerBannersActions.pushBackDownloadExtensionBanner);
  const isDashboardPath = useIsDashboardPath();
  const isDownloadExtensionBannerShownRef = useRef(false);

  useEffect(() => {
    const canShowBanner = checkNextTime(ITEMS.isDownloadExtensionBannerShown, DAY_IN_MILLISECONDS);

    if (
      !showSearchTooltip &&
      isExtensionInstalled === false &&
      canShowBanner &&
      isDashboardPath &&
      !isDownloadExtensionBannerShownRef.current
    ) {
      isDownloadExtensionBannerShownRef.current = true;
      pushBackDownloadExtension();
    }
  }, [isExtensionInstalled, isDashboardPath, isDownloadExtensionBannerShownRef, pushBackDownloadExtension]);
};

export default useHeaderBannersPorter;
