import * as ALOOMA from 'consts/alooma';
import Modal from 'components/templates/Modal/Modal';
import ModalBodyResetPasswordNew from 'components/molecules/ModalBodyResetPasswordNew/ModalBodyResetPasswordNew';

const ModalResetPasswordNew = props => {
  const { closeModal } = props;

  return (
    <Modal onClose={closeModal} source={ALOOMA.SOURCE_PAGES.MODAL_RESET_PASSWORD}>
      <ModalBodyResetPasswordNew {...props} />
    </Modal>
  );
};

export default ModalResetPasswordNew;
