import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as notificationsActions from '../actions';
import * as notificationsApi from '../api';

function* deleteNotifications() {
  try {
    yield call(notificationsApi.deleteNotifications);
    yield put(notificationsActions.successDeleteNotifications());
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default deleteNotifications;
