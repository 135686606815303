import { Component } from 'react';
import CConsole from 'functions/CConsole';

export class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    CConsole.error('ErrorBoundaryComponent:', error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) return null;

    return children;
  }
}

const ErrorBoundary = WrapperComponent => props =>
  (
    <ErrorBoundaryComponent>
      <WrapperComponent {...props} />
    </ErrorBoundaryComponent>
  );

export default ErrorBoundary;
