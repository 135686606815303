const BROWSERS = {
  INTERNET_EXPLORER: 'ie',
  OLD_EDGE: 'oldEdge',
  EDGE: 'edge',
  FIREFOX: 'firefox',
  OPERA: 'opera',
  CHROME: 'chrome',
  SAFARI: 'safari',
};

export default BROWSERS;
