import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SleepyIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <ellipse cx="9.7998" cy="16.25" rx="1.5" ry="1.5" fill="currentColor" />
      <g clipPath="url(#clip0_8702_182888)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.04721 10.8654L6 10.8492L6.63336 9L6.68057 9.01617C7.7663 9.38803 8.94566 9.38325 10.0283 9.0026L10.6767 10.8466C9.17949 11.373 7.54861 11.3796 6.04721 10.8654Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9995 1.05637C14.7521 0.600384 13.4049 0.351562 11.9996 0.351562C5.56549 0.351562 0.349609 5.56745 0.349609 12.0016C0.349609 18.4357 5.56549 23.6516 11.9996 23.6516C18.4337 23.6516 23.6496 18.4357 23.6496 12.0016C23.6496 11.3191 23.5909 10.6503 23.4783 10H21.7482C21.8803 10.6466 21.9496 11.316 21.9496 12.0016C21.9496 17.4968 17.4948 21.9516 11.9996 21.9516C6.50438 21.9516 2.04961 17.4968 2.04961 12.0016C2.04961 6.50633 6.50438 2.05156 11.9996 2.05156C13.4224 2.05156 14.7755 2.3502 15.9995 2.88821V1.05637Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3705 10.8654L13.3232 10.8492L13.9566 9L14.0038 9.01617C15.0895 9.38803 16.2689 9.38325 17.3516 9.0026L17.9999 10.8466C16.5027 11.373 14.8719 11.3796 13.3705 10.8654Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9395 2.75H18V1.25H21.4597C22.5078 1.25 23.0905 2.46242 22.4358 3.28087L20.0605 6.25H23V7.75H19.5403C18.4922 7.75 17.9095 6.53758 18.5642 5.71913L20.9395 2.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8702_182888">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default SleepyIcon;
