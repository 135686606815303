import { useRef, useState } from 'react';
import { AndroidShareIcon } from '@karma/components/icons';
import Popover from '@karma/components/Popover/Popover';
import useOnClickOutside from '@karma/components/utils/useOnClickOutside';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import ShareProductComponent from 'components/organisms/ShareProductComponent/ShareProductComponent';
import {
  ToggleButtonWrapper,
  SavedIconButtonContainer,
  PopoverWrapper,
  PopoverBodyWrapper,
} from './ShareTagButton.style';

const ShareTagButton = ({ tag }) => {
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const closeToggleModal = () => setShowModal(false);

  const handleActiveButton = () => {
    setShowModal(prevValue => !prevValue);
  };

  useOnClickOutside(containerRef, closeToggleModal, { capture: true });

  return (
    <ToggleButtonWrapper ref={containerRef}>
      <SavedIconButtonContainer {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.SHARE_PRODUCT}>
        <AndroidShareIcon size="medium" onClick={handleActiveButton} />
      </SavedIconButtonContainer>
      <PopoverWrapper>
        <Popover
          isOpened={showModal}
          anchorElement={containerRef.current}
          WrapperComponent={PopoverBodyWrapper}
          onExited={() => setShowModal(false)}
        >
          <ShareProductComponent tag={tag} />
        </Popover>
      </PopoverWrapper>
    </ToggleButtonWrapper>
  );
};

export default ShareTagButton;
