import styled, { keyframes, css } from 'styled-components';
import { getColor } from '@karma/components/colors';

const leftPosition = css`
  transform: translateX(calc(-100% - 40px));
`;

const rightPosition = css`
  transform: translateX(0);
`;

const moveFromLeftToRight = keyframes`
  0% { 
    ${leftPosition}
  }

  100% { 
    ${rightPosition}
  }
`;

const moveFromRightToLeft = keyframes`
  0% {
    ${rightPosition}
  }

  100% { 
    ${leftPosition}
  }
`;

export const AlertContainer = styled.div`
  width: fit-content;
  min-width: 400px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 16px 12px 16px 30px;
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  color: ${({ color }) => getColor(color)};
  border-radius: 10px;
  box-shadow: 0px 7px 17px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  border-color: ${({ containerBorderColor }) => getColor(containerBorderColor)};

  ${({ shouldShowReverseAnimation }) =>
    shouldShowReverseAnimation
      ? css`
          animation: ${moveFromRightToLeft} ease-in-out 0.2s;
          animation-fill-mode: forwards;
        `
      : css`
          animation: ${moveFromLeftToRight} ease-in-out 0.2s;
          animation-fill-mode: both;
        `}
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
`;
