import { oldApiInstance } from 'functions/request';

export const fetchTag = ({ tag_id, product_name, brand, retailer } = {}) =>
  oldApiInstance.get('/shop/tag', {
    params: {
      tag_id,
      name: product_name,
      brand,
      retailer,
    },
  });

export const fetchCoupons = ({ rule_id } = {}) => oldApiInstance.get(`/shop/${rule_id}/shop_offers`);

export const fetchYouMintAlsoLike = ({ id } = {}) => oldApiInstance.get(`/shop/tags/${id}/you_might_also_like`);

export const fetchSimilar = ({ id } = {}) => oldApiInstance.get(`/shop/tags/${id}/similar_tags`);
