import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SelectArrowIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7402 8.30422L22.5404 7.09987L12.6607 16.9423L2.7408 7.04112L1.53986 8.24433L12.6596 19.3431L23.7402 8.30422Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SelectArrowIcon;
