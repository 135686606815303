import styled, { css } from 'styled-components';
import { StyledPopperProps } from './Popper.types';

export const StyledPopper = styled.div<StyledPopperProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: ${({ variant }) => (variant === 'small' ? '14px' : '16px')};
  width: ${({ variant }) => (variant === 'small' ? '112px' : '292px')};
  z-index: ${({ zIndex }) => zIndex};

  ${({ theme }) => {
    const { text, background, boxShadow } = theme[theme.color].Popper;

    return css`
      background: ${background};
      color: ${text};
      box-shadow: ${boxShadow};
    `;
  }};

  &[data-popper-placement^='top'] > #arrow {
    bottom: -5px;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -5px;
  }

  &[data-popper-placement^='left'] > #arrow {
    right: -5px;
  }

  &[data-popper-placement^='right'] > #arrow {
    left: -5px;
  }
`;

export const PopperArrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;

  ${({ theme }) => {
    const { background } = theme[theme.color].Popper;

    return css`
      background: ${background};
    `;
  }};
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  line-height: 0;
`;
