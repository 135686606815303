import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favoriteRetailers: [],
  ids: [],
  isLoaded: false,
  wasInitialUpdate: false,
  hasError: false,
};

export const favoriteRetailersSlice = createSlice({
  name: 'favoriteRetailers',
  initialState,
  reducers: {
    failureCreateFavoriteRetailers: state => {
      return {
        ...state,
        hasError: true,
      };
    },
    initialUpdateCompleted: state => {
      return {
        ...state,
        wasInitialUpdate: true,
      };
    },
    successCreateFavoriteRetailers: (state, action) => {
      return {
        ...state,
        ids: [...state.ids, ...action.payload],
        hasError: false,
      };
    },
    successDeleteFavoriteRetailers: (state, action) => {
      return {
        ...state,
        favoriteRetailers: state.favoriteRetailers.filter(retailer => !action.payload.includes(retailer.retailer_id)),
        ids: state.ids.filter(id => !action.payload.includes(id)),
        hasError: false,
      };
    },
    successFetchFavoriteRetailers: (state, action) => {
      return {
        favoriteRetailers: action.payload,
        ids: action.payload.map(retailer => retailer.retailer_id),
        isLoaded: true,
        hasError: false,
      };
    },
  },
});

export default favoriteRetailersSlice.reducer;
