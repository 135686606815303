import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CreditCardCancelledIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 61 60"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.0625 39.4341C57.0625 45.5416 52.1114 50.4927 46.0039 50.4927L3.93556 50.4927L3.93556 20.566C3.93556 14.4585 8.88667 9.5074 14.9942 9.5074L46.0039 9.5074C52.1114 9.5074 57.0625 14.4585 57.0625 20.566L57.0625 39.4341ZM46.0039 46.6165C49.9706 46.6165 53.1863 43.4008 53.1863 39.4341L53.1863 20.566C53.1863 16.5993 49.9706 13.3836 46.0039 13.3836L14.9942 13.3836C11.0274 13.3836 7.81178 16.5993 7.81178 20.566L7.81178 46.6165L46.0039 46.6165Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4394 27.8337H6.69922V23.9575H54.4394V27.8337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2824 50.5562L0.5 13.3817L2.71756 9.44385L60.5 46.6184L58.2824 50.5562Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CreditCardCancelledIcon;
