import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyListCreate from 'components/molecules/ModalBodyListCreate/ModalBodyListCreate';
import Modal from 'components/templates/Modal/Modal';

const ModalListCreate = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.MODAL_LIST_CREATE} onClose={closeModal}>
      <ModalBodyListCreate {...props} />
    </Modal>
  );
};

ModalListCreate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalListCreate;
