import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const PayPalIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'primary2',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6439 5.64844H17.4137C21.048 5.64844 22.4166 7.48823 22.2053 10.1956C21.8568 14.6589 19.1583 17.1268 15.5791 17.1268H13.772C13.2815 17.1268 12.9514 17.4511 12.8179 18.3323L12.0427 23.4489C11.9923 23.7812 11.8175 23.9764 11.5549 24H7.30995C6.91053 24 6.76843 23.6944 6.87298 23.0326L9.46524 6.61939C9.5691 5.96163 9.92894 5.64844 10.6439 5.64844Z"
        fill={getColor(secondaryColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0427 23.4489L12.8179 18.3323C12.9514 17.4511 13.2815 17.1268 13.772 17.1268H15.5791C19.1583 17.1268 21.8568 14.6589 22.2053 10.1956C22.4166 7.48823 21.048 5.64844 17.4137 5.64844H10.6439C9.92894 5.64844 9.5691 5.96163 9.46524 6.61939L6.87298 23.0326C6.76843 23.6944 6.91053 24 7.30995 24H11.5549C11.8175 23.9764 11.9923 23.7812 12.0427 23.4489ZM11.0992 23L11.8292 18.1825C11.8292 18.1825 11.8292 18.1825 11.8292 18.1825C11.9076 17.6651 12.0631 17.1518 12.3952 16.7561C12.771 16.3084 13.2731 16.1268 13.772 16.1268H15.5791C17.1291 16.1268 18.4342 15.5979 19.3938 14.6306C20.3593 13.6575 21.0485 12.1649 21.2083 10.1178C21.3004 8.93782 21.0371 8.12481 20.5318 7.60097C20.0199 7.07017 19.0794 6.64844 17.4137 6.64844H10.6439C10.5625 6.64844 10.51 6.65504 10.4788 6.66129C10.471 6.68513 10.4614 6.72195 10.453 6.77535L7.89052 23H11.0992ZM7.84138 23.4392C7.84136 23.4392 7.84122 23.4383 7.84104 23.4365L7.84138 23.4392ZM11.0527 23.3059C11.0525 23.3071 11.0524 23.3076 11.0524 23.3076L11.0527 23.3059Z"
        fill={getColor(secondaryColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41098 0H13.1875C15.0962 0 17.3598 0.06196 18.8741 1.3975C19.8857 2.28967 20.4165 3.7111 20.294 5.23706C19.8784 10.4116 16.7832 13.3098 12.6326 13.3098H9.29046C8.72161 13.3098 8.34454 13.6867 8.1841 14.7076L7.25146 20.6405C7.19098 21.0255 7.02411 21.2523 6.71951 21.2796H2.54511C2.0819 21.2796 1.91721 20.9253 2.03842 20.1578L5.04434 1.12586C5.1648 0.36316 5.58198 0 6.41098 0Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PayPalIcon;
