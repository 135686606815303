import { useTranslation } from 'react-i18next';
import { Button } from '@karma/components';
import { FacebookIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';

const FacebookShareButton = ({ url, wideButton, description }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const linkUrl = encodeURIComponent(url);
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${linkUrl}&quote=${
    t(description) || ''
  }&amp;src=sdkpreparse`;

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: facebookShareLink,
    'data-alooma-element': ALOOMA.ELEMENTS.FACEBOOK,
    ...contextDataAttrs,
  };

  return wideButton ? (
    <Button as="a" Icon={FacebookIcon} size="small" fullWidth {...linkProps}>
      {t('app:shareOnFacebook')}
    </Button>
  ) : (
    <a {...linkProps}>
      <FacebookIcon role="button" size="medium" />
    </a>
  );
};

export default FacebookShareButton;
