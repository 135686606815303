import React from 'react';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { LoaderWrapper } from './Loader.styles';
import { LoaderProps } from './Loader.types';
import useStaticFile from '../utils/useStaticFile';

const Loader: React.FC<LoaderProps> = ({ width, height, absolute = false, ...rest }) => {
  const path = useStaticFile('/lottie/loader.json');

  return (
    <LoaderWrapper absolute={absolute} {...rest}>
      <LottieAnimation width={width} height={height} path={path} />
    </LoaderWrapper>
  );
};

export default React.memo(Loader, () => true);
