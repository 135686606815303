import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as userSelectors from 'store/user/selectors';
import { RetailersDBContext } from 'contexts/RetailersDBContext';
import { SESSION_STORAGE_ITEMS } from 'consts/sessionStorage';

const useUpdateRetailersDB = () => {
  const retailersDB = useContext(RetailersDBContext);
  const userLoaded = useSelector(userSelectors.getLoaded);
  const userCountryCode = useSelector(userSelectors.getUserCountryCode);

  useEffect(() => {
    const cachedUserCountryCode = sessionStorage.getItem(SESSION_STORAGE_ITEMS.userCountryCode);

    if (retailersDB.isConfigured && userLoaded && cachedUserCountryCode && cachedUserCountryCode !== userCountryCode) {
      sessionStorage.setItem(SESSION_STORAGE_ITEMS.userCountryCode, userCountryCode);
      retailersDB.resetRetailersTable();
    }
  }, [retailersDB, userCountryCode, userLoaded]);
};

export default useUpdateRetailersDB;
