import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CDN_URL } from '@karma/utils/constants/env';
import { Stack, Typography } from '@karma/components';
import Swiper from 'react-id-swiper';
import { getCookieValue } from 'functions/cookies';
import { COOKIES, UTM_TERMS } from 'consts/cookies';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useAction from 'hooks/useAction';
import { setShowSearchTooltip } from 'store/dashboardInterface/actions';
import { deleteQueryParams } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import {
  Container,
  Slide,
  SlideContent,
  SlideImage,
  SlidePagination,
  SlidePaginationItem,
  StyledButton,
} from './ModalBodyOnboarding.style';

const SWIPER_SETTINGS = {
  spaceBetween: 30,
  updateOnWindowResize: false,
  shouldSwiperUpdate: true,
  watchOverflow: true,
  observer: true,
  noSwipingSelector: '.swiper-container',
  slidesPerView: 1,
};

const useSlides = () => {
  const utmTerm = getCookieValue(COOKIES.utmTerm);

  return useMemo(() => {
    const COUPONS = {
      id: 1,
      name: 'coupons',
      title: 'app:couponsHandled',
      description: 'app:skipCouponHunt',
      image: `${CDN_URL}/images/dashboard/onboarding_coupons.png`,
    };

    const ALERTS = {
      id: 2,
      name: 'alerts',
      title: 'app:smartAlerts',
      description: 'app:saveItemsGetAlerted',
      image: `${CDN_URL}/images/dashboard/onboarding_alerts.png`,
    };

    const CASHBACK = {
      id: 3,
      name: 'cashback',
      title: 'app:cashbackMadeSimple',
      description: 'app:earnMoneyBack',
      image: `${CDN_URL}/images/dashboard/onboarding_cashback.png`,
    };

    if (utmTerm === UTM_TERMS.COUPONS) {
      return [COUPONS, ALERTS, CASHBACK];
    }

    if (utmTerm === UTM_TERMS.ALERTS) {
      return [ALERTS, COUPONS, CASHBACK];
    }

    if (utmTerm === UTM_TERMS.CASHBACK) {
      return [CASHBACK, COUPONS, ALERTS];
    }

    return [COUPONS, ALERTS, CASHBACK];
  }, [utmTerm]);
};

const ModalBodyOnboarding = ({ closeModal }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const slides = useSlides();
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const setShowSearchTooltipFunc = useAction(setShowSearchTooltip);

  const nextHandler = () => {
    if (currentSlide === slides.length - 1) {
      closeModal();

      return;
    }

    setCurrentSlide(currentSlide + 1);
    swiperRef.current.swiper.slideTo(currentSlide + 1);
  };

  useEffect(() => {
    return () => {
      deleteQueryParams(QUERY_PARAMS.onboardingModal);
      setShowSearchTooltipFunc(true);
    };
  }, []);

  return (
    <Container>
      <Swiper {...SWIPER_SETTINGS} ref={swiperRef}>
        {slides.map(slide => (
          <Slide key={slide.id}>
            <SlideContent>
              <div>
                <Typography variant="h2" bMargin={8} rMargin={-20}>
                  {t(slide.title)}
                </Typography>
                <Typography variant="paragraphLarge" color="neutral7">
                  {t(slide.description)}
                </Typography>
              </div>
              <Stack fullWidth justifyContent="space-between">
                <SlidePagination>
                  {slides.map((s, index) => (
                    <SlidePaginationItem key={s.id} $active={index === currentSlide} />
                  ))}
                </SlidePagination>

                <StyledButton onClick={nextHandler} {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.NEXT}>
                  {t('app:next')}
                </StyledButton>
              </Stack>
            </SlideContent>
            <SlideImage src={slide.image} alt={slide.name} />
          </Slide>
        ))}
      </Swiper>
    </Container>
  );
};

export default ModalBodyOnboarding;
