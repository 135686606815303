import { put, select } from 'redux-saga/effects';
import { getMainGroup } from 'store/myItems/store/selectors';
import { reset, toggleIncludedTags } from '../actions';
import { getMultiEditEntity } from '../selectors';

function* selectAll() {
  const { tags } = yield select(getMainGroup);
  const currentMultiEdit = yield select(getMultiEditEntity);

  if (tags.length === currentMultiEdit.included.length) {
    yield put(reset());
  } else {
    const selectedTags = tags.map(tag => tag.id);

    yield put(toggleIncludedTags({ items: selectedTags }));
  }
}

export default selectAll;
