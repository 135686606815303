import { getColor } from '@karma/components/colors';
import styled, { css } from 'styled-components';

export const SavedIconButtonContainer = styled.button`
  all: unset;
  cursor: pointer;

  ${({ $roundButton }) =>
    $roundButton &&
    css`
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50%;
      transition: 0.2s;

      &:hover {
        background-color: ${getColor('secondary1')};
      }
    `}
`;
