import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Stack } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import InputFormField from 'components/molecules/InputFormField/InputFormField';
import ForgotPasswordSent from 'components/molecules/ForgotPasswordSent/ForgotPasswordSent';
import useGetForgotPasswordFormProps from 'hooks/useGetForgotPasswordFormProps';
import { StyledForm } from './ForgotPasswordFormV3.style';

const ForgotPasswordFormV3 = ({ instructionsSent, setInstructionsSent }) => {
  const { t } = useTranslation();

  const contextDataAttrs = Alooma.useContextDataAttrs();

  const [emailSentTo, setEmailSentTo] = useState('');

  const { control, onSubmit, fetching } = useGetForgotPasswordFormProps(setInstructionsSent, setEmailSentTo);

  return (
    <StyledForm onSubmit={onSubmit}>
      {instructionsSent ? (
        <ForgotPasswordSent email={emailSentTo} retryForgot={() => setInstructionsSent(false)} />
      ) : (
        <>
          <InputFormField
            id="email"
            name="email"
            label={t('app:email')}
            placeholder={t('app:enterEmailAddress')}
            replaceCharacters={/\s/g}
            type="email"
            fullWidth
            disabled={fetching}
            loading={fetching}
            control={control}
          />

          <Stack direction="column" fullWidth spacing={20} style={{ marginTop: 32 }}>
            <Button
              size="medium"
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.RESET_PASSWORD}
              fullWidth
              loading={fetching}
            >
              {t('app:resetPassword')}
            </Button>
          </Stack>
        </>
      )}
    </StyledForm>
  );
};

ForgotPasswordFormV3.propTypes = {
  instructionsSent: PropTypes.bool.isRequired,
  setInstructionsSent: PropTypes.bool.isRequired,
};

export default ForgotPasswordFormV3;
