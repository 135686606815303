import React from 'react';
import { StyledTabs } from './Tabs.styles';
import { TabsProps } from './Tabs.types';
import { TabProps } from '../Tab';

const Tabs: React.FC<TabsProps> = ({ children, value, onChange, ...props }) => {
  const childrenArray = React.useMemo(
    () => React.Children.toArray(children).filter(React.isValidElement),
    [children]
  );

  const handleClick = child => (_e: React.MouseEvent<HTMLButtonElement>) => {
    const newValue = child.props.value;

    onChange(newValue);
    if (child.props?.onClick) child.props.onClick(_e);
  };

  return (
    <StyledTabs {...props}>
      {childrenArray.map((child: React.ReactElement<TabProps>) =>
        React.cloneElement(
          child,
          { active: value === child.props?.value, onClick: handleClick(child), isTabChild: true },
          null
        )
      )}
    </StyledTabs>
  );
};

export default Tabs;
