import { Component } from 'react';
import ErrorModal from 'components/molecules/ErrorModal/ErrorModal';
import { getQueryParam } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';

function isChunkErrorCheck(error) {
  return (
    typeof error?.name === 'string' &&
    (error.name.includes('ChunkLoadError') || /loading chunk [\d\w]+ failed/i.test(error.name))
  );
}

class ErrorHandlerWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    const isChunkError = isChunkErrorCheck(error);

    if (!isChunkError || (isChunkError && getQueryParam(QUERY_PARAMS.loadError) === 'true')) {
      return { hasError: true };
    }

    return { hasError: false };
  }

  componentDidCatch(error) {
    if (window.Rollbar) window.Rollbar.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    return <ErrorModal />;
  }
}

export default ErrorHandlerWrapper;
