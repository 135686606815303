import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as extensionActions from 'store/extension/actions';
import * as extensionSelectors from 'store/extension/selectors';
import CConsole from 'functions/CConsole';
import useAction from './useAction';

const INSTALLED_MESSAGE = 'shoptagr:installed';
const RECEIVE_MESSAGE_DELAY = 5000;
const CHECK_ELEM_ID_INTERVAL = 100;

export default () => {
  const timerRef = useRef();
  const intervalRef = useRef();
  const isExtensionInstalled = useSelector(extensionSelectors.getExtInstalled);
  const setExtensionInstalled = useAction(extensionActions.setExtInstalled);
  const setExtensionNotInstalled = useAction(extensionActions.setNotExtInstalled);

  const [isExtensionInstallationChecked, setIsExtensionInstallationChecked] = useState(!!isExtensionInstalled);

  const successHandler = useCallback(() => {
    setExtensionInstalled();
    clearTimeout(timerRef.current);
    clearInterval(intervalRef.current);
  }, [setExtensionInstalled]);

  const failureHandler = useCallback(() => {
    setIsExtensionInstallationChecked(true);
    if (isExtensionInstalled === null) setExtensionNotInstalled();
    clearTimeout(timerRef.current);
    clearInterval(intervalRef.current);
  }, [isExtensionInstalled]);

  useEffect(() => {
    const messageHandler = event => {
      if (event.data === INSTALLED_MESSAGE) {
        CConsole.log('[useExtensionDetection.js] desk extension is installed: message was received');
        successHandler();
        window.removeEventListener('message', messageHandler);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [successHandler]);

  // Sets an interval to check elem in DOM from the extension
  useEffect(() => {
    if (!isExtensionInstallationChecked) {
      intervalRef.current = setInterval(() => {
        const elem = document.querySelector('#krm_desktop_extension');

        if (elem) {
          CConsole.log('[useExtensionDetection.js] desk extension is installed: elem was found in DOM');
          successHandler();
        }
      }, CHECK_ELEM_ID_INTERVAL);
    }

    return () => clearInterval(intervalRef.current);
  }, [isExtensionInstallationChecked]);

  // Sets a time limit to receive a message from the extension
  useEffect(() => {
    if (!isExtensionInstallationChecked) {
      timerRef.current = setTimeout(() => {
        CConsole.log('[useExtensionDetection.js] desk extension is not installed: timeout was finished');
        failureHandler();
      }, RECEIVE_MESSAGE_DELAY);
    }

    return () => clearTimeout(timerRef.current);
  }, [isExtensionInstallationChecked]);
};
