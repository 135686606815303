import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as shopActions from '../actions';
import * as shopApi from '../api';

function* fetchTags({ payload: { retailers, brands, sort_by, search, min_price, page } }) {
  try {
    yield put(shopActions.setTags({ loading: true }));
    const { data } = yield call(shopApi.fetchTags, {
      retailers,
      brands,
      sort_by,
      search,
      min_price,
      page,
    });

    yield put(shopActions.receiveTags({ ...data, page: page || 1 }));
  } catch (error) {
    yield put(handleServerError(error));
  } finally {
    yield put(shopActions.setTags({ loading: false }));
  }
}

export default fetchTags;
