import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const EditIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1643 2.35416C17.7512 1.82481 18.6552 1.86873 19.1873 2.45245L23.57 7.25957C24.1062 7.84767 24.0614 8.75994 23.4701 9.29328L9.09629 22.2591L2.07879 22.2796L2.78415 15.3257L17.1643 2.35416ZM18.1076 3.7921L4.40936 16.1485L3.96046 20.5741L8.44133 20.561L22.1346 8.20911L18.1076 3.7921Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4876 13.5422L12.3048 7.88176L13.5589 6.73347L18.7417 12.3939L17.4876 13.5422Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EditIcon;
