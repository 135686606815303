import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField, Typography, Button, ListItem, Checkbox } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import useAction from 'hooks/useAction';
import { addList } from 'store/lists/actions';
import { getMyLists } from 'store/lists/selectors';
import TagStack from 'components/organisms/TagStack/TagStack';
import { Container, ListWrapper } from './AddToList.style';

const AddToList = ({ headTag, multiple, size, onSubmit }) => {
  const { t } = useTranslation();
  const myLists = useSelector(getMyLists);
  const [value, setValue] = useState('');
  const addListFunc = useAction(addList);

  const [selectedLists, setSelectedLists] = useState(multiple ? [] : headTag.lists.map(({ id }) => id));

  const enterHandler = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const name = value.trim();

        if (name) {
          addListFunc({ name });
          setValue('');
        }
      }
    },
    [addListFunc, value],
  );

  const selectHandler = useCallback(listId => {
    setSelectedLists(oldSelectedLists =>
      oldSelectedLists.includes(listId) ? oldSelectedLists.filter(id => id !== listId) : [...oldSelectedLists, listId],
    );
  }, []);

  const submitHandler = () => {
    onSubmit(selectedLists);
  };

  const contextDataAttrs = Alooma.useContextDataAttrs();

  const lists = useMemo(
    () =>
      myLists.map(({ id, name }) => (
        <ListItem
          key={id}
          data-id={id}
          onClick={() => selectHandler(id)}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.LIST}
          data-alooma-list-id={id}
        >
          <Checkbox tabIndex={-1} name={name} value={selectedLists.includes(id)} />
          {name}
        </ListItem>
      )),
    [contextDataAttrs, myLists, selectHandler, selectedLists],
  );

  return (
    <Container>
      <TagStack headTag={headTag} multiple={size > 1} />
      <Typography variant="h3">
        {size > 1 ? t('app:addNumberItemsToList', { value: size }) : t('app:addOneItemToList')}
      </Typography>
      <TextField
        onKeyPress={enterHandler}
        placeholder={t('app:createListAndPressEnter')}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.INPUT}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        fullWidth
      />
      <ListWrapper>{lists}</ListWrapper>
      <Button
        size="medium"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.ADD_TO_LIST}
        onClick={submitHandler}
      >
        {t('app:addToList')}
      </Button>
    </Container>
  );
};

AddToList.propTypes = {
  headTag: PropTypes.shape({ lists: PropTypes.arrayOf(PropTypes.shape()), main_img_url: PropTypes.string }).isRequired,
  multiple: PropTypes.bool,
  size: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

AddToList.defaultProps = {
  multiple: false,
  size: 0,
};

export default AddToList;
