import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckmarkIcon } from '@karma/components/icons';
import { Button, Stack, Typography } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import withIosBugFixCaret from 'hocs/withIosBugFixCaret';

const ModalBodyWithdrawCongrats = ({ closeModal, iosBugHideHandler, iosBugShowHandler }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const close = () => {
    iosBugHideHandler();
    closeModal();
  };

  useEffect(() => {
    iosBugShowHandler();
  }, [iosBugShowHandler]);

  return (
    <Stack direction="column" spacing={32}>
      <CheckmarkIcon width={56} height={56} />
      <Stack direction="column" spacing={20}>
        <Typography variant="h3">{t('app:congratulations')}</Typography>
        <Button {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.OK} size="medium" onClick={close} fullWidth>
          {t('app:ok')}
        </Button>
        <Typography variant="paragraphMedium" color="neutral2" rMargin={30} lMargin={30}>
          {t('app:canAccessEarningsInPayPal')}
        </Typography>
      </Stack>
    </Stack>
  );
};

ModalBodyWithdrawCongrats.propTypes = {
  closeModal: PropTypes.func.isRequired,
  iosBugHideHandler: PropTypes.func.isRequired,
  iosBugShowHandler: PropTypes.func.isRequired,
};

export default withIosBugFixCaret()(ModalBodyWithdrawCongrats);
