import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BankIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.59258 19.3216V6.01562H2.89258V21.0216H21.1079V6.01562H19.4079V19.3216H4.59258Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8594 21.0215H1.14062V19.3215H22.8594V21.0215Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8594 6.86559H1.14062V5.16559H22.8594V6.86559Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8594 23.5531H1.14062V21.8531H22.8594V23.5531Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7434 19.4685L14.7434 6.01562L16.4434 6.01562L16.4434 19.4685L14.7434 19.4685ZM7.55586 19.4685L7.55586 6.01562L9.25586 6.01562L9.25586 19.4685L7.55586 19.4685Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 0.208374L13.8233 1.66717C16.654 3.93179 20.1713 5.16556 23.7964 5.16556V6.86556C19.7852 6.86556 15.8935 5.50041 12.7613 2.99465L11.9998 2.38544L11.2383 2.99465C8.10605 5.50041 4.2143 6.86556 0.203125 6.86556V5.16556C3.82828 5.16556 7.3455 3.93179 10.1763 1.66717L11.9998 0.208374Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BankIcon;
