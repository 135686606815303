import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Typography, Checkbox, ListItem, Button, TextField } from '@karma/components';
import { PlusIcon } from '@karma/components/icons';
import { getMyLists } from 'store/lists/selectors';
import useAction from 'hooks/useAction';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { updateTagRequest } from 'store/myItems/store/actions';
import { addList } from 'store/lists/actions';
import { FAVORITES_LIST_OPTION, FULFILLED_LIST_OPTION } from 'consts/lists';
import { getCurrentTagLists, getTagLists } from 'functions/listsFunctions';
import { Container, TitleWrapper, ListWrapper, RoundButton, Wrapper } from './ListModal.styles';

const ListModal = ({ closeModal, tag }) => {
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const { t } = useTranslation();
  const myLists = useSelector(getMyLists);
  const updateTagRequestFunc = useAction(updateTagRequest);
  const addListFunc = useAction(addList);

  const [selectedLists, setSelectedLists] = useState(getCurrentTagLists(tag));
  const [isShowCreateList, setIsShowCreateList] = useState(false);
  const [nameList, setNameList] = useState('');

  const selectHandler = useCallback(listId => {
    setSelectedLists(oldSelectedLists =>
      oldSelectedLists.includes(listId) ? oldSelectedLists.filter(id => id !== listId) : [...oldSelectedLists, listId],
    );
  }, []);

  const submitHandler = () => {
    const { id } = tag;
    const currentLists = getCurrentTagLists(tag);

    if (!isEqual(currentLists, selectedLists)) {
      const selectedListsIds = selectedLists.filter(
        listId => listId !== FAVORITES_LIST_OPTION.id && listId !== FULFILLED_LIST_OPTION.id,
      );

      const favorites = selectedLists.includes(FAVORITES_LIST_OPTION.id);
      const fulfilled = selectedLists.includes(FULFILLED_LIST_OPTION.id);

      updateTagRequestFunc({ id, data: { list_ids: selectedListsIds, favorites, fulfilled } });
    }
    closeModal();
  };

  const lists = useMemo(
    () =>
      getTagLists(myLists).map(({ id, name, enableTranslation }) => (
        <ListItem
          key={id}
          onClick={() => selectHandler(id)}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.LIST}
          data-alooma-list-id={id}
        >
          <Checkbox onChange={() => null} tabIndex={-1} name={name} value={selectedLists.includes(id)} />
          {enableTranslation ? t(name) : name}
        </ListItem>
      )),
    [t, contextDataAttrs, myLists, selectHandler, selectedLists],
  );

  const createNewList = () => {
    setIsShowCreateList(true);
  };

  const submitCreateList = () => {
    const name = nameList.trim();

    if (name) {
      addListFunc({ name });
      closeModal();
    }
  };

  const enterHandler = e => {
    if (e.key === 'Enter') {
      submitCreateList();
    }
  };

  return (
    <Container>
      {isShowCreateList ? (
        <Wrapper>
          <Typography variant="h4" align="left">
            {t('app:createANewList')}
          </Typography>
          <TextField
            value={nameList}
            onChange={e => setNameList(e.target.value)}
            placeholder={t('app:createListAndPressEnter')}
            onKeyPress={enterHandler}
            fullWidth
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.INPUT}
          />
          <Button
            size="small"
            onClick={submitCreateList}
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.SAVE}
            fullWidth
          >
            {t('app:save')}
          </Button>
        </Wrapper>
      ) : (
        <>
          <TitleWrapper>
            <Typography variant="h4">{t('app:addToList')}</Typography>
            <RoundButton
              onClick={createNewList}
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.OPEN_CREATE}
            >
              <PlusIcon size="small" />
            </RoundButton>
          </TitleWrapper>
          <ListWrapper>{lists}</ListWrapper>
          <Wrapper>
            <Button
              size="small"
              onClick={submitHandler}
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.UPDATE_LIST}
              fullWidth
            >
              {t('app:updateList')}
            </Button>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

export default ListModal;
