import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ArrowNoCircleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5879 13.4443L0.587891 13.4443L0.587891 11.7443L24.5879 11.7443L24.5879 13.4443Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8387 2.99286C15.8387 7.82535 19.7562 11.7429 24.5887 11.7429V13.4429C18.8173 13.4429 14.1387 8.76423 14.1387 2.99286H15.8387Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.584 13.4432C19.7515 13.4432 15.834 17.3607 15.834 22.1932L14.134 22.1932C14.134 16.4218 18.8126 11.7432 24.584 11.7432L24.584 13.4432Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ArrowNoCircleIcon;
