import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const AddIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6383 2.19286C7.14305 2.19286 2.68828 6.64763 2.68828 12.1429C2.68828 17.6381 7.14305 22.0929 12.6383 22.0929C18.1335 22.0929 22.5883 17.6381 22.5883 12.1429C22.5883 6.64763 18.1335 2.19286 12.6383 2.19286ZM0.988281 12.1429C0.988281 5.70874 6.20416 0.492859 12.6383 0.492859C19.0724 0.492859 24.2883 5.70874 24.2883 12.1429C24.2883 18.577 19.0724 23.7929 12.6383 23.7929C6.20416 23.7929 0.988281 18.577 0.988281 12.1429Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4891 6.49265V17.7926H11.7891V6.49265H13.4891Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98828 11.2925H18.2883V12.9925H6.98828V11.2925Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AddIcon;
