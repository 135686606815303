function login({ provider, autoClose = false, redirectTo = null }) {
  const close = autoClose ? 'close=1&' : '';
  let url = `/oauth/${provider}/authorize?${close}`;

  url += redirectTo ? `&redirectTo=${encodeURIComponent(redirectTo)}` : '';

  window.location.href = url;
}

const OAuth2 = {
  login,
};

export default OAuth2;
