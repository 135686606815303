import { oldApiInstance } from 'functions/request';
import { isClient } from 'functions/isClient';
import CConsole from 'functions/CConsole';
import { SESSION_STORAGE_ITEMS } from 'consts/sessionStorage';

const getUserCountry = async () => {
  if (isClient() && sessionStorage.getItem(SESSION_STORAGE_ITEMS.userCountryCode)) {
    return sessionStorage.getItem(SESSION_STORAGE_ITEMS.userCountryCode);
  }

  const response = await oldApiInstance.get('/health_check').catch(CConsole.error);
  const countryCode = response?.data?.country_code || '';

  sessionStorage.setItem(SESSION_STORAGE_ITEMS.userCountryCode, countryCode);

  return countryCode;
};

export default getUserCountry;
