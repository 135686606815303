import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ThumbsUpIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 17 16"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M5.06241 15.234V7.2764M1.44531 8.72324V13.7872C1.44531 14.5862 2.09308 15.234 2.89215 15.234H12.605C13.6761 15.234 14.5871 14.4525 14.75 13.3938L15.529 8.32982C15.7313 7.01517 14.7141 5.82956 13.384 5.82956H10.8498C10.4502 5.82956 10.1263 5.50568 10.1263 5.10614V2.54946C10.1263 1.56428 9.3277 0.765625 8.34251 0.765625C8.10753 0.765625 7.89458 0.904012 7.79915 1.11874L5.25335 6.84679C5.13724 7.10804 4.87817 7.2764 4.59228 7.2764H2.89215C2.09308 7.2764 1.44531 7.92417 1.44531 8.72324Z"
        stroke="currentColor"
        strokeWidth="1.11563"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ThumbsUpIcon;
