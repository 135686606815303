import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: {
    entities: {},
    ids: [],
  },
  loaded: false,
};

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    receiveNormalizedLists: (state, { payload: { entities, ids } }) => ({
      ...state,
      lists: {
        entities,
        ids,
      },
      loaded: true,
    }),
    addNewList: (state, { payload: list }) => ({
      ...state,
      lists: {
        entities: {
          ...state.lists.entities,
          [list.id]: list,
        },
        ids: [list.id, ...state.lists.ids],
      },
    }),
    setUpdatedList: (state, { payload: list }) => ({
      ...state,
      lists: {
        ...state.lists,
        entities: {
          ...state.lists.entities,
          [list.id]: list,
        },
      },
    }),
    deleteList: (state, { payload: id }) => {
      const newIds = state.lists.ids.filter(idFromState => idFromState !== id);

      return {
        ...state,
        lists: {
          ...state.lists,
          ids: newIds,
        },
      };
    },
  },
});

export default listsSlice.reducer;
