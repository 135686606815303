import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BookmarkIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 22 22"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M3.28906 2.17871C3.28906 1.62643 3.73678 1.17871 4.28906 1.17871H17.8724C18.4247 1.17871 18.8724 1.62643 18.8724 2.17871V19.512L11.0807 14.4704L3.28906 19.512V2.17871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83594 3.61155C1.83594 1.61694 3.44006 0 5.41885 0H16.5864C18.5651 0 20.1693 1.61694 20.1693 3.61155V20.1062C20.1693 20.9099 19.2691 21.3853 18.6053 20.932L11.2295 15.8951L3.37794 20.959C2.7125 21.3881 1.83594 20.9104 1.83594 20.1186V3.61155ZM5.41885 1.59471C4.31381 1.59471 3.418 2.49768 3.418 3.61155V19.0399L11.2543 13.9859L18.5872 18.9934V3.61155C18.5872 2.49768 17.6914 1.59471 16.5864 1.59471H5.41885Z"
        fill={getColor(secondaryColor)}
      />
    </BaseIcon>
  );
};

export default BookmarkIconFilled;
