import getUserAgent from 'functions/getUserAgent';
import BROWSERS from 'consts/browsers';

const getBrowser = () => {
  const userAgent = getUserAgent();

  if (userAgent) {
    if (/(?:msie |trident.+?; rv:)(\d+)/i.test(userAgent)) return BROWSERS.INTERNET_EXPLORER;
    if (/(edge|edgios|edga)\/((\d+)?[\w.]+)/i.test(userAgent)) return BROWSERS.OLD_EDGE;
    if (/(edg)\/((\d+)?[\w.]+)/i.test(userAgent)) return BROWSERS.EDGE;
    if (/(?:firefox|fxios)\/(\d+)/i.test(userAgent)) return BROWSERS.FIREFOX;
    if (/(?:^opera.+?version|opr)\/(\d+)/i.test(userAgent)) return BROWSERS.OPERA;
    if (/(?:chrome|crios)\/(\d+)/i.test(userAgent)) return BROWSERS.CHROME;
    if (/version\/(\d+).+?safari/i.test(userAgent)) return BROWSERS.SAFARI;
  }

  return null;
};

export default getBrowser;
