import getLanguage from 'functions/getLanguage';
import getRecaptchaToken from 'functions/getRecaptchaToken';
import { getReferralLinkParams } from 'functions/redirectLink';
import { oldApiInstance } from 'functions/request';
import Alooma from 'services/alooma';
import CConsole from 'functions/CConsole';
import { authenticateUser } from './authentication';
import guestSignUpHandler from './guestSignUpHandler';

const createGuestUser = async rollbarContext => {
  let callResponse;
  let userData;

  try {
    userData = getReferralLinkParams();
    const recaptchaToken = await getRecaptchaToken('SIGNUP');
    const response = await oldApiInstance.post('/guest_sign_up', {
      ...userData,
      'g-recaptcha-response': recaptchaToken,
      language: getLanguage(),
    });

    callResponse = response;

    authenticateUser(response.data);
    await guestSignUpHandler(response.data);

    Alooma.UNSAFE_trackAloomaEvent('Started Signup Tutorial');
  } catch (error) {
    if (rollbarContext) {
      rollbarContext.error('guest_signup', error, `api response: ${callResponse}`, `userReferralData: ${userData}`);
    }
    CConsole.error(error);
  }
};

export default createGuestUser;
