import { MessengerIcon } from '@karma/components/icons';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import CsrChecker from 'hocs/CsrChecker';

const FbMessengerShareButton = ({ url }) => {
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const linkUrl = encodeURIComponent(url);
  const shareLink = `https://web.facebook.com/dialog/send?link=${linkUrl}&app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${window.location.href}&_rdc=1&_rdr`;
  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: shareLink,
    'data-alooma-element': ALOOMA.ELEMENTS.MESSENGER,
    ...contextDataAttrs,
  };

  return (
    <a {...linkProps}>
      <MessengerIcon size="medium" />
    </a>
  );
};

export default CsrChecker(FbMessengerShareButton);
