import styled from 'styled-components';
import { LabelSmallStyles } from '../Typography/Typography.styles';
import { HelperTextWrapperProps, HelperTextRootProps } from './HelperText.types';

export const HelperTextWrapper = styled.div<HelperTextWrapperProps>`
  display: block;
  overflow: hidden;
  height: auto;
  max-height: ${({ show, maxHeight }) => (show ? `${maxHeight}px` : '0px')};
  transition: max-height 0.2s ease-in-out;
`;

export const HelperTextRoot = styled.p<HelperTextRootProps>`
  ${LabelSmallStyles}

  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  margin: 0;
  padding: 4px 0px 0px;

  color: ${({ error, success, disabled, theme }) => {
    const colors = theme[theme.color].HelperText;

    if (disabled) return colors.disabled;
    if (error) return colors.error;
    if (success) return colors.success;

    return colors.normal;
  }};

  transition-property: transform, color;
  transition-duration: 0.15s;
  transition-delay: 0.05s;
  transition-timing-function: ease-in-out;
`;
