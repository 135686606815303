import axios from 'axios';
import { oldApiInstance, newApiInstance } from 'functions/request';
import { pushFrontUnderMaintenanceModal } from '../store/modals/actions';

const setServerErrorHandler = store => {
  const errorHandler = error => {
    if (error.response && [502, 503, 504].includes(error.response.status)) {
      store.dispatch(pushFrontUnderMaintenanceModal());
    }

    return Promise.reject(error);
  };

  oldApiInstance.interceptors.response.use(response => response, errorHandler);

  newApiInstance.interceptors.response.use(response => response, errorHandler);

  axios.interceptors.response.use(response => response, errorHandler);
};

export default setServerErrorHandler;
