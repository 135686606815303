import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const MehIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        id="base (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75107C6.33967 1.75107 1.75107 6.33967 1.75107 12C1.75107 17.6603 6.33967 22.2489 12 22.2489C17.6603 22.2489 22.2489 17.6603 22.2489 12C22.2489 6.33967 17.6603 1.75107 12 1.75107ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="currentColor"
      />
      <path
        id="Ellipse 17"
        d="M9.52847 8.91072C9.52847 9.9347 8.69837 10.7648 7.67439 10.7648C6.65041 10.7648 5.82031 9.9347 5.82031 8.91072C5.82031 7.88674 6.65041 7.05664 7.67439 7.05664C8.69837 7.05664 9.52847 7.88674 9.52847 8.91072Z"
        fill="currentColor"
      />
      <path
        id="Ellipse 18"
        d="M18.1808 8.91072C18.1808 9.9347 17.3507 10.7648 16.3267 10.7648C15.3028 10.7648 14.4727 9.9347 14.4727 8.91072C14.4727 7.88674 15.3028 7.05664 16.3267 7.05664C17.3507 7.05664 18.1808 7.88674 18.1808 8.91072Z"
        fill="currentColor"
      />
      <path
        id="Line 9 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15039 15.1504H16.8504V16.8504H7.15039V15.1504Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MehIcon;
