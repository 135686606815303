import { isClient } from 'functions/isClient';
import { isMobile } from 'functions/isMobile';

const trackGoogleTagEvent = eventName =>
  new Promise(resolve => {
    if (isClient() && window.gtm && eventName) {
      const analyticsTimerId = setTimeout(resolve, 2000);

      window.gtm.push({
        event: eventName,
        eventCallback: () => {
          clearTimeout(analyticsTimerId);
          resolve();
        },
      });
    } else {
      resolve();
    }
  });

export function trackInstallMobileApp() {
  if (isClient() && window.gtm && isMobile()) {
    window.gtm.push({ event: 'install_mobile_app' });
  }
}

export function trackUserSignUpOnboarding(email) {
  if (isClient() && window.gtm) {
    window.gtm.push({ event: 'onboarding_sign_up', userEmail: email });
  }
}

export default trackGoogleTagEvent;
