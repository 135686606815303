import styled from 'styled-components';
import { Loader, Stack } from '@karma/components';
import { MAX_TABLET_WIDTH } from 'consts/common';

export const StyledLoader = styled(Loader)`
  position: unset;
  transform: translate(0, 0);
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectsContainer = styled(Stack)`
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    flex-wrap: wrap;
  }
`;
