import { Provider } from 'react-redux';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { isIOS } from 'functions/isIOS';
import isTablet from 'functions/isTablet';
import ErrorHandlerWrapper from 'components/molecules/ErrorHandlerWrapper/ErrorHandlerWrapper';
import { LandingPageWithRoute } from 'components/pages/LandingPage/LandingPage';
import { MAX_TABLET_WIDTH } from 'consts/common';
import { PATHS } from 'consts/paths';
import setServerErrorHandler from 'functions/setServerErrorHandler';
import deleteCaches from 'functions/deleteCaches';
import useThemeConfig from 'hooks/useThemeConfig';
import App from './App';
import createStore from './store/createStore';

const preloadedState = window.__STATE__;
const store = createStore(preloadedState);

setServerErrorHandler(store);
deleteCaches(store);

delete window.__STATE__;

if (window.innerWidth <= MAX_TABLET_WIDTH) document.body.classList.add(isIOS() ? '--ios' : '--android');
if (isTablet()) document.body.classList.add('--tablet');
if (navigator.appVersion.includes('Mac')) document.body.classList.add('--mac-os');

const ClientApp = () => {
  const themeConfig = useThemeConfig();

  if (
    matchPath(window.location.pathname, PATHS.LANDING_PAGES) ||
    matchPath(window.location.pathname, PATHS.LANDING_PAGES_2)
  ) {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <LandingPageWithRoute />
        </BrowserRouter>
      </Provider>
    );
  }

  return (
    <ThemeProvider theme={themeConfig}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorHandlerWrapper>
            <App />
          </ErrorHandlerWrapper>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default ClientApp;
