/* eslint-disable camelcase */
import { WEBSITE_PATH } from '../constants/env';

const getReferralLinkParam = (isCoupon, isCouponDeal) => {
  if (isCoupon) return '&coupon=true';
  if (isCouponDeal) return '&coupon_deal=true';

  return '';
};

export const getRedirectLinkToShop = ({ ruleId, notification_id, ruleName, source, ...data }) => {
  const userId = data.userId ? `user=${data.userId}&` : '';
  const couponEventId = data.couponEventId ? `coupon_event_id=${data.couponEventId}&` : '';
  const cashback = data.cashback ? 'cashback=1&' : '';
  const notificationId = notification_id ? `notification_id=${notification_id}&` : '';
  const shop = encodeURIComponent(ruleName);

  return `${WEBSITE_PATH}/redirect?${userId}${couponEventId}${cashback}${notificationId}rule=${ruleId}&shop=${shop}&source=${source}`;
};

export const getRedirectLinkToRetailer = ({ ruleName, source, isCoupon, isCouponDeal, ...data }) => {
  const userId = data.userId ? `user=${data.userId}&` : '';
  const cashback = data.cashback ? 'cashback=1&' : '';
  const retailerId = data.retailerId ? `retailer_id=${data.retailerId}&` : '';
  const referralLinkParam = getReferralLinkParam(isCoupon, isCouponDeal);
  const shop = encodeURIComponent(ruleName);

  return `${WEBSITE_PATH}/redirect?${userId}${cashback}${retailerId}&shop=${shop}&source=${source}${referralLinkParam}`;
};

export const getRedirectLinkToTag = ({
  tagId,
  userId,
  ruleName,
  source,
  referrerId,
  singleShare,
  notification_id,
  originalUrl,
  ...data
}) => {
  const user = userId ? `user=${userId}&` : '';
  const referrer = referrerId ? `referrer_id=${referrerId}&` : '';
  const single_share = singleShare ? `single_share=true&` : '';
  const cashback = data.cashback ? 'cashback=1&' : '';
  const notificationId = notification_id ? `notification_id=${notification_id}&` : '';
  const originalLink = originalUrl ? `original_url=${encodeURIComponent(originalUrl)}&` : '';
  const shop = encodeURIComponent(ruleName);

  return `${WEBSITE_PATH}/redirect?${user}${referrer}${single_share}${cashback}${notificationId}${originalLink}tag=${tagId}&shop=${shop}&source=${source}`;
};

export const getRedirectLinkToPackage = ({
  packageId,
  userId,
  ruleName = '',
  isCoupon,
  isCouponDeal,
  source,
  ...data
}) => {
  const user = userId ? `user=${userId}&` : '';
  const cashback = data.cashback ? 'cashback=1&' : '';
  const referralLinkParam = getReferralLinkParam(isCoupon, isCouponDeal);
  const shop = encodeURIComponent(ruleName);

  return `${WEBSITE_PATH}/redirect?${user}${cashback}package=${packageId}&shop=${shop}&source=${source}${referralLinkParam}`;
};

export const getRedirectLinkToUnsupportedStore = ({ userId, query, ruleName = '', ...data }) => {
  const user = userId ? `user=${userId}&` : '';
  const shopName = ruleName ? `shop=${encodeURIComponent(ruleName)}&` : '';
  const source = data.source ? `source=${data.source}&` : '';
  const ruleId = data.ruleId ? `rule=${data.ruleId}&` : '';
  const cashback = data.cashback ? 'cashback=1&' : '';

  return `${WEBSITE_PATH}/redirect?${user}${shopName}${source}${ruleId}${cashback}query=${encodeURIComponent(query)}`;
};

export const getRedirectLinkToNetwork = ({ userId, networkId, linkedUrl, domain, source }) => {
  const user = userId ? `user=${userId}&` : '';
  const encodedLinkedUrl = encodeURIComponent(linkedUrl);
  const shop = encodeURIComponent(domain);

  return `${WEBSITE_PATH}/redirect?${user}network_id=${networkId}&linked_url=${encodedLinkedUrl}&shop=${shop}&source=${source}`;
};

/* eslint-enable camelcase */
