import React from 'react';
import useId from '../utils/useId';
import { Slider, SwitchLabel, SwitchRoot, ToggleSwitchInput, SwitchWrapper } from './Switch.styles';
import { SwitchProps } from './Switch.types';

const Switch: React.FC<SwitchProps> = ({
  disabled,
  id: idOverride,
  name,
  onChange,
  value,
  tabIndex: tabIndexProp,
  switchProps,
  label,
  ...props
}) => {
  const id = useId(idOverride);
  const labelId = label && `${id}-label`;
  const tabIndex = tabIndexProp || 0;
  const checked = !!value;

  return (
    <SwitchRoot disabled={disabled} {...props}>
      <SwitchWrapper tabIndex={tabIndex}>
        <ToggleSwitchInput
          {...switchProps}
          id={id}
          name={name}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
          aria-describedby={labelId}
        />
        <Slider disabled={disabled} checked={checked} />
      </SwitchWrapper>

      {label && (
        <SwitchLabel id={labelId} disabled={disabled} checked={checked}>
          {label}
        </SwitchLabel>
      )}
    </SwitchRoot>
  );
};

export default Switch;
