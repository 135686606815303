import { createSlice } from '@reduxjs/toolkit';
import {
  LAST_SAVED,
  HIGH_TO_LOW,
  LOW_TO_HIGH,
  ON_SALE,
  BACK_IN_STOCK,
  LOW_IN_STOCK,
  FAVORITES,
  FULFILLED,
  MUTUALLY_EXCLUSIVE_SORTS,
  MUTUALLY_EXCLUSIVE_FILTERS,
  MUTUALLY_EXCLUSIVE_LISTS,
} from 'consts/staticFilterKeys';

const initialState = {
  lists: [],
  staticFilter: {
    [LAST_SAVED]: true,
    [HIGH_TO_LOW]: false,
    [LOW_TO_HIGH]: false,
    [ON_SALE]: false,
    [BACK_IN_STOCK]: false,
    [LOW_IN_STOCK]: false,
    [FAVORITES]: false,
    [FULFILLED]: false,
  },
};

const staticSortReset = MUTUALLY_EXCLUSIVE_SORTS.reduce((result, filter) => ({ ...result, [filter]: false }), {});
const staticFilterReset = MUTUALLY_EXCLUSIVE_FILTERS.reduce((result, filter) => ({ ...result, [filter]: false }), {});
const staticListReset = MUTUALLY_EXCLUSIVE_LISTS.reduce((result, filter) => ({ ...result, [filter]: false }), {});

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    changeFilter: (state, { payload: { filterKey, filterValue, toggleMode = false, withReset = false } }) => {
      // don't use param toggleMode for update staticFilter, retailers, brands

      if (filterKey === 'lists') {
        return withReset
          ? {
              ...initialState,
              lists: toggleMode && state.lists.includes(filterValue) ? [] : [filterValue],
              staticFilter: {
                ...initialState.staticFilter,
              },
            }
          : {
              ...state,
              lists: toggleMode && state.lists.includes(filterValue) ? [] : [filterValue],
              staticFilter: {
                ...state.staticFilter,
                [FAVORITES]: false,
                [FULFILLED]: false,
              },
            };
      }

      if (Object.keys(state.staticFilter).includes(filterKey)) {
        return withReset
          ? {
              ...initialState,
              staticFilter: {
                ...initialState.staticFilter,
                [filterKey]: filterValue,
              },
            }
          : {
              ...state,
              lists: MUTUALLY_EXCLUSIVE_LISTS.includes(filterKey) ? [] : state.lists,
              staticFilter: {
                ...state.staticFilter,
                ...(MUTUALLY_EXCLUSIVE_SORTS.includes(filterKey) ? staticSortReset : {}),
                ...(MUTUALLY_EXCLUSIVE_FILTERS.includes(filterKey) ? staticFilterReset : {}),
                ...(MUTUALLY_EXCLUSIVE_LISTS.includes(filterKey) ? staticListReset : {}),
                [filterKey]: filterValue,
              },
            };
      }

      const currentValue = state[filterKey].includes(filterValue)
        ? state[filterKey].filter(el => el !== filterValue)
        : [...state[filterKey], filterValue];

      return {
        ...state,
        [filterKey]: currentValue,
      };
    },
    clear: (state, { payload: { filter } }) => ({
      ...state,
      [filter]: [],
    }),
    clearList: state => ({
      ...state,
      lists: [],
      staticFilter: {
        ...state.staticFilter,
        [FAVORITES]: false,
        [FULFILLED]: false,
      },
    }),
  },
});

export default filtersSlice.reducer;
