import moment from 'moment';
import isString from 'lodash/isString';
import i18n from 'services/i18n';
import resources from 'locales';
import { CODE_TO_I18N_LANGUAGE, DEFAULT_LANGUAGE, NUMBER_LOCALES } from 'consts/locales';

const setUpUserLanguage = language => {
  const locale = CODE_TO_I18N_LANGUAGE[language];

  if (i18n.language !== locale && resources[locale]) {
    i18n.changeLanguage(locale);
  }
  if (localStorage.getItem('language') !== locale) {
    localStorage.setItem('language', locale);
  }
};

export const localizeNumber = (number, isFraction) => {
  const locale = NUMBER_LOCALES[i18n.language] || DEFAULT_LANGUAGE;
  const value = Number(number).toLocaleString(locale);
  const zeroStart = isFraction && isString(number) && number[0] === '0' && Number(0).toLocaleString(locale);

  return zeroStart ? `${zeroStart}${value}` : value;
};

export const localizeNumberWithoutCommas = number => {
  const localizedNumber = localizeNumber(number);

  return localizedNumber.replace(/,/g, '').replace(/\./g, '').replace(/\s/g, '');
};

export const getMonthOptions = (format = 'MMMM') => {
  const now = moment().locale(i18n.language);

  return Array.from(new Array(12), (_, index) => ({
    label: now.set('month', index).format(format),
    value: `${index + 1}`.padStart(2, '0'),
  }));
};

export const getYearsOptions = (format = 'YYYY') => {
  const now = moment().locale(i18n.language);
  const years = Array.from(new Array(100), (year, index) => index + (new Date().getFullYear() - 100)).reverse();

  return years.map(year => ({
    label: now.set('year', year).format(format),
    value: year,
  }));
};

export const getDaysOptions = (year = 2021, month = 1, format = 'D') => {
  const now = moment()
    .locale(i18n.language)
    .set('year', year)
    .set('month', +month - 1);
  const dayInMonth = now.daysInMonth() || 31;

  return Array.from(new Array(dayInMonth), (_, index) => ({
    label: now.set('date', index + 1).format(format),
    value: `${index + 1}`.padStart(2, '0'),
  }));
};

/**
 * Convert moment#fromNow string into short equivalent
 * @param {string} date - moment#fromNow string (e.g. "15 days ago")
 * @returns {string} - short equivalent of moment#fromNow string (e.g. "15d")
 */
export const shortFromNowDateFormatter = date => {
  const c1 = date.charAt(0);
  const c2 = date.charAt(1);

  if (date.includes('a few seconds ago')) return '20s';
  if (date.includes('a minute ago')) return '1m';
  if (date.includes('minutes ago')) return `${c1 + (c2 !== ' ' ? c2 : '')}m`;
  if (date.includes('an hour')) return '1h';
  if (date.includes('hours ago')) return `${c1 + (c2 !== ' ' ? c2 : '')}h`;
  if (date.includes('a day ago')) return '1d';
  if (date.includes('days ago')) return `${c1 + (c2 !== ' ' ? c2 : '')}d`;
  if (date.includes('a month ago')) return '1M';
  if (date.includes('months ago')) return `${c1 + (c2 !== ' ' ? c2 : '')}M`;
  if (date.includes('a year ago')) return '1y';
  if (date.includes('years ago')) return `${c1 + (c2 !== ' ' ? c2 : '')}y`;

  return '';
};

export default setUpUserLanguage;
