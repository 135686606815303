import { createAction } from '@reduxjs/toolkit';
import { notificationsSlice } from './reducer';

export const requestCreateNotificationRemindIn = createAction('notifications/requestCreateNotificationRemindIn');

export const requestDeleteNotification = createAction('notifications/requestDeleteNotification');

export const requestDeleteNotifications = createAction('notifications/requestDeleteNotifications');

export const requestFetchUnseenCount = createAction('notifications/requestFetchUnseenCount');

export const requestFetchSortByDateNotifications = createAction('notifications/requestFetchSortByDateNotifications');

export const requestUpdateMarkAsSeen = createAction('notifications/requestUpdateMarkAsSeen');

export const {
  successCreateNotificationRemindIn,
  successDeleteNotification,
  successDeleteNotifications,
  successFetchUnseenCount,
  successUpdateMarkAsSeen,
  successFetchSortByDateNotifications,
  successMuteNotification,
  setShowNotificationsMenu,
  toggleShowNotificationsMenu,
} = notificationsSlice.actions;
