import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParser as parseQueryString } from 'functions/paramsSerializer';

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => parseQueryString(search), [search]);
};

export default useQueryParams;
