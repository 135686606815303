import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  requestCreateNotificationRemindIn,
  requestDeleteNotification,
  requestDeleteNotifications,
  requestFetchUnseenCount,
  requestUpdateMarkAsSeen,
  requestFetchSortByDateNotifications,
} from '../actions';
import createNotificationRemindIn from './worker.createNotificationRemindIn';
import deleteNotification from './worker.deleteNotification';
import deleteNotifications from './worker.deleteNotifications';
import fetchUnseenCount from './worker.fetchUnseenCount';
import updateMarkAsSeen from './worker.updateMarkAsSeen';
import fetchSortByDateNotifications from './worker.fetchSortByDateNotifications';

function* notificationsSaga() {
  yield all([
    takeEvery([requestCreateNotificationRemindIn.type], createNotificationRemindIn),
    takeEvery([requestDeleteNotification.type], deleteNotification),
    takeEvery([requestDeleteNotifications.type], deleteNotifications),
    takeLatest([requestFetchUnseenCount.type], fetchUnseenCount),
    takeLatest([requestUpdateMarkAsSeen.type], updateMarkAsSeen),
    takeLatest([requestFetchSortByDateNotifications.type], fetchSortByDateNotifications),
  ]);
}

export default notificationsSaga;
