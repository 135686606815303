import React from 'react';
import { IKarmaLogoProps } from './KarmaLogo.types';
import BaseIcon from '../BaseIcon';
import { getColor } from '../colors';

const KarmaLogo: React.FC<IKarmaLogoProps> = ({
  color = 'currentColor',
  variant,
  width,
  ...props
}) => {
  if (variant === 'symbol') {
    return (
      <BaseIcon
        width={width || 40}
        height={undefined}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={getColor(color)}
        {...props}
      >
        <path
          d="M0 34.7992V1.59998C0 0.639745 0.639577 0 1.59956 0H8.79757C9.75755 0 10.3971 0.639745 10.3971 1.59998V22.1596L31.111 1.52016C33.1107 -0.399687 36.3896 -0.55993 38.4692 1.52016C40.6285 3.68007 40.3885 6.95983 38.4692 8.88029L25.5125 21.8397C27.3521 26.5598 32.3901 29.9998 38.309 29.9998H38.3888C39.3487 29.9998 39.9883 30.6396 39.9883 31.5998V38.4C39.9883 39.3596 39.3487 40 38.3888 40H37.4294C28.8718 40 21.5136 35.9202 17.4349 29.6806L9.27694 38.3196C8.23717 39.4395 6.87761 39.9994 5.19825 39.9994C2.15934 39.9994 0 37.8395 0 34.7992Z"
          fill="currentColor"
        />
      </BaseIcon>
    );
  }

  return (
    <BaseIcon
      width={width || 150}
      height={undefined}
      viewBox="0 0 150 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M0 30.1795V1.90339C0 1.16572 0.491259 0.674462 1.22892 0.674462H6.7614C7.49907 0.674462 7.99033 1.16572 7.99033 1.90339V15.795L18.0712 1.41058C18.3781 0.919317 18.8709 0.672913 19.3621 0.672913H26.0631C26.8008 0.672913 27.354 1.59499 26.8008 2.33266L15.7358 17.7616C17.0267 21.5119 20.9614 24.0318 25.7563 24.0318H25.8182C26.5559 24.0318 27.0472 24.523 27.0472 25.2607V30.1779C27.0472 30.9156 26.5559 31.4069 25.8182 31.4069H25.0806C17.2731 31.4069 11.3734 27.6566 7.99188 21.264V30.1779C7.99188 30.9156 7.50062 31.4069 6.76295 31.4069H1.23047C0.492809 31.41 0 30.9172 0 30.1795Z"
        fill="currentColor"
      />
      <path
        d="M48.5705 30.1791V28.7658C46.6644 30.8563 43.7757 32.0233 40.0254 32.0233C33.4484 32.0233 29.5137 27.9041 29.5137 22.1872C29.5137 16.8392 33.4484 12.9664 39.841 12.9664H47.3401C48.0777 12.9664 48.569 12.4752 48.569 11.7375C48.569 9.21768 46.4784 7.43396 43.6517 7.43396C41.1319 7.43396 39.4721 8.66288 38.6725 10.7534C38.4881 11.2447 38.0573 11.5531 37.3816 11.5531H31.9715C31.1114 11.5531 30.5582 10.9999 30.6806 10.1398C31.5407 4.60728 36.2131 0.0588684 43.6502 0.0588684C51.5801 0.0588684 56.5593 4.91567 56.5593 11.7375V30.1791C56.5593 30.9168 56.0681 31.408 55.3304 31.408H49.7979C49.0633 31.4096 48.5705 30.9168 48.5705 30.1791ZM41.9935 25.2619C46.3575 25.2619 48.5705 22.8025 48.5705 19.4225V18.8073H41.1319C38.9809 18.8073 37.5056 20.0982 37.5056 21.9424C37.5071 23.8485 38.9204 25.2619 41.9935 25.2619Z"
        fill="currentColor"
      />
      <path
        d="M61.1758 30.1797V12.8461C61.1758 5.40745 65.8482 0.674622 73.4697 0.674622H73.7765C74.5142 0.674622 75.0054 1.16588 75.0054 1.90355V7.12919C75.0054 7.86685 74.5142 8.35811 73.7765 8.35811C71.0723 8.35811 69.1661 10.1403 69.1661 13.4598V30.1797C69.1661 30.9173 68.6749 31.4086 67.9372 31.4086H62.4047C61.667 31.4101 61.1758 30.9173 61.1758 30.1797Z"
        fill="currentColor"
      />
      <path
        d="M77.1582 30.1791V12.2303C77.1582 4.7917 81.8306 0.0588684 89.4521 0.0588684C93.2628 0.0588684 96.2755 1.16536 98.366 3.25593C100.457 1.16536 103.468 0.0588684 107.28 0.0588684C114.903 0.0588684 119.574 4.7917 119.574 12.2303V30.1791C119.574 30.9168 119.083 31.408 118.345 31.408H112.812C112.075 31.408 111.584 30.9168 111.584 30.1791V12.8456C111.584 9.52608 109.677 7.7439 106.973 7.7439C104.269 7.7439 102.363 9.52608 102.363 12.8456V30.1807C102.363 30.9183 101.871 31.4096 101.134 31.4096H95.6013C94.8637 31.4096 94.3724 30.9183 94.3724 30.1807V12.8456C94.3724 9.52608 92.4663 7.7439 89.762 7.7439C87.0578 7.7439 85.1516 9.52608 85.1516 12.8456V30.1807C85.1516 30.9183 84.6604 31.4096 83.9227 31.4096H78.3902C77.6495 31.4096 77.1582 30.9168 77.1582 30.1791Z"
        fill="currentColor"
      />
      <path
        d="M142.008 30.1791V28.7658C140.102 30.8563 137.213 32.0233 133.463 32.0233C126.886 32.0233 122.951 27.9041 122.951 22.1872C122.951 16.8392 126.886 12.9664 133.278 12.9664H140.778C141.515 12.9664 142.006 12.4752 142.006 11.7375C142.006 9.21768 139.916 7.43396 137.089 7.43396C134.569 7.43396 132.91 8.66288 132.11 10.7534C131.926 11.2447 131.495 11.5531 130.819 11.5531H125.412C124.552 11.5531 123.999 10.9999 124.121 10.1398C124.981 4.60728 129.654 0.0588684 137.091 0.0588684C145.021 0.0588684 150 4.91567 150 11.7375V30.1791C150 30.9168 149.509 31.408 148.771 31.408H143.238C142.501 31.4096 142.008 30.9168 142.008 30.1791ZM135.431 25.2619C139.795 25.2619 142.008 22.8025 142.008 19.4225V18.8073H134.569C132.418 18.8073 130.943 20.0982 130.943 21.9424C130.943 23.8485 132.358 25.2619 135.431 25.2619Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default KarmaLogo;
