import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ScaleIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3374 13.5117L21.6374 13.5149L21.6179 3.18642L11.2365 3.17662L11.2381 1.47662L23.3147 1.48802L23.3374 13.5117Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93995 10.7737L3.63994 10.7705L3.65943 21.099L14.0408 21.1088L14.0392 22.8088L1.96263 22.7974L1.93995 10.7737Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0536 2.92764L3.42149 22.5598L2.21941 21.3577L21.8516 1.72556L23.0536 2.92764Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ScaleIcon;
