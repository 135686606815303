import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import { showErrorAlert } from 'store/alerts/actions';
import { newApiInstance } from 'functions/request';
import useAction from 'hooks/useAction';
import Alooma from 'services/alooma';
import { setIsUpdatingTag } from 'store/myItems/store/actions';
import { StyledButton } from './AlertUndoButton.styled';

const AlertUndoButton = ({ handleCloseClick, tagId }) => {
  const [isClicked, setIsClicked] = useState(false);
  const showErrorAlertFunc = useAction(showErrorAlert);
  const setIsUpdatingTagFunc = useAction(setIsUpdatingTag);

  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const handleUndo = async () => {
    setIsClicked(true);
    try {
      await newApiInstance.post(`tags/${tagId}/restore`);
      setIsUpdatingTagFunc(true);
      handleCloseClick();
    } catch ({ response }) {
      showErrorAlertFunc({ message: response.data.error });
      setIsClicked(false);
    }
  };

  return (
    <StyledButton
      type="underlinedText"
      size="small"
      {...contextDataAttrs}
      onClick={handleUndo}
      data-alooma-element={ALOOMA.ELEMENTS.UNDO}
      disabled={isClicked}
    >
      {t('app:undo')}
    </StyledButton>
  );
};

AlertUndoButton.propTypes = {
  tagId: PropTypes.number.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
};

export default AlertUndoButton;
