import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { DEVICE_THEMES } from '../themes';

type UseOnClickOutsideConfig = {
  capture?: boolean;
  enabled?: boolean;
};

const defaultConfig = {
  capture: false,
  enabled: true,
};

/**
 * Detects clicks outside of a specified element and handles them
 *
 * @param {Ref} ref - Element for which we want to detect outside clicks
 * @param {Function} callback - Function to call on outside click
 * @param {Object} config - Config options
 * @param {Boolean} config.capture - The option which forces to dispatch events to the listener before the target object
 * @param {Boolean} config.enabled - The option which enables/disables event listening bindings
 */
const useOnClickOutside = (
  ref: React.Ref<any>,
  callback: (e: React.MouseEvent<Element, MouseEvent>) => void,
  config: UseOnClickOutsideConfig = {}
) => {
  const { device } = useContext(ThemeContext);

  useEffect(() => {
    const refElement = (ref as any)?.current ?? ref;
    const capture = typeof config.capture === 'boolean' ? config.capture : defaultConfig.capture;
    const enabled = typeof config.enabled === 'boolean' ? config.enabled : defaultConfig.enabled;
    const getExtRoot = () =>
      refElement && typeof refElement.closest === 'function'
        ? refElement.closest('.shoptagr-inject')
        : null;

    const elem = device === DEVICE_THEMES.EXTENSION ? getExtRoot() : document;

    const listener = e => {
      if (!refElement || refElement.contains(e.target)) {
        return;
      }

      callback(e);
    };

    if (enabled) {
      elem?.addEventListener('click', listener, { capture });
    }

    return () => elem?.removeEventListener('click', listener, { capture });
  }, [ref, callback, config, device]);
};

export default useOnClickOutside;
