import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const DesktopIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.650391 3.96994C0.650391 2.94821 1.47866 2.11993 2.50039 2.11993H21.6504C22.6721 2.11993 23.5004 2.94821 23.5004 3.96993V20.0301C23.5004 21.0518 22.6721 21.8801 21.6504 21.8801H2.50039C1.47866 21.8801 0.650391 21.0518 0.650391 20.0301V3.96994ZM2.50039 3.81993C2.41755 3.81993 2.35039 3.88709 2.35039 3.96994V20.0301C2.35039 20.1129 2.41755 20.1801 2.50039 20.1801H21.6504C21.7332 20.1801 21.8004 20.1129 21.8004 20.0301V3.96993C21.8004 3.88709 21.7332 3.81993 21.6504 3.81993H2.50039Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5816 10.17H2.18164V8.46997H22.5816V10.17Z"
        fill="currentColor"
      />
      <path
        d="M5.27961 6.20993C5.27961 6.67385 4.90353 7.04993 4.43961 7.04993C3.97569 7.04993 3.59961 6.67385 3.59961 6.20993C3.59961 5.74601 3.97569 5.36993 4.43961 5.36993C4.90353 5.36993 5.27961 5.74601 5.27961 6.20993Z"
        fill="currentColor"
      />
      <path
        d="M8.88117 6.20993C8.88117 6.67385 8.50509 7.04993 8.04117 7.04993C7.57725 7.04993 7.20117 6.67385 7.20117 6.20993C7.20117 5.74601 7.57725 5.36993 8.04117 5.36993C8.50509 5.36993 8.88117 5.74601 8.88117 6.20993Z"
        fill="currentColor"
      />
      <path
        d="M12.4808 6.20993C12.4808 6.67385 12.1047 7.04993 11.6408 7.04993C11.1769 7.04993 10.8008 6.67385 10.8008 6.20993C10.8008 5.74601 11.1769 5.36993 11.6408 5.36993C12.1047 5.36993 12.4808 5.74601 12.4808 6.20993Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DesktopIcon;
