import { all, takeEvery } from 'redux-saga/effects';
import { clear, changeFilter } from 'store/filters/actions';
import * as api from '../api';
import {
  deleteTag,
  toggleTag,
  requestTagsBulkAddToLists,
  requestTagsBulkDelete,
  requestTagsBulkFulfill,
  requestTagsBulkMute,
  selectAllMultiEdit,
} from '../actions';
import filtersChangesWorker from './worker.filtersChanges';
import tagsBulkUpdateWorker from './worker.tagsBulkUpdate';
import tagsChangesWorker from './worker.tagsChanges';
import selectAllWorker from './worker.selectAll';

function* multiEditSaga() {
  yield all([
    takeEvery([selectAllMultiEdit], selectAllWorker),
    takeEvery([clear, changeFilter], filtersChangesWorker),
    takeEvery([deleteTag, toggleTag], tagsChangesWorker),
    takeEvery([requestTagsBulkAddToLists], tagsBulkUpdateWorker, api.tagsBulkAddToLists),
    takeEvery([requestTagsBulkDelete], tagsBulkUpdateWorker, api.tagsBulkDelete),
    takeEvery([requestTagsBulkFulfill], tagsBulkUpdateWorker, api.tagsBulkFulfill),
    takeEvery([requestTagsBulkMute], tagsBulkUpdateWorker, api.tagsBulkMute),
  ]);
}

export default multiEditSaga;
