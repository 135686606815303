import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const InfoIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6391 2.49285C7.30951 2.49285 2.98906 6.8133 2.98906 12.1428C2.98906 17.4724 7.30951 21.7928 12.6391 21.7928C17.9686 21.7928 22.2891 17.4724 22.2891 12.1428C22.2891 6.8133 17.9686 2.49285 12.6391 2.49285ZM1.28906 12.1428C1.28906 5.87441 6.37063 0.792847 12.6391 0.792847C18.9075 0.792847 23.9891 5.87441 23.9891 12.1428C23.9891 18.4113 18.9075 23.4928 12.6391 23.4928C6.37063 23.4928 1.28906 18.4113 1.28906 12.1428Z"
        fill="currentColor"
      />
      <path
        d="M13.9043 8.68195L12.2043 8.68195L12.2043 6.98195L13.9043 6.98195L13.9043 8.68195Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75391 9.71811L13.932 9.71811L13.932 18.3631L12.232 18.3631L12.232 11.4181L9.75391 11.4181L9.75391 9.71811Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default InfoIcon;
