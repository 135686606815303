import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CartIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8179 12.9314L20.5433 5.78329L22.1958 6.18218L20.3134 13.9808C20.2212 14.3625 19.8797 14.6314 19.4871 14.6314H5.23438V12.9314H18.8179Z"
        fill="currentColor"
      />
      <path
        d="M8.19188 22.3865C8.19188 23.2776 7.46948 24 6.57836 24C5.68724 24 4.96484 23.2776 4.96484 22.3865C4.96484 21.4953 5.68724 20.7729 6.57836 20.7729C7.46948 20.7729 8.19188 21.4953 8.19188 22.3865Z"
        fill="currentColor"
      />
      <path
        d="M18.9497 22.3865C18.9497 23.2776 18.2273 24 17.3362 24C16.4451 24 15.7227 23.2776 15.7227 22.3865C15.7227 21.4953 16.4451 20.7729 17.3362 20.7729C18.2273 20.7729 18.9497 21.4953 18.9497 22.3865Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91807 3.19384C4.83704 2.50311 3.05354 2.50593 1.45082 3.00297L0.947266 1.37926C2.75085 0.819925 5.13561 0.676487 6.8334 1.7613C7.71814 2.32661 8.37434 3.20356 8.63529 4.41402C8.8905 5.59789 8.75805 7.04925 8.1927 8.79176L8.18622 8.81095L4.8185 18.4058L19.4856 18.4058V20.1058L3.61932 20.1058C3.34372 20.1058 3.08523 19.9722 2.92585 19.7474C2.76648 19.5225 2.72601 19.2344 2.81728 18.9743L6.57875 8.25764C7.08623 6.68967 7.1437 5.56195 6.97346 4.77227C6.80862 4.00759 6.42276 3.51631 5.91807 3.19384Z"
        fill="currentColor"
      />
      <path
        d="M11.0131 4.50892C10.1937 5.32831 10.1965 6.6615 11.0194 7.47736L12.5297 8.9749L14.4195 10.8958L17.7747 7.5111C18.5913 6.68729 18.5884 5.35454 17.7682 4.53432C16.9518 3.71794 15.6325 3.71643 14.8143 4.53094L14.39 4.95332L13.9559 4.51537C13.145 3.6974 11.8275 3.69451 11.0131 4.50892Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CartIcon;
