import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WinkIcon } from '@karma/components/icons';
import { Typography, Stack, Button } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { pushFrontCompleteSignUpModal } from 'store/modals/actions';
import { ITEMS } from 'consts/localStorage';
import useActionWithDelay from 'hooks/useActionWithDelay';

const ModalBodyCongratsGuest = ({ closeModal }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const pushFrontCompleteSignUpModalFunc = useActionWithDelay(pushFrontCompleteSignUpModal);

  const openOnboardingSignUpModal = () => {
    closeModal();
    pushFrontCompleteSignUpModalFunc();
  };

  useEffect(() => {
    localStorage.setItem(ITEMS.congratulateSignupModal, true);
  }, []);

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing="20">
      <WinkIcon width={54} height={54} />

      <div>
        <Typography align="center" variant="h3" color="primary1">
          {t('app:congrats')}
        </Typography>
        <Typography align="center" variant="h4" color="primary1" bMargin={8}>
          {t('app:savedFirstItem')}
        </Typography>
        <Typography align="center" variant="paragraphLarge" color="primary1">
          {t('app:neverMissProducts')}
        </Typography>
      </div>

      <Button
        size="medium"
        fullWidth
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.COMPLETE_SIGN_UP}
        onClick={openOnboardingSignUpModal}
      >
        {t('app:completeSignUp')}
      </Button>
    </Stack>
  );
};

ModalBodyCongratsGuest.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default ModalBodyCongratsGuest;
