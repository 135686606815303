import styled, { css } from 'styled-components';
import { getColor } from '@karma/components/colors';
import { COLOR_THEMES } from '@karma/components/themes';

const V_1_STYLES = css`
  .swiper-container {
    position: static;
    padding: 32px 0 60px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: ${({ isMultipleLinesSlider }) => (isMultipleLinesSlider ? '50%' : '45%')};
    color: ${props =>
      props.theme.color === COLOR_THEMES.DARK ? getColor('primary2') : getColor('primary1')} !important;
  }

  .swiper-button-prev {
    left: ${({ isMultipleLinesSlider }) => (isMultipleLinesSlider ? '-35px' : '-20px')};
  }

  .swiper-button-next {
    right: ${({ isMultipleLinesSlider }) => (isMultipleLinesSlider ? '-35px' : '-20px')};
  }
`;

const V_2_STYLES = css`
  width: 100%;
  position: relative;

  .swiper-container {
    position: static;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: ${props =>
      props.theme.color === COLOR_THEMES.DARK ? getColor('primary2') : getColor('primary1')} !important;
  }

  .swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next {
    right: -20px;
  }
`;

export const SliderContainer = styled.div`
  ${({ $v2 }) => ($v2 ? V_2_STYLES : V_1_STYLES)}

  .swiper-button-prev {
    display: ${({ showPrevButton }) => (showPrevButton ? 'block' : 'none')};
  }

  .swiper-button-next {
    display: ${({ showNextButton }) => (showNextButton ? 'block' : 'none')};
  }
`;

export const LeftShadow = styled.div`
  background: linear-gradient(
    270deg,
    ${({ theme }) => getColor(theme.color === COLOR_THEMES.DARK ? 'primary1' : 'neutral6')} 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const RightShadow = styled.div`
  background: linear-gradient(
    90deg,
    ${({ theme }) => getColor(theme.color === COLOR_THEMES.DARK ? 'primary1' : 'neutral6')} 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;
