import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getColor } from '@karma/components/colors';
import { Typography } from '@karma/components';

export const OriginalPrice = styled(Typography).attrs(props => ({
  ...props,
  variant: 'paragraphSmall',
  component: 'span',
  color: 'neutral2',
}))`
  text-decoration-line: line-through;
`;

export const StyledLazyLoadImage = styled(LazyLoadImage)`
  display: block;
  object-fit: cover;
  height: 144px;
  width: 116px;
  max-width: 116px;
  min-height: 144px;
  border-radius: 10px 10px 0 0;
`;

export const LinkSlide = styled.a`
  background: ${getColor('neutral4')};
  display: flex;
  flex-direction: column;
  width: 116px;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;
`;

export const CardBody = styled.div`
  padding: 26px 12px 12px;
  height: 100%;
`;

export const CardSomeItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

export const CardSomeItemWrap = styled.div`
  width: 50%;
`;

export const CardItemDescription = styled(Typography).attrs(props => ({
  ...props,
  variant: 'labelSmall',
  component: 'div',
  tMargin: 20,
}))`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CardItemBrand = styled(Typography).attrs(props => ({
  ...props,
  variant: 'labelSmallUppercase',
  color: 'neutral2',
  tMargin: 8,
}))`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
