import { lazy } from 'react';
import { isClient } from 'functions/isClient';

/**
 * @typedef {Function} ImportFunc - async function
 * @returns {Promise<Component>} - imported Component
 */

/**
 * @typedef {Object} RetryOptions
 * @property {Number} [retries=3] - retries count
 * @property {Number} [interval=100] - interval between retries
 * @property {Boolean} [exponentialBackoff=true] - exponential backoff
 */

/**
 * Retry importFunc
 *
 * @param {ImportFunc} importFunc
 * @param {RetryOptions} [retryOptions]
 *
 * @returns {Promise<Component>} - imported Component
 */
export function retry(importFunc, { retries = 3, interval = 100, exponentialBackoff = true } = {}) {
  return new Promise((resolve, reject) => {
    importFunc()
      .then(resolve)
      .catch(error => {
        if (retries === 1) {
          if (window.Rollbar) window.Rollbar.error('Module retry failed', { error, retries });
          reject(error);

          return;
        }

        setTimeout(() => {
          if (window.Rollbar) window.Rollbar.error('Module retry after error', { error, retries });
          retry(importFunc, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
            exponentialBackoff,
          }).then(resolve, reject);
        }, interval);
      });
  });
}

/**
 * "lazy" with "retry" for client side rendering
 *
 * @param {ImportFunc} importFunc
 * @param {RetryOptions} [retryOptions]
 *
 * @returns {Component} - imported Component
 */
export const csrLazyWithRetry = (importFunc, retryOptions) => {
  return lazy(() => retry(importFunc, retryOptions));
};

/**
 * "lazy" with "retry" for server side rendering
 *
 * @param {ImportFunc} importFunc
 * @param {RetryOptions} [retryOptions]
 *
 * @returns {Component} - imported Component
 */
export const ssrLazyWithRetry = (importFunc, retryOptions) => {
  if (!isClient()) {
    return lazy(importFunc);
  }

  return csrLazyWithRetry(importFunc, retryOptions);
};
