import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { COLOR_THEMES } from '@karma/components/themes';
import ModalBodyAuthenticate from 'components/molecules/ModalBodyAuthenticate/ModalBodyAuthenticate';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';
import { PATHS } from 'consts/paths';

const mapAloomaModalSource = {
  [AUTHENTICATE_TYPE.LOGIN]: ALOOMA.SOURCE_PAGES.MODAL_LOGIN,
  [AUTHENTICATE_TYPE.SIGN_UP]: ALOOMA.SOURCE_PAGES.MODAL_SIGN_UP,
};

const ModalAuthenticate = props => {
  const { closeModal, authType } = props;
  const { pathname } = useLocation();
  const [authState, setAuthState] = useState(authType);

  const showImage = pathname !== PATHS.WEBSITE_LANDING_PATH;

  return (
    <Modal
      source={mapAloomaModalSource[authState]}
      aloomaModalType={ALOOMA.MODAL_TYPES.WINDOW}
      aloomaModalName={mapAloomaModalSource[authState]}
      onClose={closeModal}
      colorTheme={showImage ? COLOR_THEMES.LIGHT : COLOR_THEMES.DARK}
    >
      <ModalBodyAuthenticate authState={authState} setAuthState={setAuthState} {...props} showImage={showImage} />
    </Modal>
  );
};

ModalAuthenticate.propTypes = {
  closeModal: PropTypes.func.isRequired,
  authType: PropTypes.string.isRequired,
};

export default ModalAuthenticate;
