import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@karma/components';
import { PayPalIcon } from '@karma/components/icons';
import PayPalLogInButton from '../PayPalLogInButton/PayPalLogInButton';
import { ButtonContainer } from './ModalBodyPaypalConnect.style';

const ModalBodyPaypalConnect = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing={20}>
      <PayPalIcon width={60} height={60} />
      <Typography variant="h3" align="center">
        {t('app:connectToPayPalToCashOut')}
      </Typography>
      <ButtonContainer>
        <PayPalLogInButton renderWithoutContainer />
      </ButtonContainer>
    </Stack>
  );
};

export default ModalBodyPaypalConnect;
