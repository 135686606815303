import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import SimilarTagCard from '../SimilarTagCard/SimilarTagCard';
import { Wrapper } from './TemplateSimilarItems.style';

const TemplateSimilarItems = ({ items, slidesView }) => {
  const settings = {
    lazy: true,
    spaceBetween: 15,
    slidesPerView: slidesView,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  };

  return (
    <Wrapper>
      <Swiper {...settings}>
        {items.map(slide => (
          <div key={slide.id}>
            <SimilarTagCard tag={slide} />
          </div>
        ))}
      </Swiper>
    </Wrapper>
  );
};

TemplateSimilarItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  slidesView: PropTypes.number,
};

TemplateSimilarItems.defaultProps = {
  items: [],
  slidesView: 3,
};
export default TemplateSimilarItems;
