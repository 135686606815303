import { createSlice } from '@reduxjs/toolkit';
import { LAST_SAVED, FAVORITES, MAIN, GLOBAL_ITEMS, MY_GLOBAL_ITEMS, SAVED_FROM_STORE } from 'consts/myItemsGroups';

const groupsHandler = (state, action, callback) => {
  return [LAST_SAVED, FAVORITES, MAIN, MY_GLOBAL_ITEMS].reduce((result, groupName) => {
    // eslint-disable-next-line no-param-reassign
    result[groupName] = callback(state[groupName], action);

    return result;
  }, {});
};

const deleteTagHandler = (group, { payload: deletedId }) => {
  const { tags, totalCount } = group;
  const newTags = tags.filter(({ id }) => id !== deletedId);

  return {
    ...group,
    tags: newTags,
    totalCount: tags.length === newTags.length ? totalCount : totalCount - 1,
  };
};

const initialState = {
  // for preview of last_saved on /home page
  [LAST_SAVED]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    totalCount: 0,
  },
  // for preview of favorites on /home page
  [FAVORITES]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    totalCount: 0,
  },
  // for items on /my-items page
  [MAIN]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    totalCount: 0,
  },
  // for my items on global search
  [MY_GLOBAL_ITEMS]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    totalCount: 0,
  },
  // for global items on global search
  [GLOBAL_ITEMS]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    // totalCount can not be calculated correctly due to duplicate global tags, so it is always 0
    totalCount: 0,
  },
  // for saved items from store in store info modal
  [SAVED_FROM_STORE]: {
    isFetching: true,
    loaded: false,
    page: 1,
    tags: [],
    tagsLeft: true,
    totalCount: 0,
  },
  isFetching: true,
  isDeletingTag: false,
  isUpdatingTag: false,
};

export const myItemsSlice = createSlice({
  name: 'myItems',
  initialState,
  reducers: {
    fetchMyItems: (state, { payload: { isFetching } }) => ({
      ...state,
      isFetching,
      [MAIN]: {
        ...state[MAIN],
        isFetching: true,
      },
    }),
    setIsFetchingItems: (state, { payload: { group, isFetching } }) => ({
      ...state,
      isFetching,
      [group]: {
        ...state[group],
        isFetching,
      },
    }),
    resetGlobalItems: state => ({
      ...state,
      [MY_GLOBAL_ITEMS]: {
        ...initialState[MY_GLOBAL_ITEMS],
      },
      [GLOBAL_ITEMS]: {
        ...initialState[GLOBAL_ITEMS],
      },
    }),
    receiveMyItems: (state, { payload: { group = MAIN, page, tags, tagsLeft, totalCount } }) => ({
      ...state,
      isFetching: false,
      [group]: {
        loaded: true,
        page,
        tags: page === 1 ? tags : [...state[group].tags, ...tags],
        tagsLeft,
        isFetching: false,
        totalCount,
      },
    }),
    receiveMyItemsPreview: (state, { payload: { group, tags } }) => {
      return {
        ...state,
        isFetching: false,
        [group]: {
          ...initialState[group],
          loaded: true,
          tags,
          isFetching: false,
        },
      };
    },
    receiveSavedItemsFromStore: (state, { payload: { page, tags, tagsLeft, totalCount } }) => {
      return {
        ...state,
        [SAVED_FROM_STORE]: {
          loaded: true,
          page,
          tags: page === 1 ? tags : [...state[SAVED_FROM_STORE].tags, ...tags],
          tagsLeft,
          isFetching: false,
          totalCount,
        },
      };
    },
    resetMyItems: state => ({
      ...state,
      [MAIN]: {
        ...initialState[MAIN],
      },
    }),
    resetSavedItemsFromStore: state => ({
      ...state,
      [SAVED_FROM_STORE]: {
        ...initialState[SAVED_FROM_STORE],
      },
    }),
    setUpdatedTag: (state, { payload: tag }) => {
      const lastSaved = state[LAST_SAVED];
      const favorites = state[FAVORITES];
      const main = state[MAIN];
      const myGlobalItems = state[MY_GLOBAL_ITEMS];
      const globalItems = state[GLOBAL_ITEMS];

      // runs throught each block of tags and updates tags found by id (same tag can be in different blocks)
      const updatedLastSavedTags = lastSaved.tags.map(oldTag => (oldTag.id === tag.id ? tag : oldTag));
      const updatedFavoritesTags = favorites.tags.map(oldTag => (oldTag.id === tag.id ? tag : oldTag));
      const updatedMainTags = main.tags.map(oldTag => (oldTag.id === tag.id ? tag : oldTag));
      const updatedMyGlobalItemsTags = myGlobalItems.tags.map(oldTag => (oldTag.id === tag.id ? tag : oldTag));
      const updatedGlobalItemsTags = globalItems.tags.map(oldTag => (oldTag.id === tag.id ? tag : oldTag));

      return {
        ...state,
        [LAST_SAVED]: { ...lastSaved, tags: updatedLastSavedTags },
        [FAVORITES]: { ...favorites, tags: updatedFavoritesTags },
        [MAIN]: { ...main, tags: updatedMainTags },
        [MY_GLOBAL_ITEMS]: { ...myGlobalItems, tags: updatedMyGlobalItemsTags },
        [GLOBAL_ITEMS]: { ...globalItems, tags: updatedGlobalItemsTags },
      };
    },
    setDeletedTag: (state, action) => ({
      ...state,
      ...groupsHandler(state, action, deleteTagHandler),
    }),
    setIsDeletingTag: (state, { payload: isDeletingTag }) => ({
      ...state,
      isDeletingTag,
    }),
    addWebSocketTag: (state, action) => {
      const { tag, group } = action.payload;

      if (!state[group].loaded) {
        return state;
      }

      return {
        ...state,
        [group]: {
          ...state[group],
          tags: [tag, ...state[group].tags],
        },
      };
    },
    setIsUpdatingTag: (state, { payload: isUpdatingTag }) => ({
      ...state,
      isUpdatingTag,
    }),
  },
});

export default myItemsSlice.reducer;
