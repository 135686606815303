import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import { getHeadTag, getSelectedTagsCount } from 'store/multiEdit/selectors';
import TagStack from 'components/organisms/TagStack/TagStack';
import { Container } from './ModalBodyTags.style';

const ModalBodyTags = ({ closeModal, title, primaryButtonText, onSubmit }) => {
  const { t } = useTranslation();
  const headTag = useSelector(getHeadTag);
  const size = useSelector(getSelectedTagsCount);

  const contextDataAttrs = Alooma.useContextDataAttrs();

  const submit = () => {
    onSubmit();
    closeModal();
  };

  return (
    <Container>
      <Typography variant="h3">{t(title, { value: size })}</Typography>

      <TagStack headTag={headTag} />

      <Button size="medium" {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.YES} onClick={submit} fullWidth>
        {t(primaryButtonText)}
      </Button>
      <Button
        size="medium"
        type="outline"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.NO}
        onClick={closeModal}
        fullWidth
      >
        {t('app:no')}
      </Button>
    </Container>
  );
};

ModalBodyTags.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ModalBodyTags;
