import PropTypes from 'prop-types';
import { COLOR_THEMES } from '@karma/components/themes';
import Modal from 'components/templates/Modal/Modal';
import * as ALOOMA from 'consts/alooma';
import ModalBodyManageStores from 'components/molecules/ModalBodyManageStores/ModalBodyManageStores';

const ModalManageStores = props => {
  const { closeModal } = props;

  const onClose = () => {
    closeModal();
  };

  return (
    <Modal
      source={ALOOMA.SOURCE_PAGES.HOME}
      aloomaModalType={ALOOMA.MODAL_TYPES.POPUP}
      aloomaModalName={ALOOMA.MODAL_NAMES.MANAGE_STORES}
      onClose={onClose}
      colorTheme={COLOR_THEMES.LIGHT}
    >
      <ModalBodyManageStores />
    </Modal>
  );
};

ModalManageStores.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalManageStores;
