import { createAction } from '@reduxjs/toolkit';
import { myItemsSlice } from './reducers';

export const deleteTagRequest = createAction('myItems/deleteTagRequest');
export const fetchMyItemsPreview = createAction('myItems/fetchMyItemsPreview');
export const updateTagRequest = createAction('myItems/updateTagRequest');
export const fetchSavedItemsFromStore = createAction('myItems/fetchSavedItemsFromStore');

export const {
  fetchMyItems,
  receiveMyItems,
  receiveMyItemsPreview,
  receiveSavedItemsFromStore,
  resetMyItems,
  resetSavedItemsFromStore,
  setDeletedTag,
  setUpdatedTag,
  addWebSocketTag,
  recieveGroups,
  setIsFetchingItems,
  resetGlobalItems,
  setIsDeletingTag,
  setIsUpdatingTag,
} = myItemsSlice.actions;
