import { createSlice } from '@reduxjs/toolkit';
import initial from 'lodash/initial';
import tail from 'lodash/tail';

const initialState = { modals: [] };

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    bulkPushBack: (state, { payload }) => ({
      ...state,
      modals: [...state.modals, ...payload],
    }),

    popBack: state => ({
      ...state,
      modals: initial(state.modals),
    }),

    popFront: state => ({
      ...state,
      modals: tail(state.modals),
    }),

    pushBack: (state, { payload }) => ({
      ...state,
      modals: [...state.modals, payload],
    }),

    pushFront: (state, { payload }) => ({
      ...state,
      modals: [payload, ...state.modals],
    }),

    reset: () => initialState,
  },
});

export default modalsSlice;
