import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@karma/components';
import { CopyIcon } from '@karma/components/icons';
import { ENTITIES } from 'consts/entities';
import copyToClipboard from 'functions/copyToClipboard';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import EmailShareButton from 'components/atoms/EmailShareButton/EmailShareButton';
import FacebookShareButton from 'components/molecules/FacebookShareButton/FacebookShareButton';
import FbMessengerShareButton from 'components/atoms/FbMessengerShareButton/FbMessengerShareButton';
import TwitterShareButton from 'components/molecules/TwitterShareButton/TwitterShareButton';
import WhatsappShareButton from 'components/molecules/WhatsappShareButton/WhatsappShareButton';
import { Container, Item, List, ClipboardWrapper } from './ShareProductComponent.styles';

const ShareProductComponent = ({ tag }) => {
  const { t } = useTranslation();

  const url = tag.seo_link;

  const contextDataAttrs = Alooma.useContextDataAttrs();

  const [showMessage, setShowMessage] = useState(false);

  const clipboard = useCallback(() => {
    setShowMessage(true);
    copyToClipboard(url);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  }, [url]);

  return (
    <Container>
      <Typography variant="h4">{t('app:shareProduct')}</Typography>
      <List>
        <Item>
          <FacebookShareButton url={url} />
        </Item>
        <Item>
          <TwitterShareButton url={url} />
        </Item>
        <Item>
          <WhatsappShareButton url={url} />
        </Item>
        <Item>
          <FbMessengerShareButton url={url} />
        </Item>
        <Item>
          <EmailShareButton url={url} />
        </Item>
        <Item role="button" onClick={clipboard} {...contextDataAttrs} data-alooma-element={ALOOMA.ELEMENTS.COPY_LINK}>
          <CopyIcon size="medium" />
        </Item>
      </List>
      <ClipboardWrapper>
        {showMessage && <Typography variant="labelSmall">{t('app:linkCopiedToClipboard')}</Typography>}
      </ClipboardWrapper>
    </Container>
  );
};

ShareProductComponent.propTypes = {
  tag: ENTITIES.tag.isRequired,
};

export default ShareProductComponent;
