import { newApiInstance } from 'functions/request';

export const getNewSales = ({ page, perPage, biscotti } = {}) =>
  newApiInstance.get('/new_sales', {
    params: {
      page: page || null,
      per_page: perPage || null,
      biscotti: biscotti || null,
    },
  });
