import styled, { css } from 'styled-components';
import { StyledLoaderProps } from './Loader.types';
import omitForwardedProps from '../utils/omitForwardedProps';

export const LoaderWrapper = styled.div.withConfig(
  omitForwardedProps<StyledLoaderProps>(['absolute'])
)<StyledLoaderProps>`
  width: auto;
  height: auto;

  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;
