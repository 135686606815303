import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const WhatsappIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06198 6.92035C8.83833 6.42533 8.60286 6.41542 8.39009 6.40676C8.21605 6.39933 8.01681 6.39979 7.81789 6.39979C7.61881 6.39979 7.29531 6.47427 7.02173 6.77171C6.748 7.06916 5.97656 7.78821 5.97656 9.25064C5.97656 10.7132 7.04662 12.1264 7.19577 12.3249C7.34508 12.5231 9.26138 15.6202 12.2963 16.8116C14.8185 17.8018 15.3318 17.6049 15.8792 17.5553C16.4267 17.5058 17.6457 16.8364 17.8946 16.1423C18.1434 15.4483 18.1434 14.8534 18.0688 14.7291C17.9941 14.6052 17.7951 14.5309 17.4964 14.3822C17.1978 14.2336 15.7299 13.5144 15.4562 13.4153C15.1824 13.3162 14.9834 13.2666 14.7843 13.5642C14.5852 13.8615 14.0133 14.5309 13.8391 14.7291C13.6649 14.9277 13.4907 14.9525 13.1921 14.8038C12.8935 14.6547 11.9318 14.3412 10.791 13.3285C9.90341 12.5407 9.30414 11.5677 9.12995 11.2702C8.95575 10.9729 9.11127 10.8118 9.26106 10.6636C9.39512 10.5305 9.55968 10.3167 9.70899 10.1431C9.85798 9.9695 9.90775 9.84563 10.0073 9.64744C10.1068 9.44893 10.0571 9.27538 9.98241 9.12674C9.90775 8.97809 9.32747 7.50811 9.06198 6.92035Z"
        fill="currentColor"
      />
      <path
        d="M20.5032 3.48771C18.2469 1.23994 15.2467 0.00140716 12.0502 0C5.46335 0 0.102964 5.33475 0.100316 11.8919C0.0995399 13.988 0.649657 16.034 1.69528 17.8375L0 24L6.33478 22.3463C8.08015 23.2939 10.0453 23.7933 12.0451 23.7941H12.0502H12.0502C18.636 23.7941 23.9972 18.4584 24 11.9015C24.0011 8.72365 22.7592 5.73563 20.5032 3.48771ZM12.0502 21.7855H12.0464C10.2638 21.7846 8.5158 21.3082 6.99112 20.4075L6.62826 20.1933L2.86908 21.1745L3.87272 17.5271L3.63631 17.1531C2.64201 15.5792 2.11709 13.7603 2.11787 11.8927C2.12004 6.44262 6.57539 2.00871 12.054 2.00871C14.707 2.0098 17.2005 3.0393 19.0759 4.90757C20.9511 6.77583 21.9831 9.2594 21.9823 11.9008C21.98 17.351 17.5245 21.7855 12.0502 21.7855Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default WhatsappIcon;
