import { addCustomHeaders } from '@karma/utils/functions/axios';
import { X_PLATFORMS } from '@karma/utils/constants/axios';
import { ITEMS } from 'consts/localStorage';

const configureApiRequests = () => {
  addCustomHeaders(() => ({
    token: localStorage.getItem(ITEMS.token) || null,
    platform: X_PLATFORMS.dashboard,
    version: process.env.REACT_APP_VERSION,
  }));
};

export default configureApiRequests;
