import styled from 'styled-components';
import Typography from '@karma/components/Typography';
import { Button, Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';

export const InputBox = styled.div`
  all: unset;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ $isLastInGroup }) => {
    if ($isLastInGroup) {
      return '40px';
    }

    return '20px';
  }};
`;

export const StyledStack = styled(Stack)`
  justify-content: space-between;
  width: 95%;

  &:hover {
    .visit-store-button {
      opacity: 1;
    }
  }
`;

export const StarWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${getColor('shades11')};
    border-radius: 50%;
  }
`;

export const Text = styled(Typography)`
  max-width: 200px;
`;

export const StyledButton = styled(Button)`
  font-weight: 400;
  margin-right: 20px;
  opacity: 0;
  transition: opacity 0.8s ease-out;
`;
