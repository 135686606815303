import styled from 'styled-components';
import { getColor } from '../colors';

export const StyledTabs = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 4px;
  background: ${getColor('neutral4')};
  border-radius: 100px;
`;
