import React, { useMemo } from 'react';
import { StyledComponent } from 'styled-components';
import { TTypographyVariant, TypographyProps, TypographyRootProps } from './ExtTypography.types';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  ParagraphBig,
  LabelLarge,
  LabelMedium,
  LabelMediumBold,
  LabelSmall,
  LabelSmallUppercase,
  LabelExtraSmallUppercase,
  ParagraphLarge,
  ParagraphMedium,
  LabelSmallUppercaseBold,
  LabelExtraSmallUppercaseSemibold,
  LineThrough,
  H4SemiBold,
} from './ExtTypography.styles';

const getComponent = (
  variant: TTypographyVariant
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StyledComponent<any, any, TypographyRootProps> => {
  switch (variant) {
    case 'h1':
      return H1;
    case 'h2':
      return H2;
    case 'h3':
      return H3;
    case 'h4':
      return H4;
    case 'h4SemiBold':
      return H4SemiBold;
    case 'h5':
      return H5;
    case 'h6':
      return H6;
    case 'paragraphBig':
      return ParagraphBig;
    case 'paragraphLarge':
      return ParagraphLarge;
    case 'paragraphMedium':
      return ParagraphMedium;
    case 'labelLarge':
      return LabelLarge;
    case 'labelMedium':
      return LabelMedium;
    case 'labelMediumBold':
      return LabelMediumBold;
    case 'labelSmall':
      return LabelSmall;
    case 'labelSmallUppercase':
      return LabelSmallUppercase;
    case 'labelSmallUppercaseBold':
      return LabelSmallUppercaseBold;
    case 'labelExtraSmallUppercase':
      return LabelExtraSmallUppercase;
    case 'labelExtraSmallUppercaseSemibold':
      return LabelExtraSmallUppercaseSemibold;
    case 'lineThrough':
      return LineThrough;
    default:
      return getComponent('paragraphLarge');
  }
};

const ExtTypography = React.forwardRef<any, TypographyProps>(function ExtTypography(
  {
    children,
    align = 'inherit',
    fontWeight,
    textTransform,
    textDecoration,
    bMargin = 0,
    lMargin = 0,
    rMargin = 0,
    tMargin = 0,
    noWrap = false,
    lineThrough = false,
    variant = 'paragraphLarge',
    color = 'currentColor',
    component,
    ...props
  },
  ref
) {
  const Component = useMemo(() => getComponent(variant), [variant]);

  return (
    <Component
      align={align}
      fontWeight={fontWeight}
      textTransform={textTransform}
      textDecoration={textDecoration}
      bMargin={bMargin}
      lMargin={lMargin}
      rMargin={rMargin}
      tMargin={tMargin}
      noWrap={noWrap}
      lineThrough={lineThrough}
      variant={variant}
      color={color}
      as={component}
      ref={ref}
      {...props}
    >
      {children}
    </Component>
  );
});

export default ExtTypography;
