import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as tagActions from '../actions';
import * as tagApi from '../api';

function* fetchTagAlsoLike({ payload: { id } }) {
  try {
    const response = yield call(tagApi.fetchYouMintAlsoLike, { id });

    yield put(tagActions.recieveAlsoLike(response.data));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchTagAlsoLike;
