import { Divider } from '@karma/components';

const StoreInfoBadgeDivider = () => (
  <Divider
    orientation="vertical"
    lMargin={24}
    rMargin={24}
    color="neutral4"
    style={{
      height: '40px',
      alignSelf: 'center',
    }}
  />
);

export default StoreInfoBadgeDivider;
