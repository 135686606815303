import PropTypes from 'prop-types';
import useResolutionType from 'hooks/useResolutionType';
import RESOLUTION_TYPES from 'consts/resolutionTypes';
import LandingTypographyMobile from '../LandingTypographyMobile/LandingTypographyMobile';
import LandingTypographyDesktop from '../LandingTypographyDesktop/LandingTypographyDesktop';

const LandingTypography = ({ mobileVariant, desktopVariant, ...props }) => {
  const resolutionType = useResolutionType();

  if (resolutionType === RESOLUTION_TYPES.PHONE || resolutionType === RESOLUTION_TYPES.TABLET) {
    return <LandingTypographyMobile {...props} variant={mobileVariant} />;
  }

  return <LandingTypographyDesktop {...props} variant={desktopVariant} />;
};

LandingTypography.propTypes = {
  mobileVariant: PropTypes.string,
  desktopVariant: PropTypes.string,
};

LandingTypography.defaultProps = {
  mobileVariant: 'h1',
  desktopVariant: 'h1',
};

export default LandingTypography;
