import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { Loader, Typography } from '@karma/components';
import { getColor } from '@karma/components/colors';
import { WHITE } from 'consts/colors';
import { ChartWrapperModalProduct, LoaderWrapper, PriceWrapper } from './PriceEvolution.style';

const PriceEvolution = ({ tag: { price }, seoTag, data: { group_by, dates, prices, max }, fetch, height }) => {
  const { t } = useTranslation();

  if (fetch) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (dates?.length === 1) {
    const yesterday = moment(dates[0], 'YYYY/MM/DD').subtract(1, 'days').format('YYYY/MM/DD');

    dates.unshift(yesterday);
    prices.push(prices[0]);
  }

  let smallPriceChange;

  if (prices) {
    const maxPrice = Math.max(...prices);
    const minPrice = Math.min(...prices);
    const difference = maxPrice - minPrice;
    const smallPart = maxPrice * 0.1;

    smallPriceChange = !(difference > smallPart);
  }

  const maxPointY = smallPriceChange ? max * 1.4 : max * 1.03;
  const minPointY = smallPriceChange ? max * 0.4 : 0;
  const stepOnOY = smallPriceChange ? max * 0.2 : maxPointY / 5;

  // It's price line of chart
  const data = canvas => {
    return {
      labels: dates || [],
      datasets: [
        {
          backgroundColor: getColor('secondary1'),
          borderColor: getColor('secondary1'),
          borderCapStyle: 'butt',
          borderJoinStyle: 'miter',
          pointBorderColor: WHITE,
          pointBackgroundColor: getColor('secondary1'),
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: getColor('secondary1'),
          pointHoverBorderColor: WHITE,
          pointHoverBorderWidth: 3,
          pointRadius: 0,
          pointHitRadius: Math.round(canvas.width / dates?.length / 4),
          data: prices || [],
          lineTension: 0.4,
        },
      ],
    };
  };
  const timeOption =
    group_by === 'month'
      ? { displayFormats: { month: 'MMM' }, unit: 'month' }
      : { displayFormats: { month: 'MMM D' }, unit: 'day' };

  const options = {
    showAllTooltips: true,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      // tooltip that we see after hover
      tooltip: {
        backgroundColor: getColor('primary2'),
        cornerRadius: 8,
        borderWidth: 0.5,
        borderColor: getColor('primary2'),
        displayColors: false,
        caretPadding: 10,
        callbacks: {
          label: tooltipItem => t('app:price', { currency: price.currency.symbol || '', value: tooltipItem.parsed.y }),
          labelTextColor: () => getColor('primary1'),
          title: () => null,
        },
      },
    },
    scales: {
      // vertical grid
      y: {
        grid: {
          drawTicks: false,
        },
        min: Math.round(minPointY),
        max: Math.round(maxPointY),
        ticks: {
          color: getColor('neutral2'),
          stepSize: stepOnOY,
          font: {
            family: "'Roboto'",
            size: 10,
            weight: '600',
          },
          precision: 0,
        },
      },
      x: {
        type: 'time',
        time: timeOption,
        ticks: {
          color: getColor('neutral2'),
          font: {
            family: "'Roboto'",
            size: 10,
            weight: '600',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scaleStartValue: 0,
    showXLabels: 3,
  };

  const centeringChart = paramSeoTag => {
    if (paramSeoTag) {
      return <Line data={data} options={options} height={height || 180} style={{ maxHeight: `${height}px` }} />;
    }

    return (
      <ChartWrapperModalProduct>
        <Line data={data} options={options} height={height || 180} style={{ maxHeight: `${height}px` }} />
      </ChartWrapperModalProduct>
    );
  };

  if (!dates?.length) {
    return null;
  }

  return (
    <PriceWrapper>
      <Typography variant="labelSmallUppercase" color="neutral2" bMargin={12}>
        Price ({price.currency.symbol})
      </Typography>
      {centeringChart(seoTag)}
    </PriceWrapper>
  );
};

export default PriceEvolution;
