import styled from 'styled-components';

export const SocialListV3 = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 360px;
`;

export const SocialItem = styled.li`
  width: 100%;
`;
