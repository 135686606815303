import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const BrowserIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 20 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99965 0.291748C4.63789 0.291748 0.291321 4.63832 0.291321 10.0001C0.291321 15.3618 4.63789 19.7084 9.99965 19.7084C15.3614 19.7084 19.708 15.3618 19.708 10.0001C19.708 4.63832 15.3614 0.291748 9.99965 0.291748ZM8.48061 1.84725C5.97123 2.31185 3.85834 3.90643 2.6895 6.08343H7.15005C7.22866 5.46911 7.32576 4.89988 7.43919 4.38947C7.60164 3.65844 7.81417 2.97039 8.09778 2.43058C8.1965 2.24267 8.32131 2.03866 8.48061 1.84725ZM1.70799 10.0001C1.70799 9.12884 1.84236 8.28897 2.09152 7.50009H7.00798C6.94871 8.29234 6.9173 9.13314 6.9173 10.0001C6.9173 11.1782 6.97531 12.3081 7.0824 13.3334H2.40523C1.95687 12.3134 1.70799 11.1858 1.70799 10.0001ZM3.20252 14.7501C4.42517 16.4964 6.30369 17.7499 8.48059 18.1529C8.3213 17.9615 8.1965 17.7575 8.09778 17.5696C7.81417 17.0298 7.60164 16.3417 7.43919 15.6107C7.37897 15.3397 7.32335 15.0522 7.27266 14.7501H3.20252ZM8.71077 14.7501C9.01642 16.4288 9.48072 17.5001 10.0006 17.5001C10.5205 17.5001 10.9848 16.4288 11.2905 14.7501H8.71077ZM12.7286 14.7501C12.6779 15.0522 12.6223 15.3397 12.5621 15.6107C12.3996 16.3417 12.1871 17.0298 11.9035 17.5696C11.8048 17.7574 11.6801 17.9612 11.521 18.1525C13.6969 17.749 15.5746 16.4958 16.7968 14.7501H12.7286ZM17.5941 13.3334H12.9189C13.0259 12.3081 13.084 11.1782 13.084 10.0001C13.084 9.13314 13.0525 8.29234 12.9933 7.50009H17.9078C18.1569 8.28897 18.2913 9.12884 18.2913 10.0001C18.2913 11.1858 18.0424 12.3134 17.5941 13.3334ZM11.4941 13.3334H8.50721C8.39632 12.3293 8.33396 11.1975 8.33396 10.0001C8.33396 9.1235 8.36738 8.28204 8.4288 7.50009H11.5725C11.6339 8.28204 11.6673 9.1235 11.6673 10.0001C11.6673 11.1975 11.6049 12.3293 11.4941 13.3334ZM12.8512 6.08343H17.3098C16.1413 3.9071 14.0294 2.31283 11.521 1.84768C11.6801 2.03896 11.8048 2.24281 11.9035 2.43058C12.1871 2.97039 12.3996 3.65844 12.5621 4.38947C12.6755 4.89988 12.7726 5.46911 12.8512 6.08343ZM10.0006 2.50009C9.39914 2.50009 8.87207 3.9339 8.579 6.08343H11.4223C11.1292 3.9339 10.6021 2.50009 10.0006 2.50009Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BrowserIcon;
