import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const TwitterIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M21.3839 7.3111C21.3983 7.51916 21.3983 7.72722 21.3983 7.93719C21.3983 14.3353 16.5276 21.7142 7.62128 21.7142V21.7103C4.99034 21.7142 2.41404 20.9606 0.199219 19.5396C0.581779 19.5856 0.966258 19.6087 1.35169 19.6096C3.532 19.6115 5.64999 18.88 7.36528 17.5329C5.29332 17.4935 3.47639 16.1426 2.84167 14.1703C3.56748 14.3103 4.31534 14.2816 5.02773 14.0869C2.7688 13.6305 1.14364 11.6458 1.14364 9.34088C1.14364 9.31978 1.14364 9.29965 1.14364 9.27951C1.81671 9.6544 2.57033 9.86246 3.3412 9.88547C1.21363 8.46357 0.557809 5.6332 1.8426 3.42029C4.30096 6.4453 7.9281 8.28428 11.8218 8.47892C11.4315 6.79718 11.9646 5.03491 13.2226 3.85271C15.1728 2.01949 18.24 2.11345 20.0732 4.06269C21.1576 3.84888 22.1969 3.45097 23.1481 2.8872C22.7866 4.00804 22.0301 4.96012 21.0195 5.56513C21.9793 5.45199 22.917 5.19503 23.8001 4.80288C23.15 5.77702 22.3312 6.62556 21.3839 7.3111Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TwitterIcon;
