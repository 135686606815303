import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const RefurbishedIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 13 12"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10148 1.63529C6.32377 1.25419 6.87472 1.25508 7.09578 1.63689L8.51901 4.09509L7.64697 4.00058L7.55539 4.84563L9.96627 5.10692L10.2276 2.69604L9.38251 2.60445L9.26514 3.68737L7.83138 1.211C7.28354 0.264767 5.91815 0.262564 5.36725 1.20703L4.48439 2.72062L5.21862 3.14888L6.10148 1.63529Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.783 9.1099C2.34581 9.10585 2.07291 8.63466 2.28696 8.25342L3.83252 5.50065L4.01359 6.4787L4.84938 6.32397L4.40794 3.93948L2.02346 4.38093L2.17819 5.21673L3.11443 5.0434L1.54579 7.83729C1.01533 8.78209 1.69163 9.94981 2.77512 9.95987L4.55783 9.97641L4.56572 9.12644L2.783 9.1099Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3071 9.02069C10.7493 9.02184 11.0272 8.54431 10.8079 8.16041L9.94224 6.64562L10.6802 6.22389L11.5459 7.73868C12.0895 8.6901 11.4007 9.87354 10.3049 9.87069L7.2159 9.86266L7.8159 10.4627L7.21486 11.0637L5.50013 9.34897L7.21486 7.63424L7.8159 8.23528L7.03899 9.01219L10.3071 9.02069Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RefurbishedIcon;
