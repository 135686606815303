import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyTagCreate from 'components/molecules/ModalBodyTagCreate/ModalBodyTagCreate';
import Modal from 'components/templates/Modal/Modal';

const ModalTagCreate = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.TAG_CREATE} onClose={closeModal}>
      <ModalBodyTagCreate {...props} />
    </Modal>
  );
};

ModalTagCreate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalTagCreate;
