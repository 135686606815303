import { all, takeLatest, call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import { setRetailer, fetchRetailerRequest } from './actions';
import { fetchRetailer } from './api';

function* getRetailer(event) {
  try {
    const { data } = yield call(fetchRetailer, event.payload);

    yield put(setRetailer(data.data));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default function* () {
  yield all([takeLatest(fetchRetailerRequest.type, getRetailer)]);
}
