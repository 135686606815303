import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useTransitionTo = () => {
  const history = useHistory();

  return useCallback(path => history.push(path), [history]);
};

export default useTransitionTo;
