import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyWithdrawCongrats from 'components/molecules/ModalBodyWithdrawCongrats/ModalBodyWithdrawCongrats';
import Modal from 'components/templates/Modal/Modal';

const ModalWithdrawCongrats = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.WITHDRAW_CONGRATULATIONS} onClose={closeModal}>
      <ModalBodyWithdrawCongrats {...props} />
    </Modal>
  );
};

ModalWithdrawCongrats.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalWithdrawCongrats;
