import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, StoreLogo, Typography } from '@karma/components';
import { ListsIcon, MuteNotificationsIcon } from '@karma/components/icons';
import * as ALOOMA from 'consts/alooma';
import { DEFAULT_TAG } from 'consts/defaultImages';
import Alooma from 'services/alooma';
import checkItemHasPrice from 'functions/checkItemHasPrice';
import PropertiesSelectList from 'components/organisms/PropertiesSelectList/PropertiesSelectList';
import ToggleButton from 'components/organisms/ToggleButton/ToggleButton';
import NotifyWhenComponent from 'components/organisms/NotifyWhenComponent/NotifyWhenComponent';
import ListModal from 'components/organisms/ListModal/ListModal';
import hasDiscount from 'functions/hasDiscount';
import useTagIsOutOfStock from 'hooks/useTagIsOutOfStock.js';
import { ENTITIES } from 'consts/entities';
import { useIsSavedItem } from 'hooks/useGlobalItemHooks';
import ModalProductLists from '../ModalProductLists/ModalProductLists';
import {
  Column,
  NameLink,
  PriceWrapper,
  OriginalPrice,
  BrandWrapperLink,
  ColumnContent,
  RetailerName,
} from './ModalProductInfoSection.style';

const ModalProductInfoSection = ({ closeModal, tag, redirectToTagLink, redirectToShopLink }) => {
  const { marker, retailer_logo, name, retailer_name, prevent_notifications, price } = tag;

  const { t } = useTranslation();

  const contextDataAttrs = Alooma.useContextDataAttrs();
  const isTagOutOfStock = useTagIsOutOfStock(marker);
  const isSavedItem = useIsSavedItem(tag);

  const {
    current_price: { full: fullCurrentPrice, humanized: humanizedCurrentPrice },
    original_price: { humanized: humanizedOriginalPrice },
  } = price;

  const settings = [
    {
      id: 1,
      tooltip: t('app:wishlists'),
      isActive: false,
      Icon: ListsIcon,
      alooma: ALOOMA.ELEMENTS.ADD_TO_LIST,
      Component: ListModal,
    },
    {
      id: 2,
      tooltip: t('app:notifications'),
      isActive: prevent_notifications,
      Icon: MuteNotificationsIcon,
      alooma: prevent_notifications ? ALOOMA.ELEMENTS.MUTE_NOTIFICATIONS : ALOOMA.ELEMENTS.UNMUTE_NOTIFICATIONS,
      Component: NotifyWhenComponent,
    },
  ];

  const itemHasDiscount = hasDiscount(price.current_price, price.original_price, price.currency);

  const shouldShowPrice = !isTagOutOfStock && checkItemHasPrice(fullCurrentPrice);

  return (
    <Column>
      <ColumnContent>
        <BrandWrapperLink
          target="_blank"
          href={redirectToShopLink}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.OPEN_RETAILER}
          rel="nofollow"
        >
          <StoreLogo size="small" src={retailer_logo || DEFAULT_TAG} alt={retailer_name} />
          <RetailerName variant="h5" color="neutral2">
            {retailer_name}
          </RetailerName>
        </BrandWrapperLink>
        <Typography variant="paragraphLarge">
          <NameLink
            target="_blank"
            href={redirectToTagLink}
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.OPEN_ITEM_RETAILER}
          >
            {name}
          </NameLink>
        </Typography>
        {shouldShowPrice && (
          <PriceWrapper>
            {itemHasDiscount && <OriginalPrice>{humanizedOriginalPrice}</OriginalPrice>}
            <Typography variant="h4">{humanizedCurrentPrice}</Typography>
          </PriceWrapper>
        )}
        <ModalProductLists tag={tag} closeModal={closeModal} />
        {isSavedItem && <ToggleButton setting={settings} tag={tag} />}
        <PropertiesSelectList tag={tag} isSavedItem={isSavedItem} />
        <Button
          size="small"
          as="a"
          target="_blank"
          href={redirectToTagLink}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.BUY_AT}
          style={{ marginTop: 'auto' }}
        >
          {t('app:buyOnShop')}
          {` `}
          {retailer_name}
        </Button>
      </ColumnContent>
    </Column>
  );
};

ModalProductInfoSection.propTypes = {
  tag: ENTITIES.tag.isRequired,
  closeModal: PropTypes.func.isRequired,
  redirectToTagLink: PropTypes.string.isRequired,
  redirectToShopLink: PropTypes.string.isRequired,
};

export default ModalProductInfoSection;
