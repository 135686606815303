import { StoreLogoSize } from './StoreLogo.types';

export const getLogoDimension = (size: StoreLogoSize): number => {
  switch (size) {
    case 'extraLarge':
      return 72;
    case 'large':
      return 64;
    case 'medium':
      return 48;
    case 'small':
      return 40;
    case 'extraSmall':
      return 32;
    default:
      return getLogoDimension('large');
  }
};

export const getPlaceholderDimension = (size: StoreLogoSize): number => {
  switch (size) {
    case 'extraLarge':
      return 36;
    case 'large':
      return 30;
    case 'medium':
      return 24;
    case 'small':
      return 12;
    case 'extraSmall':
      return 12;
    default:
      return getPlaceholderDimension('large');
  }
};
