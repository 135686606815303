import { NEW_API_URL } from '@karma/utils/constants/env';
import { getCookieValue, setCookie, setCookieForLp } from 'functions/cookies';
import CConsole from 'functions/CConsole';
import { newApiInstance } from 'functions/request';
import { ITEMS as LOCAL_STORAGE_ITEMS } from 'consts/localStorage';
import isValidUrl from 'functions/isValidUrl';

export const getReferralLinkParams = () => {
  return {
    ref_src: localStorage.getItem('ref_src'),
    gclid: localStorage.getItem('gclid'),
    referral_src: localStorage.getItem('referral_src') || localStorage.getItem('ref_src'),
    referral_code: localStorage.getItem('referral_code'),
    referer_id: localStorage.getItem('referer_id'),
    referer_list_id: localStorage.getItem('referer_list_id'),
    uid_token: localStorage.getItem('uid_token'),
    campaign: localStorage.getItem('campaign'),
    campaign_full_url: localStorage.getItem('campaign_full_url'),
    sub_campaign: localStorage.getItem('sub_campaign'),
    session_id: getCookieValue('sessionId'),
    lp_test: localStorage.getItem(LOCAL_STORAGE_ITEMS.lpTest),
    utm_source: getCookieValue('utm_source'),
    utm_medium: getCookieValue('utm_medium'),
    utm_content: getCookieValue('utm_content'),
    utm_term: getCookieValue('utm_term'),
    utm_campaign: getCookieValue('utm_campaign'),
  };
};

export const processReferralLink = async (customHref = '') => {
  const href = customHref && isValidUrl(customHref) ? customHref : window.location.href;
  const url = new URL(href);

  if (url.searchParams.get('utm_source')) setCookie('utm_source', url.searchParams.get('utm_source'));
  if (url.searchParams.get('utm_medium')) setCookie('utm_medium', url.searchParams.get('utm_medium'));
  if (url.searchParams.get('utm_content')) setCookie('utm_content', url.searchParams.get('utm_content'));
  if (url.searchParams.get('utm_term')) setCookie('utm_term', url.searchParams.get('utm_term'));
  if (url.searchParams.get('utm_campaign')) setCookie('utm_campaign', url.searchParams.get('utm_campaign'));
  if (url.searchParams.get('utm_source') === 'rp') {
    setCookie('utm_campaign', url.searchParams.get('sid'));
    setCookie('sid', url.searchParams.get('sid'));
  }
  if (url.searchParams.get('sessionId')) setCookie('sessionId', url.searchParams.get('sessionId'));

  if (url.searchParams.get('ref_src')) localStorage.setItem('ref_src', url.searchParams.get('ref_src'));

  if (!localStorage.getItem('campaign_full_url')) {
    localStorage.setItem('campaign_full_url', href);

    await newApiInstance
      .get(`/referral_links/user${url.search}`)
      .then(response => {
        Object.keys(response.data || {}).forEach(param => {
          const refValue = response.data[param];

          if (param && refValue) localStorage.setItem(param, refValue);
        });
      })
      .catch(CConsole.error);
  }
};

export const processReferralLinkForLp = () => {
  return `
    (function processReferralLinkForLp() {
      ${setCookieForLp()}
    
      const url = new URL(window.location.href);
  
      if (url.searchParams.get('utm_source')) setCookieForLp('utm_source', url.searchParams.get('utm_source'));
      if (url.searchParams.get('utm_medium')) setCookieForLp('utm_medium', url.searchParams.get('utm_medium'));
      if (url.searchParams.get('utm_content')) setCookieForLp('utm_content', url.searchParams.get('utm_content'));
      if (url.searchParams.get('utm_term')) setCookieForLp('utm_term', url.searchParams.get('utm_term'));
      if (url.searchParams.get('utm_campaign')) setCookieForLp('utm_campaign', url.searchParams.get('utm_campaign'));
      if (url.searchParams.get('utm_source') === 'rp') {
        setCookieForLp('utm_campaign', url.searchParams.get('sid'));
        setCookieForLp('sid', url.searchParams.get('sid'));
      }
      if (url.searchParams.get('sessionId')) setCookieForLp('sessionId', url.searchParams.get('sessionId'));
    
      if (url.searchParams.get('ref_src')) localStorage.setItem('ref_src', url.searchParams.get('ref_src'));
    
      if (!localStorage.getItem('campaign_full_url')) {
        localStorage.setItem('campaign_full_url', window.location.href);
        
        fetch('${NEW_API_URL}/referral_links/user' + url.search)
          .then(response => response.json())
          .then(data => {
            Object.keys(data || {}).forEach(param => {
              const refValue = data[param];
    
              if (param && refValue) localStorage.setItem(param, refValue);
            });
          })
          .catch(console.error);
      }
    })();
  `;
};
