import BROWSERS from 'consts/browsers';

export const SUPPORTED_BROWSERS = [
  BROWSERS.CHROME,
  BROWSERS.EDGE,
  BROWSERS.FIREFOX,
  BROWSERS.OLD_EDGE,
  BROWSERS.OPERA,
  BROWSERS.SAFARI,
];

export const ENV_DEVELOPMENT = 'development';
export const ENV_PRODUCTION = 'production';

export const ROLES = {
  admin: 'admin',
};

export const MAX_TABLET_WIDTH = 768;
export const MAX_SCREEN_WIDTH = 5000;
export const MIN_SCREEN_WIDTH = 320;
export const D_PUBLIC_PAGE_BASE_WIDTH = 1440;
export const D_LANDING_PAGE_BASE_WIDTH = 1728;
export const M_PAGE_BASE_WIDTH = 375;

export const USERS_AMOUNT = '6';
export const REVIEWS_AMOUNT = 20; // k+
export const APP_RATING = '4.5';
export const TOTAL_SAVINGS = 221141372;
export const RETAILERS_AMOUNT = 100;
export const REVIEWS_AMOUNT_2 = 37;
export const HAPPY_SHOPPERS = 5;

export const NARROW_CONTAINER_MAX_WIDTH = 300;
