import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  allCoupons: {
    coupons: [],
    couponsLeft: true,
    page: 1,
    search: '',
    sort: '',
    loading: false,
  },
  liveCoupons: {
    coupons: [],
    newCoupons: [],
  },
};

export const allCouponsSlice = createSlice({
  name: 'allCoupons',
  initialState,
  reducers: {
    setCoupons: (state, { payload: { coupons, sort_by, search, couponsLeft } }) => ({
      ...state,
      allCoupons: {
        ...state.allCoupons,
        coupons,
        sort: sort_by,
        search,
        couponsLeft,
      },
    }),
    addCoupons: (state, { payload: { coupons, page, couponsLeft } }) => ({
      ...state,
      allCoupons: {
        ...state.allCoupons,
        coupons: [...state.allCoupons.coupons, ...coupons],
        page,
        couponsLeft,
      },
    }),
    toggleLoading: state => ({
      ...state,
      allCoupons: {
        ...state.allCoupons,
        loading: !state.allCoupons.loading,
      },
    }),
    recieveLiveCoupons: (state, action) => ({
      ...state,
      liveCoupons: {
        ...state.liveCoupons,
        coupons: action.payload,
      },
    }),
    recieveNewLiveCoupons: (state, action) => ({
      ...state,
      liveCoupons: {
        ...state.liveCoupons,
        newCoupons: action.payload,
      },
    }),
    moveNewCoupons: state => ({
      ...state,
      liveCoupons: {
        ...state.liveCoupons,
        coupons: [...state.liveCoupons.newCoupons, ...state.liveCoupons.coupons],
        newCoupons: [],
      },
    }),
  },
});

export default allCouponsSlice.reducer;
