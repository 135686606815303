import styled from 'styled-components';
import { getColor } from '@karma/components/colors';
import { Stack } from '@karma/components';

export const ChartWrapperModalProduct = styled.div``;

export const LoaderWrapper = styled(Stack)`
  padding: 20px;
`;

export const PriceWrapper = styled.div`
  position: relative;
  background: ${getColor('neutral4')};
  border-radius: 10px;
  padding: 20px;
`;
