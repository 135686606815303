import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const HidePasswordIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4373 5.03482L5.34658 20.5412L4.24023 19.2504L22.331 3.74408L23.4373 5.03482Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79049 12.1426C4.80346 15.9788 8.4858 18.4926 12.6383 18.4926C16.7908 18.4926 20.4731 15.9788 22.4861 12.1426C20.4731 8.30643 16.7908 5.79262 12.6383 5.79262C8.4858 5.79262 4.80346 8.30643 2.79049 12.1426ZM1.0738 11.771C3.27798 7.2363 7.60284 4.09262 12.6383 4.09262C17.6737 4.09262 21.9986 7.2363 24.2028 11.771C24.3168 12.0056 24.3168 12.2796 24.2028 12.5142C21.9986 17.0489 17.6737 20.1926 12.6383 20.1926C7.60284 20.1926 3.27798 17.0489 1.0738 12.5142C0.959773 12.2796 0.959773 12.0056 1.0738 11.771Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6375 9.39284C11.1187 9.39284 9.8875 10.6241 9.8875 12.1428C9.8875 13.1597 10.4389 14.0487 11.2631 14.5255L10.4119 15.997C9.08399 15.2288 8.1875 13.791 8.1875 12.1428C8.1875 9.68517 10.1798 7.69284 12.6375 7.69284C14.1862 7.69284 15.5495 8.48464 16.3453 9.68154L14.9297 10.6228C14.4356 9.87965 13.5932 9.39284 12.6375 9.39284Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HidePasswordIcon;
