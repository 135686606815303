import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const XIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 20 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M0.4328 0.994482H6.09196L11.3229 8.03069L17.834 0.833252L19.3811 0.860124L12.0942 9.0429L19.5672 19.0933H13.9103L8.9731 12.5364L2.94175 19.2546H1.41934L8.22423 11.5779L0.4328 0.994482ZM5.55833 2.03357H2.56956L14.5269 18.0274H17.4618L5.55833 2.03357Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default XIcon;
