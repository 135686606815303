import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const VisaCardIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2891 15.0179C24.2891 18.2487 21.6699 20.8679 18.4391 20.8679L0.989063 20.8679L0.989064 8.74286C0.989065 5.51199 3.6082 2.89286 6.83907 2.89286L24.2891 2.89286L24.2891 15.0179ZM18.4391 19.1679C20.731 19.1679 22.5891 17.3098 22.5891 15.0179L22.5891 4.59286L6.83907 4.59286C4.54708 4.59286 2.68907 6.45088 2.68907 8.74286L2.68906 19.1679L18.4391 19.1679Z"
        fill="currentColor"
      />
      <path
        d="M8.42008 12.8454L7.47508 10.1304H6.70508L8.02508 13.6304H8.81508L10.1101 10.1304H9.36008L8.42008 12.8454Z"
        fill="currentColor"
      />
      <path d="M11.2466 13.6304V10.1304H10.5266V13.6304H11.2466Z" fill="currentColor" />
      <path
        d="M13.3811 13.7054C14.2511 13.7054 14.8111 13.2804 14.8111 12.5754C14.8111 11.2204 12.7061 11.8654 12.7061 11.0854C12.7061 10.8204 12.9511 10.6504 13.3361 10.6504C13.8011 10.6504 14.0561 10.8854 13.9961 11.2954H14.7211C14.8111 10.5504 14.2611 10.0554 13.3511 10.0554C12.5161 10.0554 11.9761 10.4704 11.9761 11.1654C11.9761 12.5404 14.0761 11.8754 14.0761 12.6554C14.0761 12.9254 13.8211 13.1104 13.3961 13.1104C12.9061 13.1104 12.6311 12.8704 12.6561 12.4604H11.9311C11.8711 13.2204 12.4561 13.7054 13.3811 13.7054Z"
        fill="currentColor"
      />
      <path
        d="M18.5717 13.6304L17.2067 10.1304H16.3317L14.9717 13.6304H15.7417L16.0067 12.9304H17.5667L17.8317 13.6304H18.5717ZM16.7867 10.8504L17.3467 12.3404H16.2267L16.7867 10.8504Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default VisaCardIcon;
