import PropTypes from 'prop-types';
import { KarmaLogo } from '@karma/components';
import onErrorImageHandler from 'functions/onErrorImageHandler';
import { Head, EmptyHead, Stack, Tail } from './TagStack.style';

const TagStack = ({ headTag, multiple, ...rest }) => (
  <Stack {...rest}>
    {headTag?.main_img_url ? (
      <Head src={headTag.main_img_url} onError={onErrorImageHandler} />
    ) : (
      <EmptyHead>
        <KarmaLogo variant="symbol" width={40} />
      </EmptyHead>
    )}
    {multiple && <Tail />}
  </Stack>
);

TagStack.propTypes = {
  headTag: PropTypes.shape({ main_img_url: PropTypes.string.isRequired }).isRequired,
  multiple: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
};

TagStack.defaultProps = {
  multiple: true,
};

export default TagStack;
