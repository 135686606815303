import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as ALOOMA from 'consts/alooma';
import { getList } from 'store/lists/selectors';
import ModalBodyListDelete from 'components/molecules/ModalBodyListDelete/ModalBodyListDelete';
import Modal from 'components/templates/Modal/Modal';

const ModalListDelete = props => {
  const { closeModal, listId } = props;
  const list = useSelector(getList)(listId);

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.MODAL_LIST_DELETE} onClose={closeModal}>
      <ModalBodyListDelete list={list} {...props} />
    </Modal>
  );
};

ModalListDelete.propTypes = {
  closeModal: PropTypes.func.isRequired,
  listId: PropTypes.number.isRequired,
};

export default ModalListDelete;
