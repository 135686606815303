import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { isClient } from 'functions/isClient';

const FAVICON_LIGHT_MODE = `${process.env.PUBLIC_URL}/static/favicon_light.png`;
const FAVICON_DARK_MODE = `${process.env.PUBLIC_URL}/static/favicon_dark.png`;

const HeadFavicon = () => {
  return (
    <Helmet>
      <link
        rel="icon"
        href={
          isClient() && window.matchMedia('(prefers-color-scheme: dark)').matches === true
            ? FAVICON_DARK_MODE
            : FAVICON_LIGHT_MODE
        }
      />
    </Helmet>
  );
};

export default memo(HeadFavicon);
