import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const ClosedBoxIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75268 2.81908C5.69322 2.81908 5.63937 2.85421 5.61541 2.90862L3.36599 8.01654H11.6191V2.81908H5.75268ZM13.3191 2.81908V8.01654H21.8507L19.3486 2.90315C19.3235 2.8517 19.2712 2.81908 19.2139 2.81908H13.3191ZM22.3629 9.71654H2.91289V21.3166C2.91289 21.3995 2.98005 21.4666 3.06289 21.4666H22.2129C22.2957 21.4666 22.3629 21.3995 22.3629 21.3166V9.71654ZM4.05959 2.22347C4.35516 1.55229 5.0193 1.11908 5.75268 1.11908H19.2139C19.9204 1.11908 20.5651 1.52141 20.8756 2.15598L24.0629 8.66973V21.3166C24.0629 22.3384 23.2346 23.1666 22.2129 23.1666H3.06289C2.04116 23.1666 1.21289 22.3384 1.21289 21.3166V8.68766L4.05959 2.22347Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ClosedBoxIcon;
