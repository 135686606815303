import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as tagActions from '../actions';
import * as tagApi from '../api';

function* fetchTag({ payload: { tag_id, product_name, brand, retailer } }) {
  try {
    const response = yield call(tagApi.fetchTag, { tag_id, product_name, brand, retailer });

    yield put(tagActions.recieveTag(response?.data?.tag));

    const response1 = yield call(tagApi.fetchCoupons, { rule_id: response?.data?.tag?.rule_id });

    yield put(tagActions.recieveCoupons(response1.data));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchTag;
