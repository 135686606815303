import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as filtersActions from 'store/filters/actions';
import * as myItemsActions from 'store/myItems/store/actions';
import changeFilters from './changeFilters';
import deleteTag from './deleteTag';
import fetchMyItems from './fetchMyItems';
import fetchMyItemsPreview from './fetchMyItemsPreview';
import updateTag from './updateTag';
import fetchSavedItemsFromStore from './fetchSavedItemsFromStore';

export default function* () {
  yield all([
    takeLatest(
      [filtersActions.clear.type, filtersActions.changeFilter.type, filtersActions.clearList.type],
      changeFilters,
    ),
    takeLatest(myItemsActions.fetchMyItems.type, fetchMyItems),
    takeLatest(myItemsActions.fetchMyItemsPreview.type, fetchMyItemsPreview),
    takeLatest(myItemsActions.fetchSavedItemsFromStore.type, fetchSavedItemsFromStore),
    takeEvery(myItemsActions.updateTagRequest.type, updateTag),
    takeEvery(myItemsActions.deleteTagRequest.type, deleteTag),
  ]);
}
