import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import * as myItemsGroups from 'consts/myItemsGroups';

const getMyItems = store => store.myItems;

export const getMyItemsFetching = createSelector(getMyItems, myItems => myItems?.isFetching);

export const getIsDeletingTag = createSelector(getMyItems, myItems => myItems?.isDeletingTag);

export const getIsUpdatingTag = createSelector(getMyItems, myItems => myItems?.isUpdatingTag);

export const getFavoritesGroup = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.FAVORITES]);

export const getLastSavedGroup = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.LAST_SAVED]);

export const getMainGroup = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.MAIN]);

export const getMyGlobalItemsGroup = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.MY_GLOBAL_ITEMS]);

export const getGlobalItemsGroup = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.GLOBAL_ITEMS]);

export const getSavedItemsFromStore = createSelector(getMyItems, myItems => myItems?.[myItemsGroups.SAVED_FROM_STORE]);

export const getMainGroupTags = createSelector(getMainGroup, group => group?.tags);

export const getMainGroupTotalCount = createSelector(getMainGroup, group => group?.totalCount);

export const getTag = createSelector(getMyItems, myItems =>
  memoize((tagId, group) => myItems?.[group]?.tags?.find(({ id }) => id === tagId)),
);
