import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyCongratsGuest from 'components/molecules/ModalBodyCongratsGuest/ModalBodyCongratsGuest';
import Modal from 'components/templates/Modal/Modal';

const ModalCongratsGuest = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.CONGRATS_GUEST} onClose={closeModal}>
      <ModalBodyCongratsGuest {...props} />
    </Modal>
  );
};

ModalCongratsGuest.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalCongratsGuest;
