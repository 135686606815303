import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as shopActions from '../actions';
import * as shopApi from '../api';

function* fetchTags({ payload: { user_token, token, page } }) {
  try {
    if (page) {
      yield put(shopActions.enableFetching());
    }
    const { data } = yield call(shopApi.fetchTags, { user_token, token, page });

    if (page) {
      yield put(shopActions.receiveTags(data));
    } else {
      yield put(shopActions.receiveTagsData(data));
    }
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchTags;
