import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { usePopper } from 'react-popper';
import CloseIcon from '../icons/CloseIcon';
import { CloseIconContainer, PopperArrow, StyledPopper } from './Popper.styles';
import { ForwardPopperProps, PopperProps } from './Popper.types';
import ExtTypography from '../ExtTypography';
import Stack from '../Stack';

const Popper = forwardRef<ForwardPopperProps, PopperProps>(function Popper(
  {
    content,
    formattedContent,
    defaultPlacement,
    Icon,
    onClose,
    open,
    referenceElement,
    title,
    variant = 'small',
    fallbackPlacements = ['top-start'],
    style,
    offset = [0, 10],
    popperAttributes = {},
    popperModifiers = [],
    zIndex = 1,
    ...props
  },
  ref
) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    ...popperAttributes,
    placement: defaultPlacement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 10,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
        },
      },
      ...popperModifiers,
    ],
  });

  const hasOnClose = onClose && onClose instanceof Function;

  useImperativeHandle(ref, () => ({
    updatePopper: () => {
      if (update instanceof Function) {
        update();
      }
    },
  }));

  if (!open) return null;

  return (
    <StyledPopper
      ref={setPopperElement}
      style={{ ...style, ...styles.popper }}
      variant={variant}
      zIndex={zIndex}
      {...attributes.popper}
      {...props}
    >
      <PopperArrow
        ref={setArrowElement}
        style={{ ...styles.arrow, transform: `${styles.arrow.transform} rotate(45deg)` }}
        id="arrow"
        {...attributes.arrow}
      />
      {hasOnClose && (
        <CloseIconContainer onClick={onClose}>
          <CloseIcon size="extraSmall" />
        </CloseIconContainer>
      )}
      {variant === 'small' ? (
        formattedContent || (
          <ExtTypography
            variant="paragraphLarge"
            style={{
              paddingRight: hasOnClose ? 16 : 0,
            }}
          >
            {content}
          </ExtTypography>
        )
      ) : (
        <Stack fullWidth alignItems="flex-start" spacing={16}>
          {!!Icon && <Icon width={32} height={32} />}
          <Stack fullWidth alignItems="flex-start" spacing={4} direction="column">
            <ExtTypography
              variant="paragraphBig"
              style={{
                paddingRight: 14,
              }}
            >
              {title}
            </ExtTypography>
            {formattedContent || <ExtTypography variant="paragraphLarge">{content}</ExtTypography>}
          </Stack>
        </Stack>
      )}
    </StyledPopper>
  );
});

export default Popper;
