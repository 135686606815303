import { Button, Loader, Stack, Typography } from '@karma/components';
import { CheckmarkIcon, TrashIcon } from '@karma/components/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAccountDeleteIsFetching, getAccountDeleteSuccess, getWallet } from 'store/user/selectors';
import parsePrice from 'functions/parsePrice';
import { calculateBalance } from 'functions/balance';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useAction from 'hooks/useAction';
import { deleteAccount } from 'store/user/actions';
import { Container, SuccessSign } from './ModalBodyDeleteAccount.style';

const ModalBodyDeleteAccount = ({ closeModal }) => {
  const { t } = useTranslation();
  const wallet = useSelector(getWallet);
  const { integer } = parsePrice(calculateBalance(wallet));
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const deleteAccountFunc = useAction(deleteAccount);
  const isFetching = useSelector(getAccountDeleteIsFetching);
  const deleteSuccess = useSelector(getAccountDeleteSuccess);

  if (isFetching)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (!isFetching && deleteSuccess) {
    return (
      <Container direction="column" spacing={12}>
        <CheckmarkIcon width={60} height={60} color="secondary4" />
        <SuccessSign variant="h3" style={{ maxWidth: '400px' }}>
          {t('app:accountDeletedSuccessfully')}
        </SuccessSign>
      </Container>
    );
  }

  return (
    <Container direction="column" alignItems="flex-start" spacing={12}>
      <TrashIcon width={60} height={60} color="secondary2" />
      <Typography variant="h3">{t('app:areYouSureDeleteAccount')}</Typography>
      {integer >= 5 && (
        <Typography variant="paragraphMediumLarge">
          {t('app:youHaveKarmaCash', { currency: '$', value: integer })}
        </Typography>
      )}
      <Typography variant="paragraphLarge">{t('app:deletingYourAccountWill')}</Typography>
      <Stack justifyContent="space-between" style={{ width: '100%' }} tMargin={28}>
        <Button
          type="outline"
          size="small"
          onClick={closeModal}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.CANCEL}
        >
          {t('app:cancel')}
        </Button>
        <Button
          size="small"
          onClick={() => deleteAccountFunc()}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.DELETE_MY_ACCOUNT}
        >
          {t('app:deleteMyAccount')}
        </Button>
      </Stack>
    </Container>
  );
};

export default ModalBodyDeleteAccount;
