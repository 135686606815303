import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@karma/components/Button';
import { EditIcon, PayPalIcon } from '@karma/components/icons';
import Stack from '@karma/components/Stack';
import Typography from '@karma/components/Typography';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import { ITEMS } from 'consts/localStorage';
import { QUERY_PARAMS } from 'consts/queryParams';
import ROUTES from 'consts/routes';
import { DAY_IN_MILLISECONDS } from 'consts/time';
import { checkConnectPayPalError } from 'functions/checkConnectPayPalError';
import trackGoogleTagEvent from 'functions/trackGoogleTagEvent';
import { deleteQueryParams, getQueryParams } from 'functions/urlHelpers';
import CConsole from 'functions/CConsole';
import { fetchUser } from 'store/user/actions';
import { getUserId, getWallet } from 'store/user/selectors';
import { oldApiInstance } from 'functions/request';
import useAction from 'hooks/useAction';
import { showErrorAlert } from 'store/alerts/actions';
import censorEmail from 'functions/emailCensor';
import { pushBackPaypalDisconnectModal } from 'store/modals/actions';
import { ButtonContainer, Container, Content } from './PayPalLogInButton.style';

const PayPalLogInButton = ({ renderWithoutContainer, ...rest }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const userId = useSelector(getUserId);
  const { paypal_account: payPalAccount } = useSelector(getWallet);
  const dispatchPushBackPaypalConnectModal = useAction(pushBackPaypalDisconnectModal);

  const fetchUserFunc = useAction(fetchUser);
  const showErrorAlertFunc = useAction(showErrorAlert);

  const connectWithPayPal = () => {
    checkConnectPayPalError();
    localStorage.setItem(ITEMS.connectPPClickExpires, Date.now() + DAY_IN_MILLISECONDS);

    const redirectUri = encodeURIComponent(window.location.origin + window.location.pathname);

    window.location.href = `${process.env.REACT_APP_PAYPAL_CONNECT_URL}?flowEntry=lg&client_id=${process.env.REACT_APP_PAYPAL_APP_ID}&response_type=code&scope=openid%20profile%20email%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=${redirectUri}`;
  };

  const disconnectFromPayPal = () => {
    dispatchPushBackPaypalConnectModal();
  };

  useEffect(() => {
    const { code, error } = getQueryParams([QUERY_PARAMS.code, QUERY_PARAMS.error]);

    if (code) {
      oldApiInstance
        .post(ROUTES.api.paypalConnectPath, { code })
        .then(() => {
          trackGoogleTagEvent('connectpaypal');
          window.postMessage('receivePaypalAccount', '*');
          fetchUserFunc();
          deleteQueryParams([QUERY_PARAMS.code, QUERY_PARAMS.scope]);
          localStorage.removeItem(ITEMS.connectPPClickExpires);
        })
        .catch(err => {
          const { response } = err;

          if (response.data && response.data.error) {
            const serverError = response.data.error[0];

            showErrorAlertFunc({ message: serverError.err || serverError });
          }
          CConsole.error(err);
        });
    } else if (error) {
      deleteQueryParams([QUERY_PARAMS.error, QUERY_PARAMS.errorDescription, QUERY_PARAMS.errorUri]);
      localStorage.removeItem(ITEMS.connectPPClickExpires);
    }
  }, [fetchUserFunc, showErrorAlertFunc]);

  if (userId) {
    if (payPalAccount) {
      const { email, full_name: fullName } = payPalAccount;

      return (
        <Container direction="row" spacing={24} justifyContent="flex-start" withoutContainer={renderWithoutContainer}>
          <PayPalIcon
            size="large"
            secondaryColor={renderWithoutContainer && 'neutral2'}
            style={{ padding: 10, boxSizing: 'content-box' }}
          />
          <Content alignItems="center" justifyContent="space-between">
            <Stack direction="column" spacing={6} alignItems="flex-start" style={{ overflow: 'hidden' }}>
              <Typography variant={renderWithoutContainer ? 'labelMedium' : 'h5'} noWrap>
                {fullName}
              </Typography>
              <Typography variant={renderWithoutContainer ? 'labelSmall' : 'paragraphMedium'} color="neutral2" noWrap>
                {censorEmail(email)}
              </Typography>
            </Stack>
            <Button
              {...contextDataAttrs}
              data-alooma-element={ALOOMA.ELEMENTS.LINK_TO_EDIT_PAY_PAL_PANEL}
              type="icon2"
              Icon={EditIcon}
              onClick={disconnectFromPayPal}
            />
          </Content>
        </Container>
      );
    }

    const button = (
      <Button
        {...contextDataAttrs}
        {...rest}
        data-alooma-element={ALOOMA.ELEMENTS.CONNECT_WITH_PAY_PAL}
        onClick={connectWithPayPal}
        type="outline"
        size="small"
        fullWidth
        Icon={PayPalIcon}
        iconPosition="left"
      >
        {t('app:connectWithPayPal')}
      </Button>
    );

    if (renderWithoutContainer) {
      return button;
    }

    return <ButtonContainer {...rest}>{button}</ButtonContainer>;
  }

  return null;
};

export default PayPalLogInButton;
