import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SmartphoneIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79922 23.406C4.77749 23.406 3.94922 22.5777 3.94922 21.556L3.94922 2.40601C3.94922 1.38428 4.77749 0.556007 5.79922 0.556007H18.1992C19.2209 0.556007 20.0492 1.38428 20.0492 2.40601V21.556C20.0492 22.5777 19.2209 23.406 18.1992 23.406H5.79922ZM5.64922 21.556C5.64922 21.6388 5.71638 21.706 5.79922 21.706H18.1992C18.2821 21.706 18.3492 21.6388 18.3492 21.556V2.40601C18.3492 2.32316 18.2821 2.25601 18.1992 2.25601H5.79922C5.71637 2.25601 5.64922 2.32316 5.64922 2.40601L5.64922 21.556Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 18.3H14.5V20H9.5V18.3Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SmartphoneIcon;
