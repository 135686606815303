import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyVideoHowToSave from 'components/molecules/ModalBodyVideoHowToSave/ModalBodyVideoHowToSave';
import Modal from 'components/templates/Modal/Modal';

const ModalVideoHowToSave = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.VIDEO_HOW_TO_SAVE} onClose={closeModal}>
      <ModalBodyVideoHowToSave {...props} />
    </Modal>
  );
};

ModalVideoHowToSave.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalVideoHowToSave;
