import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const StarIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 25 25"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6393 0.147034L16.3092 7.94334L24.4007 9.21207L18.5639 15.1747L19.9421 23.7342L12.6393 19.6852L5.32758 23.7392L6.74714 15.1748L0.871094 9.21315L8.9695 7.94334L12.6393 0.147034ZM12.6393 4.13867L10.1236 9.48315L4.40758 10.3794L8.56589 14.5983L7.57996 20.5465L12.6393 17.7413L17.7077 20.5515L16.7492 14.5983L20.878 10.3805L15.1551 9.48315L12.6393 4.13867Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StarIcon;
